import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { PagesEditContainer } from '../../components/pages/PageEditContainer';

const PageEdit: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.editCMS) navigate('/');
  }, [me, me?.role?.editCMS, navigate])

  return <PagesEditContainer />;
};

export default PageEdit;
