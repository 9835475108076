import { RequestSortField } from '../../graphql/generated/graphql';
import { RequestTableHeadCell, ResourceSelectionType } from './type';

export const headCells: RequestTableHeadCell[] = [
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    minWidth: 80,
    textAlign: 'left',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Request Number',
    textAlign: 'left',
    minWidth: 120,
    sortable: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Request Status',
    minWidth: 100,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'requestTime',
    numeric: false,
    disablePadding: true,
    label: 'Request Date',
    minWidth: 80,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'shippedTime',
    numeric: false,
    disablePadding: true,
    label: 'Date Shipped',
    minWidth: 80,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'organization',
    numeric: false,
    disablePadding: true,
    label: 'Destination Organization',
    minWidth: 200,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    minWidth: 80,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last Name',
    minWidth: 80,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
    minWidth: 250,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City',
    minWidth: 120,
    textAlign: 'left',
    sortable: true
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: true,
    label: 'State',
    minWidth: 40,
    textAlign: 'left',
    sortable: true,
    ml: 1,
  },
];

export const requestOrder = [
  { key: 'id', name: RequestSortField.RequestNumber },
  { key: 'city', name: RequestSortField.City },
  { key: 'status', name: RequestSortField.Status },
  { key: 'requestTime', name: RequestSortField.RequestDate },
  { key: 'shippedTime', name: RequestSortField.ShippedDate },
  { key: 'organization', name: RequestSortField.Organization },
  { key: 'firstName', name: RequestSortField.FirstName },
  { key: 'lastName', name: RequestSortField.LastName },
  { key: 'email', name: RequestSortField.Email },
  { key: 'state', name: RequestSortField.State },
]

export const defaultResourceGroup: ResourceSelectionType = {
  resource: {
    author: '',
    caseWeight: 0,
    categoryId: 0,
    createTime: 0,
    exclusiveOffer: false,
    id: 0,
    imageUrl: '',
    inventory: 0,
    isActive: false,
    isDeleted: false,
    isPublished: false,
    quantityInCase: 0,
    sku: '',
    sortingOrder: 0,
    subTitle: '',
    title: '',
    updateTime: 0,
  },
  cases: 0,
  singles: 0
}
