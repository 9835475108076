import React from 'react';
import { Box } from '@mui/material';

export interface EndBoxProps {
  children: any;
  customStyle?: any;
}

const EndBox: React.FC<EndBoxProps> = ({ children, customStyle = {} }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='flex-end' sx={customStyle}>{children}</Box>
  )
}

export default EndBox;
