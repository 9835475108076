import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import axios from 'axios';
import FormTextField from '../../../libs/ui/FormTextField';
import FormDropdownField from '../../../libs/ui/FormDropdownField';
import FormPhoneField from '../../../libs/ui/FormPhoneField';
import { StateOptions, TitleOptions } from '../../../const/type';
import { RequestShippingLocationValueType } from '../type';

const modalBoxStyle = {
  width: '80vw',
  backgroundColor: '#fff',
  borderRadius: 2,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const apikey = 'AIzaSyCN2qMAkaOgR9yTXqkcAz7E0JD3LBDjbcw';

interface ShippingLocationFormProps {
  control: Control<any>;
  watch: UseFormWatch<any>;
  setValue?: UseFormSetValue<any>;
  setShippingLocationValue?: (data: RequestShippingLocationValueType) => void;
  getValues?: UseFormGetValues<any>;
  userData?: any;
}

const RequestShippingLocationForm = ({ control, watch, setValue, getValues, setShippingLocationValue, userData }: ShippingLocationFormProps) => {
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [center, setCenter] = useState({ lat: 44.0439615, lng: -121.314933 });

  const address1Input = watch('address1', '');
  const cityInput = watch('city', '');
  const stateInput = watch('state', '');

  useEffect(() => {
    const getGeocode = async () => {
      try {
        const address = `${address1Input}, ${cityInput}, ${stateInput}, USA`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apikey}`
        const response = await axios.get(url);
        const { results } = response.data;
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat, lng });
        }
      } catch (e) {
      }
    }

    getGeocode().then();
  }, [address1Input, cityInput, stateInput])


  const handleOnChange = () => {
    if (setShippingLocationValue && getValues) {
      setShippingLocationValue({
        title: getValues('title'),
        organization: getValues('organization'),
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        phone: getValues('phone'),
        email: getValues('email'),
        address1: getValues('address1'),
        address2: getValues('address2'),
        city: getValues('city'),
        state: getValues('state'),
        zipCode: getValues('zipCode'),
        isLoadingDock: getValues('isLoadingDock'),
        additionalContactInfo: getValues('additionalContactInfo'),
        resourceUse: getValues('resourceUse'),
      });
    }
  }

  return (
    <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
      <Box display='flex'>
        <Typography fontWeight={600}>Commercial Shipping Address</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='organization'
                label='Destination Organization'
                labelComponent={<Typography minWidth={130} fontSize={16}>Destination Organization</Typography>}
                size='small'
                maxlength={35}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='title'
                label='Title'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Title</Typography>}
                size='small'
                options={[{ label: '-- Select --', value: null }, ...TitleOptions]}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
             <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='firstName'
                label='First Name'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>First Name</Typography>}
                size='small'
                maxlength={50}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='lastName'
                label='Last Name'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Last Name</Typography>}
                size='small'
                maxlength={50}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormPhoneField
                name='phone'
                label='Phone Number'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Phone Number</Typography>}
                size='small'
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='email'
                label='Email'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Email</Typography>}
                size='small'
                type='email'
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='address1'
                label='Address 1'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Address 1</Typography>}
                size='small'
                maxlength={35}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='address2'
                label='Address 2'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Address 2</Typography>}
                size='small'
                maxlength={35}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormTextField
                name='city'
                label='City'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>City</Typography>}
                size='small'
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='state'
                label='State'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>State</Typography>}
                size='small'
                options={[
                  { label: '-- Select --', value: ' ' },
                  ...StateOptions
                ]}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
             <Grid item xs={12} md={3} lg={2} pr={3} pl={1}>
              <FormTextField
                name='zipCode'
                label='Zip Code'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Zip Code</Typography>}
                size='small'
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2} pr={3} pl={1} display='flex' alignItems='center' justifyContent='center'>
              <Button variant='outlined' onClick={() => setMapModalVisible(true)}>View Map</Button>
            </Grid>
            <Grid item xs={12} md={6} lg={2} pr={3} pl={1}>
              <FormDropdownField
                name='isLoadingDock'
                label='isLoadingDock'
                labelComponent={<Typography width={130} minWidth={130} fontSize={16} lineHeight='40px'>Loading Dock</Typography>}
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} pr={3} pl={1} mb={1}>
              <FormTextField
                name='additionalContactInfo'
                label='Alternate Contact Name & Phone'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Alternate Contact Name & Phone</Typography>}
                size='small'
                maxlength={100}
                control={control}
                rows={2}
                inputProps={{ sx: { backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
            <Grid item xs={6} pr={3} pl={1}>
              <FormTextField
                name='resourceUse'
                label='How do you plan to use these resources'
                labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>How do you plan to use these resources</Typography>}
                size='small'
                maxlength={200}
                control={control}
                rows={2}
                inputProps={{ sx: { backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={mapModalVisible} onClose={() => setMapModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box width='50%' height={800}>
            <LoadScript googleMapsApiKey={apikey}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14} />
            </LoadScript>
          </Box>
          <Box width='50%' height={800}>
            <LoadScript googleMapsApiKey={apikey}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
                <StreetViewPanorama options={{ position: center, pov: { heading: 0, pitch: 0 }, visible: true }} />
              </GoogleMap>
            </LoadScript>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RequestShippingLocationForm;
