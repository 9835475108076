import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { RequestEditContainer } from '../../components/requests/RequestEditContainer';

const RequestEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.editRequest) navigate('/');
  }, [me, me?.role?.editRequest, navigate])

  return <RequestEditContainer />;
};

export default RequestEditPage;
