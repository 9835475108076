import React from 'react';
import { Box, BoxProps, Stack, TextField, Typography } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Control, Controller } from 'react-hook-form';

export interface FormPhoneProps {
  name: string;
  control: Control<any>; // eslint-disable-line
  label: string;
  inputProps?: Partial<InputProps>;
  size?: 'small' | 'medium';
  rows?: number | undefined;
  placeholder?: string | undefined;
  disabled?: boolean;
  labelComponent?: React.ReactElement;
  containerProps?: BoxProps;
  onChangeInput?: (value: any) => void;
}

const FormPhoneField = ({
  name,
  control,
  label,
  inputProps,
  size = 'medium',
  labelComponent,
  disabled = false,
  containerProps,
  onChangeInput,
  placeholder,
}: FormPhoneProps) => {
  return (
    <Box sx={containerProps?.sx}>
      {labelComponent}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Stack width='100%'>
              <ReactPhoneInput
                defaultErrorMessage={error ? error.message : undefined}
                value={value}
                label={labelComponent ? '' : label}
                onChange={(e) => {
                  onChange(e);
                  if (onChangeInput) onChangeInput(e);
                }}
                disabled={disabled}
                placeholder={placeholder || '(702) 123-1234'}
                country={'us'}
                onlyCountries={['us']}
                disableCountryCode={true}
                component={TextField}
                inputProps={{ ...inputProps, size: size }}
                inputStyle={{ background: 'transparent' }}
              />
              {error && <Typography margin='4px 14px 0' sx={{ width: 'calc(100% - 4px)'}} fontSize={18} color='#d32f2f'>{error.message}</Typography>}
            </Stack>
          );
        }}
      />
    </Box>
  );
};

export default FormPhoneField;
