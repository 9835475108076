import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import FormRadioField from '../../libs/ui/FormRadioField';
import FormDateField from '../../libs/ui/FormDateField';
import { Spacer } from '../../libs/ui/Spacer';
import { SignUpDocument } from '../../graphql/generated/graphql';
import { MinistryCategories, StateOptions, TitleOptions } from '../../const/type';

const UserNewContainer = () => {
  const navigate = useNavigate();

  const [agreeTerm, setAgreeTerm] = useState(false);

  useEffect(() => {
    document.title = 'Applications';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement'; };
  }, [])

  const [signupMutation] = useMutation(SignUpDocument, {});

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required').min(10, 'Must be 10 digits'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    redFlag: Yup.bool(),
    yellowFlag: Yup.bool(),
    organization: Yup.string(),
    title: Yup.string(),
    shippingFirstName: Yup.string(),
    shippingLastName: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string().matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits'),
    shippingPhone: Yup.string().min(10, 'Must be 10 digits'),
    shippingEmail: Yup.string().email(),
    loadingDock: Yup.bool(),
    is501c3: Yup.bool(),
    contactInfo: Yup.string(),
    ministryCategory: Yup.string(),
    orgName: Yup.string(),
    isManagedByOrg: Yup.string(),
    differentOrgName: Yup.string(),
    overseeMinistry: Yup.string(),
    affiliation: Yup.string(),
    ministryIs501c3: Yup.string(),
    employerId: Yup.string(),
    hasWebsite: Yup.string(),
    ministryDetails: Yup.string(),
    requestForEvent: Yup.string(),
    eventDate: Yup.date(),
    purpose: Yup.string(),
    howDistributed: Yup.string(),
    expectToServe: Yup.string(),
    costToAttendee: Yup.string(),
    heldToDistribute: Yup.string(),
    publicNotice: Yup.string(),
    howManyPeople: Yup.string(),
    appliedBefore: Yup.string(),
    approximateTime: Yup.string(),
    howHearUs: Yup.string(),
    agreeTerm: Yup.bool(),
  });

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      redFlag: false,
      yellowFlag: false,
      organization: '',
      title: 'None',
      shippingFirstName: '',
      shippingLastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: undefined,
      shippingPhone: undefined,
      shippingEmail: undefined,
      loadingDock: false,
      is501c3: false,
      contactInfo: '',
      ministryCategory: '',
      orgName: '',
      isManagedByOrg: 'No',
      differentOrgName: '',
      overseeMinistry: '',
      affiliation: '',
      ministryIs501c3: 'No',
      employerId: '',
      hasWebsite: '',
      ministryDetails: '',
      requestForEvent: 'No',
      eventDate: new Date(),
      purpose: '',
      howDistributed: '',
      expectToServe: '',
      costToAttendee: '',
      heldToDistribute: '',
      publicNotice: '',
      howManyPeople: '',
      appliedBefore: 'No',
      approximateTime: '',
      howHearUs: '',
      agreeTerm: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, getValues, watch } = methods;

  const onSubmit = async () => {
    let variables = {
      user: {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        phone: getValues('phone'),
        email: getValues('email'),
        password: getValues('password'),
        redFlag: getValues('redFlag'),
        yellowFlag: getValues('yellowFlag'),
      },
    };
    variables = { ...variables, ...{
      shippingLocation: {
        organization: getValues('organization'),
        title: getValues('title'),
        firstName: getValues('shippingFirstName'),
        lastName: getValues('shippingLastName'),
        address1: getValues('address1'),
        address2: getValues('address2'),
        city: getValues('city'),
        state: getValues('state'),
        zipCode: getValues('zipCode'),
      },
      shippingDetail: {
        phone: getValues('shippingPhone'),
        email: getValues('shippingEmail'),
        loadingDock: getValues('loadingDock'),
        is501c3: getValues('is501c3'),
        contactInfo: getValues('contactInfo'),
      }
    },
  }
    if (agreeTerm) {
      let organizationInfoInput: any = {
        ministryCategory: getValues('ministryCategory'),
        orgName: getValues('orgName'),
        isManagedByOrg: getValues('isManagedByOrg') === 'Yes',
        affiliation: getValues('affiliation'),
        is501c3: getValues('ministryIs501c3') === 'Yes',
        hasWebsite: getValues('hasWebsite'),
        ministryDetails: getValues('ministryDetails'),
      };
      let resourceDistributionInput: any = {
        requestForEvent: getValues('requestForEvent') === 'Yes',
        purpose: getValues('purpose'),
        howDistributed: getValues('howDistributed'),
      }
      let userServeInput: any = {
        appliedBefore: getValues('appliedBefore') === 'Yes',
        howHearUs: getValues('howHearUs'),
        agreeTerm: getValues('agreeTerm'),
      }
      if (getValues('isManagedByOrg') === 'Yes') {
        organizationInfoInput = { ...organizationInfoInput, differentOrgName: getValues('differentOrgName') };
      } else {
        organizationInfoInput = { ...organizationInfoInput, overseeMinistry: getValues('overseeMinistry') };
      }
      if (getValues('ministryIs501c3') === 'Yes') {
        organizationInfoInput = { ...organizationInfoInput, employerId: getValues('employerId') };
      }

      if (getValues('requestForEvent') === 'Yes') {
        resourceDistributionInput = {
          ...resourceDistributionInput,
          eventDate: getValues('eventDate'),
          expectToServe: getValues('expectToServe'),
          costToAttendee: getValues('costToAttendee'),
          heldToDistribute: getValues('heldToDistribute'),
          publicNotice: getValues('publicNotice'),
        };
      } else {
        resourceDistributionInput = {
          ...resourceDistributionInput,
          howManyPeople: getValues('howManyPeople'),
        };
      }
      if (getValues('appliedBefore') === 'Yes') {
        userServeInput = { ...userServeInput, approximateTime: getValues('approximateTime') };
      }
      variables = {
        ...variables,
        ...{
          organizationInfoInput,
          resourceDistributionInput,
          userServeInput,
        }
      };
    }

    const { data } = await signupMutation({
      mutation: SignUpDocument,
      variables: { data: variables }
    });
    if (data?.SignUp.status) navigate(-1);
  };

  return (
    <Box px={4} py={2}>
      <Typography variant='h4'>Add Applicant</Typography>
      <Grid container>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='firstName'
            label='First Name'
            size='small'
            placeholder='First'
            labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='lastName'
            label='Last Name'
            size='small'
            placeholder='Last'
            labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormPhoneField
            name='phone'
            label='Phone'
            size='small'
            labelComponent={<Typography fontWeight={600}>Phone <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='email'
            label='Email'
            size='small'
            type='email'
            rows={1}
            labelComponent={<Typography fontWeight={600}>Email <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='password'
            label='Password'
            type='password'
            size='small'
            rows={1}
            labelComponent={<Typography fontWeight={600}>Password <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1} />
        <Grid item xs={6} p={1}>
          <FormCheckBoxField name='redFlag' label='Red Flag' size='small' control={control} />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormCheckBoxField name='yellowFlag' label='Yellow Flag' size='small' control={control} />
        </Grid>
      </Grid>
      <Typography mt={2} fontSize='1.25rem' fontWeight={600}>
        Commercial Shipping Address. We are required to ship resources to the commercial address and physical location of the organization which will distribute the resources. We do not ship to personal residences. <span style={{ fontWeight: 400, color: '#f00' }}>*</span>
      </Typography>
      <Typography variant='h4'>Shipping Location</Typography>
      <Grid container>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='organization'
            label='Organization'
            size='small'
            labelComponent={<Typography fontWeight={600}>Organization <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormDropdownField
            name='title'
            label='Title'
            size='small'
            options={[{ label: '-- Select --', value: null }, ...TitleOptions]}
            labelComponent={<Typography fontWeight={600}>Title</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='shippingFirstName'
            label='First Name'
            size='small'
            labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='shippingLastName'
            label='Last Name'
            size='small'
            labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='address1'
            label='Address 1'
            size='small'
            labelComponent={<Typography fontWeight={600}>Address 1 <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='address2'
            label='Address 2'
            size='small'
            labelComponent={<Typography fontWeight={600}>Address 2</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='city'
            label='City'
            size='small'
            labelComponent={<Typography fontWeight={600}>City <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormDropdownField
            name='state'
            label='State'
            size='small'
            options={StateOptions}
            labelComponent={<Typography fontWeight={600}>State <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='zipCode'
            label='Zip Code'
            size='small'
            labelComponent={<Typography fontWeight={600}>Zip Code <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1} />
        <Grid item xs={6} p={1}>
          <FormPhoneField
            name='shippingPhone'
            label='Phone Number'
            size='small'
            labelComponent={<Typography fontWeight={600}>Phone Number <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='shippingEmail'
            label='Email Address'
            size='small'
            type='email'
            rows={1}
            labelComponent={<Typography fontWeight={600}>Email Address <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormDropdownField
            name='loadingDock'
            label='Is there a loading dock at this location?'
            size='small'
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            labelComponent={<Typography fontWeight={600}>Is there a loading dock at this location?</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormDropdownField
            name='is501c3'
            label='501c3'
            size='small'
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            labelComponent={<Typography fontWeight={600}>501c3</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='contactInfo'
            label='Alternate Contact Information'
            size='small'
            labelComponent={<Typography fontWeight={600}>Alternate Contact Information</Typography>}
            control={control}
          />
        </Grid>
      </Grid>
      <Typography mt={2} fontSize='1.25rem' fontWeight={600}>
        I understand that this application IS NOT A GUARANTEE for providing resources.   The 1687 Foundation reserves the right to accept or deny any application. <span style={{ fontWeight: 400, color: '#f00' }}>*</span>
      </Typography>
      <Box display='flex'>
        <Checkbox checked={agreeTerm} onChange={event => setAgreeTerm(event.target.checked)} />
        <Typography mt={1.5} fontSize='1.25rem' lineHeight={1}>
          I have read and agree with these terms.
        </Typography>
      </Box>
      {agreeTerm && <>
        <Box mt={2}>
          <Typography variant='h4'>Ministry & Organization Information</Typography>
          <Spacer height={0} />
          <FormRadioField
            name='ministryCategory'
            size='small'
            options={MinistryCategories}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Ministry Category<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <FormTextField
            name='orgName'
            label='Name of Ministry or Organization'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Name of Ministry or Organization<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <FormRadioField
            name='isManagedByOrg'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry managed by or overseen by another established ministry/organization or church<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('isManagedByOrg') === 'Yes' &&
            <FormTextField
              name='differentOrgName'
              label='Please provide the name of the overseeing ministry/organization, if different than above.'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please provide the name of the overseeing ministry/organization, if different than above. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          {watch('isManagedByOrg') === 'No' &&
            <FormTextField
              name='overseeMinistry'
              label='Who does oversee this ministry?'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Who does oversee this ministry? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          <FormTextField
            name='affiliation'
            label='What is your affiliation with this ministry/organization?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is your affiliation with this ministry/organization?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <FormRadioField
            name='ministryIs501c3'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry/organization a 501c3?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('ministryIs501c3') === 'Yes' &&
            <FormTextField
              name='employerId'
              label='Enter the organization Employer ID Number (EIN):'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Enter the organization Employer ID Number (EIN): <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          <FormTextField
            name='hasWebsite'
            label='Does the ministry have a website or presence online?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Does the ministry have a website or presence online?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <FormTextField
            name='ministryDetails'
            label='Describe this ministry in detail.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe this ministry in detail.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={5}
          />
        </Box>
        <Box mt={2}>
          <Typography variant='h4'>Resource Distribution</Typography>
          <Spacer height={0} />
          <FormRadioField
            name='requestForEvent'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Are you requesting for an event with a specific date?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('requestForEvent') === 'Yes' &&
            <FormDateField
              name='eventDate'
              label=''
              size='small'
              type='datetime-local'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please select the date for the event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              rows={1}
              containerProps={{ sx: { mt: 1 } }}
            />
          }
          <FormTextField
            name='purpose'
            label='Describe the purpose and goals for distributing the resources.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe the purpose and goals for distributing the resources.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={5}
          />
          <FormTextField
            name='howDistributed'
            label='How will the resources be distributed? '
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How will the resources be distributed? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={5}
          />
          {watch('requestForEvent') === 'Yes' && <>
            <FormTextField
              name='expectToServe'
              label='How many do you expect to serve at this event?'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many do you expect to serve at this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='costToAttendee'
              label='What is the cost to attendees for this event?'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is the cost to attendees for this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='heldToDistribute'
              label='Where will the event be held to distribute these resources?'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Where will the event be held to distribute these resources? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='publicNotice'
              label='Describe any advertising or public notices for this event.'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe any advertising or public notices for this event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </>}
          {watch('requestForEvent') === 'No' &&
            <FormTextField
              name='howManyPeople'
              label='How many people will be served over the next three months?'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many people will be served over the next three months? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
        </Box>
        <Box mt={2}>
          <Typography variant='h4'>Help Us Serve You Better</Typography>
          <Spacer height={0} />
          <FormRadioField
            name='appliedBefore'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Have you previously applied for resources from The 1687 Foundation?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('appliedBefore') === 'Yes' &&
            <FormTextField
              name='approximateTime'
              label='Please tell us the approximate time frame, email and name used previously.'
              size='small'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please tell us the approximate time frame, email and name used previously. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          <FormTextField
            name='howHearUs'
            label='How did you hear about us?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How did you hear about us?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <Typography mt={1} fontSize={18} fontWeight={600}>By submitting this request for publications, I acknowledge that THIS IS NOT A GUARANTEE for providing publications. The 1687 Foundation reserves the right to accept or deny any request. I have read and understood these terms and the above guidelines. By clicking SUBMIT, I agree to comply with the terms and conditions of this offer. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>
          <FormCheckBoxField name='agreeTerm' label='I understand and agree to these terms and guidelines.' control={control} />
        </Box>
      </>}
      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='outlined' sx={{ width: 200 }} onClick={handleSubmit(onSubmit)}>Save</Button>
      </Box>
    </Box>
  )
}

export default UserNewContainer;
