import React, { useMemo } from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { useMinistryByIdLazyQuery, useTestimonialLazyQuery } from '../../graphql/generated/graphql';
import { useLocation, useNavigate } from 'react-router-dom';

export default function BasicBreadcrumbs() {
  const navigate = useNavigate();
  const location = useLocation();
  const [getMinistry, { data: ministryData }] = useMinistryByIdLazyQuery();
  const [getTestimonial, { data: testimonialData }] = useTestimonialLazyQuery();
  const currentPath = location.pathname;

  const paths = useMemo(() => {
    const pathList = currentPath.split('/');
    let newPaths = [];
    let newPath = '';
    for (const p of pathList) {
      let name = p;
      if (newPath === '/app/ministry/') {
        getMinistry({
          variables: {
            ministryId: Number(p) // value for 'ministryId'
          },
        });
        name = ministryData?.Ministry.data?.name ? ministryData?.Ministry.data?.name.toLowerCase() : p;
      }

      if (newPath === '/app/testimonials/') {
        getTestimonial({
          variables: {
            testimonialId: Number(p) // value for 'testimonialId'
          },
        });
        if (testimonialData?.Testimonial.data?.ministryId) {
          const ministryId = testimonialData?.Testimonial.data?.ministryId
          getMinistry({
            variables: {
              ministryId: Number(ministryId) // value for 'ministryId'
            },
          });
          name = ministryData?.Ministry.data?.name ? ministryData?.Ministry.data?.name.toLowerCase() : p;
        }
      }

      newPath = newPath + `${p}/`;
      newPaths.push({ name: name, path: newPath })

    }
    return newPaths
  }, [currentPath, getMinistry, ministryData, getTestimonial, testimonialData]);

  return (
    <div role='presentation'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pb: 2.5 }}>
        {paths.map((path) => (
          <Link key={path.name} color='inherit' underline='hover' sx={{ cursor: 'pointer' }} onClick={() => navigate(path.path)}>
            {path.name}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
