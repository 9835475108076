import * as React from 'react';
import { Box, BoxProps, Stack, TextFieldProps, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Control, Controller } from 'react-hook-form';
import { addWeeks } from 'date-fns';

export type FormDateFieldProps = TextFieldProps & {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  labelComponent?: React.ReactElement;
  label: string;
  size?: 'small' | 'medium';
  containerProps?: BoxProps;
  onChangeInput?: (value: any) => void;
};

/**
 * Describe your component here
 */
const FormDateValidate: React.FC<FormDateFieldProps> = (props) => {
  const {
    name,
    control,
    label,
    labelComponent,
    containerProps,
    onChangeInput,
    size = 'small',
    defaultValue = '',
  } = props;

  const isDateWithinRange = (date: Date | null) => {
    if (!date) return false;
    const today = new Date();
    const threeWeeksLater = addWeeks(today, 3);
    const sixWeeksLater = addWeeks(today, 6);
    return date >= threeWeeksLater && date <= sixWeeksLater;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={containerProps?.sx}>
        {labelComponent}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Stack width='100%' bgcolor='#fff'>
                <DatePicker
                  slotProps={{ textField: { label, size } }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (onChangeInput) onChangeInput(e);
                  }}
                  onAccept={onChange}
                  shouldDisableDate={date => !isDateWithinRange(date)}
                />
              </Stack>
              {error && <Typography sx={{ margin: '4px 14px 0', fontSize: '12px', fontWeight: 400, color: '#d32f2f' }}>{error.message}</Typography>}
            </>
          )}
          defaultValue={defaultValue}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default FormDateValidate;
