import { IPost } from "../../graphql/generated/graphql";
import { PostsTableHeadCell } from "./type";

export const headCells: PostsTableHeadCell[] = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "title",
    minWidth: 300,
    textAlign: "left",
    sortable: true
  },
  {
    id: "publishedTime",
    numeric: false,
    disablePadding: true,
    label: "Date",
    minWidth: 140,
    textAlign: "left",
    sortable: true
  },
  {
    id: "isEnabled",
    numeric: false,
    disablePadding: true,
    label: "Enabled",
    minWidth: 6,
    textAlign: "left",
    sortable: true
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    minWidth: '80px',
    width: '80px',
    maxWidth: '80px',
    textAlign: "center",
  },
];

export enum PostsSortField {
  Title = 'Title',
  Date_Published = 'Date_Published',
  Enabled = 'Enabled'
}

export const postsOrder = [
  {
    key: 'title', name: PostsSortField.Title
  }, {
    key: 'publishedTime', name: PostsSortField.Date_Published
  }, {
    key: 'isEnabled', name: PostsSortField.Enabled
  }
]

export type Order = "asc" | "desc";

export const mockPosts: Partial<IPost>[] = [
  {id: 1, title: 'Book Ministry - The Story', publishedTime: new Date('06/28/2023'), isEnabled: true},
  {id: 2, title: 'Book Ministry - The Gift-Reflecting on God\'s Grace', publishedTime: new Date('07/05/2022'), isEnabled: true},
  {id: 3, title: 'Book Ministry - Hope for the Future', publishedTime: new Date('03/16/2022'), isEnabled: true},
  {id: 4, title: 'Book Ministry - A joyful Christmas Blessing to Everyone', publishedTime: new Date('12/02/2021'), isEnabled: true},
  {id: 5, title: 'Book Ministry - With Heavy Hearts', publishedTime: new Date('10/26/2021'), isEnabled: true},
  {id: 6, title: 'Book Ministry - Hope and Encouragement Update Summer 2021', publishedTime: new Date('07/07/2021'), isEnabled: true},
  {id: 7, title: 'Book Ministry - Welcome back to The 1687 Foundation', publishedTime: new Date('10/30/2017'), isEnabled: true},
]