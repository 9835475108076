import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SlideLeft from '../../assets/images/slides/slide-left.jpg';
import SlideOne from '../../assets/images/slides/slide-one.jpg';

export interface PageSlideProps {
  images: string[];
}

const PageSlide: React.FC<PageSlideProps> = ({ images = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 500);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    images.length > 0 ?
      images.length > 1 ?
        <Grid item xs={12} container>
          <Grid item xs={7}>
            <img src={SlideLeft} alt='' style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={5}>
            <img src={images[currentImageIndex]} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover', opacity: fade ? 1 : 0.5, transition: 'opacity 0.5s linear' }} />
          </Grid>
        </Grid>
        :
        <Grid item xs={12} container>
          <Grid item xs={7}>
            <img src={SlideLeft} alt='' style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={5}>
            <img src={images[currentImageIndex]} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Grid>
        </Grid>
      :
      <Grid item xs={12} container>
        <img src={SlideOne} alt='' style={{ width: '100%' }} />
      </Grid>
  )
}

export default PageSlide;
