import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import { UpdateUserAllInfoDocument, useUserRolesQuery } from '../../graphql/generated/graphql';

interface AdminUserEditContainerProps {
  user: any
}

const AdminUserEditContainer = ({ user }: AdminUserEditContainerProps) => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    document.title = 'Information Form';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement'; };
  }, [])

  const { data } = useUserRolesQuery({
    variables: { page: 1, pageSize: 100 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.UserRoles.items) {
      const items = data?.UserRoles.items.map(item => {
        return { label: item.name, value: item.id }
      })
      setRoles(items);
    }
  }, [data]);

  const [updateUserAllInfo] = useMutation(UpdateUserAllInfoDocument);

  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required').min(10, 'Must be 10 digits'),
    email: Yup.string().required('Email is required').email(),
    roleId: Yup.string().required('Role is required'),
  });

  const profileMethods = useForm({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phone: user.phone || '',
      email: user.email || '',
      roleId: user.roleId || '',
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const { handleSubmit, control, getValues } = profileMethods;

  const updateProfile = async () => {
    const updated = {
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      phone: getValues('phone'),
      email: getValues('email'),
      roleId: getValues('roleId'),
      id: user.id,
    }

    const { data } = await updateUserAllInfo(
      {
        mutation: UpdateUserAllInfoDocument,
        variables: {
          exclusionGroup: 0,
          user: { user: updated }
        },
      }
    );
    if (data?.UpdateUserAllInfo.status) navigate(-1);
  }

  return (
    <Box px={4}>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5'>Edit Admin Profile</Typography>
        </Box>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='firstName'
              label='First Name'
              size='small'
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='lastName'
              label='Last Name'
              size='small'
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='phone'
              label='Phone'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='email'
              label='Email'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='roleId'
              label='Role'
              size='small'
              options={roles}
              labelComponent={<Typography fontWeight={600}>Role</Typography>}
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
      
      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='outlined' sx={{ width: 200 }} onClick={handleSubmit(updateProfile)}>Update</Button>
      </Box>
    </Box>
  )
}

export default AdminUserEditContainer;
