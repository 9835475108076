import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import AdminUserNewContainer from '../../components/users/AdminUserNewContainer';

const AdminUserNewPage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changeUsersProfile) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changeUsersProfile, navigate])

  return (
    <Box>
      <AdminUserNewContainer />
    </Box>
  )
}

export default AdminUserNewPage;
