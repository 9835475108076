import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import TestimonialEditContainer from '../../components/testimonials/TestimonialEditContainer';
import { useTestimonialQuery } from '../../graphql/generated/graphql';

const TestimonialEditPage = () => {
  const { testimonialId } = useParams();

  const { data } = useTestimonialQuery({
    variables: { testimonialId: Number(testimonialId) },
    fetchPolicy: 'network-only',
  })

  return (
    <Box>
      {data?.Testimonial.data && <TestimonialEditContainer testimonial={data?.Testimonial.data} />}
    </Box>
  )
}

export default TestimonialEditPage;
