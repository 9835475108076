import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cookie from 'cookie';
import { onError } from '@apollo/link-error';
import { showError } from '../libs/utils/toasts';

export const COOKIES_TOKEN_NAME = 'token';
const API_URL = process.env.REACT_APP_BASE_URL;
// const API_URL = 'http://localhost:5000';
// const API_URL = 'https://4yv3dq59lb.execute-api.us-west-2.amazonaws.com/prod';

export const getToken = () => {
  const parsedCookie = cookie.parse(document.cookie);
  return parsedCookie[COOKIES_TOKEN_NAME];
};
const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get token from cookie
  const token = getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      showError(`Error: ${message}`);
    });
  }

  if (networkError) {
    showError(`[Network error]: ${networkError.message}`);
  }
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([omitTypenameLink, authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

export default apolloClient;