import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormDateField from '../../libs/ui/FormDateField';
import FormEditorField from '../../libs/ui/FormEditorField';
import FormSwitch from '../../libs/ui/FormSwitch';
import { showSuccess } from '../../libs/utils/toasts';
import { PostsDocument, useCreatePostMutation } from '../../graphql/generated/graphql';

export const PostsCreateContainer = () => {
  const navigate = useNavigate();
  const { ministryId } = useParams();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    url: Yup.string().required('Url is required'),
    publishedTime: Yup.date().required('Date Published is required'),
    isEnabled: Yup.boolean(),
    content: Yup.string().required('This field is required'),
  });

  const methods = useForm({
    defaultValues: {
      title: '',
      url: '',
      publishedTime: new Date(),
      isEnabled: false,
      content: '<p>test</p>',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [createPost] = useCreatePostMutation({
    refetchQueries: [
      {
        query: PostsDocument,
        variables: {
          page: 1,
          pageSize: 25,
          ministryId: ministryId!
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const onSubmit = async (_data: FieldValue<any>) => {
    const {data} = await createPost({
      variables: { post: { ..._data, ministryId } },
    });
    if (data?.CreatePost.status) {
      showSuccess('Post was created successfully.');
      setTimeout(() => navigate(-1), 3000)
    }
  };

  return (
    <>
      <Box border='solid 1px lightgray' p={2}>
        <Stack
          direction='column'
          justifyContent='center'
          spacing={1}
          sx={{ width: 'fit-content', background: 'white', mt: '-28px', padding: '0px 8px' }}>
          <Typography variant='body1' fontWeight={600}>Add new Post</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} pr={1} pl={1}>
            <FormTextField
              name='title'
              label='Title *'
              size='small'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Title *</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
          <Grid item xs={12} pr={1} pl={1}>
            <FormTextField
              name='url'
              label='Clean Url *'
              size='small'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Clean Url *</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
            <Typography sx={{ marginTop: '4px', fontSize: 14, lineHeight: '20px' }}>This will be the url token used to refer to this record. It must be unique and must include only letters, numbers, en dash or periods.</Typography>
          </Grid>
          <Grid item xs={12} pr={1} pl={1}>
            <FormDateField
              name='publishedTime'
              label='Date Published *'
              size='small'
              type='datetime-local'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Date Published *</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
            <FormSwitch
              name='isEnabled'
              label='Enabled'
              labelComponent={<Typography sx={{ width: '80px', fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Enabled</Typography>}
              control={control}
              containerProps={{ sx: { mt: 1.5, display: 'flex' } }}
            />
          </Grid>
          <Grid item xs={12} p={1}>
            <FormEditorField
              name='content'
              label='content *'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Content *</Typography>}
              defaultValue=''
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
        </Grid>
        <Box mt={2} mx={5} display='flex' justifyContent='flex-end'>
          <Button variant='contained' onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
      </Box>
    </>
  );
}
