import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { useAuth } from '../../contexts/auth';
import { EnhancedTableHead } from './EnhancedTableHead';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import CircularLoading from '../../libs/ui/Loading';
import {
  CategoriesDocument,
  CreateResourceCategoryDocument,
  CreateResourceCategoryMutation,
  DeleteResourceCategoryDocument,
  DeleteResourceCategoryMutation,
  IResourceCategory,
  UpdateResourceCategoryDocument,
  UpdateResourceCategoryMutation,
  useCategoriesQuery,
} from '../../graphql/generated/graphql';
import { HeadCell, Order } from './type';

const deleteModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Title',
    sortable: false,
    textAlign: 'left',
    ml: 2
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description',
    textAlign: 'left',
    sortable: false,
    ml: 2
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: true,
    label: 'Active',
    minWidth: 80,
    textAlign: 'center',
    sortable: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 80,
    textAlign: 'center',
    sortable: false,
  },
];

export function CategoryContainer() {
  const { me } = useAuth();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IResourceCategory>('title');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rows, setRows] = useState<IResourceCategory[]>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);

  const [onAction, setOnAction] = useState<number | undefined>(undefined);

  const [tempTitle, setTempTitle] = useState<string>('');

  const [tempDescription, setTempDescription] = useState('');
  const [tempActive, setTempActive] = useState<boolean>(false);

  const memoHeadCells = useMemo(() => {
    if (me?.isSuperuser || me?.role?.editCategory) {
      return headCells;
    } else {
      return  headCells.filter(item => item.id !== 'actions')
    }
  }, [me?.isSuperuser, me?.role?.editCategory])

  const [createCategoryQuery] = useMutation(CreateResourceCategoryDocument, {
    refetchQueries: [
      {
        query: CategoriesDocument,
        variables: {
          page: page + 1,
          pageSize: rowsPerPage,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [updateCategoryQuery] = useMutation(UpdateResourceCategoryDocument, {
    refetchQueries: [
      {
        query: CategoriesDocument,
        variables: {
          page: page + 1,
          pageSize: rowsPerPage,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [deleteCategoryMutation] = useMutation(DeleteResourceCategoryDocument, {
    refetchQueries: [
      {
        query: CategoriesDocument,
        variables: {
          page: page + 1,
          pageSize: rowsPerPage,
        },
        fetchPolicy: 'network-only',
      },
    ]
  });

  const [totalCount, setTotalCount] = useState(0);

  const { data, loading } = useCategoriesQuery({
    variables: {
       page: page + 1,
       pageSize: rowsPerPage
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.Categories.items) {
      setRows(_rows => {
        data.Categories.items.forEach((item: IResourceCategory) => {
          const _exist = _rows.find((row) => row.id === item.id);
          if (!_exist) {
            _rows.push(item);
          }
          if (_exist && _exist !== item) {
            _rows[_rows.indexOf(_exist)] = item;
          }
        });
        return [..._rows]
      })
    }
    if (data?.Categories) setTotalCount(data.Categories.total);
  }, [data]);

  const handleRequestSort = (property: keyof IResourceCategory) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const isSelected = (name: number) => {
    return selected.indexOf(name) !== -1;
  };

  const handleDelete = async() => {
    if (onAction) {
      const {data}: FetchResult<DeleteResourceCategoryMutation> = await deleteCategoryMutation({
        variables: { categoryId: onAction }
      });

      if (data?.DeleteCategory.status) {
        setRows(_rows => {
          return _rows.filter(item => item.id !== onAction)
        })
      }
      
      setOnAction(undefined);
    } else {
      setSelected([]);
    }
    setOpenDeleteModal(false);
  };

  const createResourceCategory = async () => {
    const { data }: FetchResult<CreateResourceCategoryMutation> =
      await createCategoryQuery({
        variables: {
          category: {
            description: tempDescription,
            title: tempTitle,
            isActive: tempActive,
          },
        },
      });
    if (data?.CreateResourceCategory.data) {
      const _rows = rows;
      _rows.push(data.CreateResourceCategory.data);
      setRows([..._rows]);
      setTotalCount(totalCount + 1);

      setTempTitle('');
      setTempActive(false);
      setTempDescription('');
      setOpenAddModal(false);
    }
  };

  const updateResourceCategory = async () => {
    const { data }: FetchResult<UpdateResourceCategoryMutation> =
      await updateCategoryQuery({
        variables: {
          updateResourceCategory: {
            description: tempDescription,
            title: tempTitle,
            isActive: tempActive,
            id: onAction,
          },
        },
      });
    if (data?.UpdateResourceCategory.message === 'success') {
      setOnAction(undefined);
      setTempTitle('');
      setTempActive(false);
      setTempDescription('');
      setOpenAddModal(false);
    }
  };

  const handleAdd = () => {
    if (onAction) {
      updateResourceCategory();
    } else {
      createResourceCategory();
    }
  };

  const visibleRows = useMemo(() => {
    return rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [ page, rowsPerPage, rows]);

  if (loading) return (<CircularLoading />)

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Categories'
          numSelected={selected.length}
          onAdd={() => {
            setOpenAddModal(true);
            setTempActive(true);
          }}
          disableAction={(!me?.isSuperuser && !me?.role?.editCategory)}
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 210px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
              headCells={memoHeadCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, row.id)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id.toString()}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell align='left'>{row.title}</TableCell>
                    <TableCell align='left' sx={{ flex: 10 }}>{row.description}</TableCell>
                    <TableCell align='left' sx={{ width: '70px', textAlign: 'center' }}>{row.isActive && <CheckIcon sx={{ color: 'green' }} />}</TableCell>
                    {(me?.isSuperuser || me?.role?.editCategory) && <TableCell width={80} align='right'>
                      <Box>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOnAction(row.id);
                            setTempTitle(row.title);
                            setTempDescription(row.description ?? '');
                            setTempActive(row.isActive);
                            setOpenAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOnAction(row.id);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <DeleteModal
        title='Are you sure delete this category?'
        open={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setOnAction(undefined);
        }}
        onConfirm={handleDelete}
      />
      <Modal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        aria-labelledby='modal-add-title'
        aria-describedby='modal-add-description'
      >
        <Box sx={deleteModalStyle}>
          <Typography variant='h4' mb={3}>{onAction ? 'Edit Title' : 'Add Category'}</Typography>
          <TextField
            id='Category-title'
            label='Title'
            sx={{ width: '100%' }}
            variant='outlined'
            defaultValue={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
          />

          <TextField
            id='Category-description'
            label='Description'
            sx={{ width: '100%', mt: 2 }}
            variant='outlined'
            defaultValue={tempDescription}
            onChange={(e) => {
              setTempDescription(e.target.value);
            }}
          />
          <Box sx={{mt: 2, display: 'flex'}}>
            <Typography sx={{marginTop: '6px'}}>IsActive</Typography>
            <Switch
              checked={tempActive}
              name='isActive'
              onChange={(e) => {setTempActive(e.target.checked)}}
            />
          </Box>
          <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='outlined'
              onClick={() => {
                setOpenAddModal(false);
                setTempActive(false);
                setTempTitle('');
                setTempDescription('');
                setOnAction(undefined);
              }}
            >
              Cancel
            </Button>
            <Button sx={{ ml: 2 }} variant='contained' onClick={handleAdd}>Save</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
