export function findDifferentValues<T>(obj1: T, obj2: T): Partial<T> {
  const differences: Partial<T> = {};

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    } else {
      differences[key] = obj1[key];
    }
  }

  for (const key in obj2) {
    if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
      differences[key] = obj2[key];
    }
  }

  return differences;
}

export function removeNullProperties(obj: { [key: string]: any }): void {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
}