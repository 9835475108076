import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMinistriesDropDownItemsQuery } from "../../graphql/generated/graphql";
import SlideHomeImg from "../../assets/images/slide-home.jpg";

const containerStyle = {
  backgroundImage: `url(${SlideHomeImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  paddingTop: 20,
};

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const { data: ministryDropDownData } = useMinistriesDropDownItemsQuery({
    fetchPolicy: "network-only",
  });

  const ministries = useMemo(() => {
    if (ministryDropDownData?.Ministries.items) {
      return ministryDropDownData.Ministries.items.map(_item => ({ name: _item.name, url: `/ministries/${_item.url}`, summary: _item.summary }))
    }
    return []
  }, [ministryDropDownData])

  return (
    <Grid container spacing={2} style={containerStyle}>
      <Grid item xs={12} md={5} mt={6} display='flex' alignItems='flex-end' justifyContent={{ xs: 'center', md: "flex-end" }}>
        <Box width={{ xs: '450px', md: '450px', lg: '500px', xl: '600px' }} bgcolor='#150809' p={4.5} fontFamily='Barlow' color='#fff'>
          <Typography
            sx={{
              fontFamily: "Barlow Condensed",
              fontWeight: "600",
              fontSize: { xs: '2rem', lg: '2.5rem', xl: "3.325rem" },
              lineHeight: { xs: '2.5rem', lg: '2.8rem', xl: "3.7rem" },
              textAlign: "center",
              letterSpacing: "unset",
            }}
          >
            THE 1687 FOUNDATION
          </Typography>
          <Box width='100%' height={6} bgcolor='#e83231' my={1} />
          <Typography fontFamily='Barlow Condensed' fontSize={{ xs: '1.1rem', lg: '1.35rem', xl: "1.85rem" }} letterSpacing='unset'>
            Founded in 2008, The 1687 Foundation exists to improve lives by
            providing free, life-changing experiences, accommodations and
            resources to 501(c)(3) non-profit organizations and individuals that serve others.
          </Typography>
          <Typography my={2} mb={2} fontFamily='Barlow Condensed' fontSize={{ xs: '1.1rem', lg: '1.35rem', xl: "1.85rem" }} letterSpacing='unset'>
            Our ministries and programs are provided free of charge.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} md={6} bgcolor={{ xs: 'black', md: 'transparent' }} pb={6} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        {ministries.map((item, index) => (
          <Box
            key={item.url}
            sx={{
              display: "flex", flexDirection: "row",
              maxWidth: { xs: '700px', xl: '760px' },
              padding: '.75rem', cursor: "pointer",
            }}
            onClick={() => navigate(item.url)}
          >
            <Box
              sx={{
                width: { xs: '36px', md: '44px', lg: '56px', xl: "70px" },
                backgroundColor: "#e83231",
                borderTopLeftRadius: { xs: "25px", md: '30px', lg: "35px", xl: '50px' },
                borderBottomLeftRadius: { xs: "25px", md: '30px', lg: "35px", xl: '50px' },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: '900',
                fontSize: { xs: "50px", md: '60px', lg: "70px", xl: '80px' },
                color: "#ef9090",
                paddingLeft: '10px',
                fontFamily: "Barlow Condensed",
              }}
            >
              {index + 1}
            </Box>
            <Box
              p={{ xs: 0.5, md: 1, lg: 1.5, xl: 2 }}
              sx={{
                backgroundColor: "white",
                borderTopRightRadius: { xs: "25px", md: '30px', lg: "35px", xl: '50px' },
                borderBottomRightRadius: { xs: "25px", md: '30px', lg: "35px", xl: '50px' },
                paddingRight: '3rem',
                flex: 1,
                "&:hover": {
                  color: "#d2232a",
                },
              }}
            >
              <Typography variant="h4" fontFamily='Barlow Condensed' fontSize={{ xs: '22px', md: '26px', lg: '30px', xl: '34px' }}>{item.name}</Typography>
              <Typography fontFamily='Barlow Condensed' fontSize={{ xs: "1.2rem", xl: '1.4rem' }} lineHeight={{ xs: 1.0, xl: 1.3 }}>{item.summary}</Typography>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default HomePage;
