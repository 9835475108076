import React from 'react';
import { Typography } from '@mui/material';

const imageStyle = {
  width: '90%',
  border: '15px solid #231f20',
};

const descriptionStyle = {
  marginTop: '10px',
  fontSize: '0.8rem',
  textAlign: 'center',
};

export interface SidebarBorderImageProps {
  image: any;
  description?: any;
}

const SidebarBorderImage: React.FC<SidebarBorderImageProps> = ({ image, description }) => {
  return (
    <>
      <img src={image} loading='lazy' style={imageStyle}  alt='' />
      {description && <Typography sx={descriptionStyle}>{description}</Typography>}
    </>
  )
}

export default SidebarBorderImage;
