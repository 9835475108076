import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchResult } from '@apollo/client';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormSwitch from '../../libs/ui/FormSwitch';
import FormDropdownField, { DropDownItem } from '../../libs/ui/FormDropdownField';
import FormNumberField from '../../libs/ui/FormNumberField';
import FormEditorField from '../../libs/ui/FormEditorField';
import { showSuccess } from '../../libs/utils/toasts';
import { uploadFile } from '../../libs/utils/fileupload';
import CircularLoading from '../../libs/ui/Loading';
import {
  CreateMinistryMutation,
  GetPreSignedUrlDocument,
  GetPreSignedUrlMutation,
  MinistriesDocument,
  MinistryInput,
  useCreateMinistryMutation,
  useGetPreSignedUrlMutation,
  useMinistriesDropDownItemsQuery,
  useResourcesListQuery,
} from '../../graphql/generated/graphql';
import '../../const/gallery.css';

export const MinistryCreateContainer = () => {
  const navigate = useNavigate();

  const [resourceItems, setResourceItems] = useState<DropDownItem[]>([]);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | undefined | null>();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parentPages, setParentPages] = useState<DropDownItem[]>([{ label: 'no parent', value: null }]);

  const validationSchema = Yup.object().shape({
    content: Yup.string().required('This field is required'),
    headLine: Yup.string().required('Header Text is required'),
    isNav: Yup.boolean(),
    name: Yup.string().required('Page Name is required'),
    order: Yup.number(),
    pageTitle: Yup.string().required('page title is required'),
    parentId: Yup.number().nullable(),
    resourceIds: Yup.array().nullable(),
    sideContent: Yup.string().nullable(),
    url: Yup.string(),
    summary: Yup.string().nullable(),
    btnLabel: Yup.string().required('Button Label is required'),
    btnUrl: Yup.string().required('Button Url is required'),
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      url: '',
      pageTitle: '',
      isNav: false,
      parentId: null,
      order: 0,
      headLine: '',
      content: '',
      sideContent: '',
      resourceIds: [],
      summary: null,
      btnLabel: '',
      btnUrl: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [createMinistryMutation] = useCreateMinistryMutation({
    refetchQueries: [
      {
        query: MinistriesDocument,
        variables: {
          page: 1,
          pageSize: 100,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const { data: resourcesData } = useResourcesListQuery({ fetchPolicy: 'network-only' });

  const { data: ministryDropDownData } = useMinistriesDropDownItemsQuery({ fetchPolicy: 'network-only' });

  const [getPreSignedUrlMutation] = useGetPreSignedUrlMutation();

  const handleFileUpload = async () => {
    if (file) {
      const { name, type } = file;
      const presignedName = `${new Date().getTime()}-${name}`;
      const { data }: FetchResult<GetPreSignedUrlMutation> =
        await getPreSignedUrlMutation({
          mutation: GetPreSignedUrlDocument,
          variables: {
            key: { id: presignedName },
          },
        });
      if (data?.GetPreSignedUrl.status) {
        return await uploadFile({
          file: file,
          presignedUrl: `${data?.GetPreSignedUrl.data}`,
          type,
          presignedName,
        });
      }
    }
    return '';
  };

  useEffect(() => {
    if (ministryDropDownData?.Ministries.items) {
      const _parentPages = ministryDropDownData.Ministries.items.map((item) => {
        return { label: item.name, value: item.id };
      });
      setParentPages([{ label: ' --- ', value: null }, ..._parentPages]);
    }
  }, [ministryDropDownData]);

  useEffect(() => {
    if (resourcesData?.Resources.items) {
      const _forms = [ ...resourcesData.Resources.items ].sort(
        (a: any, b: any) => {
          return (a?.title?.toLowerCase() > b?.title?.toLowerCase()) ? 1 : ((b?.title?.toLowerCase() > a?.title?.toLowerCase()) ? -1 : 0)
        }).map((item) => ({
        label: item.title,
        value: item.id,
      }));
      setResourceItems([..._forms]);
    }
  }, [resourcesData]);

  const createMinistry = async (inputData: MinistryInput) => {
    setIsLoading(true);
    if (file) inputData.sideImage = await handleFileUpload();
    const { data: createResult }: FetchResult<CreateMinistryMutation> =
      await createMinistryMutation({
        variables: { ministry: inputData },
      });
    setIsLoading(false);
    if (createResult?.CreateMinistry.data) {
      if (createResult.CreateMinistry.status) {
        showSuccess('Ministry was created successfully');
        setTimeout(() => navigate(-1), 3000);
      }
    }
  };

  const onSubmit = (data: FieldValue<any>) => {
    onSave({ ...data });
  };

  const onSave = (data: MinistryInput) => {
    createMinistry(data);
  };

  const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const _file = target?.files && target?.files[0];
    setFile(_file);

    if (_file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result);
      };
      reader.readAsDataURL(_file);
    }
  };

  return (
    <Grid container display='flex' justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h5' id='delete-category-modal-description'>Add Ministry</Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={5} pr={2}>
          <FormTextField
            name='name'
            label={'Ministry Name'}
            size='small'
            labelComponent={<Typography sx={{ minWidth: 180, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Ministry Name</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
          <FormTextField
            name='pageTitle'
            label={'Title'}
            size='small'
            labelComponent={<Typography sx={{ minWidth: 180, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Title</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
          <FormTextField
            name='url'
            label={'Url'}
            size='small'
            labelComponent={<Typography sx={{ minWidth: 180, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Url</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={5} height='168px'>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormSwitch
              control={control}
              name='isNav'
              label={'Is Nav'}
              containerProps={{ sx: { mt: 1.5, display: 'flex' } }}
              labelComponent={<Typography sx={{ width: 80, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Is Nav</Typography>}
            />
          </Box>
          <FormNumberField
            name='order'
            label={'Order'}
            size='small'
            labelComponent={<Typography sx={{ width: 200, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Order</Typography>}
            control={control}
            type={'number'}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
          />
          <FormDropdownField
            name='parentId'
            label={'Parent Menu'}
            size='small'
            disabled={parentPages.length === 0}
            options={parentPages}
            labelComponent={<Typography sx={{ width: 200, marginTop: '16px', marginBottom: '4px', fontSize: 16, fontWeight: 500 }}>Parent Menu</Typography>}
            control={control}
            sx={{ background: 'white' }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormTextField
            name='headLine'
            label={'Head Line'}
            size='small'
            labelComponent={<Typography sx={{ minWidth: 180, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>HeadLine</Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormTextField
            name='summary'
            label={'Summary'}
            size='small'
            labelComponent={<Typography sx={{ minWidth: 180, fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Summary</Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='content'
            label={'content *'}
            defaultValue=''
            labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Content</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { backgroundColor: 'white', height: 40 } }}
            containerProps={{ sx: { mt: 1 } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='sideContent'
            label={'Side Content *'}
            defaultValue=''
            labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Side Content</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { backgroundColor: 'white', height: 40 } }}
            containerProps={{ sx: { mt: 1 } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Side Image</Typography>
            <Box sx={{ width: '202px', position: 'relative', lineHeight: 1 }}>
              {!previewImage && (
                <Box sx={{ width: '200px', height: '150px' }}>
                  <IconButton component='label' color='primary' sx={{ width: '100%', height: '100%', border: 'solid 1px gray', borderRadius: '4px', position: 'absolute' }}>
                    <input hidden accept='image/*' type='file' onChange={handleFileSelected} />
                    <PhotoCamera fontSize='large' />
                  </IconButton>
                </Box>
              )}
              {previewImage && (
                <>
                  <img src={`${previewImage}`} loading='lazy' style={{ width: '200px', border: 'solid 1px gray', borderRadius: '4px' }} alt='resource-cover' />
                  <IconButton component='label' color='primary' sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
                    <input hidden accept='image/*' type='file' onChange={handleFileSelected} />
                    <PhotoCamera fontSize='large' sx={{ stroke: 'white' }} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={5} pr={2}>
          <FormTextField
            name='btnLabel'
            label='Button Label'
            size='small'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={500} lineHeight='40px'>Button Label</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={5} pl={2}>
          <FormTextField
            name='btnUrl'
            label='Button Url'
            size='small'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={500} lineHeight='40px'>Button Url</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormDropdownField
            name='resourceIds'
            label='Resources'
            size='small'
            multiple
            maxSelectLimit={4}
            options={resourceItems}
            labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Resources</Typography>}
            control={control}
            sx={{ background: 'white', height: '40px' }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={8} pr={1} mt={2} mb={4}>
        <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
          <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
          <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
      </Grid>

      {isLoading && <CircularLoading />}
    </Grid>
  );
};
