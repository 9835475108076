import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Modal,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { showSuccess } from '../../libs/utils/toasts';
import { DeletePageDocument, DuplicatePageDocument, IPages, PagesDocument, usePagesQuery } from '../../graphql/generated/graphql';

const deleteModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const PagesContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [rows, setRows] = useState<Partial<IPages>[]>([]);
  const [page] = useState(0);
  const [rowsPerPage] = useState(100);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number| undefined>(undefined)

  const [deletePageMutation] = useMutation(DeletePageDocument);
  const [duplicatePageMutation] = useMutation(DuplicatePageDocument);

  const { data } = usePagesQuery({
    variables: { page: page + 1, pageSize: rowsPerPage },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.Pages.items) setRows(data.Pages.items);
  }, [data]);

  const handleDeletePage = async (id: number) => {
    const { data: deleteResponse } = await deletePageMutation({
      variables: { pageId: id },
      refetchQueries: [
        {
          query: PagesDocument,
          variables: { page: page + 1, pageSize: rowsPerPage },
          fetchPolicy: 'network-only',
        },
      ],
    })
    if (deleteResponse?.DeletePage.status) {
      showSuccess('Page was removed successfully');
      setIdToDelete(undefined);
      setOpenDeleteModal(false);
    }
  }

  const onEdit = (row: Partial<IPages>) => {
    if (!me?.role?.editCMS) return showSuccess('Your role doesn\'t have the permission');
    navigate(`/app/pages/${row.id}`)
  }

  const onDuplicatePage = async (id: number) => {
    if (!me?.role?.editCMS) return showSuccess('Your role doesn\'t have the permission');
    await duplicatePageMutation({
      variables: { pageId: id },
      refetchQueries: [
        {
          query: PagesDocument,
          variables: { page: page + 1, pageSize: rowsPerPage },
          fetchPolicy: 'network-only',
        },
      ],
    })
  }

  const onDelete = (id: number) => {
    if (!me?.role?.editCMS) return showSuccess('Your role doesn\'t have the permission');
    setIdToDelete(id);
    setOpenDeleteModal(true);
  }

  return (
    <Box>
      <CustomTableToolbar
        title='Content Page Editor'
        numSelected={0}
        onDelete={() => {}}
        onAdd={() => navigate(`/app/pages/new`)}
        disableAction={(!me?.isSuperuser && !me?.role?.editCMS)}
      />
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Title</TableCell>
              {(me?.isSuperuser || me?.role?.editCMS) && <TableCell sx={{ width: '80px' }}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} sx={{ cursor: 'pointer' }}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.url}</TableCell>
                <TableCell>{row.pageTitle}</TableCell>
                {(me?.isSuperuser || me?.role?.editCMS) &&
                  <TableCell width='80px' align='right'>
                    <Box display='flex'>
                      <IconButton onClick={() => onEdit(row)}>
                        <Tooltip id='button-report' title='Edit'>
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => onDuplicatePage(row.id || 0)}>
                        <Tooltip id='button-report' title='Duplicate'>
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => onDelete(row.id ?? 0)}>
                        <Tooltip id='button-report' title='Delete'>
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box sx={deleteModalStyle}>
          <Typography>This page will be removed. Are you sure?</Typography>
          <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' onClick={() => { setOpenDeleteModal(false); setIdToDelete(undefined) }}>Cancel</Button>
            <Button variant='contained' sx={{ ml: 2 }} onClick={() => handleDeletePage(idToDelete || 0)}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
