import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Stack, Grid, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormRadioField from '../../libs/ui/FormRadioField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import FormDateValidate from '../../libs/ui/FormDateValidate';
import SubTitle from '../common/SubTitle';
import { Spacer } from '../../libs/ui/Spacer';
import { showError } from '../../libs/utils/toasts';
import { StyledTypography } from '../../libs/ui/StyledComponents';
import { SignUpDocument, useCheckSignUpLazyQuery } from '../../graphql/generated/graphql';
import { MinistryCategories, StateOptions, TitleOptions } from '../../const/type';
import CloseIcon from '@mui/icons-material/Close';

export const BookApplyForm = () => {
  const navigate = useNavigate();

  const [errorCode, setErrorCode] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);
  const today = new Date();
  const threeWeeksFromNow = new Date(today);
  threeWeeksFromNow.setDate(today.getDate() + 21);
  const sixWeeksFromNow = new Date(today);
  sixWeeksFromNow.setDate(today.getDate() + 42);

  const eventDateValidation = Yup.date().nullable()
    .min(threeWeeksFromNow, 'The Event Date must be more than than 3 weeks and less than 6 weeks from today. Please select a date within this timeframe.')
    .max(sixWeeksFromNow, 'The Event Date must be more than than 3 weeks and less than 6 weeks from today. Please select a date within this timeframe.');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required').min(10, 'Must be 10 digits'),
    email: Yup.string().required('Email is required').test('valid-email', 'Invalid email format', (value) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }),
    confirmEmail: Yup.string().oneOf([Yup.ref("email"), ''], "Emails don't match").required('Confirm Email is required'),
    organization: Yup.string().required('Organization is required'),
    title: Yup.string(),
    shippingFirstName: Yup.string().required('First Name is required'),
    shippingLastName: Yup.string().required('Last Name is required'),
    address1: Yup.string().required('Address1 is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('ZipCode is required').matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits'),
    shippingPhone: Yup.string().required('Phone is required').min(10, 'Must be 10 digits'),
    shippingEmail: Yup.string().required('Email is required').test('valid-email', 'Invalid email format', (value) => {
         return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
    loadingDock: Yup.bool().required('Loading Doc is required'),
    contactInfo: Yup.string(),
    ministryCategory: Yup.string().required('This field is required'),
    orgName: Yup.string().required('This field is required'),
    isManagedByOrg: Yup.string().required('This field is required'),
    differentOrgName: Yup.string().when('isManagedByOrg', {
      is: 'Yes',
      then: (schema) => schema.required('This field is required')
    }),
    overseeMinistry: Yup.string().when('isManagedByOrg', {
      is: 'No',
      then: (schema) => schema.required('This field is required')
    }),
    affiliation: Yup.string().required('This field is required'),
    ministryIs501c3: Yup.string().required('This field is required'),
    employerId: Yup.string().when('ministryIs501c3', {
        is: 'Yes',
        then: (schema) =>
          schema.matches(
            /^(?:\d{2}-\d{7}|\d{9})$/,
            'Please enter 9 digit number. It can have a dash in the number. Two valid formats would be, XX-XXXXXXX, or XXXXXXXXX.'
          )
            .required('This field is required'),
    }),
    hasWebsite: Yup.string().required('This field is required'),
    ministryDetails: Yup.string().required('This field is required'),
    requestForEvent: Yup.string(),
    eventDate: Yup.date().when('requestForEvent', {
        is: 'Yes',
        then: () => eventDateValidation.required('This field is required')
    }),
    purpose: Yup.string().required('This field is required'),
    howDistributed: Yup.string().required('This field is required'),
    expectToServe: Yup.string().when('requestForEvent', {
        is: 'Yes',
        then: (schema) => schema.required('This field is required')
    }),
    costToAttendee: Yup.string().when('requestForEvent', {
        is: 'Yes',
        then: (schema) => schema.required('This field is required')
    }),
    heldToDistribute: Yup.string().when('requestForEvent', {
        is: 'Yes',
        then: (schema) => schema.required('This field is required')
    }),
    publicNotice: Yup.string().when('requestForEvent', {
        is: 'Yes',
        then: (schema) => schema.required('This field is required')
    }),
    howManyPeople: Yup.string().when('requestForEvent', {
        is: 'No',
        then: (schema) => schema.required('This field is required')
    }),
    appliedBefore: Yup.string().required('This field is required'),
    approximateTime: Yup.string().when('appliedBefore', {
        is: 'Yes',
        then: (schema) => schema.required('This field is required')
    }),
    howHearUs: Yup.string().required('This field is required'),
    agreeTerm: Yup.bool().required('This field is required').oneOf([true], 'This field is required'),
  });

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      // password: '',
      confirmEmail: '',
      organization: '',
      title: '',
      shippingFirstName: '',
      shippingLastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      shippingPhone: '',
      shippingEmail: '',
      loadingDock: false,
      contactInfo: '',
      ministryCategory: '',
      orgName: '',
      isManagedByOrg: 'No',
      differentOrgName: '',
      overseeMinistry: '',
      affiliation: '',
      ministryIs501c3: 'No',
      employerId: '',
      hasWebsite: '',
      ministryDetails: '',
      requestForEvent: 'No',
      eventDate: new Date(),
      purpose: '',
      howDistributed: '',
      expectToServe: '',
      costToAttendee: '',
      heldToDistribute: '',
      publicNotice: '',
      howManyPeople: '',
      appliedBefore: 'No',
      approximateTime: '',
      howHearUs: '',
      agreeTerm: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, getValues, watch, formState: { errors }, trigger } = methods;

  const [checkSignUp, { data: checkSignUpData }] = useCheckSignUpLazyQuery({ variables: { email: watch('email').toLowerCase() } });

  const [signupMutation] = useMutation(SignUpDocument);
        
   useEffect(() => {
    if (!checkSignUpData?.CheckSignUp.status) setErrorCode(checkSignUpData?.CheckSignUp.errorCode!);
  }, [checkSignUpData])

  const emailEditingEnd = async () => {
    await trigger('email');
    watch('email') && await checkSignUp();
  }

  const confirmEmailEditingEnd = async () => {
    await trigger('confirmEmail');
  }

  const onSubmit = async () => {
    if (isLoading) return
    setIsLoading(true)
    let variables = {
      user: {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        phone: getValues('phone'),
        email: getValues('email').toLowerCase(),
        // password: getValues('password')
      },
        shippingLocation: {
          organization: getValues('organization'),
          firstName: getValues('shippingFirstName'),
          lastName: getValues('shippingLastName'),
          address1: getValues('address1'),
          address2: getValues('address2'),
          city: getValues('city'),
          state: getValues('state'),
          zipCode: getValues('zipCode'),
          title: getValues('title') 
        },
        shippingDetail: {
          phone: getValues('shippingPhone'),
          email: getValues('shippingEmail')?.toLowerCase(),
          loadingDock: getValues('loadingDock'),
          contactInfo: getValues('contactInfo'),
        }
    };    
      let organizationInfoInput: any = {
        ministryCategory: getValues('ministryCategory'),
        orgName: getValues('orgName'),
        isManagedByOrg: getValues('isManagedByOrg') === 'Yes',
        affiliation: getValues('affiliation'),
        is501c3: getValues('ministryIs501c3') === 'Yes',
        hasWebsite: getValues('hasWebsite'),
        ministryDetails: getValues('ministryDetails'),
      };
      let resourceDistributionInput: any = {
        requestForEvent: getValues('requestForEvent') === 'Yes',
        purpose: getValues('purpose'),
        howDistributed: getValues('howDistributed'),
      }
      let userServeInput: any = {
        appliedBefore: getValues('appliedBefore') === 'Yes',
        howHearUs: getValues('howHearUs'),
        agreeTerm: getValues('agreeTerm'),
      }
      if (getValues('isManagedByOrg') === 'Yes') {
        organizationInfoInput = { ...organizationInfoInput, differentOrgName: getValues('differentOrgName') };
      } else {
        organizationInfoInput = { ...organizationInfoInput, overseeMinistry: getValues('overseeMinistry') };
      }
      if (getValues('ministryIs501c3') === 'Yes') {
        organizationInfoInput = { ...organizationInfoInput, employerId: getValues('employerId') };
      }

      if (getValues('requestForEvent') === 'Yes') {
        resourceDistributionInput = {
          ...resourceDistributionInput,
          eventDate: getValues('eventDate'),
          expectToServe: getValues('expectToServe'),
          costToAttendee: getValues('costToAttendee'),
          heldToDistribute: getValues('heldToDistribute'),
          publicNotice: getValues('publicNotice'),
        };
      } else {
        resourceDistributionInput = {
          ...resourceDistributionInput,
          howManyPeople: getValues('howManyPeople'),
        };
      }
      if (getValues('appliedBefore') === 'Yes') {
        userServeInput = { ...userServeInput, approximateTime: getValues('approximateTime') };
      }
      variables = {
        ...variables,
        ...{
          organizationInfoInput,
          resourceDistributionInput,
          userServeInput,
        }
      };
      const { data } = await signupMutation({
        mutation: SignUpDocument,
        variables: { data: variables }
      });
      if (data?.SignUp.status) {
        setResultModalVisible(true)
      } else {
        showError(data?.SignUp.message);
      }
      setIsLoading(false)
  };


  return (
    <Stack direction='column' spacing={1} pt={0} justifyContent='center'>
      <StyledTypography sx={{ borderBottom: '2px solid #d2232a', fontSize: '2rem' }}>Contact Information</StyledTypography>
      <Spacer height={8} />
      <StyledTypography sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
        The 1687 Foundation protects your contact information and does not share it with other organizations.
      </StyledTypography>
      <Spacer height={8} />
      <FormTextField
        name='firstName'
        label='First Name'
        size='small'
        maxlength={50}
        placeholder='First'
        labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
        control={control}
        inputProps={{ sx: { backgroundColor: '#fff' } }}
      />
      <FormTextField
        name='lastName'
        label='Last Name'
        size='small'
        maxlength={50}
        placeholder='Last'
        labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
        control={control}
        inputProps={{ sx: { backgroundColor: '#fff' } }}
      />
      <FormPhoneField
        name='phone'
        label='Phone'
        size='small'
        placeholder={'••• ••• ••••'}
        labelComponent={<Typography fontWeight={600}>Phone <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
        control={control}
        inputProps={{ sx: { backgroundColor: '#fff !important' } }}
      />
      <FormTextField
        name='email'
        label='Email Address'
        size='small'
        type='email'
        rows={1}
        labelComponent={<Typography fontWeight={600}>Email Address <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
        control={control}
        inputProps={{ sx: { backgroundColor: '#fff' } }}
        onBlurInput={() => emailEditingEnd()}
        />
        <Spacer height={8} />
        <FormTextField
        name='confirmEmail'
        label='Confirm Email Address'
        size='small'
        type='email'
        rows={1}
        labelComponent={<Typography fontWeight={600}>Confirm Email Address <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
        control={control}
        inputProps={{ sx: { backgroundColor: '#fff' } }}
        onBlurInput={() => confirmEmailEditingEnd()}
        />
      <Typography fontSize='1.25rem'>
        <b>Commercial Shipping Address</b>
        <br /> We are required to ship resources to the commercial address and physical location of the organization where the resources will be distributed. We do not ship to personal residences.
        <br /> <br /> We understand that not every ministry or organization has a commercial shipping address. However, we need adequate documentation connecting the shipping location with your ministry. If your organization operates exclusively out of your home or other location which does not qualify as commercial addresses, then we may be able to ship to the commercial address of your church or other viable ministry associated with your ministry.
      </Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='organization'
              label='Destination Organization'
              size='small'
              maxlength={35}
              placeholder='Name of organization at the destination location'
              labelComponent={<Typography fontWeight={600}>Destination Organization <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='title'
              label='Title'
              size='small'
              options={TitleOptions}
              labelComponent={<Typography fontWeight={600}>Title</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingFirstName'
              label='First Name'
              size='small'
              maxlength={50}
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingLastName'
              label='Last Name'
              size='small'
              maxlength={50}
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address1'
              label='Address 1'
              size='small'
              maxlength={35}
              labelComponent={<Typography fontWeight={600}>Address 1 <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address2'
              label='Address 2'
              size='small'
              maxlength={35}
              labelComponent={<Typography fontWeight={600}>Address 2</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='city'
              label='City'
              size='small'
              maxlength={50}
              labelComponent={<Typography fontWeight={600}>City <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='state'
              label='State'
              size='small'
              options={StateOptions}
              labelComponent={<Typography fontWeight={600}>State <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='zipCode'
              label='Zip Code'
              size='small'
              placeholder={'•••••'}
              labelComponent={<Typography fontWeight={600}>Zip Code <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='shippingPhone'
              label='Phone Number'
              size='small'
              placeholder={'••• ••• ••••'}
              labelComponent={<Typography fontWeight={600}>Phone Number <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff !important' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingEmail'
              label='Email Address'
              size='small'
              maxlength={50}
              rows={1}
              type='email'
              labelComponent={<Typography fontWeight={600}>Email Address <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='contactInfo'
              label='Alternate Contact Name and Phone'
              size='small'
              maxlength={100}
              placeholder='For receiving the shipment'
              labelComponent={<Typography fontWeight={600}>Alternate Contact Name and Phone</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </Grid>
        </Grid>
        <Box>
          <SubTitle label='Ministry & Organization Information' customStyle={{ my: 1 }} />
          <Spacer height={0} />
          <FormRadioField
            name='ministryCategory'
            size='small'
            options={MinistryCategories}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Ministry Category <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          <FormTextField
            name='orgName'
            label='Name of Ministry or Organization'
            size='small'
            maxlength={35}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Name of Ministry or Organization <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormRadioField
            name='isManagedByOrg'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry managed by or overseen by another established ministry/organization or church <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('isManagedByOrg') === 'Yes' &&
            <FormTextField
              name='differentOrgName'
              label='Please provide the name of the overseeing ministry/organization, if different than above.'
              size='small'
              maxlength={50}
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please provide the name of the overseeing ministry/organization, if different than above. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          {watch('isManagedByOrg') === 'No' &&
            <FormTextField
              name='overseeMinistry'
              label='Who does oversee this ministry?'
              size='small'
              maxlength={100}
              placeholder='Name of governing or adminstering ministry/oranization'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Who does oversee this ministry? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          <FormTextField
            name='affiliation'
            size='small'
            maxlength={100}
            label='What is your affiliation with this ministry/organization?'
            placeholder='Define your title, role or position'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is your affiliation with this ministry/organization? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormRadioField
            name='ministryIs501c3'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry/organization a 501c3? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('ministryIs501c3') === 'Yes' &&
            <FormTextField
              name='employerId'
              size='small'
              label='Enter the organization Employer ID Number (EIN):'
              placeholder='XX-XXXXXXX or XXXXXXXXX'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Enter the organization Employer ID Number (EIN): <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
              einTyping={true}
            />
          }
          <FormTextField
            name='hasWebsite'
            size='small'
            maxlength={100}
            label='Does the ministry have a website or presence online?'
            placeholder='Enter web address, Facebook, Twitter, social media address(es) or none'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Does the ministry have a website or presence online? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='ministryDetails'
            size='small'
            maxlength={200}
            label='Describe this ministry in detail.'
            placeholder='Define the purpose, mission, vision, goals and background'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe this ministry in detail. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        </Box>
        <Box>
          <SubTitle label='Resource Distribution' customStyle={{ my: 1 }} />
          <Spacer height={0} />
          <FormRadioField
            name='requestForEvent'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={
              <Typography my={1} fontSize={18} fontWeight={600}>Are you planning for an event with a specific date? <span style={{ fontWeight: 600, color: '#f00' }}>*</span>
              <br /> If you are planning for a specific event, the form must be submitted more than 3 but less than 6 weeks before the event.
              <br /> If your event date is outside of this 3-6 week window, DO NOT SUBMIT AN INQUIRY FORM UNTIL YOU ARE WITHIN THE TIMEFRAME.
              </Typography>}
            control={control}
          />
          {watch('requestForEvent') === 'Yes' &&
            <FormDateValidate
              name='eventDate'
              label=''
              size='small'
              type='datetime-local'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please select the date for the event. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              rows={1}
              containerProps={{ sx: { mt: 1 } }}
            />
          }
          <FormTextField
            name='purpose'
            size='small'
            maxlength={200}
            label='Describe the purpose and goals for distributing the resources.'
            placeholder='Describe the purpose and goals. Include expected results and outcomes.'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe the purpose and goals for distributing the resources.<span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='howDistributed'
            size='small'
            maxlength={200}
            label='How will the resources be distributed?'
            placeholder='Please provide details.'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How will the resources be distributed? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          {watch('requestForEvent') === 'Yes' && <>
            <FormTextField
              name='expectToServe'
              size='small'
              maxlength={100}
              label='How many do you expect to serve at this event?'
              placeholder='Number of adults, children, youth by age ranges.'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many do you expect to serve at this event? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='costToAttendee'
              size='small'
              maxlength={100}
              label='What is the cost to attendees for this event?'
              placeholder='Enter cost per person.'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is the cost to attendees for this event? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='heldToDistribute'
              size='small'
              maxlength={200}
              label='Where will the event be held to distribute these resources?'
              placeholder='Event Location, City and State, including sponsor, if any.'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Where will the event be held to distribute these resources? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
            <FormTextField
              name='publicNotice'
              size='small'
              maxlength={200}
              label='Describe any advertising or public notices for this event.'
              placeholder='Provide links to Facebook, Twitter, social media, email advertising or flyer.'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe any advertising or public notices for this event. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          </>}
          {watch('requestForEvent') === 'No' &&
            <FormTextField
              name='howManyPeople'
              size='small'
              maxlength={100}
              label='How many people will be served over the next three months?'
              placeholder='Number of adults, children, youth by age ranges.'
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many people will be served over the next three months? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
        </Box>
        <Box>
          <SubTitle label='Help Us Serve You Better' customStyle={{ my: 1 }} />
          <Spacer height={0} />
          <FormRadioField
            name='appliedBefore'
            size='small'
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Have you previously requested resources from The 1687 Foundation? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
          />
          {watch('appliedBefore') === 'Yes' &&
            <FormTextField
              name='approximateTime'
              label='Please tell us the approximate time frame, email and name used previously.'
              size='small'
              maxlength={100}
              labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please tell us the approximate time frame, email and name used previously. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
            />
          }
          <FormTextField
            name='howHearUs'
            size='small'
            maxlength={100}
            label='How did you hear about us?'
            placeholder='Please provide names of people and/or ministries who referred you to us.'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How did you hear about us? <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <Typography mt={1} fontSize={18} fontWeight={600}>By submitting this information, I acknowledge that THIS IS NOT A GUARANTEE for providing publications. The 1687 Foundation reserves the right to accept or deny any inquiry. <span style={{ fontWeight: 600, color: '#f00' }}>*</span></Typography>
          <FormCheckBoxField 
            name='agreeTerm'
            label='I understand and agree to these terms and guidelines.'
            control={control}
          />
        </Box>
      <Spacer height={20} />
      {Object.keys(errors).length > 0 && <Typography fontWeight={800} color='#f00'>All required fields must be filled in.</Typography>}
      <Spacer height={10} />
      <Button variant='contained' sx={{ backgroundColor: '#f00' }} disabled={errorCode === 1 || errorCode === 2 || isLoading} onClick={handleSubmit(onSubmit)}>
        <Typography fontSize='1.5rem' fontWeight={800}>Submit</Typography>
      </Button>
      <Modal open={resultModalVisible} onClose={() => { setResultModalVisible(false); navigate("/") }}>
        <Box sx={{ 
          backgroundColor: '#fff', 
          borderRadius: 2, 
          position: 'absolute', 
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '@media (max-width: 600px)': {
            width: '90%',
          }, width: '50%' 
        }}>
          <Box bgcolor='#e3e3e3' borderTop={1} p={1} display='flex' justifyContent='space-between'>
            <Typography fontWeight={800}>Inquiry Submitted Successfully</Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => { setResultModalVisible(false); navigate("/") }} />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Typography margin={5} fontWeight={800}>Your inquiry is PENDING review.</Typography>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};
