import React, { FC, useCallback, useEffect } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { DropMenu } from '../../libs/ui/DropMenu';
import { PagesModel } from '../../graphql/generated/graphql';
import LogoTransparentImg from '../../assets/images/logo-transparent.png';

const appLogoStyle = {
  width: 148,
  height: 64,
  backgroundColor: '#fff',
  mr: 3,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const pages = [
  { name: 'Pages', url: '/app/pages' },
  { name: 'Forms', url: '/app/forms' },
  { name: 'News', url: '/app/posts' },
  { name: 'Gallery', url: '/app/ministry' },
  { name: 'Ministries', url: '/app/ministry' },
  { name: 'Testimonials', url: '/app/testimonials' },
];
const resourcePages = [
  { name: 'Inventory/Log', url: '/app/resource' },
  { name: 'Exclusion Groups', url: '/app/exclusions' },
];

const AppHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, canAutoLogin, signOut } = useAuth();

  const currentPath = location.pathname;

  useEffect(() => {
    if (!canAutoLogin && location.pathname.includes('app')) navigate('login');
  }, [canAutoLogin, location, navigate]);

  const pageToHeader = useCallback((item: PagesModel): any => {
    let _pageHeader;

    if (item.children && item.children?.length > 0) {
      _pageHeader = {
        name: item.name,
        title: item.pageTitle,
        url: `/${item.url}`,
        DropMenu: item.dropMenu,
        order: item.order,
        id: item.id,
        children: item.children.map((child: PagesModel) => { return pageToHeader(child) }).sort((a, b) => a.order > b.order ? 1 : -1)
      };
    } else {
      _pageHeader = {
        name: item.name,
        title: item.pageTitle,
        url: `/${item.url}`,
        id: item.id,
        DropMenu: item.dropMenu,
        order: item.order,
      };
    }
    return _pageHeader;
  }, []);

  return (
    <AppBar component='nav'>
      <Toolbar sx={{ backgroundColor: '#00001a', pl: '0px !important' }}>
        <Box sx={appLogoStyle} onClick={() => navigate('/')}>
          <img src={LogoTransparentImg} loading='lazy' style={{ height: 48 }} alt='' />
        </Box>
        <Box width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Box display={{ xs: 'none', sm: 'block' }} px={1}>
            {(me?.isSuperuser || me?.role?.changeUsersProfile) &&
              <a href="/app/application"><Button sx={{ color: '#fff', border: currentPath.includes('/application') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/application')}>
                Inquiries
              </Button></a>
            }
            {(me?.isSuperuser || me?.role?.viewRequest) &&
              <a href="/app/request"><Button sx={{ color: '#fff', border: currentPath.includes('/request') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/request')}>
                Requests
              </Button></a>
            }
            {(me?.isSuperuser || me?.role?.viewResource) &&
              <DropMenu
                navName='Resources'
                menuList={resourcePages}
                role='Admin'
                landing={false}
                focused={currentPath.includes('/resource') || currentPath.includes('/exclusions')}
              />
              // <Button sx={{ color: '#fff', border: currentPath.includes('/resource') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/resource')}>
              //   Resources
              // </Button>
            }
            {/* {(me?.isSuperuser || me?.role?.viewResource) &&
              <Button sx={{ color: '#fff', border: currentPath.includes('/exclusions') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/exclusions')}>
                Exclusion Groups
              </Button>
            } */}
            {(me?.isSuperuser || me?.role?.viewResource) &&
              <Button sx={{ color: '#fff', border: currentPath.includes('/reports') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/reports')}>
                Reports
              </Button>
            }
            {(me?.isSuperuser || me?.role?.viewCategory) &&
              <Button sx={{ color: '#fff', border: currentPath.includes('/categories') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/categories')}>
                Categories
              </Button>
            }
            {(me?.isSuperuser || me?.role?.changeUsersProfile) &&
              <Button sx={{ color: '#fff', border: currentPath.includes('/admin') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/admin')}>
                Admins
              </Button>
            }
            {(me?.isSuperuser || me?.role?.changePermission) &&
              <Button sx={{ color: '#fff', border: currentPath.includes('/roles') ? '1px solid #fff' : 'none' }} onClick={() => navigate('/app/roles')}>
                Roles
              </Button>
            }
            <DropMenu
              navName='Content'
              menuList={pages}
              role='Admin'
              landing={false}
              focused={currentPath.includes('/pages') || currentPath.includes('/forms') || currentPath.includes('/posts') || currentPath.includes('/testimonials') || currentPath.includes('/ministry')}
            />
          </Box>
          <Box width={140} display={{ xs: 'none', sm: 'block' }}>
            <IconButton sx={{ border: currentPath.includes('/profile') ? '2px solid #fff' : 'none' }} onClick={() => navigate('/app/profile')}>
              <PersonIcon color='primary' />
            </IconButton>
            <Button sx={{ color: '#fff' }} onClick={() => { signOut(); navigate('/') }}>Log out</Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
};

export default AppHeader;
