import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormBuilder } from '@formio/react';
import FormTextField from '../../libs/ui/FormTextField';
import FormEditorField from '../../libs/ui/FormEditorField';
import { UpdateFormDocument, useFormQuery } from '../../graphql/generated/graphql';

export const FormsEmailContainer = () => {
  const navigate = useNavigate();
  const { formId } = useParams();

  const [components, setComponents] = useState<any[]>([]);

  const { data } = useFormQuery({
    variables: { formId: Number(formId) ?? 0 },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    data?.Form && setComponents(JSON.parse(data?.Form.data?.content || "{ display: 'form' }"));
  }, [data])

  const [updateFormMutation] = useMutation(UpdateFormDocument);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Form Name is required'),
    adminEmail: Yup.string().email().required('Admin Email is required'),
    fromEmail: Yup.string().required('From Email is required'),
    bccEmails: Yup.string().nullable(),
    emailHeader: Yup.string().nullable(),
    emailFooter: Yup.string().nullable(),
  });

  const methods = useForm({
    values: {
      name: data?.Form.data?.name ?? '',
      adminEmail: data?.Form.data?.adminEmail ?? '',
      fromEmail: data?.Form.data?.fromEmail ?? '',
      bccEmails: data?.Form.data?.bccEmails ?? '',
      emailHeader: data?.Form.data?.emailHeader ?? '',
      emailFooter: data?.Form.data?.emailBottom ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const exportToJson = async (formData: FieldValue<any>) => {
    const { data } = await updateFormMutation({
      variables: {
        form: {
          id: Number(formId),
          name: formData.name,
          content: JSON.stringify(components),
          adminEmail: formData.adminEmail,
          fromEmail: formData.fromEmail,
          bccEmails: formData.bccEmails,
          emailHeader: formData.emailHeader ?? '',
          emailBottom: formData.emailFooter ?? '',
          replyEmail: ''
        },
      }
    });
    if (data?.UpdateForm.status) navigate(-1);
  };

  return (
    <Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={4} sm={4} md={3} px={1}>
          <FormTextField
            name='name'
            label='Name'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Name</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <FormBuilder form={components} onChange={(newFormState: any) => setComponents(newFormState)} />
        <Grid container mb={2}>
          <Grid item xs={4} sm={3} md={2} />
          <Grid item xs={4} sm={4} md={3}>
            <FormTextField
              name='adminEmail'
              label='Admin Email'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Admin Email</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={4} sm={3} md={2} />
          <Grid item xs={4} sm={4} md={3}>
            <FormTextField
              name='fromEmail'
              label='From Email'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={4} sm={3} md={2} />
          <Grid item xs={10}>
            <FormTextField
              name='bccEmails'
              label='Bcc Emails'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={4} sm={3} md={2} />
          <Grid item xs={10}>
            <FormEditorField
              name='emailHeader'
              label='Email Header'
              defaultValue={data?.Form.data?.emailHeader ?? ''}
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Header</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={4} sm={3} md={2} />
          <Grid item xs={10}>
            <FormEditorField
              name='emailFooter'
              label='Email Footer'
              defaultValue={data?.Form.data?.emailBottom ?? ''}
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Footer</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={5} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='contained' onClick={handleSubmit(exportToJson)}>Update</Button>
      </Box>
    </Grid>
  )
}
