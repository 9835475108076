import React from "react";
import { Box } from "@mui/material";
import { MinistryEditContainer } from "../../components/ministries/MinistryEditContainer";

const MinistryEditPage: React.FC = () => {
  return (
    <Box>
      <MinistryEditContainer />
    </Box>
  );
};

export default MinistryEditPage;
