import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormSubmissionQuery } from '../../graphql/generated/graphql';

const FormSubmissionDetailContainer = () => {
  const { submissionId } = useParams();

  const [content, setContent] = useState<any>(null);

  const { data } = useFormSubmissionQuery({
    variables: { submissionId: Number(submissionId) || 0 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.FormSubmission.data) {
      const value = JSON.parse(data?.FormSubmission.data.content).data;
      delete value.submit;
      setContent(value);
    }
  }, [data])

  return (
    <Box p={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '200px' }}><b>Field</b></TableCell>
              <TableCell><b>Value</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {content && Object.keys(content).map((item: string, index: number) => (
              <TableRow key={index}>
                <TableCell>{item}</TableCell>
                <TableCell>{content[item]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default FormSubmissionDetailContainer;
