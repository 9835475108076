import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import { showSuccess } from '../../libs/utils/toasts';
import { removeTags } from '../../libs/utils/htmlContent';
import { DeleteTestimonialDocument, ITestimonial, useTestimonialsQuery } from '../../graphql/generated/graphql';

const TestimonialsContainer = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState<Partial<ITestimonial>[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined | null>(undefined);

  const [deleteTestimonial] = useMutation(DeleteTestimonialDocument, {});

  const { data} = useTestimonialsQuery({
    variables: {},
    nextFetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data?.Testimonials.items) setRows(data?.Testimonials.items);
  }, [data]);

  const handleDelete = async () => {
    const { data } = await deleteTestimonial({
      mutation: DeleteTestimonialDocument,
      variables: { id: deleteId }
    })
    if (data.DeleteTestimonial.status) {
      const filtered = rows.filter(item => item.id !== deleteId);
      setRows(filtered);
      setDeleteId(undefined);
      setDeleteModalVisible(false);
      showSuccess(`The testimonial was removed`);
    }
  }

  return (
    <Box>
      <CustomTableToolbar
        title='Testimonials'
        numSelected={0}
        onDelete={() => {}}
        onAdd={() => navigate('./new')}
      />
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Summary</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Url</TableCell>
              <TableCell width={80}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{removeTags(row.summary ?? '')}</TableCell>
                <TableCell>{row.pageTitle}</TableCell>
                <TableCell>{row.url}</TableCell>
                <TableCell width={80} align='right'>
                  <Box display='flex'>
                    <IconButton onClick={() => navigate(`./${row.id}`)}>
                      <Tooltip id='button-report' title='Edit'>
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => { setDeleteId(row.id); setDeleteModalVisible(true) }}>
                      <Tooltip id='button-report' title='Delete'>
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteModal
        open={deleteModalVisible}
        title='Are you sure you want to delete this testimonial?'
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />
    </Box>
  )
}

export default TestimonialsContainer;
