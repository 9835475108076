import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import SubTitle from '../../components/common/SubTitle';
import NormalParagraph from '../../components/common/NormalParagraph';
import ReadMoreButton from '../../components/common/ReadMoreButton';
import { NewsList } from '../../components/news/leftList';
import { Spacer } from '../../libs/ui/Spacer';
import { PostModel, useGetNewsCategoryQuery, usePostsInPageQuery } from '../../graphql/generated/graphql';
import { StyledTypography } from '../../libs/ui/StyledComponents';

const NewsPage = () => {
  const navigate = useNavigate();
  const { ministryId } = useParams();

  const [page] = useState<number>(0);
  const [pageSize] = useState<number>(25);
  const [news, setNews] = useState<PostModel[]>([]);

  const { data: newsCategoryData } = useGetNewsCategoryQuery({ variables: { newsCategoryId: ministryId! } });

  const headers =  useMemo(() => {
    if (newsCategoryData?.GetNewsCategory.data?.headLine) {
      const _headLine = newsCategoryData?.GetNewsCategory.data?.headLine;
      const headerList = _headLine.split('\n')
      return headerList.length > 1 ? headerList : ['', headerList[0]]
    }
    return ['', '']
  }, [newsCategoryData])

  const sideContent =  useMemo(() => {
    if (newsCategoryData?.GetNewsCategory.data?.sideContent) return newsCategoryData?.GetNewsCategory.data?.sideContent;
    return '';
  }, [newsCategoryData])

  const { data } = usePostsInPageQuery({ variables: { page: page + 1, pageSize, ministryId: ministryId! } });

  useEffect(() => {
    if (data?.Posts) {
      setNews(_rows => {
        return [...data.Posts.items]
      })
    }
  }, [data])

  return (
    <Grid container>
      <PageSlide images={[]} />
      <PageHeader subTitle={headers[0]} mainTitle={headers[1]} />
      <Grid container bgcolor='#f9f1df' p={3}>
        <Grid item xs={0} xl={1.5} />
        <Grid item xs={0} md={4} xl={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <NewsList sideContent={sideContent} />
        </Grid>
        <Grid item xs={12} md={8} xl={6} pt={5} pl={{ xs: 0, md: 3 }}>
          {news.map(_news => (
            <Box key={_news.id} position='relative'>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <StyledTypography fontSize={24}>{new Date(_news.publishedTime).toDateString()}</StyledTypography>
              </Box>
              <SubTitle label={_news.title ?? ''} customStyle={{ marginTop: '4px', fontFamily: 'Sorts Mill Goudy', fontSize: '40px', fontWeight: 500 }} />
              <NormalParagraph label={_news.content ?? ''} customStyle={{ fontFamily: 'Sorts Mill Goudy', fontSize: '28px' }} /> 
              <ReadMoreButton onClick={() => navigate(`/news/${ministryId}/${_news.url}`)} />
              <Spacer height={20} />
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
          <NewsList sideContent={sideContent} />
        </Grid>
        <Grid item xs={0} xl={2} />
      </Grid>
    </Grid>
  )
}

export default NewsPage;
