import {
  AdditionalInfoModel,
  IAdditionalInfo,
  IBlock,
  IComment,
  IForms,
  IFormSubmission,
  IMinistry,
  IOfficeUse, IOrganizationInfo,
  IRequest,
  IResource, IResourceDistribution,
  IShippingAddress,
  IShippingDetail,
  ISignature,
  ITestimonial,
  IUser,
  IUserRole, IUserServe,
  IUserShippingDetail,
  IUserShippingLocation,
  OrganizationInfoModel,
} from '../graphql/generated/graphql';

export type UserResponseData = Partial<IUser> & {
  id: number;
  shippingLocations?: Array<Partial<IUserShippingLocation>> | null;
  shippingDetails?: Array<Partial<IUserShippingDetail>> | null;
  additionalInfos?: Array<Partial<IAdditionalInfo>> | null;
  organizationInfo?:IOrganizationInfo | null;
  exclusionGroup?: any | null;
  resourceDistribution?: IResourceDistribution | null;
  userServe?: IUserServe | null;
  role?: Partial<IUserRole> | null;
};

export type RequestResponseData = IRequest & {
  officeUse?: Partial<IOfficeUse> | null,
  shippingLocation?: Partial<IShippingAddress> | null,
  shippingDetail?: Partial<IShippingDetail> | null,
  signature?: Partial<ISignature> | null,
  user?: Partial<IUser & { additionalInfos?: Partial<AdditionalInfoModel>[] | null } & { organizationInfo?: Partial<OrganizationInfoModel> | null }> | null;
  comments?: Array<CommentResponseData> | undefined | null
}

export type MinistriesResponseData = Partial<IMinistry> & {
  parent?: Partial<IMinistry> | null;
  resources?: Array<Partial<IResource>> | null;
  children?: Array<Partial<IMinistry>> | null;
  testimonials?: Array<Partial<ITestimonial>> | null;
}

export type TestimonialResponseData = Partial<ITestimonial> & {
  blocks?: Array<Partial<IBlock>> | null;
}

export type CommentResponseData = IComment & {
  user?: IUser | null
};

export type FormResponseData = IForms & {
  submissions?: Array<Partial<IFormSubmission>> | null
}

export interface MenuListItem {
  name: string;
  url: string;
  role?: string[];
  id?: string;
  title?: string;
}

export type Order = 'asc' | 'desc';

export const TitleOptions = [
  { label: 'Chaplain', value: 'Chaplain' },
  { label: 'Director', value: 'Director' },
  { label: 'Doctor', value: 'Doctor' },
  { label: 'Pastor', value: 'Pastor' },
  { label: 'President', value: 'President' },
  { label: 'Rank', value: 'Rank' },
  { label: 'Reverend', value: 'Reverend' },
  { label: 'VicePresident', value: 'VicePresident' },
]

export const ResourceExclusions = [
  { label: '1687Admin - No Exclusions (Default)', value: '1687Admin' },
  { label: 'SETFREE - Prison Ministries', value: 'SETFREE' },
  { label: '1687MIL - Military Branches, Bases, Hospitals', value: '1687MIL' },
  { label: '1687FR - First Responders', value: '1687FR' },
  { label: 'CRU18 - CRU Military', value: 'CRU18' },
  { label: 'HOS18 - Hospitals, Hospice, Nursing Homes', value: 'HOSP18' },
  { label: 'MUSEUM - Foundation Museum', value: 'MUSEUM' },
  { label: '1687LIFE - Pregnancy Centers', value: '1687LIFE' },
]

export const StateOptions = [
  { label: 'AA', value: 'AA' },
  { label: 'AK', value: 'AK' },
  { label: 'AL', value: 'AL' },
  { label: 'AR', value: 'AR' },
  { label: 'AZ', value: 'AZ' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DC', value: 'DC' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'IA', value: 'IA' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'MA', value: 'MA' },
  { label: 'MD', value: 'MD' },
  { label: 'ME', value: 'ME' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MO', value: 'MO' },
  { label: 'MS', value: 'MS' },
  { label: 'MT', value: 'MT' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'NE', value: 'NE' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NV', value: 'NV' },
  { label: 'NY', value: 'NY' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VA', value: 'VA' },
  { label: 'VT', value: 'VT' },
  { label: 'WA', value: 'WA' },
  { label: 'WI', value: 'WI' },
  { label: 'WV', value: 'WV' },
  { label: 'WY', value: 'WY' },
]

export const PlanOptions = [
  { value: 'military', label: 'Military' },
  { value: 'first-responders', label: 'First Responders' },
  { value: 'needs-disabilities', label: 'Special Needs & Disabilities' },
  { value: 'care-adoption', label: 'Poster Care & Adoption' },
  { value: 'pro-life', label: 'Pro-Life' },
  { value: 'other', label: 'Other' },
]

export const MinistryCategories = [
  { value: 'Military', label: 'Military' },
  { value: 'FirstResponders', label: 'First Responders' },
  { value: 'PrisonMinistry', label: 'Prison Ministry' },
  { value: 'AtRisk', label: 'At-Risk' },
  { value: 'ChildrenMinistry', label: 'Children\'s Ministry' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Other', label: 'Other' },
]
export const DisableMinistryCategories = [
  { value: 'Military', label: 'Military', disabled: true },
  { value: 'FirstResponders', label: 'First Responders', disabled: true },
  { value: 'PrisonMinistry', label: 'Prison Ministry', disabled: true },
  { value: 'AtRisk', label: 'At-Risk', disabled: true },
  { value: 'ChildrenMinistry', label: 'Children\'s Ministry', disabled: true },
  { value: 'Healthcare', label: 'Healthcare', disabled: true },
  { value: 'Inmate', label: 'Inmate' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Other', label: 'Other' },
]
export const InternalMinistryCategories = [
  { value: 'Inmate', label: 'Inmate' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Other', label: 'Other' },
]
export const MinistryCategoriesMap: { [key: string]: string } = {
  'Military': 'Military',
  'FirstResponders': 'First Responders',
  'PrisonMinistry': 'Prison Ministry',
  'AtRisk': 'At-Risk',
  'ChildrenMinistry': 'Children\'s Ministry',
  'Healthcare': 'Healthcare',
  'Other': 'Other',
  'Inmate': 'Inmate',
  'Personal': 'Personal',
}

export const NumberFilterOperators = [
  { value: 'equalTo', label: 'Equal' },
  { value: 'lessThan', label: 'Less Than' },
  // { value: 'lessThanEqual', label: 'lessThanEqual' },
  { value: 'moreThan', label: 'More Than' },
  // { value: 'moreThanEqual', label: 'moreThanEqual' },
  { value: 'notEqualTo', label: 'Not Equal' },
  // { value: 'valueIn', label: 'Contains' },
  // { value: 'valueNotIn', label: 'Does Not Contain' },
]

export const StringFilterOperators = [
  { value: 'iLike', label: 'Contains' },
  { value: 'equalTo', label: 'Equal' },
  // { value: 'like', label: 'Like' },
  { value: 'notEqualTo', label: 'Not Equal' },
  // { value: 'valueIn', label: 'Contains' },
  // { value: 'valueNotIn', label: 'Does Not Contains' },
]

export const DateFilterOperators = [
  { value: 'lessThanEqual', label: 'Before' },
  // { value: 'equalTo', label: 'On' },
  // { value: 'lessThan', label: 'Before' },
  // { value: 'moreThan', label: 'After' },
  { value: 'moreThanEqual', label: 'After' },
  { value: 'notEqualTo', label: 'Not on' },
]

export const BooleanFilterOperators = [
  { value: 'equalTo', label: 'equalTo' },
  { value: 'notEqualTo', label: 'notEqualTo' },
]
