import React from "react";
import { Box } from "@mui/material";
import { MinistryCreateContainer } from "../../components/ministries/MinistryCreateContainer";

const MinistryCreatePage: React.FC = () => {
  return (
    <Box>
      <MinistryCreateContainer />
    </Box>
  );
};

export default MinistryCreatePage;
