import React, { useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Paper,
  TableRow,
  TablePagination,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Modal,
  Typography,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import { UsersTableHead } from './UsersTableHead';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { UsersDocument, useUsersQuery, useUsersLazyQuery, DeleteUserDocument } from '../../graphql/generated/graphql';
import { formatPhoneNumber } from '../../const/function';
import { UserResponseData } from '../../const/type';
import { adminHeadCells } from './const';

const deleteModalStyle = {
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  boxShadow: 24,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const AdminUsersContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<UserResponseData[]>([]);
  const [filter, setFilter] = useState<any>({});
  const [filterId, setFilterId] = useState<string | undefined>('');
  const [filterType, setFilterType] = useState<string>('equalTo');
  const [filterText, setFilterText] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    document.title = 'Applications';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement' };
  }, []);

  const { data } = useUsersQuery({
    variables: { page: page + 1, pageSize: rowsPerPage, isSuperUser: true },
    fetchPolicy: 'network-only',
  });

  const [getUsers, { data: usersData }] = useUsersLazyQuery({
    variables: { page: page + 1, pageSize: rowsPerPage, isSuperUser: true, filter },
    fetchPolicy: 'network-only',
  });

  const [deleteUserMutation] = useMutation(DeleteUserDocument, {
    refetchQueries: [
      {
        query: UsersDocument,
        variables: { page: page + 1, pageSize: rowsPerPage, isSuperUser: true },
      },
    ],
  });

  useEffect(() => {
    if (data?.Users.items) {
      setRows((_rows) => {
        return [...data.Users.items];
      });
    }
    if (data?.Users) setTotalCount(data.Users.total);
  }, [data]);

  useEffect(() => {
    if (usersData?.Users.items) {
      setRows((_rows) => {
        usersData.Users.items.forEach((item: UserResponseData) => {
          const _exist = _rows.find((row) => row.id === item.id);
          if (!_exist) _rows.push(item);
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
    }
    if (usersData?.Users) setTotalCount(usersData.Users.total);
  }, [usersData]);

  const applyFilter = (filterId: string | undefined) => {
    if (filterId) {
      setFilter({ [filterId]: { [filterType]: filterText } });
    } else {
      setFilter(null);
    }
    setRows([]);
    getUsers();
    setFilterId(filterId);
  };

  const memoHeadCells = useMemo(() => {
    if (me?.isSuperuser || me?.role?.changeUsersProfile) {
      return adminHeadCells;
    } else {
      return adminHeadCells.filter((item) => item.id !== 'actions');
    }
  }, [me?.isSuperuser, me?.role?.changeUsersProfile]);

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = async () => {
    const { data } = await deleteUserMutation({
      mutation: DeleteUserDocument,
      variables: { id: deleteId },
    });
    if (data?.DeleteUser.message === 'success') setOpenDeleteModal(false);
  }

  const ToolbarActions = (
    <Button variant='contained' sx={{ minWidth: 45, padding: '4px 6px', fontSize: 12 }} onClick={() => navigate('./new')}>Add</Button>
  );

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Admin Profiles'
          numSelected={0}
          onAdd={() => navigate('/app/admin/new')}
          disableAction={!me?.isSuperuser && !me?.role?.changeUsersProfile}
          customActionButton
          actions={ToolbarActions}
          showActions
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 210px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }} aria-labelledby='tableTitle'>
            <UsersTableHead
              headCells={memoHeadCells}
              numSelected={0}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
              onSelectAllClick={() => {}}
              filterId={filterId}
              filterType={filterType}
              setFilterType={setFilterType}
              filterText={filterText}
              setFilterText={setFilterText}
              applyFilter={applyFilter}
              role='admin'
            />
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow key={row.id?.toString()} hover tabIndex={-1} sx={{ cursor: 'pointer' }}>
                    {(me?.isSuperuser || me?.role?.changeUsersProfile) && (
                      <TableCell width={40} padding='none' align='center'>
                        <IconButton onClick={() => navigate(`./${row.id}`)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => { setDeleteId(row.id); setOpenDeleteModal(true) }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell align='center'>{row.firstName}</TableCell>
                    <TableCell align='center'>{row.lastName}</TableCell>
                    <TableCell align='center'>{row.email}</TableCell>
                    <TableCell align='center'>{formatPhoneNumber(row.phone!)}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box sx={deleteModalStyle}>
          <Typography>This user will be removed. Are you sure?</Typography>
          <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button variant='contained' sx={{ ml: 2 }} onClick={handleDeleteUser}>OK</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
