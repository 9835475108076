export const ResourceImage = ({url, altString} :{url: string, altString: string}) => {
  return (
    <img
      src={url}
      alt={altString}
      loading="lazy"
      style={{ height: "80px" }}
    />
  );
};
