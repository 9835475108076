import React from 'react';
import { Typography } from '@mui/material';

const subTitleStyle = {
  borderBottom: '2px solid #d2232a',
  fontFamily: 'orts Mill Goudy,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '2.5rem',
  fontWeight: 100,
  lineHeight: 1,
};

export interface SubTitleProps {
  label: string | undefined;
  customStyle?: any;
}

const SubTitle: React.FC<SubTitleProps> = ({ label, customStyle }) => {

  return (
    <Typography variant='h6' sx={{ ...subTitleStyle, ...customStyle }}>{label}</Typography>
  )
}

export default SubTitle;
