import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import FormTextField from '../../../libs/ui/FormTextField';
import FormPhoneField from '../../../libs/ui/FormPhoneField';
import FormDateTimeField from '../../../libs/ui/FormDateTimeField';
import FormDropdownField from '../../../libs/ui/FormDropdownField';
import { InternalMinistryCategories, DisableMinistryCategories } from '../../../const/type';

interface RequestedByFormProps {
  control: Control<any>
  getValues: Function
  editableMinistryCategory?: boolean
  disabledMinistryCategories?: boolean
}

const RequestedByForm = ({ control, getValues, editableMinistryCategory, disabledMinistryCategories }: RequestedByFormProps) => {

  return (
    <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
      <Typography fontWeight={600}>Requested By</Typography>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormDateTimeField
                name='requestedDate'
                label='Request Date'
                size='small'
                type='datetime-local'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Request Date</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormDateTimeField
                name='shippedDate'
                label='Shipped Date'
                size='small'
                type='date'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Shipped Date</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='userFirstName'
                label='First Name'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>First Name</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='userLastName'
                label='Last Name'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Last Name</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormPhoneField
                name='userPhone'
                label='Phone'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Phone Number</Typography>}
                control={control}
                inputProps={{ sx: { width: '100%', height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='userEmail'
                label='Email'
                size='small'
                type='email'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Email</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
           <FormTextField
             name='digitalSignature'
             label='Digital Signature'
             size='small'
             labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Digital Signature</Typography>}
             control={control}
             inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
             containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
            />
        </Grid>
        <Grid item xs={12} lg={3} />
        <Grid item xs={12} lg={3} pr={3} pl={1}>
          <FormTextField
            name='userMinistryName'
            label='Organization / Ministry'
            size='small'
            labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16 }}>Organization / Ministry</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
          />
        </Grid>
        <Grid item sx={{ display: 'flex' }} xs={12} lg={3} pr={3} pl={1}>
        {
            !editableMinistryCategory &&
              <>
                <Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Ministry Category</Typography>
                <Typography sx={{ fontSize: 16, lineHeight: '40px' }}>{getValues('ministryCategory') ?? 'None'}</Typography>
              </>
        }
        {
            editableMinistryCategory &&
            <FormDropdownField
              // name={getValues('ministryCategory') ?? 'None'}
              name='ministryCategory'
              label='Ministry Category'
              size='small'
              options={disabledMinistryCategories ? DisableMinistryCategories : InternalMinistryCategories}
              labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Ministry Category</Typography>}
              control={control}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
            /> 
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestedByForm;
