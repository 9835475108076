import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { MenuListItem } from '../../const/type';

const buttonStyle = {
  fontFamily: 'Barlow',
  fontSize: '1.25rem',
  color: '#fff',

  '&:hover': {
    color: '#e83231',
  }
};

interface DropMenuProps {
  navName: string;
  menuList: MenuListItem[];
  role?: any;
  landing?: boolean;
  focused?: boolean;
}

export const DropMenu: FC<DropMenuProps> = ({ navName, menuList, role, landing = true, focused = false }) => {
  const navigate = useNavigate();
  const { me, signOut } = useAuth();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<HTMLElement | null>(null);
  const [nestedAnchorEl2, setNestedAnchorEl2] = useState<HTMLElement | null>(null);
  const [nestedAnchorEl3, setNestedAnchorEl3] = useState<HTMLElement | null>(null);

  const reMenuList = useMemo(() => {
    if (me?.isSuperuser || role?.changeProfile) {
      return menuList
    } else {
      return menuList.filter(item => !['Profile', 'Dashboard Page'].includes(item.name));
    }
  }, [me?.isSuperuser, menuList, role?.changeProfile])

  const navigateUrlSetting = (setting: MenuListItem) => {
    if (setting.id) {
      navigate(setting.url, { state: { id: setting.id, title: setting.title } });
    } else {
      navigate(setting.url);
    }
    setAnchorElUser(null);
  };

  const navigateNewsPage = (value: string) => {
    setNestedAnchorEl(null);
    setNestedAnchorEl2(null);
    setNestedAnchorEl3(null);
    setAnchorElUser(null);
    value !== '' && navigate(`/app/posts/${value}`);
  }
  const navigateGalleryPage = (value: string) => {
    setNestedAnchorEl(null);
    setNestedAnchorEl2(null);
    setNestedAnchorEl3(null);
    setAnchorElUser(null);
    value !== '' && navigate(`/app/ministry/${value}`);
  }
  const navigateTestimonialPage = (value: string) => {
    setNestedAnchorEl(null);
    setNestedAnchorEl2(null);
    setNestedAnchorEl3(null);
    setAnchorElUser(null);
    value !== '' && navigate(`/app/testimonials/${value}`);
  }

  return (
    <>
      <Button
        sx={ landing ? buttonStyle : { color: '#fff', border: focused ? '1px solid #fff' : 'none' }}
        onClick={event => setAnchorElUser(event.currentTarget)}
      >
        {navName}
        {!navName && <>{me?.firstName}<PersonIcon color='primary' /></>}
      </Button>
      <Menu
        id='menu-ministries'
        keepMounted
        disableScrollLock={true}
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        {reMenuList.map((setting: MenuListItem, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(event) => {
                if (setting.name === 'Logout') {
                  signOut();
                } else if (setting.name === 'News') {
                  setNestedAnchorEl(event.currentTarget);
                } else if (setting.name === 'Gallery') {
                  setNestedAnchorEl2(event.currentTarget);
                } else if (setting.name === 'Testimonials') {
                  setNestedAnchorEl3(event.currentTarget);
                } else {
                  navigateUrlSetting(setting);
                }
              }}
            >
              <Box width='100%' display='flex' justifyContent='space-between'>
                <Typography textAlign='center'>{setting.name}</Typography>
                {setting.name === 'News' && <ArrowRightIcon />}
                {setting.name === 'Gallery' && <ArrowRightIcon />}
                {setting.name === 'Testimonials' && <ArrowRightIcon />}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        id="nested-menu"
        anchorEl={nestedAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(nestedAnchorEl)}
        onClose={() => navigateNewsPage('')}
      >
        <MenuItem onClick={() => navigateNewsPage('book-ministry')}>Book Ministry</MenuItem>
        <MenuItem onClick={() => navigateNewsPage('retreat-program')}>Retreat Program</MenuItem>
        <MenuItem onClick={() => navigateNewsPage('hunting-fishing')}>Hunting & Fishing</MenuItem>
        <MenuItem onClick={() => navigateNewsPage('woodys-classic-cars-and-baseball-museum')}>Woody’s Classic Cars and Baseball Museum</MenuItem>
      </Menu>
      <Menu
        id="nested-menu"
        anchorEl={nestedAnchorEl2}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(nestedAnchorEl2)}
        onClose={() => navigateGalleryPage('')}
      >
        <MenuItem onClick={() => navigateGalleryPage('11')}>Retreat Gallery</MenuItem>
        <MenuItem onClick={() => navigateGalleryPage('10')}>Hunting & Fishing Gallery</MenuItem>
      </Menu>
      <Menu
        id="nested-menu"
        anchorEl={nestedAnchorEl3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(nestedAnchorEl3)}
        onClose={() => navigateTestimonialPage('')}
      >
        <MenuItem onClick={() => navigateTestimonialPage('1')}>Book Ministry</MenuItem>
        <MenuItem onClick={() => navigateTestimonialPage('2')}>Retreat Ministry</MenuItem>
        <MenuItem onClick={() => navigateTestimonialPage('3')}>Hunting & Fishing Ministry</MenuItem>
      </Menu>
    </>
  );
};
