import React from 'react';
import { Box, Button } from '@mui/material';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

const buttonContainerStyle = {
  height: '48px',
  position: 'relative'
};

const buttonMainStyle = {
  height: '48px',
  textTransform: 'capitalize',
  backgroundColor: '#ec4b49',
  border: '1px solid #e83231',
  fontSize: '30px',
  padding: '30px',
  paddingRight: '72px',
  fontWeight: 600,

  '&:hover': {
    backgroundColor: '#c9403e',
    borderColor: '#ba2827',
  },
};

const buttonArrowStyle = {
  width: '60px',
  height: '62px',
  backgroundColor: '#e83231',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export interface ArrowButtonProps {
  label: string;
  onClick?: () => void;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ label, onClick }) => {
  return (
    <Box sx={buttonContainerStyle} onClick={onClick}>
      <Button variant='contained' sx={buttonMainStyle}>{label}</Button>
      <Box sx={buttonArrowStyle}>
        <KeyboardDoubleArrowRight sx={{fontSize: '32px', color: '#fff' }} />
      </Box>
    </Box>
  )
}

export default ArrowButton;
