import React, { useEffect } from 'react';
import { ReportContainer } from '../../components/reports/ReportContainer';

const ReportsPage = () => {
  useEffect(() => {
    document.title = 'The 1687 Foundation - Admin';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement' };
  }, [])

  return <ReportContainer />;
};

export default ReportsPage;
