import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { LoginForm } from './loginForm';
import { useMeLazyQuery } from '../../graphql/generated/graphql';

const Container = styled.div(() => ({
  width: '100vw',
  height: '700px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const LoginContainer = () => {
  const { canAutoLogin, me, setMe } = useAuth();

  const [getMe, { data }] = useMeLazyQuery();

  useEffect(() => {
    if (canAutoLogin && !me) getMe();
  }, [canAutoLogin, me, getMe])

  useEffect(() => {
    if (data?.Me.data) setMe(data.Me.data)
  }, [data, setMe])

  if (canAutoLogin) {
    if (me) return <Navigate to='/app/application' replace />;
  }

  return (
    <Container>
      <LoginForm />
    </Container>
  );
};
