import React, { useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import FormPasswordField from '../../libs/ui/FormPasswordField';
import FormTextField from '../../libs/ui/FormTextField';
import { Spacer } from '../../libs/ui/Spacer';
import { LoginMutationVariables } from '../../graphql/generated/graphql';

const LoginCard = styled.div(() => ({
  minWidth: 380,
  height: 'fit-content',
  border: '1px solid #1c3e84',
  borderRadius: 20,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const [errorMsg, setErrorMsg] = useState<string>('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const methods = useForm<LoginMutationVariables>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const onSubmit = useCallback(async (data: FieldValue<any>) => {
    const result = await signIn(data);
    if (result.success) {
      if (result.data.isSuperuser) {
        navigate('/app/application');
      } else {
        navigate('/resources');
      }
    } else {
      if (result?.message) {
        setErrorMsg(result?.message);
      }
    }
  }, [navigate, signIn]);


  return (
    <Box>
      <LoginCard>
        <Stack direction='column' spacing={1} justifyContent='center' sx={{ pt: 0 }}>
          <FormTextField name='email' label='Email Address' control={control} />
          <Spacer height={8} />
          <FormPasswordField name='password' label='Password' control={control} />
          <Spacer height={8} />
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Button variant='contained' sx={{ background: '#155bd5' }} onClick={() => navigate('/forgot-password')}>Forgot Password</Button>
            <Button variant='contained' sx={{ background: '#155bd5' }} onClick={handleSubmit(onSubmit)}>Log In</Button>
          </Box>
          {errorMsg !== '' && <Typography fontSize={18} color='#f00'>{errorMsg}</Typography>} 
        </Stack>
      </LoginCard>
    </Box>
  );
};
