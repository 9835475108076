import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { PostsCreateContainer } from '../../components/posts/PostCreateContainer';

const PostCreatePage: FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.viewCMS) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.viewCMS, navigate])

  return <PostsCreateContainer />;
};

export default PostCreatePage;
