import { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Tooltip,
  TablePagination,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useMutation } from '@apollo/client';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import { showSuccess } from '../../libs/utils/toasts';
import { MinistriesDocument, DuplicateMinistryDocument, useDeleteMinistryMutation, useMinistriesQuery } from '../../graphql/generated/graphql';
import { MinistriesResponseData } from '../../const/type';

export const MinistriesContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null)
  const [totalCount, setTotalCount] = useState(0);

  const [rows, setRows] = useState<MinistriesResponseData[]>([]);

  const [duplicateMinistryMutation] = useMutation(DuplicateMinistryDocument);
  const [deleteMinistryMutation] = useDeleteMinistryMutation({
    refetchQueries: [
      {
        query: MinistriesDocument,
        variables: {
          page: page + 1,
          pageSize: rowsPerPage,
        },
        fetchPolicy: 'network-only',
      },
    ]
  })

  const {data} = useMinistriesQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage
    },
    nextFetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data?.Ministries.items) {
      setRows(_rows => {
        data.Ministries.items.forEach((item: MinistriesResponseData) => {
          const _exist = _rows.find((row) => row.id === item.id);
          if (!_exist) {
            _rows.push(item);
          }
          if (_exist && _exist !== item) {
            _rows[_rows.indexOf(_exist)] = item;
          }
        });
        return [..._rows]
      })
    }
    if (data?.Ministries) {
      setTotalCount(data.Ministries.total);
    }
  }, [data]);

  const deletePage = async (id: number | null) => {
    if (id) {
      const {data: deleteResult} = await deleteMinistryMutation({
        variables: {
          deleteMinistryId: id
        }
      })
      if (deleteResult?.DeleteMinistry.status) {
        showSuccess('Ministry was removed successfully');
        setRows(_rows => {
          const _ministries = _rows.filter(row => row.id !== id);
          return [..._ministries]
        })
      }
    }
    setIdToDelete(null);
    setOpenDeleteModal(false);
  }

  const onDelete = async (id: number | null) => {
    setIdToDelete(id);
    setOpenDeleteModal(true);
  }

  const onDuplicateMinistry = async (id: number) => {
    if (!me?.role?.editCMS) return showSuccess('Your role doesn\'t have the permission');
    await duplicateMinistryMutation({
      variables: { ministryId: id },
      refetchQueries: [
        {
          query: MinistriesDocument,
          variables: { page: page + 1, pageSize: rowsPerPage },
          fetchPolicy: 'network-only',
        },
      ],
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    return rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [ page, rowsPerPage, rows]);

  return (
    <Box>
      <CustomTableToolbar
        title='Ministries'
        numSelected={0}
        onDelete={() => {}}
        onAdd={() => navigate(`/app/ministry/new`)}
        disableAction={(!me?.isSuperuser && !me?.role?.editCMS)}
      />
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Sorting</TableCell>
              {(me?.isSuperuser || me?.role?.editCMS) && <TableCell sx={{ width: '80px' }}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <TableRow key={row.id} sx={{ cursor: 'pointer' }}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.parent?.name}</TableCell>
                <TableCell>{row.order}</TableCell>
                {(me?.isSuperuser || me?.role?.editCMS) && <TableCell align='right' sx={{ width: '80px' }}>
                  <Box sx={{ display: 'flex' }}>
                    <IconButton onClick={() => navigate(`/app/ministry/${row.id}`)}>
                      <Tooltip id='button-report' title='Edit'>
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => onDuplicateMinistry(row.id || 0)}>
                        <Tooltip id='button-report' title='Duplicate'>
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(row.id ? Number(row.id) : null);
                      }}
                    >
                      <Tooltip id='button-report' title='Delete'>
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteModal
        title='Are you sure delete this ministry?'
        open={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setIdToDelete(null);
        }}
        onConfirm={() => {deletePage(idToDelete)}}
      />
    </Box>
  );
};
