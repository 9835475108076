import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import CustomFormView from '../../components/pages/CustomFormView';
import { NormalParagraphHTMLContent } from '../../components/common/NormalParagraph';
import { BookApplyForm } from '../../components/book-apply/book-apply-form';
import { RequestLoginForm } from '../../components/auth/RequestLoginForm';
import { usePageByUrlQuery } from '../../graphql/generated/graphql';

export const CustomContentPage = () => {
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.title) document.title = location.state.title;
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement'; }
  }, [location])

  const { data } = usePageByUrlQuery({
    variables: { url: id ?? '' },
    fetchPolicy: 'network-only'
  });

  const headers = useMemo(() => {
    if (data?.PageByUrl.data?.headLine) {
      const _headLine = data.PageByUrl.data.headLine;
      const headerList = _headLine.split('\n')
      return headerList.length > 1 ? headerList : ['', headerList[0]]
    }
    return ['', '']
  }, [data])

  return (
    <Grid container>
      <PageSlide images={[]} />
      <PageHeader subTitle={headers[0]} mainTitle={headers[1]} />
      <Grid container bgcolor='#f9f1df' p={3} display='flex' justifyContent='center'>
        <Grid item xs={0} xl={1.5} />
        {data?.PageByUrl.data?.sideContent ? (
          <>
            <Grid item xs={12} lg={4} xl={3} pl={2} pr={2}>
              <NormalParagraphHTMLContent label={data?.PageByUrl.data?.sideContent ?? ''} />
            </Grid>
            <Grid item xs={12} lg={8} xl={6} pl={2} pr={2} pt={5}>
              <NormalParagraphHTMLContent label={data?.PageByUrl.data?.content ?? ''} />
              {data?.PageByUrl.data?.formId && <CustomFormView formId={data?.PageByUrl.data?.formId} />}
              {data?.PageByUrl.data?.isApplicationForm && <BookApplyForm />}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={2} xl={1.5} pl={2} pr={2} />
            <Grid item xs={12} lg={8} xl={6} pl={2} pr={2} pt={5}>
              <NormalParagraphHTMLContent label={data?.PageByUrl.data?.content ?? ''} />
              {data?.PageByUrl.data?.formId && <CustomFormView formId={data?.PageByUrl.data?.formId} />}
              {data?.PageByUrl.data?.isApplicationForm && <BookApplyForm />}
              {data?.PageByUrl.data?.isApplicationLogin && <RequestLoginForm />}
            </Grid>
            <Grid item xs={12} lg={2} xl={1.5} pl={2} pr={2} />
          </>
        )}
        <Grid item xs={0} xl={1.5} />
      </Grid>
    </Grid>
  )
}
