import React, { useEffect } from 'react';
import { Box, CssBaseline, styled } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import AppHeader from './app/header';
import BasicBreadcrumbs from './app/breadcrums';
import { useAuth } from '../contexts/auth';
import { useMeLazyQuery } from '../graphql/generated/graphql';
import { COOKIES_TOKEN_NAME } from '../graphql/apolloClient';

const Main = styled('main')(() => ({
  height: '100vh',
  padding: '84px 20px 20px 20px',
  overflowY: 'auto',
  flexGrow: 1,
}));

const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies([COOKIES_TOKEN_NAME]);
  const { canAutoLogin, me, setMe } = useAuth();

  const [getMe, { data, error }] = useMeLazyQuery();

  useEffect(() => {
    if (data?.Me.data) setMe(data.Me.data)
  }, [data, setMe])

  useEffect(() => {
    if (canAutoLogin && !me && !error) {
      getMe();
    } else if (!me && canAutoLogin && error) {
      removeCookie(COOKIES_TOKEN_NAME);
      navigate('/login');
    } else if (!canAutoLogin) {
      navigate('/login');
    }
  }, [me, navigate, canAutoLogin, getMe, error, removeCookie]);

  return (
    <Box display='flex'>
      <CssBaseline />
      <AppHeader />
      <Main>
        <BasicBreadcrumbs />
        <Outlet />
      </Main>
    </Box>
  );
};

export default AppLayout;
