import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import LandingHeader from './landing/header';
import LandingFooter from './landing/footer';
import ScrollToTop from './scrollToTop';
import { useMeLazyQuery } from '../graphql/generated/graphql';

const Main = styled('main')(() => ({
  paddingTop: 96,
  overflowY: 'auto',
  flexGrow: 1,
}));

const LandingLayout: React.FC = () => {
  const location = useLocation();
  const { canAutoLogin, me, setMe } = useAuth();

  const [getMe, { data, error }] = useMeLazyQuery();

  useEffect(() => {
    if (data?.Me.data) setMe(data.Me.data);
  }, [data, setMe]);

  useEffect(() => {
    if (canAutoLogin && !me && !error) getMe();
  }, [me, canAutoLogin, getMe, error]);

  return (
    <Box flexGrow={1} sx={{ overflowX: 'hidden' }}>
      <ScrollToTop />
      <LandingHeader />
      <Main>
        <Outlet />
      </Main>
      {location.pathname !== '/resources' && <LandingFooter />}
    </Box>
  );
};

export default LandingLayout;
