import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography
} from '@mui/material';
import apolloClient from '../../graphql/apolloClient';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { format } from 'date-fns';
import axios from 'axios';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import FormRadioField from '../../libs/ui/FormRadioField';
import FormDateField from '../../libs/ui/FormDateField';
import { Spacer } from '../../libs/ui/Spacer';
import FormEditorField from '../../libs/ui/FormEditorField';
import {
  UpdateUserAllInfoDocument,
  UpdateUserStatusDocument,
  UserByIdDocument,
  useUserByIdQuery,
  useLoginPageQuery,
  useExclusionGroupListQuery,
  ExclusionGroupByIdDocument,
  useCloseAppPageQuery,
  SendCloseAppEmailDocument
} from '../../graphql/generated/graphql';
import { MinistryCategories, StateOptions, TitleOptions, MinistryCategoriesMap } from '../../const/type';
import { useAuth } from '../../contexts/auth';
import Comments from './Comments';

const modalBoxStyle = {
  width: '80vw',
  backgroundColor: '#fff',
  borderRadius: 2,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const apikey = 'AIzaSyCN2qMAkaOgR9yTXqkcAz7E0JD3LBDjbcw';

const UserEditContainer = () => {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const { requestSignIn } = useAuth();

  const [trigger, setTrigger] = useState(0);
  const [user, setUser] = useState<any>(null);
  const [openUserEmailModal, setOpenUserEmailModal] = useState<boolean>(false);
  const [autoRedFlag, setAutoRedFlag] = useState<Boolean>(false);
  const [autoYellowFlag, setAutoYellowFlag] = useState<Boolean>(false);
  const [priorAppKey, setPriorAppKey] = useState<number>(0);
  const [status, setStatus] = useState('received');
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [center, setCenter] = useState({ lat: 44.0439615, lng: -121.314933 });
  const [exclusionGroups, setExclusionGroups]:any[] = useState([]);
  const [exclusionGroupError, setExclusionGroupError]:any[] = useState('');

  const emailValidationSchema = Yup.object().shape({
    emailContent: Yup.string().required('Body is required'),
    fromEmail: Yup.string().required(''),
    bccEmail: Yup.string(),
    subjectEmail: Yup.string().required('Subject Content is required'),
    titleEmail: Yup.string().required('Title Content is required'),
    linkEmail: Yup.string().required('Link Content is required'),
  })
  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string(),
    email: Yup.string(),
    redFlag: Yup.bool(),
    yellowFlag: Yup.bool(),
    endDate: Yup.date().nullable(),
    acceptedDate: Yup.date().nullable(),
    organization: Yup.string().nullable(),
    title: Yup.string().nullable(),
    shippingFirstName: Yup.string().nullable(),
    shippingLastName: Yup.string().nullable(),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zipCode: Yup.string().nullable().test('valid-zipcode', 'Invalid zip code', function (value) {
      if (value !== null && value !== undefined && value !== '') {
        return Yup.string().matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits').isValidSync(value);
      }
      return true;
    }),
    shippingPhone: Yup.string().nullable().test('valid-shippingphone', 'Invalid shipping phone', function (value) {
      if (value !== null && value !== undefined && value !== '') {
        return Yup.string().min(10, 'Must be 10 digits').isValidSync(value);
      }
      return true;
    }),
    shippingEmail: Yup.string().nullable().email(),
    loadingDock: Yup.bool().nullable(),
    is501c3: Yup.bool().nullable(),
    contactInfo: Yup.string().nullable(),
    ministryCategory: Yup.string().required('This field is required'),
    originalMinistryCategory: Yup.string().optional(),
    orgName: Yup.string().required('This field is required'),
    isManagedByOrg: Yup.string(),
    differentOrgName: Yup.string(),
    overseeMinistry: Yup.string(),
    affiliation: Yup.string().required('This field is required'),
    ministryIs501c3: Yup.string(),
    employerId: Yup.string(),
    hasWebsite: Yup.string().required('This field is required'),
    ministryDetails: Yup.string().required('This field is required'),
    requestForEvent: Yup.string(),
    eventDate: Yup.date(),
    purpose: Yup.string().required('This field is required'),
    howDistributed: Yup.string().required('This field is required'),
    expectToServe: Yup.string(),
    costToAttendee: Yup.string(),
    heldToDistribute: Yup.string(),
    publicNotice: Yup.string(),
    howManyPeople: Yup.string(),
    appliedBefore: Yup.string(),
    approximateTime: Yup.string(),
    howHearUs: Yup.string().required('This field is required'),
    agreeTerm: Yup.bool(),
    exclusionGroup: Yup.string(),
  });

  const emailMethods = useForm({
    defaultValues: {
      emailContent: '',
      fromEmail: '',
      bccEmail: '',
      subjectEmail: '',
      titleEmail: '',
      linkEmail: '',
    },
    resolver: yupResolver(emailValidationSchema)
  })
  const profileMethods = useForm({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phone: user?.phone || '',
      email: user?.email || '',
      redFlag: user?.redFlag || false,
      yellowFlag: user?.yellowFlag || false,
      endDate: user?.endDate ? new Date(user?.endDate) : null,
      acceptedDate: user?.acceptedDate ? new Date(user?.acceptedDate) : null,
      organization: user?.shippingLocations.length > 0 && user?.shippingLocations[0].organization ? user?.shippingLocations[0].organization : '',
      title: user?.shippingLocations.length > 0 && user?.shippingLocations[0].title ? user?.shippingLocations[0].title : 'None',
      shippingFirstName: user?.shippingLocations.length > 0 && user?.shippingLocations[0].firstName ? user?.shippingLocations[0].firstName : '',
      shippingLastName: user?.shippingLocations.length > 0 && user?.shippingLocations[0].lastName ? user?.shippingLocations[0].lastName : '',
      address1: user?.shippingLocations.length > 0 && user?.shippingLocations[0].address1 ? user?.shippingLocations[0].address1 : '',
      address2: user?.shippingLocations.length > 0 && user?.shippingLocations[0].address2 ? user?.shippingLocations[0].address2 : '',
      city: user?.shippingLocations.length > 0 && user?.shippingLocations[0].city ? user?.shippingLocations[0].city : '',
      state: user?.shippingLocations.length > 0 && user?.shippingLocations[0].state ? user?.shippingLocations[0].state : 'AA',
      zipCode: user?.shippingLocations.length > 0 && user?.shippingLocations[0].zipCode ? user?.shippingLocations[0].zipCode : '',
      shippingPhone: user?.shippingDetails.length > 0 && user?.shippingDetails[0].phone ? user?.shippingDetails[0].phone : '',
      shippingEmail: user?.shippingDetails.length > 0 && user?.shippingDetails[0].email ? user?.shippingDetails[0].email : '',
      loadingDock: user?.shippingDetails.length > 0 && user?.shippingDetails[0].loadingDock ? user?.shippingDetails[0].loadingDock : false,
      is501c3: user?.shippingDetails.length > 0 && user?.shippingDetails[0].is501c3 ? user?.shippingDetails[0].is501c3 : false,
      contactInfo: user?.shippingDetails.length > 0 && user?.shippingDetails[0].contactInfo ? user?.shippingDetails[0].contactInfo : '',
      ministryCategory: user?.organizationInfo && user?.organizationInfo.ministryCategory ? user?.organizationInfo.ministryCategory : '',
      originalMinistryCategory: user?.organizationInfo && user?.organizationInfo.originalMinistryCategory ? user?.organizationInfo.originalMinistryCategory : '',
      orgName: user?.organizationInfo && user?.organizationInfo.orgName ? user?.organizationInfo.orgName : '',
      isManagedByOrg: user?.organizationInfo && user?.organizationInfo.isManagedByOrg ? 'Yes' : 'No',
      differentOrgName: user?.organizationInfo && user?.organizationInfo.differentOrgName ? user?.organizationInfo.differentOrgName : '',
      overseeMinistry: user?.organizationInfo && user?.organizationInfo.overseeMinistry ? user?.organizationInfo.overseeMinistry : '',
      affiliation: user?.organizationInfo && user?.organizationInfo.affiliation ? user?.organizationInfo.affiliation : '',
      ministryIs501c3: user?.organizationInfo && user?.organizationInfo.is501c3 ? 'Yes' : 'No',
      employerId: user?.organizationInfo && user?.organizationInfo.employerId ? user?.organizationInfo.employerId : '',
      hasWebsite: user?.organizationInfo && user?.organizationInfo.hasWebsite ? user?.organizationInfo.hasWebsite : '',
      ministryDetails: user?.organizationInfo && user?.organizationInfo.ministryDetails ? user?.organizationInfo.ministryDetails : '',
      requestForEvent: user?.resourceDistribution && user?.resourceDistribution.requestForEvent ? 'Yes' : 'No',
      eventDate: user?.resourceDistribution && user?.resourceDistribution.eventDate ? new Date(user?.resourceDistribution.eventDate) : new Date(),
      purpose: user?.resourceDistribution && user?.resourceDistribution.purpose ? user?.resourceDistribution.purpose : '',
      howDistributed: user?.resourceDistribution && user?.resourceDistribution.howDistributed ? user?.resourceDistribution.howDistributed : '',
      expectToServe: user?.resourceDistribution && user?.resourceDistribution.expectToServe ? user?.resourceDistribution.expectToServe : '',
      costToAttendee: user?.resourceDistribution && user?.resourceDistribution.costToAttendee ? user?.resourceDistribution.costToAttendee : '',
      heldToDistribute: user?.resourceDistribution && user?.resourceDistribution.heldToDistribute ? user?.resourceDistribution.heldToDistribute : '',
      publicNotice: user?.resourceDistribution && user?.resourceDistribution.publicNotice ? user?.resourceDistribution.publicNotice : '',
      howManyPeople: user?.resourceDistribution && user?.resourceDistribution.howManyPeople ? user?.resourceDistribution.howManyPeople : '',
      appliedBefore: user?.userServe && user?.userServe.appliedBefore ? 'Yes' : 'No',
      approximateTime: user?.userServe && user?.userServe.approximateTime ? user?.userServe.approximateTime : '',
      howHearUs: user?.userServe && user?.userServe.howHearUs ? user?.userServe.howHearUs : '',
      agreeTerm: user?.userServe && user?.userServe.agreeTerm ? user?.userServe.agreeTerm : false,
      exclusionGroup: '',
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const { handleSubmit, setValue, control, getValues, watch, reset, formState: { errors } } = profileMethods;
  const { 
    handleSubmit: handleSubmitEmail, 
    control: controlEmail,
    getValues: getValuesEmail, 
    // watch: watchEmail, 
    reset: resetEmail, 
    formState: { errors: errorsEmail } 
  } = emailMethods;

  const address1Input = watch('address1', '');
  const cityInput = watch('city', '');
  const stateInput = watch('state', '');

  const { data: exclusionGroupData } = useExclusionGroupListQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (exclusionGroupData?.ExclusionGroups?.items && user) {
      const _forms = exclusionGroupData.ExclusionGroups.items.map((item: any) => ({
        label: item.name,
        value: item.id,
        endDateDays: item.endDateDays,
      }));
      setExclusionGroups([ { 
        label: 'Please Select:', value: -1, 
        endDateDays: user?.resourceDistribution && user?.resourceDistribution.requestForEvent ? 7 : 365
      }, ..._forms ]);
    }
  }, [exclusionGroupData, user]);

  useEffect(() => {
    const getGeocode = async () => {
      try {
        const address = `${address1Input}, ${cityInput}, ${stateInput}, USA`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apikey}`
        const response = await axios.get(url);
        const { results } = response.data;
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat, lng });
        }
      } catch (e) {
      }
    }

    getGeocode().then();
  }, [address1Input, cityInput, stateInput])

  useEffect(() => {
    document.title = 'Applications';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement'; };
  }, [])

  const { data } = useUserByIdQuery({
    variables: { userId: Number(userId) },
    fetchPolicy: 'network-only',
  });
  const { data: loginPageData } = useLoginPageQuery({
    variables: { id: '1234' },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    const asyncWrapper = async () => {
      if (loginPageData?.RequestLoginPage?.data) {
        let exclusionGroupData: any = {}
        if (getValues('exclusionGroup')) {
          const exclusionGroupResponse = await apolloClient.query({
            query: ExclusionGroupByIdDocument,
            variables: { exclusionGroupId: Number(getValues('exclusionGroup')) },
            fetchPolicy: 'network-only',
          });        
          exclusionGroupData = exclusionGroupResponse?.data
        }
  
        let emailContent = ''
        if (loginPageData?.RequestLoginPage.data?.loginEmailContent) {
          if (exclusionGroupData?.ExclusionGroup?.data?.endDateDays > 364) {
            emailContent = loginPageData?.RequestLoginPage.data?.loginEmailLongTermContent ?? ''
          } else {
            emailContent = loginPageData?.RequestLoginPage.data?.loginEmailContent
          }
        }
  
        resetEmail({
          emailContent: emailContent?.replace("{end date}", moment(getValues('endDate')).format('MM/DD/YYYY'))?.replace("{ministry category}", MinistryCategoriesMap[user?.organizationInfo?.ministryCategory]) ?? '',
          fromEmail: loginPageData.RequestLoginPage.data.loginFromEmail ?? '',
          bccEmail: loginPageData.RequestLoginPage.data.loginBccEmails ?? '',
          subjectEmail: loginPageData.RequestLoginPage.data.loginSubjectEmail ?? '',
          titleEmail: loginPageData.RequestLoginPage.data.loginTitleEmail ?? '',
          linkEmail: loginPageData.RequestLoginPage.data.loginLinkEmail ?? '',
        })
      } 
    }

    asyncWrapper()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loginPageData, resetEmail]);

  const { data: closeAppPageData } = useCloseAppPageQuery({
    variables: { id: '1234' },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (closeAppPageData?.CloseAppPage.data?.closeAppEmailContent) {
      resetEmail({
        emailContent: 
            `${closeAppPageData?.CloseAppPage.data?.closeAppEmailContent}`?.replace("{end date}", moment(getValues('endDate')).format('MM/DD/YYYY'))?.replace("{ministry category}", MinistryCategoriesMap[user?.organizationInfo?.ministryCategory]),
        fromEmail: closeAppPageData.CloseAppPage.data.closeAppFromEmail ?? '',
        bccEmail: closeAppPageData.CloseAppPage.data.closeAppBccEmails ?? '',
        subjectEmail: closeAppPageData.CloseAppPage.data.closeAppSubjectEmail ?? '',
        titleEmail: closeAppPageData.CloseAppPage.data.closeAppTitleEmail ?? '',
        linkEmail: 'n/a',
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, closeAppPageData, resetEmail]);

  const [updateUserAllInfo] = useMutation(UpdateUserAllInfoDocument, {});

  const [updateUserStatusMutation] = useMutation(UpdateUserStatusDocument, {
    refetchQueries: [
      {
        query: UserByIdDocument,
        variables: { userId: Number(userId) },
      },
    ],
  });

  const [sendCloseAppEmail] = useMutation(SendCloseAppEmailDocument, {});

  useEffect(() => {
    if (data?.User.data) setUser(data?.User.data);
    if (data?.User.data?.status) setStatus(data?.User.data?.status);
  }, [data?.User.data]);

  useEffect(() => {
    setAutoRedFlag(user?.autoRedFlag);
    setAutoYellowFlag(user?.autoYellowFlag);
    setPriorAppKey(user?.priorApplicationKey);
  }, [user]);

  useEffect(() => {
    if (user) {
      console.log(user?.endDate )
      reset({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        phone: user?.phone || '',
        email: user?.email || '',
        redFlag: user?.redFlag || false,
        yellowFlag: user?.yellowFlag || false,
        endDate: user?.endDate ? new Date(user?.endDate) : moment().add('days', 365).toDate(),
        acceptedDate: user?.acceptedDate ? new Date(user?.acceptedDate) : null,
        organization: user?.shippingLocations.length > 0 && user?.shippingLocations[0].organization ? user?.shippingLocations[0].organization : undefined,
        title: user?.shippingLocations.length > 0 && user?.shippingLocations[0].title ? user?.shippingLocations[0].title : undefined,
        shippingFirstName: user?.shippingLocations.length > 0 && user?.shippingLocations[0].firstName ? user?.shippingLocations[0].firstName : undefined,
        shippingLastName: user?.shippingLocations.length > 0 && user?.shippingLocations[0].lastName ? user?.shippingLocations[0].lastName : undefined,
        address1: user?.shippingLocations.length > 0 && user?.shippingLocations[0].address1 ? user?.shippingLocations[0].address1 : undefined,
        address2: user?.shippingLocations.length > 0 && user?.shippingLocations[0].address2 ? user?.shippingLocations[0].address2 : undefined,
        city: user?.shippingLocations.length > 0 && user?.shippingLocations[0].city ? user?.shippingLocations[0].city : undefined,
        state: user?.shippingLocations.length > 0 && user?.shippingLocations[0].state ? user?.shippingLocations[0].state : 'AA',
        zipCode: user?.shippingLocations.length > 0 && user?.shippingLocations[0].zipCode ? user?.shippingLocations[0].zipCode : undefined,
        shippingPhone: user?.shippingDetails.length > 0 && user?.shippingDetails[0].phone ? user?.shippingDetails[0].phone : undefined,
        shippingEmail: user?.shippingDetails.length > 0 && user?.shippingDetails[0].email ? user?.shippingDetails[0].email : undefined,
        loadingDock: user?.shippingDetails.length > 0 && user?.shippingDetails[0].loadingDock ? user?.shippingDetails[0].loadingDock : false,
        is501c3: user?.shippingDetails.length > 0 && user?.shippingDetails[0].is501c3 ? user?.shippingDetails[0].is501c3 : false,
        contactInfo: user?.shippingDetails.length > 0 && user?.shippingDetails[0].contactInfo ? user?.shippingDetails[0].contactInfo : undefined,
        ministryCategory: user?.organizationInfo && user?.organizationInfo.ministryCategory ? user?.organizationInfo.ministryCategory : '',
        originalMinistryCategory: user?.organizationInfo && user?.organizationInfo.originalMinistryCategory ? user?.organizationInfo.originalMinistryCategory : user?.organizationInfo.ministryCategory ? user?.organizationInfo.ministryCategory : '',
        orgName: user?.organizationInfo && user?.organizationInfo.orgName ? user?.organizationInfo.orgName : '',
        isManagedByOrg: user?.organizationInfo && user?.organizationInfo.isManagedByOrg ? 'Yes' : 'No',
        differentOrgName: user?.organizationInfo && user?.organizationInfo.differentOrgName ? user?.organizationInfo.differentOrgName : '',
        overseeMinistry: user?.organizationInfo && user?.organizationInfo.overseeMinistry ? user?.organizationInfo.overseeMinistry : '',
        affiliation: user?.organizationInfo && user?.organizationInfo.affiliation ? user?.organizationInfo.affiliation : '',
        ministryIs501c3: user?.organizationInfo && user?.organizationInfo.is501c3 ? 'Yes' : 'No',
        employerId: user?.organizationInfo && user?.organizationInfo.employerId ? user?.organizationInfo.employerId : '',
        hasWebsite: user?.organizationInfo && user?.organizationInfo.hasWebsite ? user?.organizationInfo.hasWebsite : '',
        ministryDetails: user?.organizationInfo && user?.organizationInfo.ministryDetails ? user?.organizationInfo.ministryDetails : '',
        requestForEvent: user?.resourceDistribution && user?.resourceDistribution.requestForEvent ? 'Yes' : 'No',
        eventDate: user?.resourceDistribution && user?.resourceDistribution.eventDate ? new Date(user?.resourceDistribution.eventDate) : new Date(),
        purpose: user?.resourceDistribution && user?.resourceDistribution.purpose ? user?.resourceDistribution.purpose : '',
        howDistributed: user?.resourceDistribution && user?.resourceDistribution.howDistributed ? user?.resourceDistribution.howDistributed : '',
        expectToServe: user?.resourceDistribution && user?.resourceDistribution.expectToServe ? user?.resourceDistribution.expectToServe : '',
        costToAttendee: user?.resourceDistribution && user?.resourceDistribution.costToAttendee ? user?.resourceDistribution.costToAttendee : '',
        heldToDistribute: user?.resourceDistribution && user?.resourceDistribution.heldToDistribute ? user?.resourceDistribution.heldToDistribute : '',
        publicNotice: user?.resourceDistribution && user?.resourceDistribution.publicNotice ? user?.resourceDistribution.publicNotice : '',
        howManyPeople: user?.resourceDistribution && user?.resourceDistribution.howManyPeople ? user?.resourceDistribution.howManyPeople : '',
        appliedBefore: user?.userServe && user?.userServe.appliedBefore ? 'Yes' : 'No',
        approximateTime: user?.userServe && user?.userServe.approximateTime ? user?.userServe.approximateTime : '',
        howHearUs: user?.userServe && user?.userServe.howHearUs ? user?.userServe.howHearUs : '',
        agreeTerm: user?.userServe && user?.userServe.agreeTerm ? user?.userServe.agreeTerm : false,
        exclusionGroup: user?.exclusionGroup?.id ? user?.exclusionGroup.id : -1,
      })
    }
  }, [reset, user])

  const onChangeStatus = async (value: string) => {
    if (value === 'approved') {
      setStatus('approved')

      let exclusionGroupData: any = {}
      if (getValues('exclusionGroup')) {
        const exclusionGroupResponse = await apolloClient.query({
          query: ExclusionGroupByIdDocument,
          variables: { exclusionGroupId: Number(getValues('exclusionGroup')) },
          fetchPolicy: 'network-only',
        });        
        exclusionGroupData = exclusionGroupResponse?.data
      }

      let emailContent = ''
      if (loginPageData?.RequestLoginPage.data?.loginEmailContent) {
        if (exclusionGroupData?.ExclusionGroup?.data?.endDateDays > 364) {
          emailContent = loginPageData?.RequestLoginPage.data?.loginEmailLongTermContent ?? ''
        } else {
          emailContent = loginPageData?.RequestLoginPage.data?.loginEmailContent
        }
      }

      resetEmail({
        emailContent: emailContent?.replace("{end date}", moment(getValues('endDate')).format('MM/DD/YYYY'))?.replace("{ministry category}", MinistryCategoriesMap[getValues('ministryCategory')]) ?? '',
        fromEmail: loginPageData?.RequestLoginPage?.data?.loginFromEmail ?? '',
        bccEmail: loginPageData?.RequestLoginPage?.data?.loginBccEmails ?? '',
        subjectEmail: loginPageData?.RequestLoginPage?.data?.loginSubjectEmail ?? '',
        titleEmail: loginPageData?.RequestLoginPage?.data?.loginTitleEmail ?? '',
        linkEmail: loginPageData?.RequestLoginPage?.data?.loginLinkEmail ?? '',
      })
      setOpenUserEmailModal(true);
    } else if (value === 'closed') {
      setStatus('closed')
      resetEmail({
        emailContent: `${closeAppPageData?.CloseAppPage.data?.closeAppEmailContent}`?.replace("{end date}", moment(getValues('endDate')).format('MM/DD/YYYY'))?.replace("{ministry category}", MinistryCategoriesMap[getValues('ministryCategory')]),
        fromEmail: closeAppPageData?.CloseAppPage?.data?.closeAppFromEmail ?? '',
        bccEmail: closeAppPageData?.CloseAppPage?.data?.closeAppBccEmails ?? '',
        subjectEmail: closeAppPageData?.CloseAppPage?.data?.closeAppSubjectEmail ?? '',
        titleEmail: closeAppPageData?.CloseAppPage?.data?.closeAppTitleEmail ?? '',
        linkEmail: 'n/a',
      })
      setOpenUserEmailModal(true);
    } else {
      setStatus(value);
      await updateUserStatusMutation({
        mutation: UpdateUserStatusDocument,
        variables: { input: { id: user.id, status: value } },
      });
    }
  };
  const onEmailSubmit = async () => {
    await updateProfile()
    await updateUserStatusMutation({
      mutation: UpdateUserStatusDocument,
      variables: { input: { id: user.id, status } },
    });
    if (status === 'approved') {
      await requestSignIn(
        { input: { ids: [user.id], content: getValuesEmail('emailContent'), link: `${window.location.origin.toString()}/${getValuesEmail('linkEmail')}`, subject: getValuesEmail('subjectEmail'), title: getValuesEmail('titleEmail'), from: getValuesEmail('fromEmail'), bcc: getValuesEmail('bccEmail') ?? '' } }
      );
    } else if (status === 'closed') {
      await sendCloseAppEmail({
        mutation: SendCloseAppEmailDocument,
        variables: { 
          input: {
            id: user.id, 
            content: getValuesEmail('emailContent'), 
            subject: getValuesEmail('subjectEmail'), 
            title: getValuesEmail('titleEmail'), 
            from: getValuesEmail('fromEmail'), 
            bcc: getValuesEmail('bccEmail') 
          }
        },
      });
    }
    setOpenUserEmailModal(false);
  }

  const updateProfile = async (modelClick = false) => {
    const updated = {
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      phone: getValues('phone'),
      email: getValues('email'),
      redFlag: getValues('redFlag'),
      yellowFlag: getValues('yellowFlag'),
      endDate: getValues('endDate'),
      acceptedDate:getValues('acceptedDate'),
      id: user.id,
      status
    }
    let shippingLocation: any = {
      organization: getValues('organization'),
      title: getValues('title'),
      firstName: getValues('shippingFirstName'),
      lastName: getValues('shippingLastName'),
      address1: getValues('address1'),
      address2: getValues('address2'),
      city: getValues('city'),
      state: getValues('state'),
      zipCode: getValues('zipCode'),
    }
    if (user?.shippingLocations && user?.shippingLocations.length > 0) {
      shippingLocation = { ...shippingLocation, id: user?.shippingLocations[0].id }
    }
    let shippingDetail: any = {
      phone: getValues('shippingPhone'),
      email: getValues('shippingEmail'),
      loadingDock: getValues('loadingDock'),
      is501c3: getValues('is501c3'),
      contactInfo: getValues('contactInfo'),
    }
    if (user?.shippingDetails && user?.shippingDetails.length > 0) {
      shippingDetail = { ...shippingDetail, id: user?.shippingDetails[0].id }
    }
    let organizationInfoInput: any = {
      ministryCategory: getValues('ministryCategory'),
      orgName: getValues('orgName'),
      isManagedByOrg: getValues('isManagedByOrg') === 'Yes',
      affiliation: getValues('affiliation'),
      is501c3: getValues('ministryIs501c3') === 'Yes',
      hasWebsite: getValues('hasWebsite'),
      ministryDetails: getValues('ministryDetails'),
    };
    if (getValues('isManagedByOrg') === 'Yes') {
      organizationInfoInput = { ...organizationInfoInput, differentOrgName: getValues('differentOrgName') };
    } else {
      organizationInfoInput = { ...organizationInfoInput, overseeMinistry: getValues('overseeMinistry') };
    }
    if (getValues('ministryIs501c3') === 'Yes') {
      organizationInfoInput = { ...organizationInfoInput, employerId: getValues('employerId') };
    }
    if (user?.organizationInfo && user?.organizationInfo.id) {
      organizationInfoInput = { ...organizationInfoInput, id: user?.organizationInfo.id }
    }
    let resourceDistributionInput: any = {
      requestForEvent: getValues('requestForEvent') === 'Yes',
      purpose: getValues('purpose'),
      howDistributed: getValues('howDistributed'),
    };
    if (getValues('requestForEvent') === 'Yes') {
      resourceDistributionInput = {
        ...resourceDistributionInput,
        eventDate: getValues('eventDate'),
        expectToServe: getValues('expectToServe'),
        costToAttendee: getValues('costToAttendee'),
        heldToDistribute: getValues('heldToDistribute'),
        publicNotice: getValues('publicNotice'),
      };
    } else {
      resourceDistributionInput = {
        ...resourceDistributionInput,
        howManyPeople: getValues('howManyPeople'),
      };
    }
    if (user?.resourceDistribution && user?.resourceDistribution.id) {
      resourceDistributionInput = { ...resourceDistributionInput, id: user?.resourceDistribution.id }
    }
    let userServeInput: any = {
      appliedBefore: getValues('appliedBefore') === 'Yes',
      howHearUs: getValues('howHearUs'),
      agreeTerm: getValues('agreeTerm'),
    }
    if (getValues('appliedBefore') === 'Yes') {
      userServeInput = { ...userServeInput, approximateTime: getValues('approximateTime') };
    }
    if (user?.userServe && user?.userServe.id) {
      userServeInput = { ...userServeInput, id: user?.userServe.id }
    }

    const { data } = await updateUserAllInfo(
      {
        mutation: UpdateUserAllInfoDocument,
        variables: {
          user: {
            user: updated,
            shippingLocation,
            shippingDetail,
            organizationInfoInput,
            resourceDistributionInput,
            userServeInput,
          },
          exclusionGroup: getValues('exclusionGroup')
        },
      }
    );

    if (!modelClick) {
      if (data?.UpdateUserAllInfo.status) navigate(-1);
    }
  }

  return (
    <Box px={2}>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Box mb={1} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5'>Contact Information</Typography>
          {user?.requests && user?.requests.length > 0 &&
            <Button variant='text' size='large' sx={{ textTransform: 'capitalize' }} href={`/app/request/${user.requests[(user?.requests?.length ?? 1) - 1].id}`}>View Request</Button>}
        </Box>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='firstName'
              label='First Name'
              size='small'
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='lastName'
              label='Last Name'
              size='small'
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='phone'
              label='Phone'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='email'
              label='Email'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
       </Grid>
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Box mb={1} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5'>Inquiry Status</Typography>
        </Box>
        <Grid container>
          <Grid item xs={6} p={1}>
          <Typography><b>Inquiry Date:  </b> {user?.createTime ? format(new Date(user?.createTime), "MM/dd/yyyy hh:mmaaaaa'm'") : ''}</Typography>
          </Grid>
          <Grid item xs={2} p={1}>
            <FormCheckBoxField
              name='redFlag'
              label='Red Flag'
              size='small'
              control={control}
            />
            <span>{(autoRedFlag) ? 'Auto Red Flag due to ' : ''}<a href={`/app/application/${priorAppKey}`}>{(autoRedFlag) ? priorAppKey : ''}</a></span>
          </Grid>
          <Grid item xs={2} p={1}>
            <FormCheckBoxField
              name='yellowFlag'
              label='Yellow Flag'
              size='small'
              control={control}
            />
            <span>{(autoYellowFlag) ? 'Auto Yellow Flag due to ' : ''}<a href={`/app/application/${priorAppKey}`}>{(autoYellowFlag) ? priorAppKey : ''}</a></span>
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='ministryCategory'
              label='Ministry Category'
              size='small'
              options={MinistryCategories}
              labelComponent={<Typography fontWeight={600}>Ministry Category</Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
             <FormDropdownField
              name='exclusionGroup'
              label='Resource Exclusion Category'
              size='small'
              options={exclusionGroups}
              labelComponent={<Typography fontWeight={600}>Resource Exclusion Group <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              onChange={(event) => {
                const exclusionGroupId = event.target.value as string
                const exGroup = exclusionGroups.find((item: any) => item.value === event.target.value)
                setValue('endDate', moment().add('days', exGroup?.endDateDays ?? 365).toDate())
                setValue('exclusionGroup', exclusionGroupId)
                setExclusionGroupError('')
              }}
              />
              {
                exclusionGroupError 
                  && <>{<Typography margin='4px 14px 0' fontSize={14} color='#d32f2f'>{exclusionGroupError}</Typography>}</>
              }
          </Grid>
          <Grid item xs={4} p={1} >
            <Typography fontWeight={600}>Inquiry Status: </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '4px' }}>
              <Select size='small' value={status} label='' onChange={event => setStatus(event.target.value)}>
                <MenuItem value='received'>Received</MenuItem>
                <MenuItem disabled value='approved'>Approved</MenuItem>
                <MenuItem value='closed'>Closed</MenuItem>
              </Select>
              <Button variant='outlined' onClick={async () => {
                await updateProfile(true)
                onChangeStatus('closed')
                }}>Close+Email
              </Button>
              <Button variant='outlined' onClick={async () => {
                if (parseInt(getValues('exclusionGroup') ?? '-1') === -1) {
                  setExclusionGroupError('Please Select an Exclusion Group before approving')
                } else {
                  await updateProfile(true)
                  onChangeStatus('approved')
                }
                }}>Approve+Email
              </Button>
              <Modal
                open={openUserEmailModal}
                onClose={() => {setOpenUserEmailModal(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: "90vw",
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  overflow: 'scroll',
                  maxHeight: '90vh'
                }}>
                  <Grid item xs={1} p={1} />
                  <Typography id="modal-modal-title" variant="h5" component="h2">{status === 'approved' ? 'Approval' : 'Closed Application'} Email to User</Typography>
                  <Grid sx={{ display: 'flex' }}>
                    <div style={{ width: '75%' }}>
                      <Grid item xs={6} p={1}>
                        <FormTextField
                          name='subjectEmail'
                          label='Subject'
                          size='small'
                          rows={1}
                          labelComponent={<Typography fontWeight={600}>Subject <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
                          control={controlEmail}
                        />
                      </Grid>
                      <Grid item xs={6} p={1}>
                        <FormTextField
                          name='titleEmail'
                          label='Title'
                          size='small'
                          rows={1}
                          labelComponent={<Typography fontWeight={600}>Title <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
                          control={controlEmail}
                        />
                      </Grid>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Grid item xs={6} p={1}>
                        <Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Ministry Category</Typography>
                        {MinistryCategoriesMap[getValues('ministryCategory')]}
                      </Grid>
                      <Grid item xs={6} p={1}>
                        <Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Request Due Date</Typography>
                        {`${moment(getValues('endDate') ? getValues('endDate')?.toISOString() : '').format('MM/DD/YYYY')}`}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item p={1}>
                    <FormEditorField
                      name='emailContent'
                      label='Body'
                      labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Body <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
                      rows={1}
                      defaultValue={getValuesEmail('emailContent')}
                      inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                      containerProps={{ sx: { mt: 1 } }}
                      control={controlEmail}
                      menubar=''
                      toolbar = "bold italic underline | help |"
                    />
                  </Grid>
                  {/* Text Field for the CMS to control the login route in the email sent to applicants */}
                  {/* <Grid item xs={6} p={1}>
                    <FormTextField
                      name='linkEmail'
                      label='Login Link'
                      size='small'
                      rows={1}
                      labelComponent={<Typography fontWeight={600}>Login Link <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
                      control={controlEmail}
                    />
                  </Grid> */}
                  {Object.keys(errorsEmail).length > 0 && <Grid item sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}><Typography fontWeight={600} color='#f00'>Some fields are not valid</Typography></Grid>}
                  <Grid item p={1} sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                  }}>
                    <Button variant='outlined' sx={{ width: 200 }} onClick={handleSubmitEmail(onEmailSubmit)}>{status === 'approved' ? 'Approve' : 'Close Application'}</Button>
                  </Grid>
                </Box>
              </Modal>
            </div>
          </Grid>
          <Grid item xs={2} p={1}>
             <Typography fontWeight={600}>Approved Date</Typography>
             <FormDateField name='acceptedDate' label='' control={control} />
          </Grid>
          <Grid item xs={2} p={1}>
            <Typography fontWeight={600}>Request Due Date</Typography>
            <FormDateField name='endDate' label='' control={control} />
          </Grid>
          <Grid item xs={2} p={1}>
            <Typography fontWeight={600}>   Update All Fields: </Typography>
            <Box display='flex' alignItems='center' justifyContent='center'>
              {Object.keys(errors).length > 0 && <Typography fontWeight={600} color='#f00'>Some fields are not valid</Typography>}
              <Typography fontWeight={600}> </Typography>
              <Button variant='outlined' onClick={handleSubmit(() => { updateProfile(false) })}>Update Inquiry Status</Button>
            </Box>
          </Grid>
        </Grid>
          {/* need to stay on page after the above 'Update'??  */}
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Commercial Shipping Address</Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='organization'
              label='Destination Organization'
              size='small'
              labelComponent={<Typography fontWeight={600}>Destination Organization </Typography>}
              disabled={true}
              control={control}
              inputProps={{ sx: { backgroundColor: '#fff' } }}
              />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='title'
              label='Title'
              size='small'
              options={[{ label: '-- Select --', value: null }, ...TitleOptions]}
              labelComponent={<Typography fontWeight={600}>Title </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingFirstName'
              label='First Name'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>First Name </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingLastName'
              label='Last Name'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>Last Name </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address1'
              label='Address 1'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>Address 1 </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address2'
              label='Address 2'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>Address 2</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='city'
              label='City'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>City </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='state'
              label='State'
              size='small'
              options={StateOptions}
              labelComponent={<Typography fontWeight={600}>State</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='zipCode'
              label='Zip Code'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>Zip Code</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1} display='flex' alignItems='flex-end'>
            <Button variant='outlined' sx={{ mb: 0.5 }} onClick={() => setMapModalVisible(true)}>View Map</Button>
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='shippingPhone'
              label='Phone Number'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone Number </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingEmail'
              label='Email Address'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email Address </Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='loadingDock'
              label='Is there a loading dock at this location?'
              size='small'
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              labelComponent={<Typography fontWeight={600}>Is there a loading dock at this location?</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='contactInfo'
              label='Alternate Contact Name and PHone'
              size='small'
              placeholder=''
              labelComponent={<Typography fontWeight={600}>Alternate Contact Name and Phone</Typography>}
              control={control}
              disabled={true}
            />
          </Grid>
        </Grid>

      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Ministry & Organization Information</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='originalMinistryCategory'
          size='small'
          options={MinistryCategories}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Ministry Category<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        <FormTextField
          name='orgName'
          label='Name of Ministry or Organization'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Name of Ministry or Organization<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        <FormRadioField
          name='isManagedByOrg'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry managed by or overseen by another established ministry/organization or church<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        {watch('isManagedByOrg') === 'Yes' &&
          <FormTextField
            name='differentOrgName'
            label='Please provide the name of the overseeing ministry/organization, if different than above.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please provide the name of the overseeing ministry/organization, if different than above. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        {watch('isManagedByOrg') === 'No' &&
          <FormTextField
            name='overseeMinistry'
            label='Who does oversee this ministry?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Who does oversee this ministry? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        <FormTextField
          name='affiliation'
          label='What is your affiliation with this ministry/organization?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is your affiliation with this ministry/organization?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        <FormRadioField
          name='ministryIs501c3'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry/organization a 501c3?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        {watch('ministryIs501c3') === 'Yes' &&
          <FormTextField
            name='employerId'
            label='Enter the organization Employer ID Number (EIN):'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Enter the organization Employer ID Number (EIN): <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
            disabled={true}
          />
        }
        <FormTextField
          name='hasWebsite'
          label='Does the ministry have a website or presence online?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Does the ministry have a website or presence online?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        <FormTextField
          name='ministryDetails'
          label='Describe this ministry in detail.'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe this ministry in detail.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
          rows={5}
        />
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Resource Distribution</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='requestForEvent'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Are you requesting for an event with a specific date?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        {watch('requestForEvent') === 'Yes' &&
          <FormDateField
            name='eventDate'
            label=''
            size='small'
            type='datetime-local'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please select the date for the event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            rows={1}
            containerProps={{ sx: { mt: 1 } }}
            disabled={true}
          />
        }
        <FormTextField
          name='purpose'
          label='Describe the purpose and goals for distributing the resources.'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe the purpose and goals for distributing the resources.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
          rows={5}
        />
        <FormTextField
          name='howDistributed'
          label='How will the resources be distributed? '
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How will the resources be distributed? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
          rows={5}
        />
        {watch('requestForEvent') === 'Yes' && <>
          <FormTextField
            name='expectToServe'
            label='How many do you expect to serve at this event?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many do you expect to serve at this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='costToAttendee'
            label='What is the cost to attendees for this event?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is the cost to attendees for this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='heldToDistribute'
            label='Where will the event be held to distribute these resources?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Where will the event be held to distribute these resources? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='publicNotice'
            label='Describe any advertising or public notices for this event.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe any advertising or public notices for this event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        </>}
        {watch('requestForEvent') === 'No' &&
          <FormTextField
            name='howManyPeople'
            label='How many people will be served over the next three months?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many people will be served over the next three months? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Help Us Serve You Better</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='appliedBefore'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Have you previously applied for resources from The 1687 Foundation?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
        {watch('appliedBefore') === 'Yes' &&
          <FormTextField
            name='approximateTime'
            label='Please tell us the approximate time frame, email and name used previously.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please tell us the approximate time frame, email and name used previously. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            disabled={true}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        <FormTextField
          name='howHearUs'
          label='How did you hear about us?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How did you hear about us?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={control}
          disabled={true}
        />
      </Box>

      <Comments userId={Number(user?.id)} comments={user?.comments} trigger={trigger} setTrigger={setTrigger} />

      <Modal open={mapModalVisible} onClose={() => setMapModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box width='50%' height={800}>
            <LoadScript googleMapsApiKey={apikey}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14} />
            </LoadScript>
          </Box>
          <Box width='50%' height={800}>
            <LoadScript googleMapsApiKey={apikey}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
                <StreetViewPanorama options={{ position: center, pov: { heading: 0, pitch: 0 }, visible: true }} />
              </GoogleMap>
            </LoadScript>
          </Box>
        </Box>
      </Modal>

    </Box>
  )
}

export default UserEditContainer;
