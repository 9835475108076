import React from 'react';
import { Grid, Typography } from '@mui/material';

const pageHeaderStyle = {
  backgroundColor: '#d2cbbd',
  paddingTop: '5px',
  paddingBottom: '25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};


const pageHeaderStyle1 = {
  backgroundColor: '#d2cbbd',
  paddingTop: '26px',
  paddingBottom: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};


const pageHeaderTopStyle = {
  fontFamily: "Sorts Mill Goudy",
  fontSize: '2.6rem',
  fontWeight: 400,
  paddingTop: '9px',
  paddingBottom: '8px',
  lineHeight: 1.1,
  maxWidth: '70%',
  textAlign: 'center',
  textTransform: 'uppercase',

  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  },
};
const pageHeaderBottomStyle = {
  fontFamily: "Sorts Mill Goudy",
  fontSize: '6.2rem',
  lineHeight: 1.3,
  textAlign: 'center',

  '@media (max-width: 600px)': {
    fontSize: '2rem',
    lineHeight: 1,
  },
};

const pageHeaderBottomStyle1 = {
  fontFamily: "Sorts Mill Goudy",
  fontSize: '6.1rem',
  lineHeight: 1.3,
  textAlign: 'center',

  '@media (max-width: 600px)': {
    fontSize: '2rem',
    lineHeight: 1,
  },
};

export interface PageHeaderProps {
  subTitle?: string;
  mainTitle: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ subTitle, mainTitle }) => {
  return (
    <>
      {
        subTitle ? <Grid item xs={12} sx={pageHeaderStyle}>
            <Typography sx={pageHeaderTopStyle}>{subTitle}</Typography>
            <Typography sx={pageHeaderBottomStyle}>{mainTitle}</Typography>
          </Grid>
          :
          <Grid item xs={12} sx={pageHeaderStyle1}>
            <Typography sx={pageHeaderBottomStyle1}>{mainTitle}</Typography>
          </Grid>
      }
    </>
  )
}

export default PageHeader;
