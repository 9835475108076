import React from 'react'
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

export interface FormCheckboxFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const FormCheckBoxField = ({ name, control, label, size = 'medium', disabled = false }: FormCheckboxFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <div>
        <FormControlLabel label={label} sx={{ mb: 0 }} control={<Checkbox checked={value} onChange={onChange} />} disabled={disabled} />
        {error && <FormHelperText sx={{ mt: 0, fontSize: 18 }} error>{error.message}</FormHelperText>}
      </div>
    )}
  />
)

export default FormCheckBoxField;
