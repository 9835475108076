import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import { NormalParagraphHTMLContent } from '../../components/common/NormalParagraph';
import { useTestimonialByUrlLazyQuery } from '../../graphql/generated/graphql';
import { TestimonialResponseData } from '../../const/type';
import { BookSlides, HuntingSlides, RetreatSlides, WoodySlides } from '../../const/slide';

const TestimonialDetailPage = () => {
  const { testimonialUrl } = useParams();

  const [testimonialData, setTestimonialData] = useState<TestimonialResponseData | null>(null);

  const [getTestimonial, { data: testimonialResponse }] = useTestimonialByUrlLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    testimonialUrl && getTestimonial({ variables: { url: testimonialUrl } });
  }, [testimonialUrl, getTestimonial]);

  useEffect(() => {
    if (testimonialResponse?.TestimonialByUrl.data) setTestimonialData(testimonialResponse.TestimonialByUrl.data);
  }, [testimonialResponse]);

  const headers = useMemo(() => {
    if (testimonialData?.headLine) {
      const _headLine = testimonialData.headLine;
      return _headLine.split('\n');
    }
    return ['', ''];
  }, [testimonialData]);

  const blockData = useMemo(() => {
    if (testimonialData?.blocks && testimonialData.blocks.length > 0) {
      return testimonialData.blocks.map((_item) => ({
        sideContent: _item.sideContent,
        content: _item.content,
      }));
    }
    return [];
  }, [testimonialData]);

  const slides = useMemo(() => {
    if (testimonialUrl?.includes('book')) return BookSlides;
    if (testimonialUrl?.includes('retreat')) return RetreatSlides;
    if (testimonialUrl?.includes('hunting')) return HuntingSlides;
    if (testimonialUrl?.includes('woody')) return WoodySlides;
    return [];
  }, [testimonialUrl])

  return (
    <Grid container>
      <PageSlide images={slides} />
      <PageHeader subTitle={headers[0]} mainTitle={headers[1]} />
      <Grid container bgcolor='#f9f1df' p={3} display='flex' justifyContent='center'>
        <Grid item xs={0} xl={1.5} />
        <Grid item xs={12} lg={4} xl={3} pl={2} pr={2}>
          <Grid item xs={12} pt={2}>
            <NormalParagraphHTMLContent label={testimonialData?.sideContent ?? ''} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={6} pl={2} pr={2} pt={2}>
          <NormalParagraphHTMLContent label={testimonialData?.content ?? ''} />
        </Grid>
        <Grid item xs={0} xl={1.5} />
      </Grid>
      {blockData && blockData.length > 0 && blockData.map((_block) => (
        <Grid container bgcolor='#f9f1df' p={3} display='flex' justifyContent='center'>
          <Grid item xs={0} xl={1.5} />
          <Grid item xs={12} lg={4} xl={3} pl={2} pr={2}>
            <Grid item xs={12} pt={2}>
              <NormalParagraphHTMLContent label={_block.sideContent ?? ''} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} xl={6} pl={2} pr={2} pt={2}>
            <NormalParagraphHTMLContent label={_block?.content ?? ''} />
          </Grid>
          <Grid item xs={0} xl={1.5} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TestimonialDetailPage;
