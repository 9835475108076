/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import RequestedByForm from './forms/RequestByForm';
import RequestShippingLocationForm from './forms/NewRequestShippingLocationForm';
import RequestOfficeUseForm from './forms/NewRequestOfficeUseForm';
// import RequestAdditionalInfoForm from './forms/RequestAdditionalInfoForm';
// import RequestResourceSelect from './forms/RequestResourceSelect';
// import RequestUserSelect from './forms/RequestUserSelect';
import { showSuccess } from '../../libs/utils/toasts';
import { CreateRequestInput, OfficeUseState, useCreateRequestByAdminMutation } from '../../graphql/generated/graphql';
import {
  RequestOfficeUseValueType,
  RequestShippingLocationValueType,
  ResourceSelectionType,
} from './type';
// import { defaultResourceGroup } from './const';
import { UserResponseData } from '../../const/type';
import { useAuth } from '../../contexts/auth';
import { useMutation } from '@apollo/client';
import { SignUpDocument, UpdateUserStatusDocument } from '../../graphql/generated/graphql';
import RequestResourceSelect from './forms/RequestResourceSelect';

export const RequestNewContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [user] = useState<UserResponseData | null>(null);
  const [resourceGroup, setResourceGroup] = useState<ResourceSelectionType[]>([]);
  const [requestOfficeUse, setRequestOfficeUse] = useState<RequestOfficeUseValueType | null>(null);
  const [open, setOpen] = useState(false);
  // const [signature, setSignature] = useState<string | null>(null);

  const [createRequestMutation] = useCreateRequestByAdminMutation();
  const [signupMutation] = useMutation(SignUpDocument, {});

  const [updateUserStatusMutation] = useMutation(UpdateUserStatusDocument, {});

  const validationSchema = Yup.object().shape({
    userId: Yup.number().required('User is required'),
    userFirstName: Yup.string().nullable(),
    userLastName: Yup.string().nullable(),
    // userFirstName: Yup.string().required('First Name is required'),
    // userLastName: Yup.string().required('Last Name is required'),
    userEmail: Yup.string().required('Email is required'),
    userPhone: Yup.string()
      .required('Phone number is required')
      .test('is-valid-length', 'Phone number must be 10 digits', (value) => {
        return value?.length === 0 || value?.length === 10;
      }),
    userMinistryName: Yup.string().nullable(),
    // userMinistryName: Yup.string().required('Ministry/Org name is required'),
    ministryCategory: Yup.string().required('Ministry Category is required'),
    userYellowFlag: Yup.bool().nullable(),
    userRedFlag: Yup.bool().nullable(),
    requestedDate: Yup.date().nullable(),
    shippedDate: Yup.date().nullable(),
    title: Yup.string().nullable(),
    resourceGroup: Yup.array().nullable(),
    // resourceGroup: Yup.array()
    //   .test('is-valid-length', 'Select at least one resource', (values) => {
    //     return (values?.every(value => value?.resource !== 0 && (value?.resourceCases > 0 || value.resourceSingles > 0)));
    //   }),
    organization: Yup.string().required('Destination Organization is required'),
    // firstName: Yup.string().trim().required('First Name is required'),
    // lastName: Yup.string().trim().required('Last Name is required'),
    shippingFirstName: Yup.string().trim().required('First Name is required'),
    shippingLastName: Yup.string().trim().required('Last Name is required'),
    shippingEmail: Yup.string().required('Email is required'),
    phone: Yup.string()
      .required('Phone number is required')
      .test('is-valid-length', 'Phone number must be 10 digits', (value) => {
        return value?.length === 0 || value?.length === 10;
      }),
    // email: Yup.string().required('Email is required'),
    address1: Yup.string().trim().required('Address 1 is required'),
    address2: Yup.string().nullable(),
    city: Yup.string().trim().required('City Name is required'),
    state: Yup.string()
      .trim()
      .required('State is required')
      .test('is-valid-length', 'State must be selected', (value) => {
        return Boolean(value) && value !== ' ' && value !== 'AA';
      }),
    zipCode: Yup.string().required('Zip Code is required'),
    is501c3: Yup.boolean().nullable(),
    isLoadingDock: Yup.boolean().nullable(),
    additionalContactInfo: Yup.string().nullable(),
    digitalSignature: Yup.string().required('This field is required'),
    status: Yup.string()
      .trim()
      .nullable()
      .test('is-valid-length', 'Status must be selected', (value) => {
        return Boolean(value) && value !== ' ';
      }),
    moreInfo: Yup.bool(),
    previous: Yup.bool(),
    usps: Yup.bool(),
    category: Yup.bool(),
    wsGmGs: Yup.bool(),
    freightDelivery: Yup.bool(),
    shippingCarrier: Yup.string().trim(),
    monitorTracking: Yup.bool(),
    feedbackFormRequested: Yup.bool(),
    feedbackReceived: Yup.bool(),
    upsLocation: Yup.string().trim(),
    trackingInfo: Yup.string().trim(),
    resourceUse: Yup.string().required('Resource use is required')
  });

  const requestedByValue = useMemo(() => {
    return {
      userFirstName: user?.firstName ?? '',
      userLastName: user?.lastName ?? '',
      userPhone: user?.phone ?? null,
      userEmail: user?.email ?? null,
      requestedDate: new Date(),
      shippedDate: null,
      userRedFlag: user?.redFlag ?? false,
      userYellowFlag: user?.yellowFlag ?? false,
      userMinistryName: user?.ministryName ?? '',
      ministryCategory: '',
    };
  }, [user]);

  const requestShippingLocationValue: RequestShippingLocationValueType =
    useMemo(() => {
      return {
        title:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].title
            : null,
        organization:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].organization
            : '',
        firstName:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].firstName
            : '',
        lastName:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].lastName
            : '',
        phone:
          user?.shippingDetails && user?.shippingDetails.length
            ? user?.shippingDetails[0].phone
            : '',
        email:
          user?.shippingDetails && user?.shippingDetails.length
            ? user?.shippingDetails[0].email
            : '',
        address1:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].address1
            : '',
        address2:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].address2
            : '',
        city:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].city
            : '',
        state:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].state
            : null,
        zipCode:
          user?.shippingLocations && user?.shippingLocations?.length
            ? user?.shippingLocations[0].zipCode
            : '',
        is501c3:
          user?.shippingDetails && user?.shippingDetails.length
            ? user?.shippingDetails[0].is501c3
            : false,
        isLoadingDock:
          user?.shippingDetails && user?.shippingDetails.length
            ? user?.shippingDetails[0].loadingDock
            : false,
        additionalContactInfo:
          user?.shippingDetails && user?.shippingDetails.length
            ? user?.shippingDetails[0].contactInfo
            : '',
      };
    }, [user]);
  const [shippingLocationValue, setShippingLocationValue] =
    useState<RequestShippingLocationValueType>(requestShippingLocationValue);

  useEffect(() => { setShippingLocationValue(requestShippingLocationValue) }, [requestShippingLocationValue])

  const methods = useForm({
    values: {
      userId: user?.id ?? 0,
      userFirstName: '',
      userLastName: '',
      // userFirstName: me?.firstName ?? '',
      // userLastName: me?.lastName ?? '',
      userEmail: 'info@1687foundation.com',
      userPhone: '0000000000',
      userMinistryName: requestedByValue.userMinistryName ?? '',
      ministryCategory: 'Inmate',
      userYellowFlag: requestedByValue.userYellowFlag ?? false,
      userRedFlag: requestedByValue.userRedFlag ?? false,
      requestedDate: requestedByValue.requestedDate ?? null,
      shippedDate: requestedByValue.shippedDate ?? null,
      organization: '',
      // organization: shippingLocationValue?.organization ?? '',
      title: shippingLocationValue?.title ?? 'None',
      // firstName: me?.firstName ?? '',
      // lastName: me?.lastName ?? '',
      shippingFirstName: shippingLocationValue?.firstName ?? '',
      shippingLastName: shippingLocationValue?.lastName ?? '',
      phone: shippingLocationValue?.phone ?? '',
      // email: me?.email ?? '',
      shippingEmail: shippingLocationValue?.email ?? '',
      address1: shippingLocationValue?.address1 ?? '',
      address2: shippingLocationValue?.address2 ?? '',
      city: shippingLocationValue?.city ?? '',
      state: shippingLocationValue?.state ?? ' ',
      zipCode: shippingLocationValue?.zipCode ?? '',
      is501c3: shippingLocationValue?.is501c3 ?? false,
      isLoadingDock: shippingLocationValue?.isLoadingDock ?? false,
      additionalContactInfo: shippingLocationValue?.additionalContactInfo ?? '',
      status: requestOfficeUse?.status ?? OfficeUseState.Received,
      moreInfo: requestOfficeUse?.moreInfo ?? false,
      previous: requestOfficeUse?.previous ?? false,
      usps: requestOfficeUse?.usps ?? false,
      category: requestOfficeUse?.category ?? false,
      wsGmGs: requestOfficeUse?.wsGmGs ?? false,
      freightDelivery: requestOfficeUse?.freightDelivery ?? false,
      shippingCarrier: requestOfficeUse?.shippingCarrier ?? ' ',
      monitorTracking: requestOfficeUse?.monitorTracking ?? false,
      feedbackFormRequested: requestOfficeUse?.feedbackFormRequested ?? false,
      feedbackReceived: requestOfficeUse?.feedbackReceived ?? false,
      upsLocation: requestOfficeUse?.upsLocation ?? ' ',
      trackingInfo: requestOfficeUse?.trackingInfo ?? '',
      digitalSignature: `${me?.firstName} ${me?.lastName}`,
      resourceUse: shippingLocationValue?.resourceUse ?? '',
      resourceGroup: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, watch, getValues, setValue, formState: { errors } } = methods;

  useEffect(() => {
    setValue('resourceGroup', resourceGroup)
  }, [resourceGroup])

  const onSubmit = async (updatedData: FieldValue<any>, type: string) => {
    setOpen(true)
    const _resourceGroup = resourceGroup.filter((item) => item.resource);
    // if (_resourceGroup.length === 0) return;

    const { data: newUser } = await signupMutation({
      mutation: SignUpDocument,
      variables: { data: {
        user: {
          email: updatedData.userEmail,
          firstName: updatedData.userFirstName,
          lastName: updatedData.userLastName,
          ministryName: updatedData.userMinistryName,
          phone: updatedData.userPhone,
        },
        organizationInfoInput: {
          ministryCategory: updatedData.ministryCategory,
          orgName: updatedData.userMinistryName,
          ministryDetails: '',
          isManagedByOrg: false,
          affiliation: '',
          is501c3: false,
          hasWebsite: ''
        }
      } },
    });

    await updateUserStatusMutation({
      mutation: UpdateUserStatusDocument,
      variables: { input: { id: newUser?.SignUp?.data?.id ?? '', status: 'approved' } },
    });

    const payload: CreateRequestInput = {
      user: { id: updatedData.userId },
      selections: _resourceGroup.map(_item => ({ resourceId: _item.resource as any, cases: _item.cases, singles: _item.singles }))
    };
    payload.user = {
      id: newUser?.SignUp?.data?.id ?? '',
      ministryCategory: updatedData.ministryCategory,
      phone: updatedData.userPhone,
      // redFlag: updatedData.redFlag,
      // yellowFlag: updatedData.yellowFlag,
    };

    const _updateOfficeUse = {
      status: updatedData.status,
      moreInfo: updatedData.moreInfo,
      previous: updatedData.previous,
      usps: updatedData.usps,
      category: updatedData.category,
      wsGmGs: updatedData.wsGmGs,
      freightDelivery: updatedData.freightDelivery,
      shippingCarrier: updatedData.shippingCarrier,
      monitorTracking: updatedData.monitorTracking,
      feedbackFormRequested: updatedData.feedbackFormRequested,
      feedbackReceived: updatedData.feedbackReceived,
      upsLocation: updatedData.upsLocation,
      trackingInfo: updatedData.trackingInfo,
    };

    payload.officeUse = { ..._updateOfficeUse };
    if (
      (Object.keys(_updateOfficeUse).includes('shippingCarrier') &&
        _updateOfficeUse['shippingCarrier'] === ' ') ||
      _updateOfficeUse['shippingCarrier'] === ''
    ) {
      payload.officeUse = { ...payload.officeUse, shippingCarrier: null };
    }
    if (
      (Object.keys(_updateOfficeUse).includes('upsLocation') &&
        _updateOfficeUse['upsLocation'] === ' ') ||
      _updateOfficeUse['upsLocation'] === ''
    ) {
      payload.officeUse = { ...payload.officeUse, upsLocation: null };
    }

    const _updatedShippingLocation = {
      title: updatedData.title,
      organization: updatedData.organization,
      firstName: updatedData.shippingFirstName,
      lastName: updatedData.shippingLastName,
      address1: updatedData.address1,
      address2: updatedData.address2,
      city: updatedData.city,
      state: updatedData.state,
      zipCode: updatedData.zipCode,
    };

    payload.shippingLocation = { ..._updatedShippingLocation };

    const _updatedShippingDetail = {
      contactInfo: updatedData.additionalContactInfo,
      email: updatedData.shippingEmail,
      is501c3: updatedData.is501c3,
      loadingDock: updatedData.isLoadingDock,
      phone: updatedData.phone,
      resourceUse: updatedData.resourceUse
    };

    payload.shippingDetail = { ..._updatedShippingDetail };

    const _updatedSignature = { sign: updatedData.digitalSignature };
    payload.signature = { ..._updatedSignature };

    if (updatedData.shippedDate) payload.shippedTime = updatedData.shippedDate;
    if (updatedData.requestedDate) payload.requestTime = updatedData.requestedDate;

    const { data } = await createRequestMutation({
      variables: { request: payload }
    })
    if (data && data.CreateRequest.status) {
      showSuccess('This request was updated successfully');
      if (type === 'saveAndExit') navigate(-1);
      console.log(`/app/request/${data.CreateRequest.data}`)
      if (type === 'save') navigate(`/app/request/${data.CreateRequest.data}`);
      setOpen(true)
    }
  };

  return (
      <Box px={3}>
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5' fontWeight={600}>Create Request</Typography>
        </Box>
      {/*
      <Box border='1px solid lightgray' p={2}>
        <Stack
          direction='column'
          spacing={1}
          justifyContent='center'
          sx={{ width: 'fit-content', background: 'white', mt: '-28px', padding: '0px 8px' }}
        >
          <Typography variant='body1' sx={{ fontWeight: '800' }}>Create Request</Typography>
        </Stack>
        */}
        {/* <RequestUserSelect setUser={setUser} control={control} /> */}
        {/* <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
          <Typography fontWeight={600}>Inquiry Information</Typography>
          <Grid container>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='firstName'
                label='First Name'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>First Name</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='lastName'
                label='Last Name'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Last Name</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormPhoneField
                name='userPhone'
                label='Phone'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Phone Number</Typography>}
                control={control}
                inputProps={{ sx: { width: '100%', height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} pr={3} pl={1}>
              <FormTextField
                name='email'
                label='Email'
                size='small'
                type='email'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Email</Typography>}
                control={control}
                rows={1}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
            </Grid>
          </Grid>
        </Box> */}
        <RequestedByForm editableMinistryCategory control={control} getValues={getValues} />
        <RequestShippingLocationForm
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          setShippingLocationValue={setShippingLocationValue}
        />
        {/* <RequestAdditionalInfoForm control={control} setSignature={setSignature} /> */}
        <RequestOfficeUseForm 
          control={control} 
          getValues={getValues} 
          setRequestOfficeUse={setRequestOfficeUse} 
          autoRedFlag={false} 
          autoYellowFlag={false} 
          priorApplicationKey={0} 
          setSignature={() => { }}
          setValue={setValue} 
        />
        {/* <RequestBookedResources isNewRequest resourceGroup={resourceGroup} setResourceGroup={setResourceGroup} getValues={getValues} /> */}
        <RequestResourceSelect errors={errors} resourceGroup={resourceGroup} setResourceGroup={setResourceGroup} />
        
        <Grid container direction='column' justifyContent='end' alignItems='end' my={5}>
            <Grid item>
              {Object.keys(errors).length > 0 && <Typography mb={1} fontWeight={600} color='#f00'>Some fields are not valid</Typography>}
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Button variant='outlined' onClick={() => handleSubmit((data) => onSubmit(data, "save"))()}>Save</Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={() => handleSubmit((data) => onSubmit(data, "saveAndExit"))()}>Save and exit</Button>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
     
      </Box>
  );
}
