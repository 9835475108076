import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const imageStyle = {
  width: '100%',
  border: '15px solid #231f20',
  marginBottom: 12,
  cursor: 'pointer',
};

const titleBoxStyle = {
  '&:hover': {
    color: '#d2232a',
  },
};

const titleStyle = {
  fontFamily: 'Barlow,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '1.25rem',
  fontWeight: 600,
  cursor: 'pointer',
};

const subTitleStyle = {
  fontFamily: 'Barlow,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '1rem',
  fontStyle: 'italic'
};

const authorStyle = {
  fontFamily: 'Barlow,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '1.25rem',
  fontWeight: 300,
};

const descriptionStyle = {
  marginTop: '20px',
  fontFamily: 'Barlow,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '13px',
};

export interface ResourceCardProps {
  width?: number;
  image: any;
  title: string;
  subTitle?: string;
  author?: string;
  description?: string;
  onClick?: () => void;
}

const ResourceCard: React.FC<ResourceCardProps> = ({
  width = 3,
  image,
  title,
  subTitle,
  author,
  description,
  onClick,
}) => {
  return (
    <Grid item xs={12} md={width} p={1} onClick={onClick}>
      <img src={image} loading='lazy' style={{ ...imageStyle, objectFit: 'cover' }} alt='' />
      <Box sx={titleBoxStyle}>
        <Typography sx={titleStyle}>{title}</Typography>
        {subTitle && <Typography sx={subTitleStyle}>{subTitle}</Typography>}
        {author && <Typography sx={authorStyle}>{author}</Typography>}
      </Box>
      <Typography sx={descriptionStyle}>{description}</Typography>
    </Grid>
  );
};

export default ResourceCard;
