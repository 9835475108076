import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import RoleNewContainer from '../../components/roles/RoleNewContainer';

const RoleNewPage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changePermission) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changePermission, navigate])

  return (
    <Box>
      <RoleNewContainer />
    </Box>
  )  
}

export default RoleNewPage;
