import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { PostsContainer } from '../../components/posts/PostsContainer';

const PostsPage: FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.viewCMS) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.viewCMS, navigate])

  return (
    <Box>
      <PostsContainer />
    </Box>
  );
};

export default PostsPage;
