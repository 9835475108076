import React, { useState } from 'react';
import { Box, Button, Grid, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns'
import { AddCommentDocument, DeleteCommentDocument, UpdateCommentDocument } from '../../graphql/generated/graphql';
import { CommentResponseData } from '../../const/type';
import { useNavigate } from 'react-router'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const modalBoxStyle = {
  width: '50%',
  backgroundColor: '#fff',
  borderRadius: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

interface CommentsProps {
  userId: number | undefined;
  comments: CommentResponseData[] | undefined | null;
  trigger: number;
  setTrigger: (trigger: number) => void;
}

const Comments = ({ userId, comments = [], trigger, setTrigger }: CommentsProps) => {

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const navigate = useNavigate()

  const [addComment] = useMutation(AddCommentDocument, {});
  const [updateComment] = useMutation(UpdateCommentDocument, {});
  const [deleteComment] = useMutation(DeleteCommentDocument, {});
  const [createDate, setCreateDate] = useState(Date.now());

  const onAdd = async () => {
    const { data } = await addComment({
      mutation: AddCommentDocument,
      variables: { comment: { userId: Number(userId), comment, createTime: createDate } }
    })
    if (data?.AddComment.status) {
      setAddModalVisible(false);
      setTrigger(trigger + 1);
      navigate(0)
    }
  }

  const onEdit = async () => {
    const { data } = await updateComment({
      mutation: UpdateCommentDocument,
      variables: { comment: { id: selectedId, comment, createTime: createDate } }
    })
    if (data?.UpdateComment.status) {
      setEditModalVisible(false);
      setTrigger(trigger + 1);
      navigate(0)
    }
  }

  const onDelete = async (id: number) => {
    const { data } = await deleteComment({
      mutation: DeleteCommentDocument,
      variables: { commentId: id }
    })
    if (data?.DeleteComment.status) setTrigger(trigger + 1);
  }

  return (
    <Box bgcolor='white' borderRadius={2} my={3} p={2} boxShadow={3}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography fontWeight={600}>Comments</Typography>
        <Button variant='contained' sx={{ textTransform: 'none' }} onClick={() => { setComment(''); setCreateDate(Date.now());setAddModalVisible(true) }}>Add Comment</Button>
      </Box>
      {comments && <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={80} align='center'><Typography fontSize={16}>Actions</Typography></TableCell>
              <TableCell><Typography fontSize={16}>Comment</Typography></TableCell>
              <TableCell><Typography fontSize={16}>Name</Typography></TableCell>
              <TableCell><Typography fontSize={16}>Time Stamp</Typography></TableCell>
              {/* <TableCell><Typography fontSize={16}>Update Time</Typography></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {comments.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='center'>
                  <EditIcon onClick={() => { setComment(row.comment); setCreateDate(row.createTime); setSelectedId(row.id); setEditModalVisible(true) }} />
                  <DeleteIcon onClick={() => onDelete(row.id)} />
                </TableCell>
                <TableCell><Typography fontSize={16}>{row.comment}</Typography></TableCell>
                <TableCell><Typography fontSize={16}>{row?.user?.firstName} {row?.user?.lastName}</Typography></TableCell>
                <TableCell><Typography fontSize={16}>{format(new Date(row.createTime), "MM/dd/yyyy hh:mmaaaaa'm'")}</Typography></TableCell>
                {/* <TableCell><Typography fontSize={16}>{format(new Date(row.updateTime), "MM/dd/yyyy hh:mmaaaaa'm'")}</Typography></TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>}
      <Modal open={addModalVisible} onClose={() => setAddModalVisible(false)}>
        <Box sx={modalBoxStyle} p={5}>
          <Grid container display='flex' alignItems='center'>
            <Grid item width='100%' display='flex'>
              <Grid item width='75%'>
                <TextField multiline maxRows={25} label='Comment' value={comment} sx={{ width: '100%' }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)} />
              </Grid>
              <Grid item width='20%'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker label='Time Stamp' value={createDate} sx={{ width: '100%', mx: 1 }} onChange={(value: any) => {setCreateDate(value)}} />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '100%', marginRight: '5%', marginTop: '20px' }}>
              <Button variant='contained' sx={{ float: 'right' }} onClick={onAdd}>Update</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={editModalVisible} onClose={() => setEditModalVisible(false)}>
        <Box sx={modalBoxStyle} p={5}>
          <Grid container display='flex' alignItems='center'>
            <Grid item width='100%' display='flex'>
              <Grid item width='75%'>
                <TextField multiline maxRows={25} label='Comment' value={comment} sx={{ width: '100%' }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)} />
              </Grid>
              <Grid item width='20%'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker label='Time Stamp' value={new Date(createDate)} sx={{ width: '100%', mx: 1 }} onChange={(value: any) => {setCreateDate(value)}} />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '100%', marginRight: '5%', marginTop: '20px' }}>
              <Button variant='contained' sx={{ float: 'right' }} onClick={onEdit}>Update</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}

export default Comments;
