import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import { NormalParagraphHTMLContent } from '../../components/common/NormalParagraph';
import { MinistryResourceCard } from '../../components/resources/MinistryResourceCard';
import { useMinistryByUrlLazyQuery } from '../../graphql/generated/graphql';
import { MinistriesResponseData } from '../../const/type';
import { BookSlides, HuntingSlides, RetreatSlides, WoodySlides } from '../../const/slide';
import '../../const/gallery.css';

const MinistryDetailPage = () => {
  const { ministryUrl } = useParams();

  const [ministryData, setMinistryData] = useState<MinistriesResponseData | null>(null);

  const [getMinistry, { data: ministryResponse }] = useMinistryByUrlLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    ministryUrl && getMinistry({ variables: { ministryUrl: ministryUrl } });
  }, [ministryUrl, getMinistry]);

  useEffect(() => {
    if (ministryResponse?.MinistryByUrl.data) setMinistryData(ministryResponse.MinistryByUrl.data);
  }, [ministryResponse]);

  const headers = useMemo(() => {
    if (ministryData?.headLine) {
      const _headLine = ministryData.headLine;
      return _headLine.split('\n');
    }
    return ['', ''];
  }, [ministryData]);

  const childData = useMemo(() => {
    if (ministryData?.children && ministryData.children.length > 0) {
      return ministryData.children.map((_item) => ({
        label: _item.name,
        url: `/ministries/${_item.url}`,
      }));
    }
    return [];
  }, [ministryData]);

  const slides = useMemo(() => {
    if (ministryUrl?.includes('book')) return BookSlides;
    if (ministryUrl?.includes('retreat')) return RetreatSlides;
    if (ministryUrl?.includes('hunting')) return HuntingSlides;
    if (ministryUrl?.includes('woody')) return WoodySlides;
    if (ministryUrl?.includes('at-risk')) return [];
    return [];
  }, [ministryUrl])

  return (
    <Grid container>
      <PageSlide images={slides} />
      <PageHeader subTitle={headers[0]} mainTitle={headers[1]} />
      <Grid container bgcolor='#f9f1df' p={3} display='flex' justifyContent='center'>
        <Grid item xs={0} xl={1.5} />
        <Grid item xs={12} lg={4} xl={3} pl={2} pr={2}>
          {(ministryData?.sideImage || ministryData?.parent?.sideImage) && (
            <Box mb={6}>
              <img
                src={`${ministryData.sideImage || ministryData.parent?.sideImage}`}
                loading='lazy'
                style={{ width: '100%' }}
                alt='resource-cover'
              />
            </Box>
          )}
          {childData.map((child) => (
            <Box key={child.url} sx={{ marginBottom: '1.5rem' }}>
              <Button
                variant='outlined'
                href={child.url}
                sx={{
                  width: '100%',
                  border: 'solid 2px #231f20',
                  fontFamily: 'Barlow',
                  fontSize: '1.75rem',
                  fontWeight: 500,
                  color: '#231f20',
                  '&:hover': {
                    border: 'solid 2px #231f20',
                  },
                }}
              >
                {child.label}
              </Button>
            </Box>
          ))}
          <Grid item xs={12} pt={5}>
            <NormalParagraphHTMLContent label={ministryData?.sideContent ?? ''} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={6} pl={2} pr={2} pt={5}>
          <NormalParagraphHTMLContent label={ministryData?.content ?? ''} />
          <Box mt={4}>
            {ministryData?.testimonials && ministryData.testimonials.length > 0 && ministryData.testimonials.map((_testmonical) => (
              <>
                <NormalParagraphHTMLContent label={_testmonical.summary ?? ''} />
                <Typography sx={{ fontFamily: 'Barlow', fontSize: '1.75rem', fontWeight: 500 }}>
                  {_testmonical.name ?? ''}
                </Typography>
              </>
            ))}
          </Box>
          {ministryData?.btnLabel && (
            <Box width='100%' bgcolor='#f9f1df' mt={2.5} display='flex' alignItems='center' justifyContent='flex-end'>
              <a href={ministryData?.btnUrl ? (ministryData?.btnUrl.includes('http') ? ministryData?.btnUrl : `/${ministryData?.btnUrl}`) : ''} className='btn btn-lg btn-custom'>{ministryData?.btnLabel}</a>
            </Box>
          )}
        </Grid>
        <Grid item xs={0} xl={1.5} />
      </Grid>
      <Grid container bgcolor='#f9f1df' p={3} display='flex' justifyContent='center'>
        <Grid item xs={0} xl={1.5} />
        <Grid item xs={12} xl={9} pl={1} pr={1} pt={5} container display='flex' justifyContent='center'>
          {ministryData?.resources && ministryData.resources.length > 0 && ministryData.resources.map((resource) => (
            <Grid key={resource.id} item xs={6} md={4} lg={3}>
              <MinistryResourceCard resource={resource} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={0} xl={1.5} />
      </Grid>
      {ministryData?.resources && ministryData.resources.length > 0 && (
        <Box width='100%' bgcolor='#f9f1df' py={3} display='flex' alignItems='center' justifyContent='center'>
          <a href='/resources' className='btn btn-lg btn-custom'>Additional Resources</a>
        </Box>
      )}
    </Grid>
  );
};

export default MinistryDetailPage;
