import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { FetchResult, useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import FormDropdownField, { DropDownItem } from '../../libs/ui/FormDropdownField';
import FormTextField from '../../libs/ui/FormTextField';
import FormNumberField from '../../libs/ui/FormNumberField';
import FormSwitch from '../../libs/ui/FormSwitch';
import { uploadFile } from '../../libs/utils/fileupload';
import CircularLoading from '../../libs/ui/Loading';
import {
  GetPreSignedUrlDocument,
  GetPreSignedUrlMutation,
  IResource,
  ResourceInput,
  useCategoryListQuery
} from '../../graphql/generated/graphql';
import { Editor } from '@tinymce/tinymce-react';

const modalStyle = {
  width: 800,
  maxHeight: 850,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
  boxShadow: 24,
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

interface ResourceFormProps {
  value: ResourceInput;
  setValue: (value: ResourceInput) => void;
  onCancel: () => void;
  onSave: (data: ResourceInput) => void;
  onEdit: boolean
}

export const ResourceForm = ({ value, onCancel, onSave, onEdit }: ResourceFormProps) => {

  const [categories, setCategories] = useState<DropDownItem[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | undefined | null>();
  const [resDescription, setResDescription] = useState<string | undefined>('');

  const [getPreSignedUrlMutation] = useMutation(GetPreSignedUrlDocument);

  useEffect(() => {
    if (value.imageUrl) setPreviewImage(value.imageUrl)
  }, [value])

  const { data } = useCategoryListQuery({
    variables: { page: 1, pageSize: 10000 },
  })

  useEffect(() => {
    if (data?.Categories.items) {
      const _options = data?.Categories.items.map((item: Partial<IResource>) => {
        return { value: item.id ?? '', label: item.title ?? '' }
      })
      _options && setCategories(_options)
    }
    setResDescription(value.description ?? '');
  }, [data, value.description]);

  const validationSchema = Yup.object().shape({
    sku: Yup.string().required('Sku is required'),
    title: Yup.string().required('Title is required'),
    subTitle: Yup.string(),
    author: Yup.string(),
    description: Yup.string().required('Description is required'),
    quantityInCase: Yup.number().required('Quantity in Case is required'),
    caseWeight: Yup.number().required('CaseWeight is required'),
    categoryId: Yup.number().required('Category is required'),
    imageUrl: Yup.string(),
    isPublished: Yup.bool(),
    isActive: Yup.bool(),
    exclusiveOffer: Yup.bool(),
    sortingOrder: Yup.number().required('Sorting order is required')
  })

  const methods = useForm({
    values: {
      sku: value.sku,
      title: value.title,
      subTitle: value.subTitle,
      author: value.author,
      description: value.description ?? '',
      // description: resDescription ?? '',
      quantityInCase: value.quantityInCase ?? 0,
      caseWeight: value.caseWeight ?? 0,
      categoryId: value.categoryId,
      imageUrl: value.imageUrl,
      isPublished: value.isPublished ?? true,
      isActive: value.isActive ?? true,
      exclusiveOffer: value.exclusiveOffer ?? false,
      sortingOrder: value.sortingOrder ?? 0
    },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, control, setValue } = methods

  const onSubmit = async (data: FieldValue<any>) => {
    setIsLoading(true);
    const imageUrl = await handleFileUpload();
    setIsLoading(false);
    onSave({ ...data, imageUrl });
  }

  const onEditorBlur = async (e: any) => {
    // setResDescription(e.target.getContent());
    setValue('description', e.target.getContent());
  }

  const handleFileUpload = async () => {
    if (file) {
      const { name, type } = file;
      const presignedName = `${new Date().getTime()}-${name}`;
      const { data }: FetchResult<GetPreSignedUrlMutation> = await getPreSignedUrlMutation({
        mutation: GetPreSignedUrlDocument,
        variables: { key: { 'id': presignedName } },
      });
      if (data?.GetPreSignedUrl.status) {
        return await uploadFile({ file: file, presignedUrl: `${data?.GetPreSignedUrl.data}`, type, presignedName })
      }
    }
    return value.imageUrl
  }

  const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const _file = target?.files && target?.files[0];
    setFile(_file)

    if (_file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result)
      }
      reader.readAsDataURL(_file)
    }
  };

  return (
    <Box sx={modalStyle}>
      <Grid container>
        <Grid item xs={5} pr={1}>
          <Typography variant='h5' id='delete-category-modal-description' sx={{ mb: 3 }}>{onEdit ? 'Edit Resource' : 'Add Resource'}</Typography>
          <Box width={202} position='relative' lineHeight={1}>
            {!previewImage && (
              <Box width={200} height={150}>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  sx={{ width: '100%', height: '100%', border: 'solid 1px gray', borderRadius: '8px', position: 'absolute' }}
                >
                  <input hidden accept='image/*' type='file' onChange={handleFileSelected} />
                  <PhotoCamera fontSize='large' />
                </IconButton>
              </Box>
            )}
            {previewImage && (
              <>
                <img
                  src={`${previewImage}`}
                  alt={'resource-cover'}
                  loading='lazy'
                  style={{ width: '200px', border: 'solid 1px gray', borderRadius: '8px' }}
                />
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  sx={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}
                >
                  <input hidden accept='image/*' type='file' onChange={handleFileSelected} />
                  <PhotoCamera fontSize='large' sx={{ stroke: 'white' }} />
                </IconButton>
              </>
            )}
          </Box>
          <FormSwitch
            name='exclusiveOffer'
            label='Exclusive'
            containerProps={{ sx: { width: 180, mt: 1, display: 'flex', justifyContent: 'flex-end' } }}
            labelComponent={<Typography mt={0.75}>Exclusive</Typography>}
            control={control}
          />
          <FormSwitch
            name='isPublished'
            label='Is Published'
            containerProps={{ sx: { width: 180, mt: 1, display: 'flex', justifyContent: 'flex-end' } }}
            labelComponent={<Typography mt={0.75}>Is Published</Typography>}
            control={control}
          />
          <FormSwitch
            name='isActive'
            label='Is Active'
            containerProps={{ sx: { width: 180, mt: 1, display: 'flex', justifyContent: 'flex-end' } }}
            labelComponent={<Typography mt={0.75}>Is Active</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={7} pr={1} mt={2}>
          <FormTextField
            name='sku'
            label='SKU'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mb={0.5}>SKU <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
          <FormTextField
            name='title'
            label='Title'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Title <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
          <FormTextField
            name='subTitle'
            label='SubTitle'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>SubTitle <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
          <FormTextField
            name='author'
            label='Author'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Author <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
          {/* <FormTextField
            name='description'
            label='Description'
            size='small'
            // labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Description <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          /> */}

          {<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Description <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          <Editor
            apiKey='mquzuse4wxx1kxur0nae7obrrhjb1461kgo9hrbrx5fskonp'
            // onInit={(_evt, editor) => editorRef.current = editor}
            textareaName='description'
            initialValue={resDescription}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              init_instance_callback: (editor) => {
                editor.on('blur', (e) => {
                  onEditorBlur(e);
                })
              }
            }}
          />

          <FormNumberField
            name='quantityInCase'
            label='Quantity in Case'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Quantity in Case <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            type='number'
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />

          <FormNumberField
            name='caseWeight'
            label='Case Weight'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Case Weight <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            type='number'
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />

          <FormDropdownField
            name='categoryId'
            label='Categories'
            size='small'
            options={categories}
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Categories <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            sx={{ background: 'white' }}
          />
          <FormNumberField
            name='sortingOrder'
            label='Sorting Order'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Sorting Order <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            type='number'
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
        <Button variant='outlined' onClick={onCancel}>Cancel</Button>
        <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>Save</Button>
      </Box>

      {isLoading && <CircularLoading />}
    </Box>
  );
};
