import React from 'react';
import { Box } from '@mui/material';

export interface CenterBoxProps {
  children: any;
  customStyle?: any;
}

const CenterBox: React.FC<CenterBoxProps> = ({ children, customStyle = {} }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' sx={customStyle}>{children}</Box>
  )
}

export default CenterBox;
