import { Divider, Link, TableCell, TableRow, Typography, styled } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';

const StyledTypography = styled(Typography)({
  marginBottom: '1.25rem',
  fontFamily: 'Sorts Mill Goudy',
  fontSize: '1.5rem',
  lineHeight: 1.3,
});

const HandSpan = styled('span')({
  marginBottom: '1.25rem',
  fontFamily: 'LTC Goudy Oldstyle W01 Italic',
  fontSize: '1.5rem',
  lineHeight: 1.3,
});

const StyledDivider = styled(Divider)({
  borderWidth: 1.5,
  borderColor: 'gray',
  marginTop: 0,
  paddingTop: -8,
  transform: 'translateY(-8px)'
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&>th,': {
    backgroundColor: theme.palette.action.hover,
    padding: '5px 10px',
    borderTop: 'solid 1px lightgray',
    borderBottom: 'solid 1px lightgray',
  },
  '&:last-child td': {
    borderBottom: 0,
  },
  '& td:last-child, & th:last-child': {
    // borderRight: 0,
    padding: 0,
  },
  '& td:first-of-type, & th:first-of-type': {
    borderLeft: 'solid 1px lightgray',
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: 'solid 1px lightgray',
  padding: '5px 10px',
}));

const StyledTreeItem = styled(TreeItem)( ({theme}) =>({
  [`& .${treeItemClasses.label}`]: {
    fontFamily: 'Sorts Mill Goudy !important',
    fontSize: '28px!important',
    color: '#282ba5!important',
  }
}))

const StyledLink = styled(Link)({
  fontFamily: 'Sorts Mill Goudy',
  fontSize: '24px',
  color: '#282ba5',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  }
})

export { StyledTypography, StyledDivider, HandSpan, StyledTableRow, StyledTableCell, StyledTreeItem, StyledLink };
