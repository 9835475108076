import {
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DropDownItem } from "../../../libs/ui/FormDropdownField";
import { ResourceSelectionType } from "../type";

export const ResourceSelectItem = ({
  resources,
  resourceGroup,
  onChange,
  addNew = false,
  onAdd,
  onRemove
}: {
  resources: DropDownItem[];
  resourceGroup: ResourceSelectionType;
  onChange: (data: ResourceSelectionType) => void;
  addNew?: boolean;
  onAdd: () => void;
  onRemove: () => void;
}) => {
  const handleChange = (data: Partial<ResourceSelectionType>) => {
    onChange({ ...resourceGroup, ...data });
  };

  const selectedResource: any = resources.find(r => r.value === resourceGroup.resource);

  const getOrderQty = () => {
    const calc = (selectedResource?.quantityInCase * resourceGroup.cases) + resourceGroup.singles
    return isNaN(calc) ? '-' : calc
  }
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3} lg={2.5} my={1.5}>
        <Stack sx={{ width: "100%" }}>
            
            <Select
              labelId={`select-dropdown-resource-label`}
              id={`select-dropdown-resource}`}
              value={isNaN(resourceGroup.resource as any) ? 0 : resourceGroup.resource}
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              size={"small"}
              onChange={(e) => {
                handleChange({ resource: Number(e.target.value) as any });
              }}
            >
              {resources.map((option, index) => (
                <MenuItem
                  key={`${option.value}-${option.label}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            
        </Stack>
      </Grid>
      <Grid item xs={12} md={2.5} lg={2} my={1.5}>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                minWidth: "100px",
                lineHeight: "40px",
              }}
            >
              Cases QTY
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              onChange={(e) => {
                const newCases = Math.max(0, Number(e.target.value));
                handleChange({ cases: newCases as any });
              }}
              value={selectedResource?.quantityInCase ? selectedResource?.quantityInCase : "-" }
              fullWidth
              disabled
              size="small"
            />
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item xs={12} md={2.5} lg={2} my={1.5}>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                minWidth: "100px",
                lineHeight: "40px",
              }}
            >
              Order QTY
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={getOrderQty()}
              fullWidth
              disabled
              size="small"
            />
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item xs={12} md={2} lg={1.5} my={1.5}>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                minWidth: "100px",
                lineHeight: "40px",
              }}
            >
              Cases
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              onChange={(e) => {
                const newCases = Math.max(0, Number(e.target.value));
                handleChange({ cases: newCases as any });
              }}
              value={resourceGroup.cases}
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item xs={12} md={2} lg={1.5} my={1.5}>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                minWidth: "100px",
                lineHeight: "40px",
              }}
            >
              Singles
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              onChange={(e) => {
                handleChange({ singles: Math.max(0, Number(e.target.value)) });
              }}
              value={resourceGroup.singles}
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item xs={12} md={3} lg={2.5} my={1.5} >
        <Grid container direction='row' spacing={10}>
          {
            addNew && ( 
            <Grid item xs={5}>
               <Button variant="outlined" sx={{ whiteSpace: 'nowrap' }} onClick={onAdd}>Add Resource</Button>
            </Grid>)
          }
          
          <Grid item xs={5}>
          <Button variant="outlined" sx={{ whiteSpace: 'nowrap', }} onClick={onRemove}>Remove</Button>
          </Grid>
        </Grid>
        
      </Grid>

    </Grid>
  );
};
