import React, { useState, useMemo, useEffect } from 'react';
import {
  IconButton,
  Paper,
  TablePagination,
  TableContainer,
  TableBody,
  Table,
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import { PostTableHead } from './PostsTableHeader';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import SearchInput from '../../libs/ui/SearchInput';
import FormTextField from '../../libs/ui/FormTextField';
import FormEditorField from '../../libs/ui/FormEditorField';
import { showSuccess } from '../../libs/utils/toasts';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { dateToString } from '../../libs/utils/datetimeutil';
import {
  CreateNewsCategoryDocument,
  GetNewsCategoryDocument,
  IPost,
  PostsDocument,
  SortDirectionField,
  UpdateNewsCategoryDocument,
  useDeletePostMutation,
  useGetNewsCategoryQuery,
  usePostsQuery
} from '../../graphql/generated/graphql';
import { PostsSortField, headCells, postsOrder } from './const';

export const PostsContainer = () => {
  const navigate = useNavigate();
  const { ministryId } = useParams();
  const { me } = useAuth();

  const [newsCategory, setNewsCategory] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [rows, setRows] = useState<Partial<IPost>[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState<string>('');
  const [sort, setSort] = useState<PostsSortField>(PostsSortField.Date_Published);
  const [sortDirection, setSortDirection] = useState<SortDirectionField>(SortDirectionField.Desc);

  const validationSchema = Yup.object().shape({
    headLine: Yup.string().required('This field is required'),
    sideContent: Yup.string().required('This field is required'),
  });

  const methods = useForm({
    defaultValues: { headLine: newsCategory?.headLine ?? '', sideContent: newsCategory?.sideContent ?? '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, reset, setValue } = methods;

  const [createNewsCategoryMutation] = useMutation(CreateNewsCategoryDocument, {
    refetchQueries: [{
      query: GetNewsCategoryDocument,
      variables: { newsCategoryId: ministryId! },
    }]
  });
  const [updateNewsCategoryMutation] = useMutation(UpdateNewsCategoryDocument, {
    refetchQueries: [{
      query: GetNewsCategoryDocument,
      variables: { newsCategoryId: ministryId! },
    }]
  });

  const [deletePostMutation] = useDeletePostMutation({
    refetchQueries: [
      {
        query: PostsDocument,
        variables: { page: 1, pageSize: rowsPerPage, ministryId: ministryId! },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const { data: newsCategoryData } = useGetNewsCategoryQuery({ variables: { newsCategoryId: ministryId! } });

  useEffect(() => {
    if (newsCategoryData?.GetNewsCategory.data) {
      setNewsCategory(newsCategoryData?.GetNewsCategory.data);
      setValue('headLine', newsCategoryData?.GetNewsCategory.data?.headLine);
      setValue('sideContent', newsCategoryData?.GetNewsCategory.data?.sideContent);
    }
  }, [newsCategoryData, setValue])

  const { data: postsData} = usePostsQuery({
    variables: { page: page + 1, pageSize: rowsPerPage, ministryId: ministryId! }
  });

  useEffect(() => {
    if (postsData?.Posts) {
      setTotalCount(postsData.Posts.total);
      setRows(() => {
        return [...postsData.Posts.items];
      })
    }
  }, [postsData])

  const onSubmit = async (data: FieldValue<any>) => {
    if (newsCategory?.id) {
      const { data: updateResponse } = await updateNewsCategoryMutation({
        variables: {
          newsCategory: { ...data, newsCategoryId: ministryId!, id: newsCategory?.id }
        }
      })
      if (updateResponse?.UpdateNewsCategory?.status) {
        showSuccess('Update successful');
        reset();
      }
    } else {
      const { data: saveResponse } = await createNewsCategoryMutation({
        variables: {
          newsCategory: { ...data, newsCategoryId: ministryId! }
        }
      });
      if (saveResponse?.CreateNewsCategory?.status) {
        showSuccess('Save successful');
        reset();
      }
    }
  };

  const handleResort = (sortKey: string) => {
    const _sortKey = postsOrder.find((item) => item.key === sortKey);
    if (sort === _sortKey?.name) {
      setSortDirection((_dic) => {
        if (_dic === SortDirectionField.Asc) return SortDirectionField.Desc;
        return SortDirectionField.Asc;
      });
    } else {
      setSort(_sortKey?.name ?? PostsSortField.Date_Published);
      setSortDirection(SortDirectionField.Desc);
    }
  };

  const handleDelete = async() => {
    if (deleteId) {
      const {data} = await deletePostMutation({
        variables: { postId: Number(deleteId) }
      })
      if (data?.DeletePost.status) {
        setRows(_rows => {return (_rows.filter(_row => _row.id !== deleteId))})
        showSuccess('Post was removed successfully');
      }
    }
    setDeleteModalVisible(false);
    setDeleteId(undefined);
  }

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const orderBy = useMemo(() => {
    const _sortItem = postsOrder.find((item) => item.name === sort);
    return _sortItem?.key ?? 'id';
  }, [sort]);

  return (
    <Box width='100%' p={2.5}>
      <Grid container>
        <Grid item xs={12} md={1.5} />
        <Grid item xs={12} md={9} bgcolor='white' borderRadius={2} p={2} boxShadow={3}>
          <FormTextField
            name='headLine'
            label='HeadLine'
            labelComponent={<Typography minWidth={180} fontSize={14} fontWeight={600} lineHeight='50px'>HeadLine</Typography>}
            size='small'
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { display: 'flex', flexDirection: 'row'} }}
            control={control}
          />
          <FormEditorField
            name='sideContent'
            label='Side Content'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Side Content</Typography>}
            rows={1}
            defaultValue={newsCategory?.sideContent ?? ''}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            control={control}
          />
          <Box mt={2.5} display='flex' alignItems='center' justifyContent='center' >
            <Button variant='contained' sx={{ width: 200 }} onClick={handleSubmit(onSubmit)}>{newsCategory?.id ? 'Update' : 'Save'}</Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={1.5} />
      </Grid>

      <Paper sx={{ width: '100%'}}>
        <CustomTableToolbar
          title='Posts'
          numSelected={0}
          onDelete={() => {}}
          onAdd={() => navigate('./create')}
          disableAction={!me?.isSuperuser && !me?.role?.editRequest}
          searchBar={
            <SearchInput
              onSearch={(txt: string) => {
                setPage(0);
                setSearchKey(txt);
              }}
              searchKey={searchKey}
            />
          }
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 210px)' }}>
          <Table
            sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}
            aria-labelledby='tableTitle'
            size='small'
          >
            <PostTableHead
              headCells={headCells}
              order={sortDirection === SortDirectionField.Asc ? 'asc' : 'desc'}
              onRequestSort={handleResort}
              orderBy={orderBy}
            />
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <StyledTableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                    <TableCell align='left' sx={{ width: '100px' }}>{row.title}</TableCell>
                    <TableCell align='left'>{dateToString(row.publishedTime)}</TableCell>
                    <TableCell align='left' sx={{ width: '200px' }}>{row.isEnabled && <CheckIcon sx={{ color: 'green' }} />}</TableCell>
                    {(me?.isSuperuser || me?.role?.editRequest) && (
                      <TableCell align='right' sx={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}>
                        <Box width='80px'>
                          <IconButton onClick={() => navigate(`./${row.id}`)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => { setDeleteId(row.id); setDeleteModalVisible(true) }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
        />
      </Paper>

      <DeleteModal
        title='Are you sure delete this request?'
        open={deleteModalVisible}
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />
    </Box>
  );
}
