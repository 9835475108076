import { Box, Button, Grid, Typography } from '@mui/material';
import { Control, UseFormGetValues } from 'react-hook-form';
import FormDropdownField from '../../../libs/ui/FormDropdownField';
import FormTextField from '../../../libs/ui/FormTextField';
import { RequestOfficeUseValueType } from '../type';
import { ValidateAddressDocument } from '../../../graphql/generated/graphql';
import apolloClient from '../../../graphql/apolloClient';

interface OfficeUseFormProps {
  control: Control<any>
  setRequestOfficeUse?: (data: RequestOfficeUseValueType | null) => void;
  getValues?: UseFormGetValues<any>;
  setSignature?: (data: string | null) => void;  // unused
  autoRedFlag: boolean;
  autoYellowFlag: boolean;
  priorApplicationKey: number;
  setValue?: any;
}


const RequestOfficeUseForm = ({ setValue, control, getValues, setRequestOfficeUse, autoRedFlag, autoYellowFlag, priorApplicationKey }: OfficeUseFormProps) => {
  const handleOnChange = () => {
    if (setRequestOfficeUse && getValues) {
      setRequestOfficeUse({
        status: getValues('status'),
        usps: getValues('usps'),
        category: getValues('category'),
        wsGmGs: getValues('wsGmGs'),
        freightDelivery: getValues('freightDelivery'),
        shippingCarrier: getValues('shippingCarrier'),
        monitorTracking: getValues('monitorTracking'),
        feedbackFormRequested: getValues('feedbackFormRequested'),
        feedbackReceived: getValues('feedbackReceived'),
        upsLocation: getValues('upsLocation'),
        trackingInfo: getValues('trackingInfo'),
        autoRedFlag: getValues('autoRedFlag'),
        autoYellowFlag: getValues('autoYellowFlag'),
        priorApplicationKey: getValues('priorApplicationKey')
      })
    }
  }

  return (
    <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
      <Typography fontWeight={600}>Office Use</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='status'
                label='Status'
                size='small'
                options={[
                   { label: 'Received', value: 'Received' },
                   { label: 'Printed', value: 'Printed' },
                   { label: 'OnHold', value: 'OnHold' },
                   { label: 'Shipped', value: 'Shipped' },
                   { label: 'Closed', value: 'Closed' },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Status</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='category'
                label='Category'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Category</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='freightDelivery'
                label='Freight Delivery'
                size='small'
                options={[
                  { label: 'Approved', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '30px' }}>Freight Delivery</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
              <Button 
                sx={{ display: 'flex', width: 'calc(100% - 100px)', marginLeft: '100px', marginRight: '8px', marginTop: '8px', marginBottom: '4px' }}
                onClick={async () => { 
                  const { data } = await apolloClient.query({
                    query: ValidateAddressDocument,
                    variables: { addressLine1: getValues ? getValues("address1") : '', addressLine2: getValues ? getValues("address2") : '', city: getValues ? getValues("city") : '', state: getValues ? getValues("state") : '', zip: getValues ? getValues("zipCode") : '', },
                    fetchPolicy: 'network-only',
                  });
                  setValue('upsLocation', data?.ValidateAddress?.data?.type ?? ' ')
                }} 
                variant='text' > Validate Address
              </Button>
              <FormDropdownField
                name='upsLocation'
                label='UPS Location'
                size='small'
                options={[
                  { label: '-- Select --', value: ' ' },
                  { label: 'Commercial', value: 'Commercial' },
                  { label: 'Residential', value: 'Residential' },
                  { label: 'Unknown', value: 'Unknown' },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>UPS Validated as:</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='wsGmGs'
                label='WS/GM/GS'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>WS/GM/GS</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
              <FormDropdownField
                name='shippingCarrier'
                label='Shipping Carrier'
                size='small'
                options={[
                  { label: '-- Select --', value: ' ' },
                  { label: 'USPS', value: 'USPS' },
                  { label: 'Freight', value: 'FREIGHT' },
                  { label: 'UPS', value: 'UPS' },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Shipping Carrier</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
                            <FormDropdownField
                name='usps'
                label='USPS'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>USPS</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='feedbackFormRequested'
                label='Feedback Form Requested'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '30px' }}>Feedback Form Requested</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='userRedFlag'
                label='Red Flag'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px', color: '#AA0000' }}>Red Flag</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
              <span>{(autoRedFlag) ? 'Auto Red Flag due to ' : ''}<a href={`/app/application/${priorApplicationKey}`}>{(autoRedFlag) ? priorApplicationKey : ''}</a></span>
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='monitorTracking'
                label='Monitor Tracking'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Monitor Tracking</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='feedbackReceived'
                label='Feedback Received'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '30px' }}>Feedback Received</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onSelect={handleOnChange}
              /> 
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
              <FormDropdownField
                name='userYellowFlag'
                label='Yellow Flag'
                size='small'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, color: '#AAAA00', lineHeight: '40px' }}>Yellow Flag</Typography>}
                control={control}
                sx={{ height: 40, background: 'white' }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              />
              <span>{(autoYellowFlag) ? 'Auto Yellow Flag due to ' : ''}<a href={`/app/application/${priorApplicationKey}`}>{(autoYellowFlag) ? priorApplicationKey : ''}</a></span>
            </Grid>
            <Grid item xs={12} md={6} lg={3} pr={3} pl={1}>
             <FormTextField
                name='trackingInfo'
                label='Tracking Info'
                size='small'
                labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Tracking Info</Typography>}
                control={control}
                inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
                containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
                onChangeInput={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RequestOfficeUseForm;
