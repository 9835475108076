import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useUserByIdQuery } from '../../graphql/generated/graphql';
import AdminUserEditContainer from '../../components/users/AdminUserEditContainer';

const AdminUserEditPage = () => {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changeUsersProfile) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changeUsersProfile, navigate])

  const { data } = useUserByIdQuery({
    variables: { userId: Number(userId) },
    fetchPolicy: 'network-only',
  });

  return (
    <Box>
      {data?.User.data && <AdminUserEditContainer user={data?.User.data} />}
    </Box>
  )
}

export default AdminUserEditPage;
