import React, { useEffect, useState, useMemo } from "react";
import { Form } from "@formio/react";
import { useLocation } from 'react-router-dom';
import { FetchResult, useMutation } from "@apollo/client";
import { SubmitFormDocument, SubmitFormMutation, useFormLazyQuery } from "../../graphql/generated/graphql";

export type Field = {
  name: string;
  label: string;
  required?: boolean;
  type: "text" | "number";
};

const CustomFormView: React.FC<{ formId: number }> = ({ formId }) => {
    const location = useLocation()
    const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search])

  const [formContent, setFormContent] = useState<string>("{}");

  const [getForm, { data: formData }] = useFormLazyQuery({
    variables: { formId: formId },
    fetchPolicy: 'network-only'
  });

  const [submitFormMutation] = useMutation(SubmitFormDocument);

  const submitForm = async (postData: any) => {
    const { data }: FetchResult<SubmitFormMutation> = await submitFormMutation({
      variables: {
        forms: {
          name: formId,
          content: JSON.stringify(postData),
        },
        requestId: queryParameters.get('requestId') ?? ''
      },
    });
    if (data?.SubmitForm.data) {
      if (formId !== 1 && formId !== 2) {
        window.location.reload();
      }
      else {
        if (formId === 1) {
          window.location.replace('/retreat-program-confirmation')
        }
        else if (formId === 2) {
          window.location.replace('/hunting-fishing-confirmation')
        }
      }
    }
  };

  useEffect(() => {
    // console.log("Form Page");
    if (formData?.Form.data) {
      setFormContent(formData.Form.data.content);
      // console.log(formData);
      // console.log(formId);
    } else {
      setFormContent("{}");
    }
  }, [formData]);

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId, getForm]);

  if (!formId) return <></>

  return <Form form={JSON.parse(formContent)} onSubmit={submitForm} />
};

export default CustomFormView;
