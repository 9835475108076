import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormRadioField from '../../libs/ui/FormRadioField';
import FormDateField from '../../libs/ui/FormDateField';
import { Spacer } from '../../libs/ui/Spacer';
import { showSuccess } from '../../libs/utils/toasts';
import { UpdateUserAllInfoDocument, UpdateUserDocument } from '../../graphql/generated/graphql';
import { MinistryCategories, StateOptions, TitleOptions } from '../../const/type';

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changeProfile) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changeProfile, navigate]);

  const [updateUser] = useMutation(UpdateUserDocument, {});
  const [updateUserAllInfo] = useMutation(UpdateUserAllInfoDocument, {});

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords do not match'),
  });

  const passwordMethods = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(passwordValidationSchema),
  });

  const { handleSubmit: passwordHandleSubmit, control: passwordControl, getValues: getPasswordValues } = passwordMethods;

  const updatePassword = async () => {
    const user = {
      password: getPasswordValues('password'),
      id: me?.id,
    };
    const { data } = await updateUser({
      mutation: UpdateUserDocument,
      variables: { user },
    });
    if (data?.UpdateUser.status) showSuccess('This applicant password was updated successfully');
  };

  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().min(10, 'Must be 10 digits'),
    email: Yup.string().email(),
    redFlag: Yup.bool(),
    yellowFlag: Yup.bool(),
    organization: Yup.string().required(),
    title: Yup.string(),
    shippingFirstName: Yup.string().required(),
    shippingLastName: Yup.string().required(),
    address1: Yup.string().required(),
    address2: Yup.string(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required().matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits'),
    shippingPhone: Yup.string().required().min(10, 'Must be 10 digits'),
    shippingEmail: Yup.string().required().email(),
    loadingDock: Yup.bool(),
    is501c3: Yup.bool(),
    contactInfo: Yup.string(),
    rank: Yup.string(),
    ministryCategory: Yup.string().required(),
    orgName: Yup.string().required(),
    isManagedByOrg: Yup.string(),
    differentOrgName: Yup.string(),
    overseeMinistry: Yup.string(),
    affiliation: Yup.string().required(),
    ministryIs501c3: Yup.string(),
    employerId: Yup.string(),
    hasWebsite: Yup.string().required(),
    ministryDetails: Yup.string().required(),
    requestForEvent: Yup.string(),
    eventDate: Yup.date(),
    purpose: Yup.string().required(),
    howDistributed: Yup.string().required(),
    expectToServe: Yup.string(),
    costToAttendee: Yup.string(),
    heldToDistribute: Yup.string(),
    publicNotice: Yup.string(),
    howManyPeople: Yup.string(),
    appliedBefore: Yup.string(),
    approximateTime: Yup.string(),
    howHearUs: Yup.string().required(),
    agreeTerm: Yup.bool(),
  });

  const profileMethods = useForm({
    defaultValues: {
      firstName: me?.firstName || undefined,
      lastName: me?.lastName || undefined,
      phone: me?.phone || undefined,
      email: me?.email || undefined,
      redFlag: me?.redFlag || false,
      yellowFlag: me?.yellowFlag || false,
      organization: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].organization ? me?.shippingLocations[0].organization : '',
      title: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].title ? me?.shippingLocations[0].title : '',
      shippingFirstName: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].firstName ? me?.shippingLocations[0].firstName : '',
      shippingLastName: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].lastName ? me?.shippingLocations[0].lastName : '',
      address1: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].address1 ? me?.shippingLocations[0].address1 : '',
      address2: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].address2 ? me?.shippingLocations[0].address2 : '',
      city: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].city ? me?.shippingLocations[0].city : '',
      state: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].state ? me?.shippingLocations[0].state : '',
      zipCode: me?.shippingLocations && me?.shippingLocations.length > 0 && me?.shippingLocations[0].zipCode ? me?.shippingLocations[0].zipCode : '',
      shippingPhone: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].phone ? me?.shippingDetails[0].phone : '',
      shippingEmail: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].email ? me?.shippingDetails[0].email : '',
      loadingDock: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].loadingDock ? me?.shippingDetails[0].loadingDock : false,
      is501c3: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].is501c3 ? me?.shippingDetails[0].is501c3 : false,
      contactInfo: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].contactInfo ? me?.shippingDetails[0].contactInfo : '',
      rank: me?.shippingDetails && me?.shippingDetails.length > 0 && me?.shippingDetails[0].rank ? me?.shippingDetails[0].rank : '',
      ministryCategory: me?.organizationInfo && me?.organizationInfo.ministryCategory ? me?.organizationInfo.ministryCategory : '',
      orgName: me?.organizationInfo && me?.organizationInfo.orgName ? me?.organizationInfo.orgName : '',
      isManagedByOrg: me?.organizationInfo && me?.organizationInfo.isManagedByOrg ? 'Yes' : 'No',
      differentOrgName: me?.organizationInfo && me?.organizationInfo.differentOrgName ? me?.organizationInfo.differentOrgName : '',
      overseeMinistry: me?.organizationInfo && me?.organizationInfo.overseeMinistry ? me?.organizationInfo.overseeMinistry : '',
      affiliation: me?.organizationInfo && me?.organizationInfo.affiliation ? me?.organizationInfo.affiliation : '',
      ministryIs501c3: me?.organizationInfo && me?.organizationInfo.is501c3 ? 'Yes' : 'No',
      employerId: me?.organizationInfo && me?.organizationInfo.employerId ? me?.organizationInfo.employerId : '',
      hasWebsite: me?.organizationInfo && me?.organizationInfo.hasWebsite ? me?.organizationInfo.hasWebsite : '',
      ministryDetails: me?.organizationInfo && me?.organizationInfo.ministryDetails ? me?.organizationInfo.ministryDetails : '',
      requestForEvent: me?.resourceDistribution && me?.resourceDistribution.requestForEvent ? 'Yes' : 'No',
      eventDate: me?.resourceDistribution && me?.resourceDistribution.eventDate ? new Date(me?.resourceDistribution.eventDate) : new Date(),
      purpose: me?.resourceDistribution && me?.resourceDistribution.purpose ? me?.resourceDistribution.purpose : '',
      howDistributed: me?.resourceDistribution && me?.resourceDistribution.howDistributed ? me?.resourceDistribution.howDistributed : '',
      expectToServe: me?.resourceDistribution && me?.resourceDistribution.expectToServe ? me?.resourceDistribution.expectToServe : '',
      costToAttendee: me?.resourceDistribution && me?.resourceDistribution.costToAttendee ? me?.resourceDistribution.costToAttendee : '',
      heldToDistribute: me?.resourceDistribution && me?.resourceDistribution.heldToDistribute ? me?.resourceDistribution.heldToDistribute : '',
      publicNotice: me?.resourceDistribution && me?.resourceDistribution.publicNotice ? me?.resourceDistribution.publicNotice : '',
      howManyPeople: me?.resourceDistribution && me?.resourceDistribution.howManyPeople ? me?.resourceDistribution.howManyPeople : '',
      appliedBefore: me?.userServe && me?.userServe.appliedBefore ? 'Yes' : 'No',
      approximateTime: me?.userServe && me?.userServe.approximateTime ? me?.userServe.approximateTime : '',
      howHearUs: me?.userServe && me?.userServe.howHearUs ? me?.userServe.howHearUs : '',
      agreeTerm: me?.userServe && me?.userServe.agreeTerm ? me?.userServe.agreeTerm : false,
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const { handleSubmit: profileHandleSubmit, control: profileControl, getValues: getProfileValues, watch } = profileMethods;

  const updateProfile = async () => {
    const user = {
      firstName: getProfileValues('firstName'),
      lastName: getProfileValues('lastName'),
      phone: getProfileValues('phone'),
      email: getProfileValues('email'),
      redFlag: getProfileValues('redFlag'),
      yellowFlag: getProfileValues('yellowFlag'),
      id: me?.id,
    };
    let shippingLocation: any = {
      organization: getProfileValues('organization'),
      title: getProfileValues('title'),
      firstName: getProfileValues('shippingFirstName'),
      lastName: getProfileValues('shippingLastName'),
      address1: getProfileValues('address1'),
      address2: getProfileValues('address2'),
      city: getProfileValues('city'),
      state: getProfileValues('state'),
      zipCode: getProfileValues('zipCode'),
    }
    if (me?.shippingLocations && me?.shippingLocations.length > 0) {
      shippingLocation = { ...shippingLocation, id: me?.shippingLocations[0].id }
    }
    let shippingDetail: any = {
      phone: getProfileValues('shippingPhone'),
      email: getProfileValues('shippingEmail'),
      loadingDock: getProfileValues('loadingDock'),
      is501c3: getProfileValues('is501c3'),
      contactInfo: getProfileValues('contactInfo'),
      rank: getProfileValues('rank'),
      id:  me?.shippingDetails && me?.shippingDetails.length > 0 ? me?.shippingDetails[0].id : null
    }
    if (me?.shippingDetails && me?.shippingDetails.length > 0) {
      shippingDetail = { ...shippingDetail, id: me?.shippingDetails[0].id }
    }
    let organizationInfoInput: any = {
      ministryCategory: getProfileValues('ministryCategory'),
      orgName: getProfileValues('orgName'),
      isManagedByOrg: getProfileValues('isManagedByOrg') === 'Yes',
      affiliation: getProfileValues('affiliation'),
      is501c3: getProfileValues('ministryIs501c3') === 'Yes',
      hasWebsite: getProfileValues('hasWebsite'),
      ministryDetails: getProfileValues('ministryDetails'),
    };
    if (getProfileValues('isManagedByOrg') === 'Yes') {
      organizationInfoInput = { ...organizationInfoInput, differentOrgName: getProfileValues('differentOrgName') };
    } else {
      organizationInfoInput = { ...organizationInfoInput, overseeMinistry: getProfileValues('overseeMinistry') };
    }
    if (getProfileValues('ministryIs501c3') === 'Yes') {
      organizationInfoInput = { ...organizationInfoInput, employerId: getProfileValues('employerId') };
    }
    if (me?.organizationInfo && me?.organizationInfo.id) {
      organizationInfoInput = { ...organizationInfoInput, id: me?.organizationInfo.id }
    }
    let resourceDistributionInput: any = {
      requestForEvent: getProfileValues('requestForEvent') === 'Yes',
      purpose: getProfileValues('purpose'),
      howDistributed: getProfileValues('howDistributed'),
    };
    if (getProfileValues('requestForEvent') === 'Yes') {
      resourceDistributionInput = {
        ...resourceDistributionInput,
        eventDate: getProfileValues('eventDate'),
        expectToServe: getProfileValues('expectToServe'),
        costToAttendee: getProfileValues('costToAttendee'),
        heldToDistribute: getProfileValues('heldToDistribute'),
        publicNotice: getProfileValues('publicNotice'),
      };
    } else {
      resourceDistributionInput = {
        ...resourceDistributionInput,
        howManyPeople: getProfileValues('howManyPeople'),
      };
    }
    if (me?.resourceDistribution && me?.resourceDistribution.id) {
      resourceDistributionInput = { ...resourceDistributionInput, id: me?.resourceDistribution.id }
    }
    let userServeInput: any = {
      appliedBefore: getProfileValues('appliedBefore') === 'Yes',
      howHearUs: getProfileValues('howHearUs'),
      agreeTerm: getProfileValues('agreeTerm'),
    }
    if (getProfileValues('appliedBefore') === 'Yes') {
      userServeInput = { ...userServeInput, approximateTime: getProfileValues('approximateTime') };
    }
    if (me?.userServe && me?.userServe.id) {
      userServeInput = { ...userServeInput, id: me?.userServe.id }
    }

    const { data } = await updateUserAllInfo(
      {
        mutation: UpdateUserAllInfoDocument,
        variables: {
          exclusionGroup: 0,
          user: {
            user,
            shippingLocation,
            shippingDetail,
            organizationInfoInput,
            resourceDistributionInput,
            userServeInput,
          }
        },
      }
    );
    if (data?.UpdateUserAllInfo.status) showSuccess('This applicant was updated successfully');
  };

  return (
    <Box px={5}>
      <Grid container>
        <Grid item xs={6}>
          <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
            <Typography variant='h5'>Applicant Password</Typography>
            <Spacer height={10} />
            <FormTextField
              name='password'
              label='Password'
              type='password'
              size='small'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Password{' '}<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={passwordControl}
            />
            <Spacer height={10} />
            <FormTextField
              name='confirmPassword'
              label='confirmPassword'
              type='password'
              size='small'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Confirm Password{' '}<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={passwordControl}
            />
            <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
              <Button variant='outlined' sx={{ minWidth: 200 }} onClick={passwordHandleSubmit(updatePassword)}>update</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Applicant</Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='firstName'
              label='First Name'
              size='small'
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='lastName'
              label='Last Name'
              size='small'
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='phone'
              label='Phone'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='email'
              label='Email'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormCheckBoxField name='redFlag' label='Red Flag' size='small' control={profileControl} />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormCheckBoxField name='yellowFlag' label='Yellow Flag' size='small' control={profileControl} />
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Shipping Detail</Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='organization'
              label='Organization'
              size='small'
              labelComponent={<Typography fontWeight={600}>Organization <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='title'
              label='Title'
              size='small'
              options={[{ label: '-- Select --', value: null }, ...TitleOptions]}
              labelComponent={<Typography fontWeight={600}>Title <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingFirstName'
              label='First Name'
              size='small'
              labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingLastName'
              label='Last Name'
              size='small'
              labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address1'
              label='Address 1'
              size='small'
              labelComponent={<Typography fontWeight={600}>Address 1 <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='address2'
              label='Address 2'
              size='small'
              labelComponent={<Typography fontWeight={600}>Address 2</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='city'
              label='City'
              size='small'
              labelComponent={<Typography fontWeight={600}>City <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='state'
              label='State'
              size='small'
              options={StateOptions}
              labelComponent={<Typography fontWeight={600}>State <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='zipCode'
              label='Zip Code'
              size='small'
              labelComponent={<Typography fontWeight={600}>Zip Code <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1} />
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='shippingPhone'
              label='Phone Number'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone Number <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='shippingEmail'
              label='Email Address'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email Address <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='loadingDock'
              label='Is there a loading dock at this location?'
              size='small'
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              labelComponent={<Typography fontWeight={600}>Is there a loading dock at this location?</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='is501c3'
              label='501c3'
              size='small'
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              labelComponent={<Typography fontWeight={600}>501c3</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='contactInfo'
              label='Alternate Contact Information'
              size='small'
              labelComponent={<Typography fontWeight={600}>Alternate Contact Information</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='rank'
              label='Rank'
              size='small'
              labelComponent={<Typography fontWeight={600}>Rank</Typography>}
              control={profileControl}
            />
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Ministry & Organization Information</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='ministryCategory'
          size='small'
          options={MinistryCategories}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Ministry Category<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        <FormTextField
          name='orgName'
          label='Name of Ministry or Organization'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Name of Ministry or Organization<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        <FormRadioField
          name='isManagedByOrg'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry managed by or overseen by another established ministry/organization or church<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        {watch('isManagedByOrg') === 'Yes' &&
          <FormTextField
            name='differentOrgName'
            label='Please provide the name of the overseeing ministry/organization, if different than above.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please provide the name of the overseeing ministry/organization, if different than above. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        {watch('isManagedByOrg') === 'No' &&
          <FormTextField
            name='overseeMinistry'
            label='Who does oversee this ministry?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Who does oversee this ministry? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        <FormTextField
          name='affiliation'
          label='What is your affiliation with this ministry/organization?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is your affiliation with this ministry/organization?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        <FormRadioField
          name='ministryIs501c3'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Is this ministry/organization a 501c3?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        {watch('ministryIs501c3') === 'Yes' &&
          <FormTextField
            name='employerId'
            label='Enter the organization Employer ID Number (EIN):'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Enter the organization Employer ID Number (EIN): <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        <FormTextField
          name='hasWebsite'
          label='Does the ministry have a website or presence online?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Does the ministry have a website or presence online?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        <FormTextField
          name='ministryDetails'
          label='Describe this ministry in detail.'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe this ministry in detail.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
          rows={5}
        />
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Resource Distribution</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='requestForEvent'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Are you requesting for an event with a specific date?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        {watch('requestForEvent') === 'Yes' &&
          <FormDateField
            name='eventDate'
            label=''
            size='small'
            type='datetime-local'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please select the date for the event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            rows={1}
            containerProps={{ sx: { mt: 1 } }}
          />
        }
        <FormTextField
          name='purpose'
          label='Describe the purpose and goals for distributing the resources.'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe the purpose and goals for distributing the resources.<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
          rows={5}
        />
        <FormTextField
          name='howDistributed'
          label='How will the resources be distributed? '
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How will the resources be distributed? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
          rows={5}
        />
        {watch('requestForEvent') === 'Yes' && <>
          <FormTextField
            name='expectToServe'
            label='How many do you expect to serve at this event?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many do you expect to serve at this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='costToAttendee'
            label='What is the cost to attendees for this event?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>What is the cost to attendees for this event? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='heldToDistribute'
            label='Where will the event be held to distribute these resources?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Where will the event be held to distribute these resources? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
          <FormTextField
            name='publicNotice'
            label='Describe any advertising or public notices for this event.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Describe any advertising or public notices for this event. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        </>}
        {watch('requestForEvent') === 'No' &&
          <FormTextField
            name='howManyPeople'
            label='How many people will be served over the next three months?'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How many people will be served over the next three months? <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
      </Box>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Help Us Serve You Better</Typography>
        <Spacer height={0} />
        <FormRadioField
          name='appliedBefore'
          size='small'
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Have you previously applied for resources from The 1687 Foundation?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        {watch('appliedBefore') === 'Yes' &&
          <FormTextField
            name='approximateTime'
            label='Please tell us the approximate time frame, email and name used previously.'
            size='small'
            labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>Please tell us the approximate time frame, email and name used previously. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={profileControl}
            inputProps={{ sx: { backgroundColor: '#fff' } }}
          />
        }
        <FormTextField
          name='howHearUs'
          label='How did you hear about us?'
          size='small'
          labelComponent={<Typography my={1} fontSize={18} fontWeight={600}>How did you hear about us?<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
          control={profileControl}
        />
        <Typography mt={1} fontSize={18} fontWeight={600}>By submitting this request for publications, I acknowledge that THIS IS NOT A GUARANTEE for providing publications. The 1687 Foundation reserves the right to accept or deny any request. I have read and understood these terms and the above guidelines. By clicking SUBMIT, I agree to comply with the terms and conditions of this offer. <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>
        <FormCheckBoxField name='agreeTerm' label='I understand and agree to these terms and guidelines.' control={profileControl} />
      </Box>

      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='outlined' sx={{ width: 200 }} onClick={profileHandleSubmit(updateProfile)}>Update</Button>
      </Box>
    </Box>
  );
};

export default UserProfilePage;
