import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { PagesContainer } from '../../components/pages/PagesContainer';

const PagesPage: FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.viewCMS) navigate('/');
  }, [me, me?.role?.viewCMS, navigate])

  return <PagesContainer />;
};

export default PagesPage;
