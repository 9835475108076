export function decodeHtml(html: string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const removeTags = (originalString: string) => {
  const replaceText = decodeHtml(originalString.replace(/(<([^>]+)>)/gi, ""));
  if (replaceText.length <= 200) return replaceText;
  return replaceText.slice(0, 200) + '...';
};