import React, { useState } from 'react';
import { Box, BoxProps, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Control, Controller } from 'react-hook-form';

export interface FormInputProps {
  name: string;
  label: string;
  labelComponent?: React.ReactElement;
  placeholder?: string | undefined;
  size?: 'small' | 'medium';
  rows?: number | undefined;
  maxlength?: any;
  type?: React.InputHTMLAttributes<InputProps>['type'];
  control: Control<any>;
  disabled?: boolean;
  inputProps?: Partial<InputProps>;
  containerProps?: BoxProps;
  onChangeInput?: (value: any) => void;
  onBlurInput?: () => void;
  einTyping?: boolean;
}

const FormTextField = ({
  name,
  label,
  labelComponent,
  placeholder = '',
  size = 'medium',
  rows,
  type = 'text',
  maxlength,
  control,
  disabled = false,
  inputProps,
  containerProps,
  onChangeInput,
  onBlurInput,
  einTyping = false,
}: FormInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={containerProps?.sx}>
      {labelComponent}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
          if (type === 'password') {
            return (
              <>
                <OutlinedInput
                  label={labelComponent ? '' : label}
                  placeholder={placeholder}
                  fullWidth
                  size={size}
                  type={showPassword ? 'text' : 'password'}
                  disabled={disabled}
                  inputProps={inputProps as any}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        sx={{ marginLeft: 0, borderRadius: 0 }}
                        edge='end'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                />
                {error && <Typography sx={{ width: '100%' }} margin='4px 14px 0' fontSize={16} color='#d32f2f'>{error.message}</Typography>}
              </>
            );
          }
          return (
            <TextField
              inputProps={{ maxLength: maxlength }}
              variant='outlined'
              label={labelComponent ? '' : label}
              placeholder={placeholder}
              helperText={error ? <Typography sx={{ width: 'calc(100% - 4px)'}} fontSize={18} color='#d32f2f'>{error.message}</Typography> : null}
              fullWidth
              size={size}
              type={type}
              multiline={rows === undefined ? true : rows > 1}
              rows={rows}
              InputProps={{ ...inputProps, autoComplete: 'off' }}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                onChange(e);
                if (onChangeInput) onChangeInput(e);
              }}
              onBlur={() => {
                onBlur();
                if (onBlurInput) onBlurInput();
              }}
              error={!!error}
              onInput={(e: any) => {
                if (einTyping) {
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/[^0-9-]/g, '');
                  inputValue = inputValue.slice(0, 10);
                  e.target.value = inputValue;
                }
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default FormTextField;
