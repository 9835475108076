import React from 'react';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { IExclusionGroup } from '../../graphql/generated/graphql';
import { ExclusionGroupTableHeadCell } from './type';
import { Order } from '../../const/type';

interface ExclusionGroupTableProps {
  numSelected: number;
  onRequestSort: (property: keyof IExclusionGroup) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: ExclusionGroupTableHeadCell[];
}

export function ExclusionGroupTableHead(props: ExclusionGroupTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property: keyof IExclusionGroup) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell?.minWidth}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            align={headCell.textAlign ?? 'left'}
          >
            {headCell?.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                sx={{ width: headCell?.minWidth, ml: 4 }}
                onClick={() => {
                  if (headCell.id !== 'actions') createSortHandler(headCell.id);
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? <Box component='span' sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null}
              </TableSortLabel>
            ) : (
              <Typography width={headCell?.minWidth} ml={headCell?.ml} fontSize='0.875rem' fontWeight={500} lineHeight='1.5rem' letterSpacing='0.01071em'>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
