import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import SubTitle from '../../components/common/SubTitle';
import { NormalParagraphHTML } from '../../components/common/NormalParagraph';
import { Spacer } from '../../libs/ui/Spacer';
import { NewsList } from '../../components/news/leftList';
import { PostModel, useGetNewsCategoryQuery, useGetPostByUrlLazyQuery } from '../../graphql/generated/graphql';

const NewsDetailPage = () => {
  const { ministryId, newsUrl } = useParams();

  const [news, setNews] = useState<PostModel | null>(null);

  const { data: newsCategoryData } = useGetNewsCategoryQuery({ variables: { newsCategoryId: ministryId! } });

  const headers =  useMemo(() => {
    if (newsCategoryData?.GetNewsCategory.data?.headLine) {
      const _headLine = newsCategoryData?.GetNewsCategory.data?.headLine;
      const headerList = _headLine.split('\n')
      return headerList.length > 1 ? headerList : ['', headerList[0]]
    }
    return ['', '']
  }, [newsCategoryData])

  const sideContent =  useMemo(() => {
    if (newsCategoryData?.GetNewsCategory.data?.sideContent) return newsCategoryData?.GetNewsCategory.data?.sideContent;
    return '';
  }, [newsCategoryData])

  const [getPost, { data }] = useGetPostByUrlLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    newsUrl && getPost({ variables: { url: newsUrl } });
  }, [newsUrl, getPost])

  useEffect(() => {
    if (data?.GetPostByUrl.data) setNews(data.GetPostByUrl.data);
  }, [data])

  return (
    <Grid container>
      <PageSlide images={[]} />
      <PageHeader subTitle={headers[0]} mainTitle={headers[1]} />
      <Grid container bgcolor='#f9f1df' p={3}>
        <Grid item xs={0} xl={1.5} />
        <Grid item xl={3} md={4} display={{ xs: 'none', md: 'block' }}>
          <NewsList sideContent={sideContent} />
        </Grid>
        <Grid item xs={12} xl={6} md={8} pt={5} pl={{ xs: 0, md: 3 }}>
          <Box key={news?.id}>
            <SubTitle label={news?.title ?? ''} customStyle={{ fontFamily: 'Sorts Mill Goudy', fontSize: '40px', fontWeight: 500 }}  />
            <NormalParagraphHTML label={news?.content ?? ''} />
            <Spacer height={20} />
          </Box>
        </Grid>
        <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
          <NewsList sideContent={sideContent} />
        </Grid>
        <Grid item xs={0} xl={1.5} />
      </Grid>
    </Grid>
  )
}

export default NewsDetailPage;
