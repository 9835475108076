import React, { useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { FetchResult } from '@apollo/client/link/core';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import RequestedByForm from './forms/RequestByForm';
import RequestShippingLocationForm from './forms/RequestShippingLocationForm';
import RequestOfficeUseForm from './forms/RequestOfficeUseForm';
import Comments from './forms/Comments';
import { findDifferentValues, removeNullProperties } from '../../libs/utils/getUpdates';
import { showSuccess } from '../../libs/utils/toasts';
import { RequestByIdDocument, UpdateRequestInput, UpdateRequestMutation, useRequestByIdLazyQuery, useUpdateRequestMutation, useUserByIdLazyQuery, useGetCommentsByRequestIdLazyQuery, OfficeUseState, useShipRequestsMutation, RequestSelectionsDocument, AddSelectionsDocument, UpdateSelectionsDocument } from '../../graphql/generated/graphql';
import { RequestAdditionalInfoValueType, RequestOfficeUseValueType, RequestShippingLocationValueType } from './type';
import { RequestResponseData } from '../../const/type';
import RequestResourceSelectUpdate from './forms/RequestResourceSelectUpdate';
import apolloClient from '../../graphql/apolloClient';
import { useMutation } from '@apollo/client';
import { defaultResourceGroup } from './const';

export function RequestEditContainer() {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState<RequestResponseData | null>(null);
  const [trigger, setTrigger] = useState(0);
  const [autoRedFlag, setAutoRedFlag] = useState<boolean>(false);
  const [autoYellowFlag, setAutoYellowFlag] = useState<boolean>(false);
  const [priorAppKey, setPriorAppKey] = useState<number>(0);
  const [user, setUser] = useState<any>({});
  const [selections, setSelections] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const [addSelectionsMutation] = useMutation(AddSelectionsDocument);
  const [updateSelectionsMutation] = useMutation(UpdateSelectionsDocument);

  useEffect(()=> {
   const get = async () => {
    const { data } = await apolloClient.query({
      query: RequestSelectionsDocument,
      variables: { requestId: Number(requestId) },
      fetchPolicy: "network-only",
    });

    if (data?.RequestSelections.items) {
      const tempItems = [...data?.RequestSelections.items];
      tempItems.sort((a: any, b: any) =>
        a.resource.title > b.resource.title
          ? 1
          : b.resource.title > a.resource.title
          ? -1
          : 0
      );

      const currentSelections = tempItems.map((i) => ({
        selectionId: i.id,
        resource: i.resource.id,
        cases: i.cases,
        singles: i.singles,
      }));
      setSelections([...currentSelections, {...defaultResourceGroup}]);
    }
   }
   get()
  }, [requestId])

  const [shipRequestMutation] = useShipRequestsMutation();

  const validationSchema = Yup.object().shape({
    userFirstName: Yup.string().nullable(),
    userLastName: Yup.string().nullable(),
    // userFirstName: Yup.string().required('First Name is required'),
    // userLastName: Yup.string().required('Last Name is required'),
    userEmail: Yup.string().nullable(),
    userPhone: Yup.string().nullable().test('is-valid-length', 'Phone number must be 10 digits', (value) => {
      return value?.length === 0 || value?.length === 10;
    }),
    userMinistryName: Yup.string().nullable(),
    ministryCategory: Yup.string().nullable(),
    userYellowFlag: Yup.bool().nullable(),
    userRedFlag: Yup.bool().nullable(),
    requestedDate: Yup.date().nullable(),
    shippedDate: Yup.date().nullable(),
    organization: Yup.string().nullable(),
    title: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zipCode: Yup.string().nullable().test('valid-zipcode', 'Invalid zip code', function (value) {
      if (value !== null && value !== undefined && value !== '') {
        return Yup.string().matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits').isValidSync(value);
      }
      return true;
    }),
    phone: Yup.string().nullable().test('valid-shippingphone', 'Invalid shipping phone', function (value) {
      if (value !== null && value !== undefined && value !== '') {
        return Yup.string().min(10, 'Must be 10 digits').isValidSync(value);
      }
      return true;
    }),
    email: Yup.string().nullable().email(),
    isLoadingDock: Yup.boolean().nullable(),
    is501c3: Yup.boolean().nullable(),
    additionalContactInfo: Yup.string().nullable(),
    digitalSignature: Yup.string(),
    status: Yup.string().trim().nullable().test('is-valid-length', 'Status must be selected', (value) => {
      return Boolean(value) && value !== ' ';
    }),
    moreInfo: Yup.bool(),
    previous: Yup.bool(),
    usps: Yup.bool(),
    category: Yup.bool(),
    wsGmGs: Yup.bool(),
    freightDelivery: Yup.bool(),
    shippingCarrier: Yup.string().trim(),
    monitorTracking: Yup.bool(),
    feedbackFormRequested: Yup.bool(),
    feedbackReceived: Yup.bool(),
    upsLocation: Yup.string().trim(),
    trackingInfo: Yup.string().trim(),
    exclusionGroup: Yup.string().nullable(),
    resourceUse: Yup.string().nullable()
  });

  const requestedByValue = useMemo(() => {
    return {
      userFirstName: request?.user?.firstName ?? '',
      userLastName: request?.user?.lastName ?? '',
      userPhone: request?.user?.phone ?? null,
      userEmail: request?.user?.email ?? null,
      requestedDate: request?.requestTime ? new Date(request?.requestTime) : null,
      shippedDate: request?.shippedTime ? new Date(request?.shippedTime) : null,
      userRedFlag: request?.user?.redFlag ?? false,
      userYellowFlag: request?.user?.yellowFlag ?? false,
      userMinistryName: request?.user?.ministryName || request?.user?.organizationInfo?.orgName ||  '',
      ministryCategory: request?.user?.organizationInfo?.ministryCategory ?? '',
    };
  }, [request]);

  const requestShippingLocationValue: RequestShippingLocationValueType = useMemo(() => {
    return {
      organization: request?.shippingLocation?.organization ?? undefined,
      title: request?.shippingLocation?.title ?? 'None',
      firstName: request?.shippingLocation?.firstName ?? undefined,
      lastName: request?.shippingLocation?.lastName ?? undefined,
      address1: request?.shippingLocation?.address1 ?? undefined,
      address2: request?.shippingLocation?.address2 ?? undefined,
      city: request?.shippingLocation?.city ?? undefined,
      state: request?.shippingLocation?.state ?? '',
      zipCode: request?.shippingLocation?.zipCode ?? undefined,
      phone: request?.shippingDetail?.phone ?? undefined,
      email: request?.shippingDetail?.email ?? undefined,
      isLoadingDock: request?.shippingDetail?.loadingDock ?? false,
      resourceUse: request?.shippingDetail?.resourceUse ?? '',
      is501c3: request?.shippingDetail?.is501c3 ?? false,
      additionalContactInfo: request?.shippingDetail?.contactInfo ?? undefined,
    };
  }, [request]);

  const requestAdditionalInfoValue: RequestAdditionalInfoValueType = useMemo(() => {
    if (request && request.user && request.user.additionalInfos && request.user.additionalInfos.length > 0) {
      return { digitalSignature: request.signature?.sign ?? '' };
    }
    return { digitalSignature: request?.signature?.sign ?? '' }
  }, [request]);

  const requestOfficeUseValue: RequestOfficeUseValueType = useMemo(() => {
    return {
      status: request?.officeUse?.status ?? '',
      moreInfo: request?.officeUse?.moreInfo ?? false,
      previous: request?.officeUse?.previous ?? false,
      usps: request?.officeUse?.usps ?? false,
      category: request?.officeUse?.category ?? false,
      wsGmGs: request?.officeUse?.wsGmGs ?? false,
      freightDelivery: request?.officeUse?.freightDelivery ?? false,
      shippingCarrier: request?.officeUse?.shippingCarrier ?? '',
      monitorTracking: request?.officeUse?.monitorTracking ?? false,
      feedbackFormRequested: request?.officeUse?.feedbackFormRequested ?? false,
      feedbackReceived: request?.officeUse?.feedbackReceived ?? false,
      upsLocation: request?.officeUse?.upsLocation ?? '',
      trackingInfo: request?.officeUse?.trackingInfo ?? '',
      autoRedFlag: request?.user?.autoRedFlag ?? false,
      autoYellowFlag: request?.user?.autoYellowFlag ?? false,
      priorApplicationKey: request?.user?.priorApplicationKey ?? 0
    };
  }, [request]);

  const methods = useForm({
    values: {
      userFirstName: requestedByValue?.userFirstName ?? '',
      userLastName: requestedByValue?.userLastName ?? '',
      userEmail: requestedByValue.userEmail ?? '',
      userPhone: requestedByValue.userPhone ?? '',
      userMinistryName: requestedByValue.userMinistryName ?? '',
      ministryCategory: requestedByValue.ministryCategory ?? '',
      userYellowFlag: requestedByValue.userYellowFlag ?? false,
      userRedFlag: requestedByValue.userRedFlag ?? false,
      requestedDate: requestedByValue.requestedDate ?? null,
      shippedDate: requestedByValue.shippedDate ?? null,
      organization: requestShippingLocationValue?.organization ?? undefined,
      title: requestShippingLocationValue?.title ?? undefined,
      firstName: requestShippingLocationValue?.firstName ?? undefined,
      lastName: requestShippingLocationValue?.lastName ?? undefined,
      address1: requestShippingLocationValue?.address1 ?? undefined,
      address2: requestShippingLocationValue?.address2 ?? undefined,
      city: requestShippingLocationValue?.city ?? undefined,
      state: requestShippingLocationValue?.state ?? undefined,
      zipCode: requestShippingLocationValue?.zipCode ?? undefined,
      phone: requestShippingLocationValue?.phone ?? undefined,
      email: requestShippingLocationValue?.email ?? undefined,
      isLoadingDock: requestShippingLocationValue?.isLoadingDock ?? false,
      is501c3: requestShippingLocationValue?.is501c3 ?? false,
      additionalContactInfo: requestShippingLocationValue?.additionalContactInfo ?? undefined,
      resourceUse: requestShippingLocationValue?.resourceUse ?? undefined,
      status: requestOfficeUseValue?.status ?? ' ',
      moreInfo: requestOfficeUseValue?.moreInfo ?? false,
      previous: requestOfficeUseValue?.previous ?? false,
      usps: requestOfficeUseValue?.usps ?? false,
      category: requestOfficeUseValue?.category ?? false,
      wsGmGs: requestOfficeUseValue?.wsGmGs ?? false,
      freightDelivery: requestOfficeUseValue?.freightDelivery ?? false,
      shippingCarrier: requestOfficeUseValue?.shippingCarrier && requestOfficeUseValue?.shippingCarrier.length ? requestOfficeUseValue?.shippingCarrier : ' ',
      monitorTracking: requestOfficeUseValue?.monitorTracking ?? false,
      feedbackFormRequested: requestOfficeUseValue?.feedbackFormRequested ?? false,
      feedbackReceived: requestOfficeUseValue?.feedbackReceived ?? false,
      upsLocation: requestOfficeUseValue?.upsLocation && requestOfficeUseValue?.upsLocation.length ? requestOfficeUseValue?.upsLocation : ' ',
      trackingInfo: requestOfficeUseValue?.trackingInfo ?? '',
      digitalSignature: requestAdditionalInfoValue?.digitalSignature ?? '',
      exclusionGroup: user?.User?.data?.exclusionGroup?.name ? user.User.data.exclusionGroup?.name :'Please Select:'
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, watch, setValue, getValues, formState: { errors } } = methods;

  const [getRequestById, { data: requestResponse }] = useRequestByIdLazyQuery({
    variables: { requestId: Number(requestId) },
    fetchPolicy: 'network-only',
  });

  const [getCommentsByRequestId, { data: commentsResponse }] = useGetCommentsByRequestIdLazyQuery({
    variables: { requestId: Number(requestId) },
    fetchPolicy: 'network-only',
  });

  const [getUserById, { data: userData }] = useUserByIdLazyQuery({
    variables: { userId: Number(request?.userId) },
    fetchPolicy: 'network-only',
  });

  const [updateRequestMutation] = useUpdateRequestMutation({
    refetchQueries: [
      {
        query: RequestByIdDocument,
        variables: { requestId: Number(requestId) },
        fetchPolicy: 'network-only',
      },
    ],
  });

  useEffect(() => {
    requestId && getRequestById();
  }, [getRequestById, requestId, trigger])

  useEffect(() => {
    if (requestResponse?.Request.data) setRequest(requestResponse?.Request.data);
  }, [requestResponse]);

  useEffect(() => {
    request?.userId && getUserById();
    request?.userId && getCommentsByRequestId();
  }, [getUserById, request, getCommentsByRequestId])

  useEffect(() => {
    if (request?.user) {
      setAutoRedFlag(request?.user.autoRedFlag ?? false);
      setAutoYellowFlag(request?.user.autoYellowFlag ?? false);
      setPriorAppKey(request?.user.priorApplicationKey ?? 0);
    }
  }, [request]);

  useEffect(() => {
    if (userData?.User?.data) {
      setUser(userData?.User?.data);
      setValue('exclusionGroup', userData?.User?.data?.exclusionGroup?.name ?? 'Please Select');
    }
  }, [userData, setValue]);



  const onSubmit = async (_data: FieldValue<any>, type: string) => {
    setOpen(true)
    const updatedData = findDifferentValues(
      {
        ...requestedByValue,
        ...requestShippingLocationValue,
        ...requestAdditionalInfoValue,
        ...requestOfficeUseValue,
      },
      _data
    );

    const payload: UpdateRequestInput = {
      id: Number(requestId),
      user: { id: 0 },
    };

    if (request) {
      const _updatedUser = {
        id: request.userId ?? 0,
        email: updatedData.userEmail,
        firstName: updatedData.userFirstName,
        lastName: updatedData.userLastName,
        ministryName: updatedData.userMinistryName,
        ministryCategory: updatedData.ministryCategory,
        phone: updatedData.userPhone,
        redFlag: updatedData.userRedFlag,
        yellowFlag: updatedData.userYellowFlag,
      };
      removeNullProperties(_updatedUser);
      payload.user = _updatedUser;

      const _updateOfficeUse = {
        status: updatedData.status ?? requestOfficeUseValue?.status,
        moreInfo: updatedData.moreInfo,
        previous: updatedData.previous,
        usps: updatedData.usps,
        category: updatedData.category,
        wsGmGs: updatedData.wsGmGs,
        freightDelivery: updatedData.freightDelivery,
        shippingCarrier: updatedData.shippingCarrier,
        monitorTracking: updatedData.monitorTracking,
        feedbackFormRequested: updatedData.feedbackFormRequested,
        feedbackReceived: updatedData.feedbackReceived,
        upsLocation: updatedData.upsLocation,
        trackingInfo: updatedData.trackingInfo,
      };
      removeNullProperties(_updateOfficeUse);

      if (Object.keys(_updateOfficeUse).length > 0) {
        payload.officeUse = { ..._updateOfficeUse, id: request.officeUse?.id };
      }
      if ((Object.keys(_updateOfficeUse).includes('shippingCarrier') && _updateOfficeUse['shippingCarrier'] === ' ') || _updateOfficeUse['shippingCarrier'] === '') {
        payload.officeUse = { ...payload.officeUse, shippingCarrier: null };
      }
      if ((Object.keys(_updateOfficeUse).includes('upsLocation') && _updateOfficeUse['upsLocation'] === ' ') || _updateOfficeUse['upsLocation'] === '') {
        payload.officeUse = { ...payload.officeUse, upsLocation: null };
      }

      if(selections.length > 0) {
        const updateSelections = selections.filter(selection => selection.selectionId && !isNaN(selection.resource) && selection.resource !== 0);
        const addSelections = selections.filter(selection => !selection.selectionId && !isNaN(selection.resource) && selection.resource !== 0);

        if(updateSelections.length > 0) {
          const updateSelectionsMap = updateSelections.map((selection)=> ({
            singles: selection.singles,
            cases: selection.cases,
            id: selection.selectionId,
            resourceId: selection.resource
          }))
          await updateSelectionsMutation({
            mutation: UpdateSelectionsDocument,
            variables: {
              selections: updateSelectionsMap
            }
          });
          
        }
        if(addSelections.length > 0) {
          const addSelectionsMaps = addSelections.map((selection)=> ({
            singles: selection.singles,
            cases: selection.cases,
            resourceId: selection.resource,
            requestId: +requestId!,
          }))

          await addSelectionsMutation({
            mutation: AddSelectionsDocument,
            variables: {
              selections: addSelectionsMaps
           }
          });
        }
        
      }

      const _updatedShippingLocation = {
        title: updatedData.title ?? requestShippingLocationValue?.title,
        organization: updatedData.organization,
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        address1: updatedData.address1,
        address2: updatedData.address2,
        city: updatedData.city,
        state: updatedData.state ?? requestShippingLocationValue?.state,
        zipCode: updatedData.zipCode,
      };
      removeNullProperties(_updatedShippingLocation);

      if (Object.keys(_updatedShippingLocation).length > 0) payload.shippingLocation = { ..._updatedShippingLocation, id: request.shippingLocation?.id ?? 0 };

      const _updatedShippingDetail = {
        contactInfo: updatedData.additionalContactInfo,
        email: updatedData.email,
        is501c3: updatedData.is501c3,
        loadingDock: updatedData.isLoadingDock,
        phone: updatedData.phone,
        resourceUse: updatedData.resourceUse,
      };
      removeNullProperties(_updatedShippingDetail);

      if (Object.keys(_updatedShippingDetail).length > 0) payload.shippingDetail = { ..._updatedShippingDetail, id: request.shippingDetail?.id ?? 0 };

      const _updatedSignature = { sign: updatedData.digitalSignature };
      removeNullProperties(_updatedSignature);

      if (Object.keys(_updatedSignature).length > 0) payload.signature = { ..._updatedSignature, id: request.signature?.id ?? 0 };
      if (updatedData.shippedDate) payload.shippedTime = updatedData.shippedDate;
      if (updatedData.requestedDate) payload.requestTime = updatedData.requestedDate;

      

      if(payload.officeUse?.status === OfficeUseState.Shipped) {
        await shipRequestMutation({
          variables: { requestIds: [payload.id] },
        });
        
      }
      const { data }: FetchResult<UpdateRequestMutation> = await updateRequestMutation({
        variables: { request: payload },
      });
      console.log(data)
      if (data && data.UpdateRequest.status) {
        setOpen(false)
        showSuccess('This request was updated successfully')
        if(type === 'saveAndExit') navigate(`../request`)
      };
    }
  };

  return (
    <Box px={3}>
    <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5' fontWeight={600}>Edit Request - {requestId}</Typography>
        <Button variant='text' size='large' sx={{ textTransform: 'capitalize' }} href={`/app/application/${request?.userId}`}>View Inquiry</Button>
      </Box>
      <RequestedByForm editableMinistryCategory disabledMinistryCategories control={control} getValues={getValues} />
      <RequestShippingLocationForm control={control} watch={watch} setValue={setValue} userData={userData?.User?.data} />
      <RequestOfficeUseForm setValue={setValue} getValues={getValues} control={control} autoRedFlag={autoRedFlag} autoYellowFlag={autoYellowFlag} priorApplicationKey={priorAppKey} />
          <Grid container direction='column' justifyContent='end' alignItems='end' my={5}>
            <Grid item>
              {Object.keys(errors).length > 0 && <Typography mb={1} fontWeight={600} color='#f00'>Some fields are not valid</Typography>}
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Button variant='outlined' onClick={() => handleSubmit((data) => onSubmit(data, "save"))()}>Save</Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={() => handleSubmit((data) => onSubmit(data, "saveAndExit"))()}>Save and exit</Button>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
     
      {/* <RequestBookedResources requestId={Number(requestId)} getValues={getValues} /> */}
      <RequestResourceSelectUpdate selections={selections} setSelections={setSelections} errors={errors} />
      <Comments requestId={Number(requestId)} comments={commentsResponse?.GetCommentsByRequestId?.items} trigger={trigger} setTrigger={setTrigger} />
    </Box>
  );
}
