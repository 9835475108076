import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import RoleNewModal from './modals/RoleNewModal';
import RoleEditModal from './modals/RoleEditModal';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import { DeleteUserRoleDocument, IUserRole, UserRolesDocument, useUserRolesLazyQuery } from '../../graphql/generated/graphql';

const RolesContainer = () => {
  const { me } = useAuth();

  const [page] = useState(0);
  const [rowsPerPage] = useState(100);
  const [rows, setRows] = useState<IUserRole[]>([]);
  const [trigger, setTrigger] = useState<number>(0);
  const [roleNewModalVisible, setRoleNewModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<IUserRole | null>(null);
  const [roleEditModalVisible, setRoleEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const [ getRoles, { data }] = useUserRolesLazyQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteRoleMutation] = useMutation(DeleteUserRoleDocument);

  useEffect(() => {
    getRoles();
  }, [getRoles, trigger])

  useEffect(() => {
    if (data?.UserRoles.items) setRows(data.UserRoles.items);
  }, [data]);

  const onDelete = async () => {
    await deleteRoleMutation({
      variables: { roleId: selectedRole?.id },
      refetchQueries: [
        {
          query: UserRolesDocument,
          variables: {
            page: page + 1,
            pageSize: rowsPerPage,
          },
          fetchPolicy: 'network-only',
        },
      ],
    })
    setSelectedRole(null);
    setDeleteModalVisible(false);
  }

  return (
    <Box>
      <CustomTableToolbar
        title={'Roles'}
        numSelected={0}
        onDelete={() => {}}
        onAdd={() => setRoleNewModalVisible(true)}
        disableAction={(!me?.isSuperuser && !me?.role?.changePermission)}
      />
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Role</TableCell>
              {(me?.isSuperuser || me?.role?.changePermission) && <TableCell width={80}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow sx={{ cursor: 'pointer' }} key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>Staff</TableCell>
                {(me?.isSuperuser || me?.role?.changePermission) && <TableCell width={80} align='right'>
                  <Box>
                    <EditIcon
                      onClick={() => {
                        setSelectedRole(row);
                        setRoleEditModalVisible(true);
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        setSelectedRole(row);
                        setDeleteModalVisible(true);
                      }}
                    />
                  </Box>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RoleNewModal roleNewModalVisible={roleNewModalVisible} setRoleNewModalVisible={setRoleNewModalVisible} trigger={trigger} setTrigger={setTrigger} />
      <RoleEditModal userRole={selectedRole} roleEditModalVisible={roleEditModalVisible} setRoleEditModalVisible={setRoleEditModalVisible} trigger={trigger} setTrigger={setTrigger} />
      <DeleteModal
        title='Are you sure delete this role?'
        open={deleteModalVisible}
        onCancel={() => {setDeleteModalVisible(false)}}
        onConfirm={onDelete}
      />
    </Box>
  )
}

export default RolesContainer;
