import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

interface SearchInputProps {
  searchKey: string;
  onSearch: (searchKey: string) => void;
}

function SearchInput({onSearch, searchKey}: SearchInputProps) {
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    onSearch(searchText);
  };

  const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchText(event.target.value);
  };

  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        size='small'
        label="Search"
        sx={{ width: 200, ml: 1 }}
        value={searchText}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') handleSearch();
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleSearch} edge="end">
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default SearchInput;
