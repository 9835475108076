import React, { ChangeEvent, useEffect, useMemo } from 'react';
import {
  IconButton,
  Paper,
  TableRow,
  TablePagination,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Modal,
  Typography,
  Button,
  Checkbox,
  // Select,
  // MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/auth';
import { useUsersContext } from '../../contexts/users';
import { UsersTableHead } from './UsersTableHead';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import SearchInput from '../../libs/ui/SearchInput';
import {
  SortDirectionField,
  UserSortField,
  UsersDocument,
  useUsersQuery,
  useUsersLazyQuery,
  // UpdateUsersStatusDocument,
  PrintApplicationsDocument,
} from '../../graphql/generated/graphql';
import { formatPhoneNumber, unformatPhoneNumber } from '../../const/function';
import { UserResponseData } from '../../const/type';
import { headCells } from './const';

const deleteModalStyle = {
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  boxShadow: 24,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const timezone = -(new Date().getTimezoneOffset() / 60);

export function UsersContainer() {
  const navigate = useNavigate();
  const { me } = useAuth();
  const {
    page,
    rowsPerPage,
    totalCount,
    rows,
    openDeleteModal,
    selected,
    // status,
    searchKey,
    sort,
    sortDirection,
    filter,
    filterId,
    filterType,
    filterText,
    setPage,
    setRowsPerPage,
    setTotalCount,
    setRows,
    setOpenDeleteModal,
    setSelected,
    // setStatus,
    setSearchKey,
    setSort,
    setSortDirection,
    setFilter,
    setFilterId,
    setFilterType,
    setFilterText
  } = useUsersContext();

  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [totalCount, setTotalCount] = useState(0);
  // const [rows, setRows] = useState<UserResponseData[]>([]);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [selected, setSelected] = useState<readonly number[]>([]);
  // const [status, setStatus] = useState('');

  // const [searchKey, setSearchKey] = useState<string>('');
  // const [sort, setSort] = useState<UserSortField>(UserSortField.Id);
  // const [sortDirection, setSortDirection] = useState<SortDirectionField>(SortDirectionField.Desc);

  // const [filter, setFilter] = useState<any>({});
  // const [filterId, setFilterId] = useState<string | undefined>(undefined);
  // const [filterType, setFilterType] = useState<string>('equalTo');
  // const [filterText, setFilterText] = useState<string>('');
  

  useEffect(() => {
    document.title = 'Applications';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement' };
  }, []);

  const [printApplicationsMutation] = useMutation(PrintApplicationsDocument, {
    refetchQueries: [
      {
        query: UsersDocument,
        variables: { page: page + 1, pageSize: rowsPerPage, search: searchKey, sort, sortDirection },
      },
    ],
  });

  // const [updateUsersStatusMutation] = useMutation(UpdateUsersStatusDocument, {
  //   refetchQueries: [
  //     {
  //       query: UsersDocument,
  //       variables: { page: page + 1, pageSize: rowsPerPage, search: searchKey, sort, sortDirection },
  //     },
  //   ],
  // });

  const { data } = useUsersQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      search: searchKey,
      sort,
      sortDirection
    },
    fetchPolicy: 'network-only',
  });
  
  const [getUsers, { data: usersData }] = useUsersLazyQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      search: searchKey,
      sort,
      sortDirection,
      filter,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.Users.items && Object.keys(filter)?.length <= 0) {
      // @ts-ignore
      setRows(_rows => {
        data.Users.items.forEach((item: UserResponseData) => {
          const _exist = _rows.find((row: UserResponseData) => row.id === item.id);
          if (!_exist) _rows.push(item);
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
      if (data?.Users) setTotalCount(data.Users.total);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setRows, setTotalCount]);

  useEffect(() => {
    if (usersData?.Users.items) {
      // @ts-ignore
      setRows(_rows => {
        usersData.Users.items.forEach((item: UserResponseData) => {
          const _exist = _rows.find((row: UserResponseData) => row.id === item.id);
          if (!_exist) _rows.push(item);
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
    }
    if (usersData?.Users) setTotalCount(usersData.Users.total);
  }, [setRows, setTotalCount, usersData]);

  const memoHeadCells = useMemo(() => {
    if (me?.isSuperuser || me?.role?.changeUsersProfile) {
      return headCells;
    } else {
      return headCells.filter((item) => item.id !== 'actions');
    }
  }, [me?.isSuperuser, me?.role?.changeUsersProfile]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilter = (filterId: string | undefined) => {
    if (filterId) {
      switch(filterId){
        case 'phone':
          setFilter({ [filterId]: { [filterType]: unformatPhoneNumber(filterText) } });
          break;
        case 'id':
          setFilter({ [filterId]: { [filterType]: parseInt(filterText) } });
          break
        default:
          console.log(`${filterId} - ${filterText}`)
          setFilter({ [filterId]: { [filterType]: filterText } });
      }
    } else {
      setFilter(null);
    }
    setRows([]);
    getUsers();
    setFilterId(filterId);
  };

  const printRequest = async () => {
    if (!selected.length) return;

    const { data: printResponse } = await printApplicationsMutation({ variables: { applicationIds: [...selected], timezone } });
    if (printResponse) setSelected([])
    const base64Data = printResponse?.PrintApplications || '';
    const decodedData = atob(base64Data);

    const byteArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-');
    const a = document.createElement('a');
    a.href = url;
    a.download = `Request_${timestamp}.pdf`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const isSelected = (id: number) => {
    return selected.indexOf(id) !== -1;
  };

  const handleClickRow = (name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleSelectedStatus = async (value: string) => {
  //   if (value !== '') {
  //     setStatus(value);
  //     const { data: response} = await updateUsersStatusMutation({
  //       mutation: UpdateUsersStatusDocument,
  //       variables: { input: { ids: selected, status: value } },
  //     });
  //     if (response.UpdateUsersStatus.status) setSelected([])
  //   }
  // }

  const handleResort = (sortKey: UserSortField) => {
    if (sort === sortKey) {
      // @ts-ignore
      setSortDirection(_dic => {
        if (_dic === SortDirectionField.Asc) return SortDirectionField.Desc;
        return SortDirectionField.Asc;
      });
    } else {
      setSort(sortKey ?? UserSortField.Id);
      setSortDirection(SortDirectionField.Asc);
    }
    setRows([])
  };

  const returnBgColor = (user: any) => {
    if (user.redFlag) return '#ff000033';
    if (user.yellowFlag) return '#ffff0033';
    return ''
  }

  const ToolbarActions = (
    <>
      {/* {selected.length > 0 &&
        <Select size='small' displayEmpty value={status} onChange={event => handleSelectedStatus(event.target.value)}>
          <MenuItem value=''>No Selection</MenuItem>
          <MenuItem value='received'>Received</MenuItem>
          <MenuItem value='approved'>Approved</MenuItem>
          <MenuItem value='closed'>Closed</MenuItem>
        </Select>
      } */}

      <Button sx={{ minWidth: 80, mx: 0.25, padding: '4px 10px', fontSize: 12 }} onClick={printRequest}>
        Print <PrintIcon sx={{ ml: 0.5, fontSize: 18 }} />
      </Button>
    </>
  );

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Inquiries'
          numSelected={selected.length}
          onAdd={() => navigate('./new')}
          disableAction={!me?.isSuperuser && !me?.role?.changeUsersProfile}
          customActionButton
          actions={ToolbarActions}
          showActions
          searchBar={
            <SearchInput
              searchKey={searchKey}
              onSearch={(txt: string) => { setPage(0); setRows([]); setSearchKey(txt) }}
            />
          }
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 210px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }} aria-labelledby='tableTitle'>
            <UsersTableHead
              headCells={memoHeadCells}
              numSelected={selected.length}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
              onSelectAllClick={handleSelectAllClick}
              order={sortDirection === SortDirectionField.Asc ? 'asc' : 'desc'}
              onRequestSort={handleResort}
              orderBy={sort}
              filterId={filterId}
              filterType={filterType}
              setFilterType={setFilterType}
              filterText={filterText}
              setFilterText={setFilterText}
              applyFilter={applyFilter}
            />
            <TableBody>
              {visibleRows.map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.id?.toString()}
                    sx={{ backgroundColor: returnBgColor(row), cursor: 'pointer' }}
                    onClick={() => handleClickRow(row.id)}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': row.id.toString() }} />
                    </TableCell>
                    {(me?.isSuperuser || me?.role?.changeUsersProfile) && (
                      <TableCell width={40} padding='none' align='center'>
                        <IconButton onClick={() => navigate(`./${row.id}`)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell padding='none' align='center'>{row.id}</TableCell>
                    <TableCell align='center'>{row.status}</TableCell>
                    <TableCell padding='none' align='center'>{format(new Date(row.createTime), "MM/dd/yyyy hh:mmaaaaa'm'")}</TableCell>
                    <TableCell padding='none' align='center'>{row.acceptedDate ? (format(new Date(row.acceptedDate), "MM/dd/yyyy hh:mmaaaaa'm'") !== '12/31/1969 04:00pm' ? format(new Date(row.acceptedDate), "MM/dd/yyyy hh:mmaaaaa'm'") : '') : ''}</TableCell>
                    <TableCell align='center'>{row.organizationInfo?.orgName || ''}</TableCell>
                    <TableCell align='center'>{row.firstName}</TableCell>
                    <TableCell align='center'>{row.lastName}</TableCell>
                    <TableCell align='center'>{row.email}</TableCell>
                    <TableCell align='center'>{formatPhoneNumber(row.phone!)}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box sx={deleteModalStyle}>
          <Typography>This user will be removed. Are you sure?</Typography>
          <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button variant='contained' sx={{ ml: 2 }} onClick={() => setOpenDeleteModal(false)}>OK</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
