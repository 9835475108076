import React from 'react';
import { Box } from '@mui/material';
import FormSubmissionsContainer from '../../components/forms/FormSubmissionsContainer';

const FormSubmissionsPage: React.FC = () => {
  return (
    <Box>
      <FormSubmissionsContainer />
    </Box>
  )
}

export default FormSubmissionsPage;
