import React, { ChangeEvent, useEffect, useMemo, useCallback, useState } from 'react';
import {
  IconButton,
  Paper,
  TableRow,
  TablePagination,
  TableContainer,
  TableBody,
  Table,
  Box,
  Button,
  Checkbox,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import PrintIcon from '@mui/icons-material/Print';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { useAuth } from '../../contexts/auth';
import { useRequestsContext } from '../../contexts/requests';
import { RequestTableHead } from './RequestTableHead';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
// import { dateToLocalString } from '../../libs/utils/datetimeutil';
import { showError, showSuccess } from '../../libs/utils/toasts';
import SearchInput from '../../libs/ui/SearchInput';
import {
  StyledTableCell as TableCell,
  StyledTableRow,
} from '../../libs/ui/StyledComponents';
import {
  DeleteRequestDocument,
  DeleteRequestMutation,
  DeleteRequestsMutation,
  RequestSortField,
  RequestsDocument,
  SortDirectionField,
  useDeleteRequestsMutation,
  usePrintRequestFormMutation,
  usePrintRequestsMutation,
  useRequestsQuery,
  useShipRequestsMutation,
  useRequestsLazyQuery,
  useCreateRequestByAdminMutation,
  CreateRequestInput,
  OfficeUseState,
  StateEnum,
  SignUpDocument,
} from '../../graphql/generated/graphql';
import { RequestsResponseType } from './type';
import { headCells, requestOrder } from './const';
import CircularLoading from "../../libs/ui/Loading";

export const RequestsContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();
  const {
    page,
    rowsPerPage,
    totalCount,
    rows,
    deleteModalVisible,
    deleteId,
    selected,
    searchIds,
    searchKey,
    sort,
    sortDirection,
    filter,
    filterId,
    filterType,
    filterText,
    setPage,
    setRowsPerPage,
    setTotalCount,
    setRows,
    setDeleteModalVisible,
    setDeleteId,
    setSelected,
    setSearchIds,
    setSearchKey,
    setSort,
    setSortDirection,
    setFilter,
    setFilterId,
    setFilterType,
    setFilterText
  } = useRequestsContext();

  /*
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState<RequestsResponseType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  const [searchIds, setSearchIds] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');

  const [sort, setSort] = useState<RequestSortField>(RequestSortField.RequestNumber);
  const [sortDirection, setSortDirection] = useState<SortDirectionField>(SortDirectionField.Desc);

  const [filter, setFilter] = useState<any>({});
  const [filterId, setFilterId] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('equalTo');
  const [filterText, setFilterText] = useState<string | Date | boolean>('');
  */
  const [loading, setLoading] = useState(false);

  const refetchQueries: any = {
    refetchQueries: [{
      query: RequestsDocument,
      variables: { sort: sort, sortDirection: sortDirection, page: page + 1, pageSize: rowsPerPage },
      fetchPolicy: 'network-only',
    }],
  }

  const [deleteRequestMutation] = useMutation(DeleteRequestDocument, refetchQueries);
  const [deleteRequestsMutation] = useDeleteRequestsMutation(refetchQueries);
  const [printRequestMutation] = usePrintRequestsMutation();
  const [printRequestFormMutation] = usePrintRequestFormMutation();
  const [shipRequestMutation] = useShipRequestsMutation(refetchQueries);
  const [signupMutation] = useMutation(SignUpDocument);
  const [createRequestMutation] = useCreateRequestByAdminMutation();

  const applyFilter = (filterId: string | undefined) => {
    if (filterId) {
      setFilter({ [filterId]: { [filterType]: filterText } });
    } else {
      setFilter(null);
    }
    setRows([]);
    setFilterId(filterId);
  };

  const handleSearchId = () => {
    if (searchIds.length > 0) getRequestsById();
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const isSelected = (id: number) => {
    return selected.indexOf(id) !== -1;
  };

  const { data } = useRequestsQuery({
    variables: {
      sort: sort,
      sortDirection: sortDirection,
      page: page + 1,
      pageSize: rowsPerPage,
      search: searchKey,
      filter: filter,
    },
    fetchPolicy: 'network-only',
  });

  const [getRequestsById, { data: requestsDataById }] = useRequestsLazyQuery({
    variables: {
      sort: sort,
      sortDirection: sortDirection,
      page: page + 1,
      pageSize: rowsPerPage,
      ids: searchIds,
    },
    fetchPolicy: 'network-only',
  });

  // eslint-disable-next-line
  const [getRequestsByFilter, { data: requestsDataByFilter }] = useRequestsLazyQuery({
    variables: {
      sort: sort,
      sortDirection: sortDirection,
      page: page + 1,
      pageSize: rowsPerPage,
      filter: filter,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangeData = useCallback((items: RequestsResponseType[]) => {
    if (page === 0) {
      setRows(items);
    } else {
      // @ts-ignore
      setRows(_rows => {
        items.forEach((item: RequestsResponseType) => {
          const _exist = _rows.find((row: RequestsResponseType) => row.id === item.id);
          if (!_exist) _rows = [..._rows, item];
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
    }
  },
    [page, setRows]
  );

  useEffect(() => {
    if (data?.Requests.items) {
      handleChangeData(data.Requests.items);
      setTotalCount(data.Requests.total);
    }
  }, [data, handleChangeData, setTotalCount]);

  useEffect(() => {
    if (requestsDataById?.Requests.items) {
      handleChangeData(requestsDataById.Requests.items);
      setTotalCount(requestsDataById.Requests.total);
    }
  }, [requestsDataById, handleChangeData, setTotalCount]);

  useEffect(() => {
    if (requestsDataByFilter?.Requests.items) {
      handleChangeData(requestsDataByFilter.Requests.items);
      setTotalCount(requestsDataByFilter.Requests.total);
    }
  }, [requestsDataByFilter, handleChangeData, setTotalCount]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const handleDelete = async () => {
    if (deleteId) {
      const { data }: FetchResult<DeleteRequestMutation> =
        await deleteRequestMutation({
          variables: { requestId: deleteId },
        });

      if (data?.DeleteRequest) {
        if (data.DeleteRequest.status) {
          // @ts-ignore
          setRows(_rows => {
            return _rows.filter((item: RequestsResponseType) => item.id !== deleteId);
          });
          setDeleteId(undefined);
          setDeleteModalVisible(false);
          showSuccess(`This request was removed`);
        } else {
          showError(`Error: Delete operation was failed`);
        }
      }
    } else {
      const { data }: FetchResult<DeleteRequestsMutation> =
        await deleteRequestsMutation({
          variables: { requestIds: [...selected] },
        });

      if (data?.DeletedRequests) {
        if (data.DeletedRequests.status) {
          // @ts-ignore
          setRows(_rows => {
            return _rows.filter((item: RequestsResponseType) => !selected.includes(item.id));
          });
          setSelected([]);
          setDeleteModalVisible(false);
          showSuccess(`The requests were removed successfully`);
        } else {
          showError(`Error: Delete operation was failed`);
        }
      }
    }
  };

  const handleClickRow = (name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePrintRequestForm = async () => {
    const { data: printFormResponse } = await printRequestFormMutation();
    const base64Data = printFormResponse?.PrintRequestForm || '';
    const decodedData = atob(base64Data);

    const byteArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-');
    const a = document.createElement('a');
    a.href = url;
    a.download = `Request_${timestamp}.pdf`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const printRequest = async () => {
    if (!selected.length) return;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log(userTimeZone)
    const { data: printResponse } = await printRequestMutation({
      variables: { requestIds: [...selected], timeZone: userTimeZone },
    });
    const base64Data = printResponse?.PrintRequests || '';
    const decodedData = atob(base64Data);

    const byteArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-');
    const a = document.createElement('a');
    a.href = url;
    a.download = `Request_${timestamp}.pdf`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const onClickDeletes = async () => {
    setDeleteId(undefined);
    if (selected.length === 0) return;
    setDeleteModalVisible(true);
  };

  const handleShipRequests = async () => {
    if (!selected.length) return;

    const { data } = await shipRequestMutation({
      variables: { requestIds: [...selected] },
    });
    if (data?.ShipRequests) {
      if (data.ShipRequests.status) {
        setDeleteModalVisible(false);
        setSelected([]);
        showSuccess(`This operation was finished successfully`);
      } else {
        showError(`Error: Delete operation was failed`);
      }
    }
  };

  const orderBy = useMemo(() => {
    const _sortItem = requestOrder.find((item) => item.name === sort);
    return _sortItem?.key ?? 'id';
  }, [sort]);

  const handleResort = (sortKey: string) => {
    const _sortKey = requestOrder.find((item) => item.key === sortKey);
    if (sort === _sortKey?.name) {
      // @ts-ignore
      setSortDirection(_dic => {
        if (_dic === SortDirectionField.Asc) return SortDirectionField.Desc;
        return SortDirectionField.Asc;
      });
    } else {
      setSort(_sortKey?.name ?? RequestSortField.RequestNumber);
      setSortDirection(SortDirectionField.Asc);
    }
  };

  const returnBgColor = (user: any) => {
    if (user.redFlag) return '#ff000033 !important';
    if (user.yellowFlag) return '#ffff0033 !important';
    return ''
  }

  const onDuplicate = async (item: RequestsResponseType) => {
    if (!me?.role?.editRequest) return showSuccess('Your role doesn\'t have the permission');
    setLoading(true);
    let variables = {
      user: {
        firstName: '',
        lastName: '',
        phone: item.user?.phone,
        email: 'info@1687foundation.com',
        password: 'duplicate',
        acceptedDate: new Date(),
        endDate: new Date(),
        status: 'closed'
      },
      shippingLocation: {
        organization: item.shippingLocation?.organization ?? '',
        title: '',
        firstName: '',
        lastName: '',
        address1: item.shippingLocation?.address1 ?? '',
        address2: item.shippingLocation?.address2 ?? '',
        city: item.shippingLocation?.city ?? '',
        state: item.shippingLocation?.state as StateEnum ?? StateEnum.Aa,
        zipCode: item.shippingLocation?.zipCode ?? '',
      },
      shippingDetail: {
        phone: '000-000-0000',
        email: 'info@1687foundation.com',
        loadingDock: item.shippingDetail?.loadingDock ?? false,
        contactInfo: '',
      },
      organizationInfoInput: {
        ministryCategory: 'Inmate',
        orgName: '',
        isManagedByOrg: false,
        affiliation: '',
        is501c3: false,
        hasWebsite: '',
        ministryDetails: '',
      },
      userServeInput: {
        appliedBefore: false,
        howHearUs: '',
        agreeTerm: true,
      }
    };
    const { data } = await signupMutation({
      mutation: SignUpDocument,
      variables: { data: variables }
    });
    const resUser = data?.SignUp.data;
    if (data?.SignUp.status) {
      const payload: CreateRequestInput = {
        user: {
          id: resUser?.id ?? 0,
          firstName: resUser?.firstName ?? '',
          lastName: resUser?.lastName ?? '',
          phone: resUser?.phone ?? '',
          email: resUser?.email ?? '',
          ministryName: resUser?.organizationInfo?.orgName,
          redFlag: resUser?.redFlag ?? false,
          yellowFlag: resUser?.yellowFlag ?? false,
        },
        requestTime: new Date(),
        shippedTime: undefined,
        shippingLocation: {
          title: undefined,
          organization: item.shippingLocation?.organization ?? '',
          firstName: '',
          lastName: '',
          address1: item.shippingLocation?.address1 ?? '',
          address2: item.shippingLocation?.address2 ?? '',
          city: item.shippingLocation?.city ?? '',
          state: item.shippingLocation?.state as StateEnum ?? StateEnum.Aa,
          zipCode: item.shippingLocation?.zipCode ?? '',
        },
        shippingDetail: {
          phone: '000-000-0000',
          email: 'info@1687foundation.com',
          loadingDock: item.shippingDetail?.loadingDock ?? false,
          is501c3: item.shippingDetail?.is501c3 ?? false,
          contactInfo: '',
          rank: '',
          resourceUse: item?.shippingDetail?.resourceUse
        },
        signature: {
          sign: `${me?.firstName} ${me?.lastName}`
        },
        officeUse: {
          status: OfficeUseState.Received,
          moreInfo: undefined,
          previous: undefined,
          usps: undefined,
          category: undefined,
          wsGmGs: undefined,
          freightDelivery: undefined,
          shippingCarrier: undefined,
          monitorTracking: undefined,
          feedbackFormRequested: undefined,
          feedbackReceived: undefined,
          upsLocation: undefined,
          trackingInfo: undefined,
        },
        selections: [],
      };
      const { data } = await createRequestMutation({
        variables: { request: payload }
      })
      if (data && data.CreateRequest.status) {
        setLoading(false);
        showSuccess('Duplicate successful');
        navigate(`./${data.CreateRequest.data}`);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const onEdit = (row: RequestsResponseType) => {
    if (!me?.role?.editRequest) return showSuccess('Your role doesn\'t have the permission');
    navigate(`./${row.id}`)
  }

  const ToolbarActions = (
    <>
      <Button sx={{ fontSize: 12 }} onClick={() => navigate('/app/request/new')}>
        New Request
      </Button>

      <Button sx={{ fontSize: 12 }} onClick={() => selected.length > 0 ? printRequest() : handlePrintRequestForm()}>
        Print <PrintIcon sx={{ ml: 0.5, fontSize: 18 }} />
      </Button>

      <Button sx={{ mx: 0.5, fontSize: 12 }} onClick={handleShipRequests}>
        Ship Request <DeliveryDiningIcon sx={{ ml: 0.5, fontSize: 18 }} />
      </Button>
    
    </>
  );

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Requests'
          numSelected={selected.length}
          onDelete={onClickDeletes}
          onAdd={() => navigate('./new')}
          customActionButton
          disableAction={!me?.isSuperuser && !me?.role?.editRequest}
          actions={ToolbarActions}
          showActions
          searchKey={searchKey}
          searchBar={
            <SearchInput
              onSearch={(txt: string) => {
                setPage(0);
                setSearchKey(txt);
              }}
              searchKey={searchKey}
            />
          }
          searchId={
            <TextField
              size='small'
              label='IDs'
              sx={{ width: 200, mx: 1 }}
              onChange={event => setSearchIds(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSearchId();
              }}
            />
          }
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 210px)' }}>
          <Table size='small' aria-labelledby='tableTitle' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}>
            <RequestTableHead
              headCells={headCells}
              numSelected={selected.length}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
              onSelectAllClick={handleSelectAllClick}
              order={sortDirection === SortDirectionField.Asc ? 'asc' : 'desc'}
              onRequestSort={handleResort}
              orderBy={orderBy}
              filterId={filterId}
              setFilterId={setFilterId}
              filterType={filterType}
              setFilterType={setFilterType}
              filterText={filterText}
              setFilterText={setFilterText}
              applyFilter={applyFilter}
            />
            <TableBody>
              {visibleRows.map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <StyledTableRow
                    key={row.id}
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{ backgroundColor: returnBgColor(row?.user) }}
                  >
                    <TableCell padding='checkbox' onClick={() => handleClickRow(row.id)}>
                      <Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': row.id.toString() }} />
                    </TableCell>
                    {(me?.isSuperuser || me?.role?.editRequest) && (
                      <TableCell width={40} align='center'>
                        <IconButton disabled={!me?.role?.editShippedRequest && row.officeUse?.status === 'Shipped'} onClick={() => onEdit(row)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => onDuplicate(row)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell align='left'>{row.id.toString()}</TableCell>
                    <TableCell align='left'>{row.officeUse?.status ?? ''}</TableCell>
                    <TableCell align='left'>{row.requestTime ? format(new Date(row.requestTime), "MM/dd/yyyy hh:mmaaaaa'm'") : ''}</TableCell>
                    <TableCell align='left'>{row.shippedTime ? format(new Date(row.shippedTime), "MM/dd/yyyy hh:mmaaaaa'm'") : ''}</TableCell>
                    <TableCell align='left'>{row.shippingLocation?.organization || ''}</TableCell>
                    <TableCell align='left'>{`${row.shippingLocation?.firstName}`}</TableCell>
                    <TableCell align='left'>{`${row.shippingLocation?.lastName}`}</TableCell>
                    <TableCell align='left'>{row.shippingDetail?.email}</TableCell>
                    <TableCell align='center'>{row.shippingLocation?.city}</TableCell>
                    <TableCell align='center'>{row.shippingLocation?.state}</TableCell>
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <DeleteModal
        title='Are you sure delete this request?'
        open={deleteModalVisible}
        onConfirm={handleDelete}
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
      />

      {loading && <CircularLoading />}
    </Box>
  );
}
