import React from 'react';
import { Box, Typography } from '@mui/material';
import { removeTags } from '../../libs/utils/htmlContent';

export const normalParagraphStyle = {
  marginBottom: '20px',
  fontFamily: 'Sorts Mill Goudy,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  fontSize: '1.25rem',
  lineHeight: 1.25,

  a: {
    color: '#282ba5',
  },

  'a:hover': {
    color: '#e83231',
  },
};

const RenderHTMLContent = ({htmlContent}: {htmlContent: string}) => (
  <div className='render-html-content' dangerouslySetInnerHTML={{ __html: htmlContent }} />
)

const RenderHTML = ({htmlContent}: {htmlContent: string}) => (
  <div className='render-html' dangerouslySetInnerHTML={{ __html: htmlContent }} />
)

export interface NormalParagraphProps {
  label: string;
  customStyle?: any;
}

export const NormalParagraphHTMLContent: React.FC<NormalParagraphProps> = ({ label, customStyle }) => {
  return (
    <Box sx={{ ...normalParagraphStyle, ...customStyle }}>
      <RenderHTMLContent htmlContent={label}/>
    </Box>
  )
}

export const NormalParagraphHTML: React.FC<NormalParagraphProps> = ({ label, customStyle }) => {
  return (
    <Box sx={{ ...normalParagraphStyle, ...customStyle }}>
      <RenderHTML htmlContent={label}/>
    </Box>
  )
}

const NormalParagraph: React.FC<NormalParagraphProps> = ({ label, customStyle }) => {
  return (
    <Typography sx={{ ...normalParagraphStyle, ...customStyle }}>{removeTags(label)}</Typography>
  )
}

export default NormalParagraph;
