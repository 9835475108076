import React from 'react';
import { Box } from '@mui/material';
import TestimonialNewContainer from '../../components/testimonials/TestimonialNewContainer';

const TestimonialNewPage = () => {
  return (
    <Box>
      <TestimonialNewContainer />
    </Box>
  )
}

export default TestimonialNewPage;
