import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import PageSlide from '../../components/common/PageSlide';
import PageHeader from '../../components/common/PageHeader';
import SubTitle from '../../components/common/SubTitle';
import ResourceCard from '../../components/common/ResourceCard';
import SidebarLink from '../../components/common/SidebarLink';
// import CenterBox from '../../components/common/CenterBox';
import ShippingLocationModal from '../../components/resources/modals/ShippingLocationModal';
import { Spacer } from '../../libs/ui/Spacer';
import { useAuth } from '../../contexts/auth';
import apolloClient from '../../graphql/apolloClient';
import {
  CheckoutRequestDocument,
  IResource,
  IResourceCategory,
  IUserShippingDetail,
  IUserShippingLocation,
  SignatureDocument,
  useCategoriesQuery,
  usePublicResourcesQuery,
  // useUserShippingDetailQuery,
  // useUserShippingLocationQuery,
  useExclusionGroupByIdQuery,
  UserShippingLocationDocument,
  UserShippingDetailDocument
} from '../../graphql/generated/graphql';
import { BookSlides } from '../../const/slide';
import DividerBgImg from '../../assets/images/divider-bg.png';
import LandingFooter from '../../layouts/landing/footer';

const modalBoxStyle = {
  width: '80%',
  backgroundColor: '#fff',
  borderRadius: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '@media (max-width: 600px)': {
    width: '90%',
  },
};

const modalTitleStyle = {
  margin: '10px 0',
  fontWeight: 600,
};

const modalSubTitleStyle = {
  margin: '5px 0',
  fontWeight: 500,
};

type ISelection = {
  resourceId: number;
  title: string;
  cases: number;
  singles: number;
};

const ResourcesPage = () => {

  const navigate = useNavigate();
  const { me, signOut } = useAuth();
  const selectedCategoryRef = useRef<HTMLDivElement>(null);

  const [shippingLocation, setShippingLocation] = useState<IUserShippingLocation | null>(null);
  const [shippingDetail, setShippingDetail] = useState<IUserShippingDetail | null>(null);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [categories, setCategories] = useState<IResourceCategory[]>([]);
  const [resources, setResources] = useState<IResource[]>([]);
  const [selectedResource, setSelectedResource] = useState<IResource | null>(null);
  const [selectionModalVisible, setSelectionModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [singles, setSingles] = useState(0);
  const [cases, setCases] = useState(0);
  const [selections, setSelections] = useState<ISelection[]>([]);
  const [, setCheckoutRequestId] = useState<number>();
  const [shippingLocationModalVisible, setShippingLocationModalVisible] = useState(false);
  const [, setSignatureModalVisible] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState('');
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);
  const [resDescriptionHTML, setResHTML] = useState<any>(null);
  const [exclusionGroup, setExclusionGroup] = useState<any>([]);
  const [exclusionGroupId, setExclusionGroupId] = useState<number>(-1);
  const [categoryCount, setCategoryCount] = useState<any>({});
  const [isSelectedResourceError, setIsSelectedResourceError] = useState<any>(false);
  const [isFixed, setIsFixed] = useState(false);

  // const { data: userShippingLocationData } = useUserShippingLocationQuery({ fetchPolicy: 'network-only' })
  // const { data: userShippingDetailData } = useUserShippingDetailQuery({ fetchPolicy: 'network-only' })

  const { data: categoriesData } = useCategoriesQuery({ fetchPolicy: 'network-only' })

  const { data: resourcesData } = usePublicResourcesQuery({
    variables: { page: 1, pageSize: 1000 },
    fetchPolicy: 'network-only',
  })

  const { data: exclusionGroupData } = useExclusionGroupByIdQuery({
    variables: { exclusionGroupId: exclusionGroupId },
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    const checkVisibility = () => {
      const button = document.getElementById("floating-button");

      if (button) {
        const rect = button.getBoundingClientRect();

        if (rect.top < 0) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", checkVisibility);

    checkVisibility();

    return () => window.removeEventListener("scroll", checkVisibility);
  }, []);


  useEffect(() => {
    const length = exclusionGroupData?.ExclusionGroup?.data?.resources?.length ?? 0
    if (length > 0) {
      setExclusionGroup(exclusionGroupData?.ExclusionGroup?.data?.resources.map((resource) => resource.id))
    }
  }, [exclusionGroupData])

  useEffect(() => {
    const asyncWrapper = async () => {
      if (me) {
        const { data: userShippingLocationData } = await apolloClient.query({
          query: UserShippingLocationDocument,
        });
        const { data: userShippingDetailData } = await apolloClient.query({
          query: UserShippingDetailDocument,
        });
        if (userShippingDetailData?.UserShippingDetail.data) setShippingDetail(userShippingDetailData?.UserShippingDetail.data);
        if (userShippingLocationData?.UserShippingLocation.data) setShippingLocation(userShippingLocationData?.UserShippingLocation.data);
        
        console.log(userShippingDetailData)

        if (me?.exclusionGroup?.id) {
          setExclusionGroupId(me.exclusionGroup.id);
        }
      }
    }

    asyncWrapper()
  }, [me])

  // useEffect(() => {
  //   if (userShippingLocationData?.UserShippingLocation.data) setShippingLocation(userShippingLocationData?.UserShippingLocation.data);
  // }, [userShippingLocationData])

  // useEffect(() => {
  //   if (userShippingDetailData?.UserShippingDetail.data) setShippingDetail(userShippingDetailData?.UserShippingDetail.data);
  // }, [userShippingDetailData])

  useEffect(() => {
    if (categoriesData?.Categories.items) setCategories(categoriesData?.Categories.items);
  }, [categoriesData])

  useEffect(() => {
    if (resourcesData?.PublicResources.items) {
      let resourceArray = [...resourcesData?.PublicResources.items];
      resourceArray.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      })
      resourceArray = resourceArray.filter((resource) => exclusionGroup?.length > 0 ? !exclusionGroup?.includes(resource.id) : true);
      setResources(resourceArray);

      const tempCategoryCount: any = {}
      resourceArray.forEach(resource => {
        if (tempCategoryCount[resource.categoryId]) {
          tempCategoryCount[resource.categoryId] = tempCategoryCount[resource.categoryId] + 1
        } else {
          tempCategoryCount[resource.categoryId] = 1
        }
      });
      setCategoryCount(tempCategoryCount)
    }
  }, [resourcesData, exclusionGroup])

  const handleCategoryItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedCategoryIndex(index);
    setTimeout(() => {
      if (selectedCategoryRef.current) selectedCategoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500)
  };

  const handleResourceItemClick = (item: IResource) => {
    if (me) {
      const myHTML = { __html: item.description ?? '' };
      setResHTML(myHTML);
      setSelectedResource(item);
      setSelectionModalVisible(true);
    } else {
      navigate(`/resources/${item.id}`);
    }
  };

  const deleteSelection = async (index: number) => {
    const updatedSelections = [...selections.slice(0, index), ...selections.slice(index + 1)];
    setSelections(updatedSelections);
  };

  const addToApplication = () => {
    if (!selectedResource) return;
    setSelections(prevSelections => {
      const existingIndex = prevSelections.findIndex(item => item.resourceId === selectedResource!.id);
  
      if (existingIndex !== -1) {
        const updatedSelections = [...prevSelections];
        updatedSelections[existingIndex] = {
          ...updatedSelections[existingIndex],
          cases,
          singles
        };
        return updatedSelections;
      } else {
        return [
          ...prevSelections,
          {
            resourceId: selectedResource!.id,
            title: selectedResource!.title,
            cases,
            singles,
          }
        ];
      }
    });
    setSingles(0);
    setCases(0);
    setSelectionModalVisible(false);
  };

  const completeRequest = async () => {
    const reSelections = selections.map(item => {
      return {
        resourceId: item.resourceId,
        cases: item.cases,
        singles: item.singles,
      }
    })
    const { data } = await apolloClient.mutate({
      mutation: CheckoutRequestDocument,
      variables: {
        selections: { selections: reSelections },
      }
    });

    return data?.CheckoutRequest?.data?.id
  };

  useEffect(() => {
    if (!selectedResource) return; 
  
    const selected = selections.find(v => v.resourceId === selectedResource.id);
    
    if (selected) {
      setCases(selected.cases);
      setSingles(selected.singles);
    } else {
      setCases(0);
      setSingles(0);
    }
  }, [selections, selectedResource]);

  const handlerCloseSelectResourceModal = () => {
    setSelectionModalVisible(false)
    setIsSelectedResourceError(false)
  }
  

  // const previous = (value: string) => {
  //   if (value === 'signature') setSignatureModalVisible(false);
  // }

  const next = async (value: string, requestId: number) => {
    if (value === 'signature') {
      const { data } = await apolloClient.mutate({
        mutation: SignatureDocument,
        variables: {
          sign: { requestId, sign: digitalSignature },
        }
      });
      if (data?.Signature.status) {
        setSignatureModalVisible(false);
        setResultModalVisible(true);
        setSingles(0);
        setCases(0);
        setSelections([]);
        setCheckoutRequestId(0);
        setDigitalSignature('');
        setRecaptcha(null);
        signOut();
      }
    }
  }

  // console.log('selectedResource:', selectedResource);

  return (
    <>
    <Grid container bgcolor='#f9f1df'>
      <PageSlide images={BookSlides} />
      <PageHeader mainTitle='Book Resources' />
      <Grid container>
        <Grid item xs={0} md={0} xl={1} />
        <Grid item xs={12} md={2} xl={2}>
          <Box my={5} mx={2.5}>
            <Typography variant='h6' sx={modalTitleStyle}>Categories:</Typography>
            <List>
              {categories.map((item, index) => {
                if (!categoryCount[item.id]) return null
                return (
                  <ListItemButton
                    key={index}
                    selected={selectedCategoryIndex === index}
                    onClick={(event) => handleCategoryItemClick(event, index)}
                  >
                    <ListItemText primary={item?.title} />
                  </ListItemButton>
                )
              })}
            </List>
            <img src={DividerBgImg} style={{ width: '100%', height: '4px', margin: '20px 0' }} alt='' />
            <Typography variant='h6' sx={modalTitleStyle}>Select resources by clicking on an image.</Typography>
            <img src={DividerBgImg} style={{ width: '100%', height: '4px', margin: '20px 0' }} alt='' />
            {me && 
              <Box id="floating-button">
                <Box 
                  sx={{
                    top: isFixed ? 120 : null,
                    position: isFixed ? 'fixed' : 'relative',
                    zIndex: 1000,
                    transition: "position 0.3s ease, top 0.3s ease, bottom 0.3s ease"
                  }}
                >
                  <SidebarLink label={<span style={{ fontSize: '2rem' }} onClick={() => setReviewModalVisible(true)}>Complete Request</span>} />
                </Box>
              </Box>}
          </Box>
        </Grid>
        <Grid item xs={12} md={10} xl={8} p={3} pr={5}>
          {categories.map((item, index) => { 
            if (!categoryCount[item.id]) return null
            return (
              <Box key={index} my={2}>
                <Box width='100%' ref={selectedCategoryIndex === index ? selectedCategoryRef : null}>
                  <SubTitle label={item?.title} customStyle={{ fontSize: '2rem' }} />
                </Box>
                <Grid container justifyContent='center'>
                {resources
                  .filter(resource => resource.categoryId === item.id)
                  .sort((a: IResource, b: IResource) => {
                    const orderA = a.sortingOrder === 0 ? 99 : a.sortingOrder;
                    const orderB = b.sortingOrder === 0 ? 99 : b.sortingOrder;
                  
                    if (orderA < orderB) return -1;
                    if (orderA > orderB) return 1;
                  
                    const titleA = (a.title || '').toLowerCase();
                    const titleB = (b.title || '').toLowerCase();
                  
                    return titleA.localeCompare(titleB);
                  })
                  .map((resourceItem, index) => (
                    <ResourceCard
                      key={index}
                      width={4}
                      image={resourceItem.imageUrl}
                      subTitle={resourceItem.subTitle}
                      author={resourceItem.author || ''}
                      title={resourceItem.title}
                      onClick={() => handleResourceItemClick(resourceItem)}
                    />
                  ))}
                </Grid>
              </Box>
            )
          })}
        </Grid>
        <Grid item xs={0} md={0} xl={1} />
      </Grid>

      <Modal open={selectionModalVisible} onClose={() => setSelectionModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box bgcolor='#e3e3e3' borderTop={1} p={1} display='flex' justifyContent='space-between'>
            <Typography fontWeight={800}>Select Resources</Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handlerCloseSelectResourceModal} />
          </Box>
          <Grid container p={3}>
            <Grid item xs={12} md={2}>
              <img src={selectedResource?.imageUrl} loading='lazy' style={{ width: '100%' }} alt='' />
            </Grid>
            <Grid item xs={0} md={1} />
            <Grid item xs={12} md={9}>
              <Typography variant='h5' sx={modalTitleStyle}>{selectedResource?.title}</Typography>
              <Typography variant='subtitle1' sx={modalSubTitleStyle}>{selectedResource?.author}</Typography>
              {selectedResource?.exclusiveOffer && <Typography variant='subtitle1' sx={modalSubTitleStyle}>*Exclusively offered for military audiences (active duty, retired and Veterans)</Typography>}
              {/* <Typography>{selectedResource?.description}</Typography> */}
              <div style={{ fontSize: '1.5rem' }} className='resource-description resource-text' dangerouslySetInnerHTML={resDescriptionHTML}></div>
              <Typography variant='h5' sx={modalTitleStyle}>Request Resources</Typography>
              <Box mb={2.5} fontSize='18px' display='flex' flexDirection={{ md: 'row', xs: 'column' }}>
                <TextField label='Singles' size="medium" value={singles} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!isNaN(Number(event.target.value))) {
                    setSingles(Number(event.target.value))
                  }
                }} />
                <Spacer width={10} height={20} />
                {
                  Boolean(selectedResource?.quantityInCase) &&
                  (
                    <Box>
                    <TextField label='Cases' size="medium" value={cases} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!isNaN(Number(event.target.value))) {
                        setIsSelectedResourceError(false)
                        setCases(Number(event.target.value))
                      }
                    }} />
                    <Typography fontSize='18px' fontStyle='italic'>({selectedResource?.quantityInCase} singles in case)</Typography>
                  </Box>
                  )
                }
                
                <Spacer width={30} height={30} />
                <div>
                  <Button variant='contained' size='small' color='primary' sx={{ height: 50 }} onClick={() => {
                    if (
                      singles > (selectedResource?.quantityInCase ?? 0) - 1 &&
                      (selectedResource?.quantityInCase ?? 0) !== 0
                    ) {
                      setIsSelectedResourceError(true)
                    } else {
                      addToApplication()
                    }
                  }}>ADD TO REQUEST</Button>
                  {
                    isSelectedResourceError && 
                      <Typography fontWeight={600} color='#f00'>singles can not be greater than {(selectedResource?.quantityInCase ?? 0) - 1}</Typography>
                  }
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={reviewModalVisible} onClose={() => setReviewModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box bgcolor='#e3e3e3' borderTop={1} p={1} display='flex' justifyContent='space-between'>
            <Typography fontWeight={800}>Confirm Resource Selections</Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setReviewModalVisible(false)} />
          </Box>
          <Box p={3}>
            <Box maxHeight={500} overflow='auto'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Items</strong></TableCell>
                    <TableCell align='center'><strong>Singles</strong></TableCell>
                    <TableCell align='center'><strong>Cases</strong></TableCell>
                    <TableCell align='center'><strong>Actions</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[ ...selections ].sort(
                    (a: any, b: any) => {
                      return (a?.title?.toLowerCase() > b?.title?.toLowerCase()) ? 1 : ((b?.title?.toLowerCase() > a?.title?.toLowerCase()) ? -1 : 0)
                  }).map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell align='center'>{row.singles}</TableCell>
                      <TableCell align='center'>{row.cases}</TableCell>
                      <TableCell align='center' onClick={() => deleteSelection(index)}><DeleteIcon /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Spacer height={40} />
            <Box display='flex' alignItems='center' justifyContent='flex-end' flexDirection={{ md: 'row', xs: 'column' }}>
              <SidebarLink label={<span style={{ fontSize: '1rem' }} onClick={() => setReviewModalVisible(false)}>Back to Resource Selection</span>} />
              <Spacer width={40} height={20} />
              <Button variant='contained' size='small' color='primary' disabled={selections.length === 0} onClick={() => {
                setReviewModalVisible(false);
                setShippingLocationModalVisible(true);
              }}>COMPLETE REQUEST</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ShippingLocationModal
        completeRequest={completeRequest}
        // requestId={checkoutRequestId}
        shippingLocation={shippingLocation}
        shippingDetail={shippingDetail}
        shippingLocationModalVisible={shippingLocationModalVisible}
        setReviewModalVisible={setReviewModalVisible}
        setShippingLocationModalVisible={setShippingLocationModalVisible}
        setSignatureModalVisible={setSignatureModalVisible}
        digitalSignature={digitalSignature}
        setDigitalSignature={setDigitalSignature}
        setRecaptcha={setRecaptcha}
        recaptcha={recaptcha}
        onSignature={next}
      />
      {/* <Modal open={signatureModalVisible} onClose={() => setSignatureModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box bgcolor='#e3e3e3' borderTop={1} p={1}>
            <Typography fontWeight={800}>Submit Request</Typography>
          </Box>
          <Box maxHeight={{ md: 800, xs: 500 }} overflow='auto'>
            <Box pt={5} px={5}>
              <Typography mb={2.5} fontWeight={600}>Please understand that a separate application is needed for each individual ministry/outreach effort.</Typography>
              <Typography mb={2.5}>I hereby acknowledge and agree that the resources shall only be given away as a free gift. They may not be sold, used as an encouragement for any charitable gifts, or provided for any commercial or personal gain purpose whatsoever.</Typography>
              <Typography mb={2.5}>By placing my digital signature on the application in the space below, I hereby certify that I am the intended recipient and I have full authority from the organization listed above to submit this request for resources on behalf of the organization.</Typography>
            </Box>
            <Box px={3} pb={3}>
              <Grid container pt={1} px={2}>
                <Grid item xs={12} md={5}>
                  <Typography fontWeight={600}>Digital Signature <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>
                  <TextField size='small' value={digitalSignature} onChange={event => setDigitalSignature(event.target.value)} />
                </Grid>
                <Grid item xs={0} md={7} />
              </Grid>
              <Spacer height={20} />
              <CenterBox>
                <ReCAPTCHA sitekey='6Lfd74soAAAAAFagnZQFRjTn3SpbrAfiiv7S4WKO' onChange={value => setRecaptcha(value)} />
              </CenterBox>
              <Spacer height={40} />
              <Box display='flex' alignItems='center' justifyContent='flex-end' flexDirection={{ md: 'row', xs: 'column' }}>
                <SidebarLink label={<span style={{ fontSize: '1rem' }} onClick={() => setSignatureModalVisible(false)}>Back to Resource Selection</span>} />
                <Spacer width={40} height={20} />
                <Button variant='contained' color='primary' size='medium' disabled={recaptcha === null} onClick={() => next('signature')}>Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal> */}
      <Modal open={resultModalVisible} onClose={() => setResultModalVisible(false)}>
        <Box sx={{ ...modalBoxStyle, width: '50%' }}>
          <Box bgcolor='#e3e3e3' borderTop={1} p={1} display='flex' justifyContent='space-between'>
            <Typography fontWeight={800}>Confirmation</Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => { setResultModalVisible(false); navigate('/resources') }} />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Typography margin={5} fontWeight={800}>Your Resource Request has been received. You will be notified about shipping.</Typography>
          </Box>
        </Box>
      </Modal>
      {/* <div style={{ position: 'sticky', bottom: '20px', left: '20px', paddingBottom: '20px', paddingTop: '20px', backgroundColor: '#f9f1df' }}>
        {me && <SidebarLink label={<span style={{ fontSize: '2rem' }} onClick={() => setReviewModalVisible(true)}>Complete<br />Request</span>} />}
      </div> */}
    </Grid>
      <LandingFooter />
    
    </>
  )
}

export default ResourcesPage;
