import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import { SignUpDocument, useUserRolesQuery } from '../../graphql/generated/graphql';

const AdminUserNewContainer = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    document.title = 'Applications';
    return () => { document.title = 'The 1687 Foundation - Christian Literature Offering Hope Encouragement' };
  }, []);

  const [signupMutation] = useMutation(SignUpDocument, {});

  const { data } = useUserRolesQuery({
    variables: { page: 1, pageSize: 10000 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.UserRoles.items) {
      const items = data?.UserRoles.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
      setRoles(items);
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required').min(10, 'Must be 10 digits'),
    adminEmail: Yup.string().required('Email is required').email(),
    adminPassword: Yup.string().required('Password is required'),
    roleId: Yup.number().required('Role is required'),
  });

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      adminEmail: '',
      adminPassword: '',
      roleId: undefined,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (_value: any) => {
    let variables = {
      user: {
        firstName: _value.firstName,
        lastName: _value.lastName,
        phone: _value.phone,
        email: _value.adminEmail,
        password: _value.adminPassword,
        roleId: _value.roleId,
        isSuperuser: true,
      },
    };

    const { data } = await signupMutation({
      mutation: SignUpDocument,
      variables: { data: variables },
    });
    if (data?.SignUp.status) navigate(-1);
  };

  return (
    <Box px={4}>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Add Admin Profile</Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='firstName'
              label='First Name'
              size='small'
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='lastName'
              label='Last Name'
              size='small'
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='phone'
              label='Phone'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='adminEmail'
              label='Email'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='adminPassword'
              label='Password'
              type='password'
              size='small'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Password <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormDropdownField
              name='roleId'
              label='Role'
              size='small'
              options={roles}
              labelComponent={<Typography fontWeight={600}>Role <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='outlined' sx={{ width: 200 }} onClick={handleSubmit(onSubmit)}>Save</Button>
      </Box>
    </Box>
  );
};

export default AdminUserNewContainer;
