import React, { useCallback, useState, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import FormTextField from '../../libs/ui/FormTextField';
import { Spacer } from '../../libs/ui/Spacer';
import { RequestLoginSchemaVariables } from '../../graphql/generated/graphql';

const LoginCard = styled.div(() => ({
  minWidth: 380,
  height: 'fit-content',
  border: '1px solid #1c3e84',
  borderRadius: 20,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const RequestLoginForm = () => {
  const location = useLocation()
  const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search])
  const navigate = useNavigate();
  const { requestSignInWithToken } = useAuth();

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg] = useState<string>('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required')
  });

  const methods = useForm<RequestLoginSchemaVariables>({
    defaultValues: { email: '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const onSubmit = useCallback(async (data: FieldValue<any>) => {
    const token = queryParameters.get('token')
    if (token) {
      console.log(1)
      const result = await requestSignInWithToken({ ...data, token });
      if (result.success) {
        navigate('/resources');
      } else {
        if (result?.message) {
          setErrorMsg(result?.message);
        }
      }
    } else {
      setErrorMsg('Please use the link emailed to you to log in');
    }
  }, [navigate, queryParameters, requestSignInWithToken]);

  const onContinue = () => {
    navigate('/book-apply');
  }

  return (
    <Box>
      <LoginCard>
        <Stack direction='column' spacing={1} justifyContent='center' sx={{ pt: 0 }}>
          <FormTextField name='email' label='Email Address' control={control} />
          <Spacer height={8} />
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
            <Button variant='contained' sx={{ background: '#155bd5' }} onClick={handleSubmit(onSubmit)}>Continue</Button>
          </Box>
        </Stack>
      </LoginCard>
      <Box maxWidth={900} p={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        {successMsg !== '' && <Typography fontSize={14} color='#000'>{successMsg}</Typography>}
        {errorMsg !== '' && <Typography fontSize={20} color='#f00'>{errorMsg}</Typography>}
        {errorMsg === 'Your Resource Request has been completed. Would you like to submit a new Informtion Form?' &&
          <Button variant='contained' sx={{ mt: 2 }} onClick={onContinue}>Continue</Button>}
      </Box>
    </Box>
  );
};
