import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableHead,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormDateTimeField from '../../libs/ui/FormDateTimeField';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { useRequestReportLazyQuery } from '../../graphql/generated/graphql';

const currentDate = new Date(new Date(new Date().setHours(0)).setMinutes(0));
const beforeDate = new Date(new Date(new Date().setHours(0)).setMinutes(0));

const RequestReports = () => {
  const [showData, setShowData] = useState<boolean>(false);
  const [chartType, setChartType] = useState<string>('month');
  const validationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const methods = useForm({
    defaultValues: {
      fromDate: new Date(beforeDate.setMonth(beforeDate.getMonth() - 1)),
      endDate: currentDate,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, watch } = methods;

  const [getData, { data }] = useRequestReportLazyQuery();

  const onSubmit = (_value: any) => {
    getData({ variables: { start: _value.fromDate, end: _value.endDate } });
  };

  const reportData = useMemo(() => {
    if (data?.RequestReport.data) return JSON.parse(data.RequestReport.data);
    return null;
  }, [data]);
  
  const chartData = useMemo(() => {
    if (reportData) return reportData?.chart[chartType];
    return [];
  }, [reportData, chartType]);

  const uData = useMemo(() => {
    if (chartData.length) return chartData.map((_item: any) => Number(_item.count));
    return [];
  }, [chartData]);

  const xLabels = useMemo(() => {
    if (chartData.length) return chartData.map((_item: any) => _item[chartType]);
    return [];
  }, [chartData, chartType]);

  useEffect(() => {
    if (reportData) {
      setShowData(true);
    } else setShowData(false);
  }, [reportData]);

  const handleChange = (e: any) => {
    setChartType(e.target.value);
  };

  return (
    <Box>
      <Box sx={{ border: 'solid 1px lightgray', marginTop: 2, padding: 1, paddingBottom: 2 }}>
        <Stack direction='column' justifyContent='center' spacing={1} sx={{ width: 'fit-content', background: 'white', mt: '-20px', padding: '0px 8px' }}>
          <Typography variant='body1' fontWeight={600}>Request Filter</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='fromDate'
              label='Date From'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, lineHeight: '40px' }}>Date From</Typography>}
              size='small'
              type='datetime-local'
              maxDateTime={watch('endDate') ?? currentDate}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='endDate'
              label='Date To'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, lineHeight: '40px' }}>Date To</Typography>}
              size='small'
              type='date'
              minDateTime={watch('fromDate') ?? currentDate}
              maxDateTime={currentDate}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant='outlined' onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Box>
      {showData && (
        <Box mt={4}>
          <Typography variant='h5' fontWeight={600}>Report Results</Typography>
          <Box mt={1}>
            <Typography variant='h6' fontWeight={600}>Summary</Typography>
            <TableContainer>
              <Table aria-labelledby='tableTitle' size='small'>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>Title</TableCell>
                    <TableCell sx={{ paddingLeft: '12px!important' }}>Count</TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow hover role='checkbox' tabIndex={-1}>
                    <TableCell align='left'>Total</TableCell>
                    <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{reportData?.total}</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ borderWidth: '1px', marginTop: '12px' }} />
          </Box>
          <Box mt={1}>
            <Typography variant='h6' fontWeight={600}>
              Current Request Statuses (Not looking at history of the Request)
            </Typography>
            <TableContainer>
              <Table aria-labelledby='tableTitle' size='small'>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>Status</TableCell>
                    <TableCell sx={{ paddingLeft: '12px!important' }}>Count</TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {reportData?.status?.map((row: any) => (
                    <StyledTableRow key={`status-${row.status}`} hover role='checkbox' tabIndex={-1}>
                      <TableCell align='left'>{row.status}</TableCell>
                      <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.count}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ borderWidth: '1px', marginTop: '12px' }} />
          </Box>
          {uData && uData.length > 0 && (
            <Box mt={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={chartType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    sx={{ marginBottom: 0 }}
                    label={<Typography sx={{ marginBottom: 0, fontSize: '16px' }}>Years</Typography>}
                    value='year'
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '16px' }, paddingTop: 0, paddingBottom: 0 }} />}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: 0 }}
                    value='month'
                    label={<Typography sx={{ fontSize: '16px', marginBottom: 0 }}>Months</Typography>}
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '16px' }, paddingTop: 0, paddingBottom: 0 }} />}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: 0 }}
                    value='week'
                    label={<Typography sx={{ marginBottom: 0, fontSize: '16px' }}>Weeks</Typography>}
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '16px' }, paddingTop: 0, paddingBottom: 0 }} />}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: 0 }}
                    value='day'
                    label={<Typography sx={{ marginBottom: 0, fontSize: '16px' }}>Days</Typography>}
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '16px' }, paddingTop: 0, paddingBottom: 0 }} />}
                  />
                </RadioGroup>
              </FormControl>
              <LineChart
                height={300}
                series={[{ data: uData, label: 'Total Requests' }]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                sx={{
                  '.MuiLineElement-root, .MuiMarkElement-root': {
                    strokeWidth: 1,
                  },
                  '.MuiLineElement-series-pvId': {
                    strokeDasharray: '5 5',
                  },
                  '.MuiLineElement-series-uvId': {
                    strokeDasharray: '3 4 5 2',
                  },
                  '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
                    fill: '#fff',
                  },
                  '& .MuiMarkElement-highlighted': {
                    stroke: 'none',
                  },
                }}
              />
            </Box>
          )}
          <Box mt={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Requests Per Ministry
            </Typography>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={"small"}>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>Ministry</TableCell>
                    <TableCell sx={{ paddingLeft: '12px!important' }}>Request Count</TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {reportData?.ministryCounts?.map((row: any) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`status-${row.name}`}
                    >
                      <TableCell align="left">{row.ministryCategory}</TableCell>
                      <TableCell align="left" sx={{ paddingLeft: '12px!important' }}>{row.requestCount}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ marginTop: "12px", borderWidth: "1px" }} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RequestReports;
