import { useMemo } from 'react';
import { Box, Table, TableBody, TableContainer, Typography, TableHead, Divider } from '@mui/material';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { useResourcesListQuery } from '../../graphql/generated/graphql';

const InventoryReports = () => {

  const { data } = useResourcesListQuery({ fetchPolicy: 'network-only' });

  const summary = useMemo(() => {
    if (data?.Resources?.items && data.Resources.items.length > 0) {
      return data.Resources.items
    }
    return []
  }, [data])

  return (
    <Box mt={1}>
      <Typography variant='h6' fontWeight={600}>Inventory</Typography>
      <TableContainer>
        <Table aria-labelledby='tableTitle' size='small'>
          <TableHead>
            <StyledTableRow>
              <TableCell sx={{ width: '150px' }}>SKU</TableCell>
              <TableCell>Title</TableCell>
              <TableCell sx={{ width: '150px', paddingLeft: '12px!important' }}>Count</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[ ...summary ]?.sort((a: any, b: any) => (a.title?.toLowerCase() > b.title?.toLowerCase()) ? 1 : ((b.title?.toLowerCase() > a.title?.toLowerCase()) ? -1 : 0))?.map(row => (
              <StyledTableRow hover role='checkbox' tabIndex={-1}>
                <TableCell align='left'>{row.sku}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.title}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.inventory}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ borderWidth: '1px', marginTop: '12px' }} />
    </Box>
  );
};

export default InventoryReports;
