import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Tooltip,
  IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import { showSuccess } from '../../libs/utils/toasts';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import { DeleteFormDocument, DuplicateFormDocument, FormsDocument, useFormsQuery } from '../../graphql/generated/graphql';
import { FormResponseData } from '../../const/type';

export const FormsContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<FormResponseData[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  const [duplicateFormMutation] = useMutation(DuplicateFormDocument);
  const [deleteFormMutation] = useMutation(DeleteFormDocument, {
    refetchQueries: [
      {
        query: FormsDocument,
        variables: { page: page + 1, pageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ]
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data } = useFormsQuery({
    variables: { page: page + 1, pageSize: rowsPerPage },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.Forms.items) {
      setRows((_rows) => {
        data.Forms.items.forEach((item: any) => {
          const _exist = _rows.find((row) => row.id === item.id);
          if (!_exist) _rows.push(item);
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
    }
    if (data?.Forms) setTotalCount(data.Forms.total);
  }, [data]);

  const onDuplicateForm = async (id: number) => {
    if (!me?.role?.editCMS) return showSuccess('Your role doesn\'t have the permission');
    await duplicateFormMutation({
      variables: { formId: id },
      refetchQueries: [
        {
          query: FormsDocument,
          variables: { page: page + 1, pageSize: rowsPerPage },
          fetchPolicy: 'network-only',
        },
      ],
    })
  }

  const handleDelete = async () => {
    const { data } = await deleteFormMutation({
      variables: { formId: deleteId }
    });

    if (data?.DeleteForm.status) {
      setRows(_rows => {
        return _rows.filter(item => item.id !== deleteId)
      })
      setDeleteId(undefined);
      setDeleteModalVisible(false);
    }
  }

  return (
    <Box>
      <Grid container justifyContent={'space-between'}>
        <Typography variant='h6' mb={2}>Forms</Typography>
        <Button variant='outlined' onClick={() => navigate('./new')}>Add new form</Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align='center'>Submissions</TableCell>
              {(me?.isSuperuser || me?.role?.editCMS) && <TableCell width={80} align='center'>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.adminEmail}</TableCell>
                <TableCell align='center'>
                  <strong style={{ marginRight: '4px' }}>{row?.submissions?.length}</strong>
                  {row?.submissions?.length && row?.submissions?.length > 0 ? <LaunchIcon onClick={() => navigate(`./${row.id}/submissions`)} /> : ''}
                </TableCell>
                {(me?.isSuperuser || me?.role?.editCMS) &&
                  <TableCell>
                    <Box display='flex'>
                      <IconButton onClick={() => navigate(`./${row.id}`)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDuplicateForm(row.id || 0)}>
                        <Tooltip id='button-report' title='Duplicate'>
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => { setDeleteId(row.id); setDeleteModalVisible(true) }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteModal
        open={deleteModalVisible}
        title='Are you sure you want to delete this form?'
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />
    </Box>
  )
}
