import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormPasswordField from '../../libs/ui/FormPasswordField';
import { Spacer } from '../../libs/ui/Spacer';
import { ResetPasswordDocument } from '../../graphql/generated/graphql';

const ResetPasswordContainer = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('New password is required'),
    confirmPassword: Yup.string().required('Confirm password is required'),
  });

  const methods = useForm({
    defaultValues: { newPassword: '', confirmPassword: '' },
    resolver: yupResolver(validationSchema),
  });

  const [errorMsg, setErrorMsg] = useState<string>('');

  const { handleSubmit, control, reset } = methods;

  const [resetPassword] = useMutation(ResetPasswordDocument)

  const onSubmit = async (data: FieldValue<any>) => {
    if (data?.newPassword === data?.confirmPassword) {
      const { data: response } = await resetPassword({ variables: { token: token!, password: data?.newPassword } });
      if (response?.ResetPassword?.status) {
        setErrorMsg('Password reset successful');
        reset({ newPassword: '', confirmPassword: '' });
        navigate('/login');
      } else {
        setErrorMsg(response?.ResetPassword?.message);
      }
    } else {
      setErrorMsg('Passwords do not match');
    }
  }

  return (
    <Box width='100vw' height={700} display='flex' alignItems='center' justifyContent='center'>
      <Stack width='30%' direction='column'>
        <Typography variant='h6' m={1} fontWeight={600}>Reset Password</Typography>
        <Box minWidth={300} height='fit-content' border='1px solid #1c3e84' borderRadius={2.5} padding={3.75} display='flex' flexDirection='column'>
          <Stack direction='column' spacing={1} justifyContent='center' sx={{ pt: 0 }}>
            <FormPasswordField name='newPassword' label='New Password' control={control} />
            <Spacer height={8} />
            <FormPasswordField name='confirmPassword' label='Confirm Password' control={control} />
            <Spacer height={8} />
            <Box display='flex' flexDirection='row' justifyContent='flex-end'>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Send</Button>
            </Box>
            {errorMsg !== '' && <Typography sx={{ margin: '4px 14px 0', fontSize: '18px', fontWeight: 400, color: '#d32f2f' }}>{errorMsg}</Typography>}
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

export default ResetPasswordContainer;
