import React from 'react';
import { Box } from '@mui/material';
import TestimonialsContainer from '../../components/testimonials/TestimonialsContainer';

const TestimonialsPage = () => {
  return (
    <Box>
      <TestimonialsContainer />
    </Box>
  )
}

export default TestimonialsPage;
