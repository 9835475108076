import React from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import FormNumberField from '../../libs/ui/FormNumberField';
import CenterBox from '../common/CenterBox';
import { Spacer } from '../../libs/ui/Spacer';
import { IResource, UpdateInventoryDocument } from '../../graphql/generated/graphql';

const modalBoxStyle = {
  width: '50%',
  backgroundColor: '#fff',
  borderRadius: 2,
  paddingBottom: 4,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

interface InventoryModalProps {
  resource: IResource | null;
  inventoryModalVisible: boolean;
  setInventoryModalVisible: (visible: boolean) => void;
}

const InventoryModal = ({ resource, inventoryModalVisible, setInventoryModalVisible }: InventoryModalProps) => {
  const { me } = useAuth();

  const validationSchema = Yup.object().shape({
    value: Yup.number().required('Value is required').test('non-negative', 'Inventory cannot go negative', function(value) {
      const currentValue = Number(value);
      const currentInventory = Number(resource?.inventory);
      return currentValue + currentInventory >= 0;
    }),
  })

  const methods = useForm({
    values: { value: 0 },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, control, getValues, setValue } = methods;

  const [updateInventory] = useMutation(UpdateInventoryDocument, {});

  const update = async () => {
    const { data } = await updateInventory({
      mutation: UpdateInventoryDocument,
      variables: {
        input: { id: resource?.id, count: Number(getValues('value')), userId: me?.id }
      }
    })
    if (data.UpdateInventory.data) {
      setInventoryModalVisible(false);
      setValue('value', 0);
    }
  }

  return (
    <Modal open={inventoryModalVisible} onClose={() => setInventoryModalVisible(false)}>
      <Box sx={modalBoxStyle}>
        <Box sx={{ backgroundColor: '#e3e3e3', borderRadius: 1, padding: 2, position: 'relative' }}>
          <Typography fontWeight={600} textAlign='center'>Update Inventory</Typography>
          <CloseIcon sx={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }} onClick={() => setInventoryModalVisible(false)} />
        </Box>
        <Box mx={4} p={4}>
          <Grid container>
            <Grid item xs={4}><b>Resource Name:</b></Grid>
            <Grid item xs={8}>{resource?.title}</Grid>
            <Grid item xs={4}><b>Current Inventory:</b></Grid>
            <Grid item xs={8}>{resource?.inventory}</Grid>
          </Grid>
        </Box>
        <CenterBox>
          <FormNumberField
            name='value'
            label='Update Value'
            size='small'
            labelComponent={<Typography width={160} fontWeight={500} lineHeight='40px'>Update Value:</Typography>}
            control={control}
            type={'number'}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { display: 'flex' } }}
          />
        </CenterBox>
        <Spacer height={40} />
        <CenterBox>
          <Button variant='contained' color='primary' sx={{ height: '40px' }} onClick={handleSubmit(update)}>Update</Button>
        </CenterBox>
      </Box>
    </Modal>
  )
}

export default InventoryModal;
