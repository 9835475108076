import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns'
import { DeleteModal } from '../../libs/ui/DeleteModal';
import { showSuccess } from '../../libs/utils/toasts';
import { DeleteFormSubmissionDocument, useFormSubmissionsQuery } from '../../graphql/generated/graphql';

const FormSubmissionsContainer = () => {
  const navigate = useNavigate();
  const { formId } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);

  const [deleteFormSubmission] = useMutation(DeleteFormSubmissionDocument, {});

  const { data } = useFormSubmissionsQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      formId: Number(formId) || 0,
    },
    fetchPolicy: 'network-only',
  });
  
  useEffect(() => {
    if (data?.FormSubmissions.items) {
      setRows(JSON.parse(data?.FormSubmissions.items))
    }
    if (data?.FormSubmissions.keys) {
      const filtered = data?.FormSubmissions.keys.filter(item => !['submission_id', 'Submit'].includes(item));
      setHeaders(filtered);
    }
    if (data?.FormSubmissions) setTotalCount(data.FormSubmissions.total);
  }, [data])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    const { data } = await deleteFormSubmission({
      variables: { submissionId: deleteId }
    });

    if (data?.DeleteFormSubmission.status) {
      setRows(_rows => {
        return _rows.filter(item => item['submission_id'] !== deleteId)
      })
      setDeleteId(undefined);
      setDeleteModalVisible(false);
      showSuccess('The submission was deleted successfully');
    }
  }

  return (
    <Box>
      <Grid container>
        <Typography variant='h6' mb={2}>Form Submissions</Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
              {headers.length > 0 && <TableCell align='center' width={80}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} sx={{ cursor: 'pointer' }}>
                {headers.map((item, index) => (
                  <TableCell key={index}>{item === 'createdAt' ? (format(new Date(row[item]), "MM/dd/yyyy hh:mmaaaaa'm'") !== '12/31/1969 04:00pm' ? format(new Date(row[item]), "MM/dd/yyyy hh:mmaaaaa'm'") : '' ) : row[item]}</TableCell>
                ))}
                {headers.length > 0 && <TableCell>
                  <Box sx={{ display: 'flex' }}>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/app/forms/${formId}/submissions/${row['submission_id']}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeleteId(row['submission_id']);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteModal
        title='Are you sure delete this form submission?'
        open={deleteModalVisible}
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />
    </Box>
  )
}

export default FormSubmissionsContainer;
