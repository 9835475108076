import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Button, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { DropMenu } from '../../libs/ui/DropMenu';
import { PagesModel, useMinistriesDropDownItemsQuery, usePagesQuery } from '../../graphql/generated/graphql';
import LogoTransparentImg from '../../assets/images/logo-transparent.png';

const landingLogoStyle = {
  width: 222,
  height: 96,
  backgroundColor: '#fff',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttonStyle = {
  fontFamily: 'Barlow',
  fontSize: '1.25rem',
  fontWeight: 400,
  color: '#fff',

  '&:hover': {
    color: '#e83231',
  }
};

const userMenu = [
  { name: 'Profile', url: '/app/profile' },
  { name: 'Dashboard Page', url: '/app/application' },
  { name: 'Logout', url: '/' },
];

interface MinistryMenu {
  name: string;
  url: string;
}

const LandingHeader: FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  const [ministryMenu, setMinistryMenu] = useState<MinistryMenu[]>([]);
  const [showMinistries, setShowMinistries] = useState<boolean>(false);
  const [groupMenuUrl, setGroupMenuUrl] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<HTMLElement | null>(null);

  const { data } = usePagesQuery({
    variables: { page: 1, pageSize: 10000, isNav: true },
    fetchPolicy: 'network-only',
  });

  const { data: ministryDropDownData } = useMinistriesDropDownItemsQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (ministryDropDownData?.Ministries.items) {
      const _menus = ministryDropDownData.Ministries.items.map(_item => ({ name: _item.name, url: `/ministries/${_item.url}` }))
      setMinistryMenu(_menus)
    }
  }, [ministryDropDownData])

  const pageToHeader = useCallback((item: PagesModel): any => {
    let _pageHeader;

    if (item.children && item.children?.length > 0) {
      _pageHeader = {
        name: item.name,
        title: item.pageTitle,
        url: `/${item.url}`,
        DropMenu: item.dropMenu,
        order: item.order,
        id: item.id,
        children: item.children.map((child: PagesModel) => {return pageToHeader(child)}).sort((a, b) => a.order > b.order ? 1 : -1)
      };
    } else {
      _pageHeader = {
        name: item.name,
        title: item.pageTitle,
        url: `/${item.url}`,
        id: item.id,
        DropMenu: item.dropMenu,
        order: item.order,
      };
    }
    return _pageHeader;
  }, []);

  const pageHeaders = useMemo(() => {
    let _headers = data?.Pages.items.map((item) => pageToHeader(item));
    if (_headers) return _headers.filter((header: any) => !(header.isNav && (!header.children || header.children.length === 0)))
    return []
  }, [data, pageToHeader]);

  const navigateNewsPage = (value: string) => {
    setNestedAnchorEl(null);
    value !== '' && navigate(`/news/${value}`);
  }

  const renderSubMenu = (headers: {name: string, title: string, children: PagesModel[], id: number, url: string }[]) => (
    headers?.map((pageHeader) =>
      !pageHeader.children ?
        <MenuItem key={pageHeader.id} onClick={() => { navigate(pageHeader.url, { state: { id: pageHeader.id, title: pageHeader.title } }); setAnchorEl(null) }}>
          {pageHeader.name}
        </MenuItem>
      : [
        <MenuItem
          key={pageHeader.id}
          sx={{ '&:focus': { color: '#e83231' } }}
          onClick={() => { setGroupMenuUrl(groupMenuUrl === pageHeader.url ? '' : pageHeader.url); setShowMinistries(false) }}
        >
          {pageHeader.name}
        </MenuItem>,
        groupMenuUrl === pageHeader.url && pageHeader.children.map((item: any, index: number) => (
          <MenuItem key={index} sx={{ pl: 4 }} onClick={() => { navigate(item.url); setAnchorEl(null) }}>{item.name}</MenuItem>
        ))
      ]
    )
  )

  return (
    <AppBar component='nav'>
      <Toolbar sx={{ backgroundColor: '#00001a', pl: '0px !important', justifyContent: 'space-between' }}>
        <Box sx={landingLogoStyle} onClick={() => navigate('/')}>
          <img src={LogoTransparentImg} loading='lazy' style={{ height: 80 }} alt='' />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button variant='text' sx={buttonStyle} onClick={() => navigate('/')}>Home</Button>
          {pageHeaders?.filter(pageHeader => pageHeader.name !== 'APPLY').map(pageHeader => !pageHeader.children ?
            <Button key={pageHeader.id} sx={buttonStyle} onClick={() => navigate(pageHeader.url, { state: { id: pageHeader.id, title: pageHeader.title } })}>
              {pageHeader.name}
            </Button>
            :
            <DropMenu key={pageHeader.id} navName={pageHeader.name} menuList={pageHeader.children || []} />
          )}
          {ministryMenu && ministryMenu.length > 0 && <DropMenu navName='Ministries' menuList={ministryMenu} />}
          {pageHeaders?.filter(pageHeader => pageHeader.name === 'APPLY').map(pageHeader => !pageHeader.children ?
            <Button key={pageHeader.id} sx={buttonStyle} onClick={() => navigate(pageHeader.url, { state: { id: pageHeader.id, title: pageHeader.title } })}>
              {pageHeader.name}
            </Button>
            :
            <DropMenu key={pageHeader.id} navName={pageHeader.name} menuList={pageHeader.children || []} />
          )}
          <Button sx={buttonStyle} onClick={event => setNestedAnchorEl(event.currentTarget)}>News</Button>
          {me && <DropMenu navName={''} menuList={userMenu} />}
        </Box>
        <Menu
          id="nested-menu"
          anchorEl={nestedAnchorEl}
          open={Boolean(nestedAnchorEl)}
          onClose={() => navigateNewsPage('')}
        >
          <MenuItem onClick={() => navigateNewsPage('book-ministry')}>Book Ministry</MenuItem>
          <MenuItem onClick={() => navigateNewsPage('retreat-program')}>Retreat Program</MenuItem>
          <MenuItem onClick={() => navigateNewsPage('hunting-fishing')}>Hunting & Fishing</MenuItem>
          <MenuItem onClick={() => navigateNewsPage('woodys-classic-cars-and-baseball-museum')}>Woody’s Classic Cars and Baseball Museum</MenuItem>
        </Menu>

        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <IconButton color='inherit' onClick={event => setAnchorEl(event.currentTarget)}>
            <MenuIcon />
          </IconButton>
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            sx={{ '& ul': { width: 250, backgroundColor: '#00001a', color: '#fff' } }}
          >
            <MenuItem tabIndex={-1} onClick={() => { navigate('/'); setAnchorEl(null) }}>HOME</MenuItem>
            {renderSubMenu(pageHeaders?.filter(pageHeader => pageHeader !== 'APPLY'))}
            <MenuItem sx={{ '&:focus': { color: '#e83231' } }} onClick={() => { setShowMinistries(!showMinistries); setGroupMenuUrl('') }}>MINISTRIES</MenuItem>
            {showMinistries && ministryMenu.map((item, index) => (
              <MenuItem
                key={index}
                sx={{
                  pl: 4,
                  whiteSpace: 'normal', 
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  maxWidth: '100%'
                }}
                onClick={() => { navigate(item.url); setAnchorEl(null) }}>
                  {item.name}
              </MenuItem>
            ))}
            {renderSubMenu(pageHeaders?.filter(pageHeader => pageHeader === 'APPLY'))}
            <MenuItem onClick={() => { navigate('/news'); setAnchorEl(null) }}>NEWS</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
