import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchResult } from '@apollo/client/link/core';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import EndBox from '../common/EndBox';
import { Spacer } from '../../libs/ui/Spacer';
import apolloClient from '../../graphql/apolloClient';
import { CreateUserRoleDocument, CreateUserRoleMutation } from '../../graphql/generated/graphql';

const RoleNewContainer = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    isDefault: Yup.boolean(),
    isActive: Yup.boolean(),
    addAdmin: Yup.boolean(),
    changePermission: Yup.boolean(),
    changeUsersProfile: Yup.boolean(),
    changeProfile: Yup.boolean(),
    approveUser: Yup.boolean(),
    deleteUser: Yup.boolean(),
    viewRequest: Yup.boolean(),
    editRequest: Yup.boolean(),
    editShippedRequest: Yup.boolean(),
    viewResource: Yup.boolean(),
    editResource: Yup.boolean(),
    viewCategory: Yup.boolean(),
    editCategory: Yup.boolean(),
    viewCMS: Yup.boolean(),
    editCMS: Yup.boolean(),
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      isDefault: true,
      isActive: true,
      addAdmin: true,
      changePermission: true,
      changeUsersProfile: true,
      changeProfile: true,
      approveUser: true,
      deleteUser: true,
      viewRequest: true,
      editRequest: true,
      viewResource: true,
      editShippedRequest: false,
      editResource: true,
      viewCategory: true,
      editCategory: true,
      viewCMS: true,
      editCMS: true,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, getValues } = methods;

  const save = async () => {
    const userRole = {
      name: getValues('name'),
      description: getValues('description'),
      isDefault: getValues('isDefault'),
      isActive: getValues('isActive'),
      addAdmin: getValues('addAdmin'),
      changePermission: getValues('changePermission'),
      changeUsersProfile: getValues('changeUsersProfile'),
      changeProfile: getValues('changeProfile'),
      approveUser: getValues('approveUser'),
      deleteUser: getValues('deleteUser'),
      viewRequest: getValues('viewRequest'),
      editRequest: getValues('editRequest'),
      viewResource: getValues('viewResource'),
      editResource: getValues('editResource'),
      viewCategory: getValues('viewCategory'),
      editCategory: getValues('editCategory'),
      viewCMS: getValues('viewCMS'),
      editCMS: getValues('editCMS'),
    };
    const { data }: FetchResult<CreateUserRoleMutation> = await apolloClient.mutate({
      mutation: CreateUserRoleDocument,
      variables: {
        input: userRole,
      }
    });
    if (data?.CreateUserRole.status) {
      navigate(-1);
    }
  }

  return (
    <Box p={8}>
      <Grid container>
        <Grid item xs={5}>
          <FormTextField
            name='name'
            label='Name'
            size='small'
            placeholder=''
            labelComponent={<Typography fontWeight={600}>Name</Typography>}
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormTextField
            name='description'
            label='Description'
            size='small'
            placeholder=''
            labelComponent={<Typography fontWeight={600}>Description</Typography>}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='isDefault'
            label='IsDefault'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='isActive'
            label='IsActive'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='addAdmin'
            label='Add a new Admin App user'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='changePermission'
            label='Set/Change permissions for other Admin App users'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='changeUsersProfile'
            label='Set/Change attributes of other Admin App users'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='changeProfile'
            label='Set/Change attributes (name, password, etc.) of my own user record'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='approveUser'
            label='Lock or unlock a Staff user'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='deleteUser'
            label='Delete an Admin App user'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='viewRequest'
            label='View records in the Requests section'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='editRequest'
            label='Edit records the Requests section'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='viewResource'
            label='View the Book Resources section '
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='editResource'
            label='Edit the Book Resources section'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='viewCategory'
            label='View Category'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='editCategory'
            label='Edit Cateogry'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <FormCheckBoxField
            name='viewCMS'
            label='View CMS'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <FormCheckBoxField
            name='editCMS'
            label='Edit CMS'
            size='small'
            control={control}
          />
        </Grid>
      </Grid>
      <Spacer height={40} />
      <EndBox>
        <Button variant='outlined' sx={{ minWidth: 200 }} onClick={handleSubmit(save)}>Save</Button>
      </EndBox>
    </Box>
  )
}

export default RoleNewContainer;
