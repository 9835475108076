import SlideRightBook1 from '../assets/images/slides/slide-right-book-1.jpg';
import SlideRightBook2 from '../assets/images/slides/slide-right-book-2.jpg';
import SlideRightBook3 from '../assets/images/slides/slide-right-book-3.jpg';
import SlideRightBook4 from '../assets/images/slides/slide-right-book-4.jpg';
import SlideRightBook5 from '../assets/images/slides/slide-right-book-5.jpg';
import SlideRightBook6 from '../assets/images/slides/slide-right-book-6.jpg';
import SlideRightRetreat1 from '../assets/images/slides/slide-right-retreat-1.jpg';
import SlideRightRetreat2 from '../assets/images/slides/slide-right-retreat-2.jpg';
import SlideRightRetreat3 from '../assets/images/slides/slide-right-retreat-3.jpg';
import SlideRightRetreat4 from '../assets/images/slides/slide-right-retreat-4.jpg';
import SlideRightRetreat5 from '../assets/images/slides/slide-right-retreat-5.jpg';
import SlideRightRetreat6 from '../assets/images/slides/slide-right-retreat-6.jpg';
import SlideRightRetreat7 from '../assets/images/slides/slide-right-retreat-7.jpg';
import SlideRightRetreat8 from '../assets/images/slides/slide-right-retreat-8.jpg';
import SlideRightRetreat9 from '../assets/images/slides/slide-right-retreat-9.jpg';
import SlideRightRetreat10 from '../assets/images/slides/slide-right-retreat-10.jpg';
import SlideRightRetreat11 from '../assets/images/slides/slide-right-retreat-11.jpg';
import SlideRightRetreat12 from '../assets/images/slides/slide-right-retreat-12.jpg';
import SlideRightHunting1 from '../assets/images/slides/slide-right-hunting-1.jpg';
import SlideRightHunting2 from '../assets/images/slides/slide-right-hunting-2.jpg';
import SlideRightHunting3 from '../assets/images/slides/slide-right-hunting-3.jpg';
import SlideRightHunting4 from '../assets/images/slides/slide-right-hunting-4.jpg';
import SlideRightHunting5 from '../assets/images/slides/slide-right-hunting-5.jpg';
import SlideRightHunting6 from '../assets/images/slides/slide-right-hunting-6.jpg';
import SlideRightWoodys from '../assets/images/slides/slide-right-woodys.jpg';

export const BookSlides = [SlideRightBook1, SlideRightBook2, SlideRightBook3, SlideRightBook4, SlideRightBook5, SlideRightBook6];
export const RetreatSlides = [SlideRightRetreat1, SlideRightRetreat2, SlideRightRetreat3, SlideRightRetreat4, SlideRightRetreat5, SlideRightRetreat6, SlideRightRetreat7, SlideRightRetreat8, SlideRightRetreat9, SlideRightRetreat10, SlideRightRetreat11, SlideRightRetreat12];
export const HuntingSlides = [SlideRightHunting1, SlideRightHunting2, SlideRightHunting3, SlideRightHunting4, SlideRightHunting5, SlideRightHunting6];
export const WoodySlides = [SlideRightWoodys];

