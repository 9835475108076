import React, { createContext, useContext, useState } from 'react';
import { SortDirectionField, UserSortField } from '../graphql/generated/graphql';
import { UserResponseData } from '../const/type';

interface UsersContextType {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  rows: UserResponseData[];
  openDeleteModal: boolean;
  selected: readonly number[];
  status: string;
  searchKey: string;
  sort: UserSortField;
  sortDirection: SortDirectionField;
  filter: any;
  filterId?: string;
  filterType: string;
  filterText: string;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setTotalCount: (count: number) => void;
  setRows: (rows: UserResponseData[]) => void;
  setOpenDeleteModal: (open: boolean) => void;
  setSelected: (selected: readonly number[]) => void;
  setStatus: (status: string) => void;
  setSearchKey: (searchKey: string) => void;
  setSort: (sort: UserSortField) => void;
  setSortDirection: (sortDirection: SortDirectionField) => void;
  setFilter: (filter: any) => void;
  setFilterId: (filterId?: string) => void;
  setFilterType: (filterType: string) => void;
  setFilterText: (filterText: string) => void;
}

const UsersContext = createContext<UsersContextType | undefined>(undefined);

export const UsersProvider: React.FC<{ children: any }> = ({ children }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rows, setRows] = useState<UserResponseData[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [status, setStatus] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [sort, setSort] = useState<UserSortField>(UserSortField.Id);
  const [sortDirection, setSortDirection] = useState<SortDirectionField>(SortDirectionField.Desc);
  const [filter, setFilter] = useState<any>({});
  const [filterId, setFilterId] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('equalTo');
  const [filterText, setFilterText] = useState<string>('');

  return (
    <UsersContext.Provider value={{
      page,
      rowsPerPage,
      totalCount,
      rows,
      openDeleteModal,
      selected,
      status,
      searchKey,
      sort,
      sortDirection,
      filter,
      filterId,
      filterType,
      filterText,
      setPage,
      setRowsPerPage,
      setTotalCount,
      setRows,
      setOpenDeleteModal,
      setSelected,
      setStatus,
      setSearchKey,
      setSort,
      setSortDirection,
      setFilter,
      setFilterId,
      setFilterType,
      setFilterText
    }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = (): UsersContextType => {
  const context = useContext(UsersContext);
  if (!context) throw new Error('useUsersContext must be used within a UsersProvider');
  return context;
};
