import { useEffect, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormDateField from '../../libs/ui/FormDateField';
import FormEditorField from '../../libs/ui/FormEditorField';
import FormSwitch from '../../libs/ui/FormSwitch';
import { showSuccess } from '../../libs/utils/toasts';
import { PostModel, PostsDocument, usePostByIdLazyQuery, useUpdatePostMutation } from '../../graphql/generated/graphql';

export const PostsEditContainer = () => {
  const { postId } = useParams();
  const { ministryId } = useParams();

  const [post, setPost] = useState<PostModel | null>(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    url: Yup.string().required('Url is required'),
    publishedTime: Yup.date().required('Date Published is required'),
    isEnabled: Yup.boolean(),
    content: Yup.string().required('This field is required'),
  });

  const methods = useForm({
    values: {
      title: post?.title ?? '',
      url: post?.url ?? '',
      publishedTime: post?.publishedTime ? new Date(post.publishedTime) : new Date('1/1/2025'),
      isEnabled: post?.isEnabled ?? false,
      content: post?.content ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [getPost, { data: postData}] = usePostByIdLazyQuery({
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (postData?.Post.data) setPost(postData.Post.data);
  }, [postData])

  useEffect(() => {
    if (postId) getPost({ variables: { postId: Number(postId) } });
  }, [postId, getPost])

  const [updatePost] = useUpdatePostMutation({
    refetchQueries: [
      {
        query: PostsDocument,
        variables: {
          page: 1,
          pageSize: 25,
          ministryId: ministryId!
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const onSubmit = async (_data: FieldValue<any>) => {
    const { data } = await updatePost({
      variables: { post: { ..._data, id: Number(postId), ministryId } },
    });

    if (data?.UpdatePost.status) {
      showSuccess('Post was updated successfully.');
    }
  };

  return (
    <>
      <Box border='solid 1px lightgray' p={2}>
        <Stack
          direction='column'
          spacing={1}
          justifyContent='center'
          sx={{ width: 'fit-content', background: 'white', mt: '-28px', padding: '0px 8px' }}>
          <Typography variant='body1' fontWeight={600}>Edit Post</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} pr={1} pl={1}>
            <FormTextField
              name='title'
              label='Title *'
              size='small'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Title *</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
          <Grid item xs={12} pr={1} pl={1}>
            <FormTextField
              name='url'
              label='Clean Url *'
              size='small'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Clean Url *</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
            <Typography sx={{ marginTop: '4px', fontSize: 14, lineHeight: '20px' }}>This will be the url token used to refer to this record. It must be unique and must include only letters, numbers, en dash or periods.</Typography>
          </Grid>
          <Grid item xs={12} pr={1} pl={1}>
            <FormDateField
              name='publishedTime'
              label='Date Published *'
              size='small'
              type='datetime-local'
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Date Published *</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
            <FormSwitch
              control={control}
              name='isEnabled'
              label='Enabled'
              containerProps={{ sx: { mt: 1.5, display: 'flex' } }}
              labelComponent={<Typography sx={{ width: '80px', fontSize: 16, fontWeight: 500, lineHeight: '40px' }}>Enabled</Typography>}
            />
          </Grid>
          <Grid item xs={12} p={1}>
            <FormEditorField
              name='content'
              label='Content *'
              defaultValue={post?.content ?? ''}
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Content *</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
        </Grid>
        <Box mt={2} mx={5} display='flex' justifyContent='flex-end'>
          <Button variant='contained' onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
      </Box>
    </>
  );
}
