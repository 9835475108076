import React from 'react';
import { Link } from '@mui/material';

const linkStyle = {
  marginBottom: '4px',

  span: {
    width: 'fit-content',
    borderBottom: '2px solid #282ba5',
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#282ba5',
    lineHeight: 1.25,
  },

  '&:hover': {
    span: {
      borderBottom: '2px solid #d2232a',
      color: '#d2232a',
      cursor: 'pointer',
    }
  },
};

export interface SidebarLinkProps {
  label: any;
  onClick?: () => void;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ label, onClick }) => {
  return (
    <Link underline='none' sx={linkStyle} onClick={onClick}>{label}</Link>
  )
}

export default SidebarLink;
