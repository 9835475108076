import React from 'react';
import { Box, Typography } from '@mui/material';
import Radio, { RadioProps } from '@mui/material/Radio';
import { Control, Controller } from 'react-hook-form';

export interface RadioOptionItem {
  label: string;
  value: any;
}

export interface FormRadioProps extends RadioProps {
  name: string;
  control: Control<any>;
  disabled?: boolean;
  labelComponent?: React.ReactElement;
  options: RadioOptionItem[];
  textSize?: number;
}

const FormRadioField = ({ name, control, labelComponent, textSize, options = [], ...props }: FormRadioProps) => {

  return (
    <Controller name={name} control={control} render={({ field: { onChange, value }, fieldState: { error } }) => (
      <>
        {labelComponent}
        {options.map((option) => {
          return (
            <Box display='flex' key={`${option.value}-${option.label}`}>
              <Radio
                name={`radio-option-${option.value}-${option.label}`}
                checked={value === option.value}
                value={option.value}
                onChange={onChange}
                inputProps={{ 'aria-label': 'A' }}
                sx={{ height: 30 }}
                {...props}
                />
              <Typography sx={{ fontSize: textSize ? textSize :'1.25rem', lineHeight: '30px'}}>{option.label}</Typography>
            </Box>
          );
        })}
        {error && <Typography sx={{ margin: '4px 14px 0', fontSize: '18px', fontWeight: 400, color: '#d32f2f' }}>{error.message}</Typography>}
      </>
    )} />
  );
};

export default FormRadioField;
