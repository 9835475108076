import React, { createContext, useContext, useState } from 'react';
import { IResource, ResourceInput } from '../graphql/generated/graphql';
import { initTempResourceInput } from '../components/resources/const';
import { Order } from '../const/type';

interface ResourcesContextType {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  rows: IResource[];
  selected: readonly number[];
  deleteModalVisible: boolean;
  deleteId: number | undefined;
  orderBy: keyof IResource;
  order: Order;
  openFormModal: boolean;
  tempResourceInput: ResourceInput;
  onEdit: boolean;
  inventoryModalVisible: boolean;
  selectResource: IResource | null;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setTotalCount: (count: number) => void;
  setRows: (rows: IResource[]) => void;
  setSelected: (selected: readonly number[]) => void;
  setDeleteModalVisible: (open: boolean) => void;
  setDeleteId: (deleteId: number | undefined) => void;
  setOrderBy: (searchKey: keyof IResource) => void;
  setOrder: (order: Order) => void;
  setTempResourceInput: (tempResourceInput: ResourceInput) => void;
  setOpenFormModal: (openFormModal: boolean) => void;
  setOnEdit: (onEdit: boolean) => void;
  setInventoryModalVisible: (inventoryModalVisible: boolean) => void;
  setSelectResource: (selectResource: IResource | null) => void;
}

const ResourcesContext = createContext<ResourcesContextType | undefined>(undefined);

export const ResourcesProvider: React.FC<{ children: any }> = ({ children }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rows, setRows] = useState<IResource[]>([]);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<keyof IResource>('title');
  const [order, setOrder] = useState<Order>('asc');
  const [tempResourceInput, setTempResourceInput] = useState<ResourceInput>(initTempResourceInput);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [inventoryModalVisible, setInventoryModalVisible] = useState<boolean>(false);
  const [selectResource, setSelectResource] = useState<IResource | null>(null);

  return (
    <ResourcesContext.Provider value={{
      page,
      rowsPerPage,
      totalCount,
      rows,
      selected,
      deleteModalVisible,
      deleteId,
      orderBy,
      order,
      tempResourceInput,
      openFormModal,
      onEdit,
      inventoryModalVisible,
      selectResource,
      setPage,
      setRowsPerPage,
      setTotalCount,
      setRows,
      setDeleteModalVisible,
      setDeleteId,
      setSelected,
      setOrderBy,
      setOrder,
      setTempResourceInput,
      setOpenFormModal,
      setOnEdit,
      setInventoryModalVisible,
      setSelectResource,
    }}>
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResourcesContext = (): ResourcesContextType => {
  const context = useContext(ResourcesContext);
  if (!context) throw new Error('useResourcesContext must be used within a ResourcesProvider');
  return context;
};
