export const dateToLocalString = (date: Date | null) => {
  if (date) {
    const _date = new Date(date);
    const _month = _date.getMonth() + 1;
    const _day = _date.getDate();
    const _year = _date.getFullYear();
    const _hour = _date.getHours();
    const _mins = _date.getMinutes();
    return `${_month}/${_day}/${_year} ${_hour}:${_mins}`;
  }
  return ""
}

export const dateToString = (date: Date | null) => {
  if (date) {
    const _date = new Date(date);
    const _month = _date.getMonth() + 1;
    const _day = _date.getDate();
    const _year = _date.getFullYear();
    return `${String(_month).padStart(2, '0')}/${String(_day).padStart(2, '0')}/${_year}`;
  }
  return ""
}

const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthName = (month: string) => {
    return fullMonthNames[Number(month) - 1];
  }