import { IResource, ResourceInput, IExclusionGroup, ExclusionGroupInput } from '../../graphql/generated/graphql';

export function getResourceInput(item: IResource) {
  const _item: ResourceInput = {
    id: item.id,
    title: item.title,
    subTitle: item.subTitle,
    author: item.author,
    description: item.description,
    imageUrl: item.imageUrl,
    categoryId: item.categoryId,
    quantityInCase: item.quantityInCase,
    caseWeight: item.caseWeight,
    exclusiveOffer: item.exclusiveOffer,
    isPublished: item.isPublished,
    isActive: item.isActive,
    sku: item.sku,
    sortingOrder: item.sortingOrder
  }
  return _item
}

export function getExclusionGroupInput(item: IExclusionGroup) {
  const _item: ExclusionGroupInput = {
    id: item.id,
    name: item.name,
    description: item.description,
    isActive: item.isActive,
    resourceIds: item.resources,
    endDateDays: item.endDateDays,
  }
  return _item
}
