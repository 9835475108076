import { ResourceInput, ExclusionGroupInput, IExclusionGroup } from '../../graphql/generated/graphql';
import { ResourceTableHeadCell, ExclusionGroupTableHeadCell } from './type';

export const exclusionGroupHeadCells: ExclusionGroupTableHeadCell[] = [
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    sortable: false,
    minWidth: 200,
    disablePadding: false,
    textAlign: 'center',
  },
  {
    id: 'name',
    label: 'Name',
    numeric: false,
    sortable: true,
    ml: 2,
    disablePadding: true,
    textAlign: 'left',
  },
  {
    id: 'description',
    label: 'Description',
    numeric: false,
    sortable: true,
    ml: 2,
    disablePadding: true,
    textAlign: 'left',
  },
];

export const headCells: ResourceTableHeadCell[] = [
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    sortable: false,
    minWidth: 200,
    disablePadding: false,
    textAlign: 'center',
  },
  {
    id: 'imageUrl',
    label: 'Image',
    numeric: false,
    sortable: false,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'title',
    label: 'Title',
    numeric: false,
    sortable: true,
    ml: 2,
    disablePadding: true,
    textAlign: 'left',
  },
  // {
  //   id: 'description',
  //   label: 'Description',
  //   numeric: false,
  //   sortable: true,
  //   ml: 2,
  //   disablePadding: true,
  //   textAlign: 'left',
  // },
  {
    id: 'sku',
    label: 'SKU',
    numeric: false,
    sortable: true,
    minWidth: 60,
    ml: 2,
    disablePadding: true,
    textAlign: 'left'
  },
  {
    id: 'quantityInCase',
    label: 'Quantity',
    numeric: false,
    sortable: true,
    minWidth: 60,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'caseWeight',
    label: 'Case Weight',
    numeric: false,
    sortable: true,
    minWidth: 60,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'isActive',
    label: 'Active',
    numeric: false,
    sortable: true,
    minWidth: 60,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'isPublished',
    label: 'Published',
    numeric: false,
    sortable: true,
    minWidth: 60,
    disablePadding: true,
    textAlign: 'center',
  },
];

export const initTempResourceInput: ResourceInput = {
  'title': '',
  'subTitle': '',
  'author': '',
  'sku': '',
  'quantityInCase': 0,
  'isPublished': true,
  'isActive': true,
  'imageUrl': '',
  'exclusiveOffer': false,
  'description': '',
  'categoryId': 0,
  'caseWeight': 0
}

export const initTempExclusionGroupInput: ExclusionGroupInput = {
  'name': '',
  'isActive': true,
  'description': '',
  'resourceIds': []
}
export const initTempExclusionGroup: IExclusionGroup = {
  'id': 0,
  'isDeleted': false,
  'name': '',
  'endDateDays': 365,
  'isActive': true,
  'description': '',
  'resources': []
}

export const InventoryHeadCells: any[] = [
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'sku',
    label: 'Sku',
  },
  {
    id: 'quantity',
    label: 'Amount',
  },
  {
    id: 'createTime',
    label: 'Date Entered',
  },
  {
    id: 'user',
    label: 'Updated By',
  },
];
