import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useParams } from 'react-router-dom';
import { NormalParagraphHTMLContent } from '../common/NormalParagraph';
import { StyledLink, StyledTreeItem } from '../../libs/ui/StyledComponents';
import { monthName } from '../../libs/utils/datetimeutil';
import { useGetPostsNavigationQuery } from '../../graphql/generated/graphql';

export const NewsList = ({ sideContent }: { sideContent: any }) => {
  const { ministryId } = useParams();
  const { data } = useGetPostsNavigationQuery({ variables: { ministryId: ministryId! } });

  const newsData = useMemo(() => {
    if (data?.GetPostNavigation.data) return JSON.parse(data.GetPostNavigation.data);
    return {};
  }, [data]);

  const newsYear = useMemo(() => (year: string) => {
    if (newsData && newsData[year]) return newsData[year];
    return {};
  }, [newsData]);

  const monthData = useMemo(() => (_yearData: any, month: string) => {
    if (_yearData && _yearData[month]) return _yearData[month];
    return {};
  }, []);

  return (
    <>
      <NormalParagraphHTMLContent label={sideContent ?? ''} />
      <TreeView
        defaultCollapseIcon={<ArrowDropDownRoundedIcon sx={{ fontSize: { xs: '30px!important', lg: '40px!important' } }} />}
        defaultExpandIcon={<ArrowRightRoundedIcon sx={{ fontSize: { xs: '30px!important', lg: '40px!important' } }} />}
      >
        {Object.keys(newsData).reverse().map((_year) => {
          return (
            <StyledTreeItem key={_year} nodeId={_year} label={_year}>
              {Object.keys(newsYear(_year)).map((month) => {
                return (
                  <StyledTreeItem nodeId={`${_year}_${month}`} key={month} label={monthName(month)}>
                    {monthData(newsYear(_year), month).map((item: any) => (
                      <Box key={item.post_id} display='flex' pl={2}>
                        <FiberManualRecordIcon sx={{ marginTop: '12px', marginRight: '8px', fontSize: '12px' }} />
                        <StyledLink href={`/news/${ministryId}/${item.post_url}`}>{item.post_title}</StyledLink>
                      </Box>
                    ))}
                  </StyledTreeItem>
                );
              })}
            </StyledTreeItem>
          );
        })}
      </TreeView>
    </>
  );
};
