import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography, TextField, } from '@mui/material';
import CenterBox from '../../../components/common/CenterBox';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchResult } from '@apollo/client/link/core';
import * as Yup from 'yup';
import FormTextField from '../../../libs/ui/FormTextField';
import FormDropdownField from '../../../libs/ui/FormDropdownField';
import FormCheckBoxField from '../../../libs/ui/FormCheckBoxField';
import FormPhoneField from '../../../libs/ui/FormPhoneField';
import SidebarLink from '../../common/SidebarLink';
import { Spacer } from '../../../libs/ui/Spacer';
import apolloClient from '../../../graphql/apolloClient';
import {
  IUserShippingDetail,
  IUserShippingLocation,
  ShippingAddressDocument,
  ShippingAddressMutation,
  ShippingDetailDocument,
  ShippingDetailMutation
} from '../../../graphql/generated/graphql';
import { StateOptions, TitleOptions } from '../../../const/type';

const modalBoxStyle = {
  width: '80%',
  height: '80vh',
  backgroundColor: '#fff',
  borderRadius: 2,
  overflowY: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '@media (max-width: 600px)': {
    width: '90%',
  },
};

const modalHeaderStyle = {
  backgroundColor: '#e3e3e3',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  padding: 1,
};

interface ShippingLocationModalProps {
  // requestId: number | undefined;
  shippingLocation: IUserShippingLocation | null;
  shippingDetail: IUserShippingDetail | null;
  shippingLocationModalVisible: boolean;
  setReviewModalVisible: (visible: boolean) => void;
  setShippingLocationModalVisible: (visible: boolean) => void;
  setSignatureModalVisible: (visible: boolean) => void;
  digitalSignature: any;
  setDigitalSignature: any;
  setRecaptcha: any;
  recaptcha: any;
  onSignature: any;
  completeRequest: any;
}

const ShippingLocationModal = ({
  // requestId,
  shippingLocation,
  shippingDetail,
  shippingLocationModalVisible,
  setShippingLocationModalVisible,
  setSignatureModalVisible,
  digitalSignature,
  setDigitalSignature,
  setRecaptcha,
  recaptcha,
  onSignature,
  completeRequest
}: ShippingLocationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable(),
    organization: Yup.string().required('Destination Organization is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    address1: Yup.string().required('Address 1 is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zip Code is required').matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be 5 digits').max(5, 'Must be 5 digits'),
    phone: Yup.string().required('Phone Number is required').min(10, 'Must be 10 digits'),
    email: Yup.string().required('Email is required').test('valid-email', 'Invalid email format', (value) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
    loadingDock: Yup.boolean()
    .transform((value, originalValue) => {
      if (originalValue === -1) return null;
      return originalValue; 
    })
    .nullable() 
    .oneOf([true, false], 'Please select an option for loading dock') 
    .required('Please select an option for loading dock') as any,
    contactInfo: Yup.string(),
    resourceUse: Yup.string().required('This field is required'),
    digitalSignature: Yup.string().required('This field is required'),
    agreeCondition: Yup.bool().required('This field is required').oneOf([true], 'This field is required')
  });

  const methods = useForm({
    defaultValues: {
      organization: shippingLocation?.organization || '',
      title: shippingLocation?.title || 'None',
      firstName: shippingLocation?.firstName || '',
      lastName: shippingLocation?.lastName || '',
      address1: shippingLocation?.address1 || '',
      address2: shippingLocation?.address2 || '',
      city: shippingLocation?.city || '',
      state: shippingLocation?.state || 'AA',
      zipCode: shippingLocation?.zipCode || '',
      phone: shippingDetail?.phone || '',
      email: shippingDetail?.email || '',
      loadingDock: shippingDetail?.loadingDock || -1,
      contactInfo: shippingDetail?.contactInfo || '',
      resourceUse: '',
      agreeCondition: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, getValues, reset, watch, formState: { errors } } = methods;

   useEffect(() => {
    reset({
      organization: shippingLocation?.organization || '',
      title: shippingLocation?.title || '',
      firstName: shippingLocation?.firstName || '',
      lastName: shippingLocation?.lastName || '',
      address1: shippingLocation?.address1 || '',
      address2: shippingLocation?.address2 || '',
      city: shippingLocation?.city || '',
      state: shippingLocation?.state || 'AA',
      zipCode: shippingLocation?.zipCode || '',
      phone: shippingDetail?.phone || '',
      email: shippingDetail?.email || '',
      resourceUse: getValues('resourceUse'),
      agreeCondition: getValues('agreeCondition'),
      loadingDock: shippingDetail?.loadingDock || -1,
      contactInfo: shippingDetail?.contactInfo || '',
      digitalSignature: digitalSignature || '',
    })
    // eslint-disable-next-line
  }, [reset, shippingDetail, shippingLocation])


  const digitalSignatureValue = watch("digitalSignature");
  
  useEffect(() => {
    setDigitalSignature(digitalSignatureValue);
  }, [digitalSignatureValue, setDigitalSignature]);

  const next = async () => {
    setIsLoading(true)
    const requestId = await completeRequest()
    const shippingAddress = {
      organization: getValues('organization'),
      title: getValues('title'),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      address1: getValues('address1'),
      address2: getValues('address2'),
      city: getValues('city'),
      state: getValues('state'),
      zipCode: getValues('zipCode'),
      requestId,
    };
    const { data: locationData }: FetchResult<ShippingAddressMutation> = await apolloClient.mutate({
      mutation: ShippingAddressDocument,
      variables: { shippingAddress }
    });
    const shippingDetail = {
      phone: getValues('phone'),
      email: getValues('email'),
      loadingDock: getValues('loadingDock'),
      contactInfo: getValues('contactInfo'),
      resourceUse: getValues('resourceUse'),
      requestId,
    };
    const { data: detailData }: FetchResult<ShippingDetailMutation> = await apolloClient.mutate({
      mutation: ShippingDetailDocument,
      variables: { shippingDetail }
    });
    if (locationData?.ShippingAddress.status && detailData?.ShippingDetail.status) {
      setShippingLocationModalVisible(false);
      setSignatureModalVisible(true);
    }

    
    await onSignature('signature', requestId)
    setIsLoading(false)
  }

  return (
    <Modal open={shippingLocationModalVisible} onClose={() => setShippingLocationModalVisible(false)}>
      <Box sx={modalBoxStyle}>
        <Box sx={modalHeaderStyle}>
          <Typography fontWeight={800}>Commercial Shipping Address - Complete all required fields.<span style={{ fontWeight: 900, color: '#f00' }}>*</span></Typography>
        </Box>
        <Box p={3} sx={{ maxHeight: { md: 900, xs: 500 }, overflow: 'auto' }}>
          <Grid container>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='organization'
                label='Destination Organization'
                size='small'
                maxlength="35"
                placeholder=''
                labelComponent={<Typography fontWeight={600}>Destination Organization <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={8} p={1} />
            <Grid item xs={12} md={4} p={1}>
              <FormDropdownField
                name='title'
                label='Title'
                size='small'
                options={[{ label: '-- Select --', value: null }, ...TitleOptions]}
                labelComponent={<Typography fontWeight={600}>Title </Typography>}
                rows={1}
                control={control}
                sx={{ background: 'white' }}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='firstName'
                label='First Name'
                size='small'
                maxlength="50"
                placeholder=''
                labelComponent={<Typography fontWeight={600}>First Name <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='lastName'
                label='Last Name'
                size='small'
                maxlength="50"
                placeholder=''
                labelComponent={<Typography fontWeight={600}>Last Name <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='address1'
                label='Address 1'
                size='small'
                maxlength="35"
                placeholder=''
                labelComponent={<Typography fontWeight={600}>Address 1 <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='address2'
                label='Address 2'
                size='small'
                maxlength="35"
                placeholder=''
                labelComponent={<Typography fontWeight={600}>Address 2</Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1} />
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='city'
                label='City'
                size='small'
                placeholder=''
                labelComponent={<Typography fontWeight={600}>City <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormDropdownField
                name='state'
                label='State'
                size='small'
                options={StateOptions}
                labelComponent={<Typography fontWeight={600}>State <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                control={control}
                sx={{ background: 'white' }}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='zipCode'
                label='Zip Code'
                size='small'
                placeholder=''
                labelComponent={<Typography fontWeight={600}>Zip Code <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormPhoneField
                name='phone'
                label='Phone Number'
                size='small'
                labelComponent={<Typography fontWeight={600}>Phone Number <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                rows={1}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormTextField
                name='email'
                label='Email Address'
                size='small'
                type='email'
                rows={1}
                labelComponent={<Typography fontWeight={600}>Email Address <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                control={control}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormDropdownField
                name='loadingDock'
                label='Is there a loading dock at this location?'
                size='small'
                placeholder=''
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                  { label: 'Please Select', value: -1 },
                ]}
                labelComponent={<Typography fontWeight={600}>Is there a loading dock at this location? <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                control={control}
                sx={{ background: 'white' }}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} md={6} p={1}>
              <FormTextField
                name='contactInfo'
                label='Alternate Contact Name and Phone'
                size='small'
                labelComponent={<Typography fontWeight={600}>Alternate Contact Name and Phone</Typography>}
                control={control}
                maxlength="100"
                disabled={false}
              />
            </Grid>
             <Grid item xs={12} md={6} p={1}>
              <FormTextField
                name='resourceUse'
                label='How do you plan to use these resources?'
                size='small'
                labelComponent={<Typography fontWeight={600}>How do you plan to use these resources? <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>}
                control={control}
                maxlength="200"
                disabled={false}
              />
            </Grid>
          </Grid>
        </Box>
        <Box maxHeight={{ md: 600, xs: 500 }} overflow='auto'>
          <Box px={5}>
            <Typography mt={1} fontSize={18} fontWeight={600}>I agree to not post on social media any information related to The 1687 Foundation or any resources which may be received from The 1687 Foundation. </Typography>
            <Typography mt={1} fontSize={18} fontWeight={600}>I agree that the resources shall only be given away as a free gift. They may not be sold, used as an encouragement for any charitable gifts, or provided for any commercial or personal gain purpose whatsoever.</Typography>
            <Typography mt={1} fontSize={18} fontWeight={600}>By placing my digital signature in the space below, I hereby certify that I am the intended recipient and I have full authority from the organization listed above to submit this request for resources on behalf of the organization.</Typography>
            <Typography mt={1} fontSize={18} fontWeight={600}>I have read and understood the above terms and the above guidelines. By clicking SUBMIT, I agree to comply with the terms and conditions of this offer.</Typography>
            <FormCheckBoxField name='agreeCondition' label='I agree to comply with the above terms and conditions. *' control={control} />
          </Box>
          <Box px={3} pb={3}>
            <Grid container pt={1} px={2}>
              <Grid item xs={12} md={4}>
                <Typography fontWeight={600}>Digital Signature <span style={{ fontWeight: 800, color: '#f00' }}>*</span></Typography>
                <Controller
                    name="digitalSignature"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!errors.digitalSignature}
                        helperText={errors.digitalSignature ? (
                          <Typography sx={{ width: 'calc(100% - 4px)' }} fontSize={18} color='#d32f2f'>
                            {errors.digitalSignature.message}
                          </Typography>
                        ) : null}
                      />
                    )}
                  />
              </Grid>
              <CenterBox>
              <ReCAPTCHA sitekey='6LdVqqcqAAAAAO8uzIDhAqMJliGImTYcvwP5epYR' onChange={value => setRecaptcha(value)} />
            </CenterBox>
            <Spacer height={20} />
            <div style={{ display: Object.keys(errors).length > 0 ? 'flex' : 'none', justifyContent: 'flex-end' }}>
              <Typography fontWeight={800} color='#f00'>All required fields (*) must be filled in.</Typography>
            </div>
            </Grid> 
            <Spacer height={10} />
            <Box display='flex' flexDirection={{ md: 'row', xs: 'column' }} alignItems='center' justifyContent='flex-end'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                <SidebarLink label={<span style={{ fontSize: '1rem' }} onClick={() => setShippingLocationModalVisible(false)}>Back to Resource Selection</span>} />
                <Button variant='contained' size='small' color='primary' disabled={recaptcha === null || isLoading} onClick={handleSubmit(next)}>Complete Request</Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ShippingLocationModal;
