import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { CategoryContainer } from '../../components/category/CategoryContainer';

const CategoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.viewCategory) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.viewCategory, navigate])

  return (
    <Box>
      <CategoryContainer />
    </Box>
  );
};

export default CategoryPage;
