import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import FormTextField from '../../libs/ui/FormTextField';
import {
  ExclusionGroupInput,
  IExclusionGroup,
  IResource,
  useCategoryListQuery,
  useResourcesListQuery,
  useExclusionGroupByIdQuery
} from '../../graphql/generated/graphql';
import FormDropdownField, { DropDownItem } from '../../libs/ui/FormDropdownField';

const modalStyle = {
  width: 800,
  maxHeight: 850,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
  boxShadow: 24,
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

interface ExclusionGroupFormProps {
  value: ExclusionGroupInput;
  exclusionGroup: IExclusionGroup;
  setValue: (value: ExclusionGroupInput) => void;
  onCancel: () => void;
  onSave: (data: ExclusionGroupInput) => void;
  onEdit: boolean
}

export const ExclusionGroupForm = ({ value, onCancel, onSave, onEdit, exclusionGroup }: ExclusionGroupFormProps) => {
  const [resDescription, setResDescription] = useState<string | undefined>('');
  const [resEndDateDays, setResEndDateDays] = useState<number | undefined>(365);
  const [resourceItems, setResourceItems] = useState<DropDownItem[]>([{label: 'Loading...', value: 'Loading...'}]);

  const { data: resourcesData } = useResourcesListQuery({ fetchPolicy: 'network-only' });

  const { data } = useCategoryListQuery({
    variables: { page: 1, pageSize: 10000 },
  })

  useEffect(() => {
    setResDescription(value.description ?? '');
  }, [data, value.description]);

  useEffect(() => {
    setResEndDateDays(value.endDateDays ?? 365);
  }, [data, value.endDateDays]);

  const { data: exclusionGroupData } = useExclusionGroupByIdQuery({
    variables: { exclusionGroupId: Number(exclusionGroup.id) },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (resourcesData?.Resources.items) {
      const _forms = [ ...resourcesData.Resources.items ].sort(
        (a: any, b: any) => {
          return (a?.title?.toLowerCase() > b?.title?.toLowerCase()) ? 1 : ((b?.title?.toLowerCase() > a?.title?.toLowerCase()) ? -1 : 0)
        }).map((item) => ({
        label: item.title,
        value: item.id,
      }));
      setResourceItems([..._forms]);
    }
  }, [resourcesData]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    endDateDays: Yup.number().required('Days Till End Date is required'),
    isActive: Yup.bool(),
    resourceIds: Yup.array().nullable(),
  })

  const methods = useForm({
    values: {
      name: value.name,
      // description: value.description ?? '',
      description: resDescription ?? '',
      endDateDays: resEndDateDays ?? 365,
      isActive: value.isActive ?? true,
      resourceIds: exclusionGroupData?.ExclusionGroup?.data?.resources ? exclusionGroupData?.ExclusionGroup?.data?.resources?.map((_resource: IResource) => _resource.id): [],
    },
    resolver: yupResolver(validationSchema),
  })
  
  const { handleSubmit, control, reset } = methods

  useEffect(() => {
    if (!onEdit) {
      reset({
        name: '',
        description: '',
        isActive: true,
        resourceIds: [],
      })
    } 
  }, [onEdit, reset])


  const onSubmit = async (data: FieldValue<any>) => {
    onSave({ ...data });
  }

  return (
    <Box sx={modalStyle}>
      <Typography variant='h5' id='delete-category-modal-description' sx={{ mb: 3 }}>{onEdit ? 'Edit Exclusion Group' : 'Add Exclusion Group'}</Typography>
      <Grid container>
        <Grid item xs={6} pr={2}>
          <FormTextField
            name='name'
            label='Name'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Title <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
          <FormTextField
            name='description'
            label='Description'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Description <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={6} pr={1}>
          <FormDropdownField
              name='resourceIds'
              label='Excluded Resources'
              size='small'
              multiple
              options={resourceItems}
              labelComponent={<Typography sx={{ minWidth: 130, fontSize: 16, lineHeight: '40px' }}>Excluded Resources</Typography>}
              control={control}
              sx={{ background: 'white', height: '40px', width: 'fit-content', maxWidth: '100%' }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' } }}
            />
            <FormTextField
              name='endDateDays'
              label='Days Till End Date'
              size='small'
              type='number'
              labelComponent={<Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>Days Till Due Date <span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
            />
        </Grid>
      </Grid>
      <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
        <Button variant='outlined' onClick={onCancel}>Cancel</Button>
        <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>Save</Button>
      </Box>
    </Box>
  );
};
