import * as React from "react";
import { Box, BoxProps, Switch, SwitchProps, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

export type FormSwitchProps = SwitchProps & {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  labelComponent?: React.ReactElement;
  label: string;
  containerProps: BoxProps;
};

/**
 * Describe your component here
 */
const FormSwitch: React.FC<FormSwitchProps> = (props) => {
  const {
    name,
    control,
    label,
    labelComponent,
    containerProps,
    defaultValue = "",
    ...restProps
  } = props;

  return (
    <Box sx={containerProps.sx}>
      {labelComponent ? (
        labelComponent
      ) : (
        <Typography sx={{ marginTop: "6px" }}>{label}</Typography>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Switch checked={value} {...restProps} onChange={(event) => onChange(event.target.checked)} />
        )}
        defaultValue={defaultValue}
      />
    </Box>
  );
};

export default FormSwitch;
