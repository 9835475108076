import { UsersTableHeadCell } from './type';

export const headCells: UsersTableHeadCell[] = [
  {
    id: 'actions',
    numeric: false,
    label: '',
    minWidth: 40,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'id',
    numeric: false,
    sortable: true,
    label: 'Inquiry Number',
    minWidth: 60,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'status',
    numeric: false,
    sortable: false,
    label: 'Status',
    minWidth: 60,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'createTime',
    numeric: false,
    sortable: true,
    label: 'Inquiry Date',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'acceptedDate',
    numeric: false,
    label: 'Approved Date',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'organization',
    numeric: false,
    sortable: true,
    label: 'Ministry / Organization',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'firstName',
    numeric: false,
    sortable: true,
    label: 'First Name',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'lastName',
    numeric: false,
    sortable: true,
    label: 'Last Name',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'email',
    numeric: false,
    sortable: true,
    label: 'Email',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'phone',
    numeric: false,
    sortable: true,
    label: 'Phone',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
];

export const adminHeadCells: UsersTableHeadCell[] = [
  {
    id: 'actions',
    numeric: false,
    label: '',
    minWidth: 40,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'firstName',
    numeric: false,
    sortable: true,
    label: 'First Name',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'lastName',
    numeric: false,
    sortable: true,
    label: 'Last Name',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'email',
    numeric: false,
    sortable: true,
    label: 'Email',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
  {
    id: 'phone',
    numeric: false,
    sortable: true,
    label: 'Phone',
    minWidth: 100,
    ml: 1,
    disablePadding: true,
    textAlign: 'center',
  },
];
