import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { RequestNewContainer } from '../../components/requests/RequestNewContainer';

const RequestNewPage: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.editRequest) navigate('/');
  }, [me, me?.role?.editRequest, navigate])

  return <RequestNewContainer />;
};

export default RequestNewPage;
