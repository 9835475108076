import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResourceInventoriesQuery } from '../../graphql/generated/graphql';
import { InventoryHeadCells } from './const';
import {format} from "date-fns";

const ResourceInventoriesContainer = () => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rows, setRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { data } = useResourceInventoriesQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      resourceId: Number(id!),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.resourceInventories.items) {
        setRows(data?.resourceInventories.items);
    }
    if (data?.resourceInventories.total) setTotalCount(data.resourceInventories.total);
  }, [data, page]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  return (
    <Box width='100%' p={3}>
      <Typography component='div' variant='h6' my={2}>Inventories</Typography>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ minHeight: 'calc(100vh - 410px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}>
            <TableHead>
              <TableRow>
                {InventoryHeadCells.map((item, index) => (
                  <TableCell key={index} align='left'>{item.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow key={index} hover tabIndex={-1} sx={{ cursor: 'pointer' }}>
                    <TableCell align='left'>{row.resource.title}</TableCell>
                    <TableCell align='left'>{row.resource.sku}</TableCell>
                    <TableCell align='left'>{row.quantity}</TableCell>
                    <TableCell align='left'>{format(new Date(row.createTime), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='left'>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default ResourceInventoriesContainer;
