import { FC } from 'react';
import styled from '@emotion/styled';

interface SpacerProps {
  width?: number;
  height?: number;
}

const Div = styled.div((props: SpacerProps) => ({
  width: props?.width || 'auto',
  height: props?.height || 'auto',
}))

export const Spacer: FC<SpacerProps> = ({ width, height }) => (
  <Div width={width} height={height} />
)