import { Box, Grid, Link, Typography } from '@mui/material';
import LogoWhiteImg from '../../assets/images/logo-white.png';

const footerStyle = {
  backgroundColor: '#1c3e84',
  color: '#fff',
};

const footerInnerStyle = {
  maxWidth: 1320,
  margin: 'auto',
  py: 4.5,
  px: 1,
}

const footerLinkStyle = {
  mt: 0.5,
  fontFamily: 'Barlow',
  fontSize: 16,
  fontWeight: 600,
  color: '#fff',
  underline: 'none',

  '&:hover': {
    color: '#d2232a',
    underline: '',
  }
}

const BookMinistries = ['PO Box 1961', 'Sisters, Oregon 97759', 'tel: 541-549-7600', 'info@1687foundation.com'];
const RetreatMinistries = ['PO Box 306', 'Cross Plains, Texas 76443', 'tel: 254-725-7228', 'retreats@1687foundation.com'];
const HuntingFishingMinistries = ['PO Box 306', 'Cross Plains, Texas 76443', 'tel: 254-725-7228', 'huntingandfishing@1687foundation.com'];
const WoodyMuseums = ['500 SW Fifth St., TX-36 West', 'Cross Plains, Texas 76443', 'tel: 254-725-4042', 'info@woodysmuseum.com'];


const LandingFooter = () => {

 
  return (
    <footer style={footerStyle}>
      <Grid container sx={footerInnerStyle}>
        <Grid item xs={12} md={4} lg={4} px={1}>
          <Typography variant='h5' pb={1} fontFamily='Barlow' fontWeight={600}>Our Mission</Typography>
          <Typography fontFamily='Barlow' fontSize={15}>
            The 1687 Foundation (“Foundation”) is a nonprofit corporation that is organized for Christian, educational, and charitable purposes. The Foundation is recognized by the IRS as tax-exempt under Section 501(c)(3) of the Internal Revenue Code. The mission of the Foundation is accomplished through the operation of its Retreat Ministry, Book Ministry, Hunting and Fishing Ministry, Museum Ministry; all as explained in this website. All of the Foundation’s activities are provided free of charge.
          </Typography>
          <img src={LogoWhiteImg} alt='' loading='lazy' style={{ width: '50%', marginTop: 20 }} />
        </Grid>
        <Grid item xs={12} md={4} lg={4} px={1}>
          <Box display='flex' flexDirection='column'>
            <Link href={'/about-us'} sx={footerLinkStyle}>ABOUT US</Link>
            <Link href={'/ministries/book-ministry'} sx={footerLinkStyle}>BOOK MINISTRY</Link>
            <Link href={'/ministries/retreat-program'} sx={footerLinkStyle}>RETREAT PROGRAM</Link>
            <Link href={'/ministries/retreat-program'} sx={footerLinkStyle}>&nbsp;&nbsp;&nbsp;&nbsp;APPLY TO RETREAT PROGRAM</Link>
            <Link href={'/ministries/hunting-fishing-ministry'} sx={footerLinkStyle}>HUNTING & FISHING MINISTRY</Link>
            <Link href={'/ministries/hunting-fishing-ministry'} sx={footerLinkStyle}>&nbsp;&nbsp;&nbsp;&nbsp;APPLY TO HUNTING & FISHING MINISTRY</Link>
            <Link href={'/ministries/woodys-museum'} sx={footerLinkStyle}>WOODY'S CLASSIC CARS & BASEBALL MUSEUM</Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={4} px={1}>
          <Typography variant='h6' mt={1} fontFamily='Barlow' fontSize={15} fontWeight={600}>Book Ministry</Typography>
          {BookMinistries.map((item, index) => (
            <Typography key={index} ml={2} fontFamily='Barlow' fontSize={15}>{item}</Typography>
          ))}

          <Typography variant='h6' mt={1} fontFamily='Barlow' fontSize={15} fontWeight={600}>Retreat Ministry</Typography>
          {RetreatMinistries.map((item, index) => (
            <Typography key={index} ml={2} fontFamily='Barlow' fontSize={15}>{item}</Typography>
          ))}

          <Typography variant='h6' mt={1} fontFamily='Barlow' fontSize={15} fontWeight={600}>Hunting & Fishing Ministry</Typography>
          {HuntingFishingMinistries.map((item, index) => (
            <Typography key={index} ml={2} fontFamily='Barlow' fontSize={15}>{item}</Typography>
          ))}

          <Typography variant='h6' mt={1} fontFamily='Barlow' fontSize={15} fontWeight={600}>Woody's Classic Car & Baseball Museum</Typography>
          {WoodyMuseums.map((item, index) => (
            <Typography key={index} ml={2} fontFamily='Barlow' fontSize={15}>{item}</Typography>
          ))}

          <Typography pt={5} fontSize={14} fontFamily='Barlow'>Copyright {new Date().getFullYear()} The 1687 Foundation</Typography>
          <Link href={'https://www.lambcreek.com'} sx={footerLinkStyle}>Website design by Lamb Creek, Inc.</Link>
        </Grid>
      </Grid>
    </footer>
  )
};

export default LandingFooter;

