import React from 'react';
import { Box, BoxProps, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

export type FormNumberFieldProps = TextFieldProps & {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  labelComponent?: React.ReactElement;
  label: string,
  containerProps?: BoxProps;
  onChangeInput?: (value: any) => void;
};

const FormNumberField: React.FC<FormNumberFieldProps> = (props) => {
  const { name, control, label, labelComponent, containerProps, onChangeInput, defaultValue = '', ...restProps } = props;

  return (
    <Box sx={containerProps?.sx}>
      {labelComponent}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Stack direction='column'>
            <TextField
              {...restProps}
              error={!!error}
              onChange={(e) => {
                onChange(e);
                if (onChangeInput) onChangeInput(e);
              }}
              value={value}
              fullWidth
              label={labelComponent? '' : label}
              type='number'
            />
            {error && <Typography sx={{ margin: '4px 14px 0', fontSize: '16px', fontWeight: 400, color: '#d32f2f' }}>{error.message}</Typography>}
          </Stack>
        )}
        defaultValue={defaultValue}
      />
    </Box>
  );
};

export default FormNumberField;
