import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ResourceSelectItem } from "./ResourceSelectItem";
import { DropDownItem } from "../../../libs/ui/FormDropdownField";
import {
  DeleteSelectionDocument,
  useResourcesListQuery,
} from "../../../graphql/generated/graphql";
import { ResourceSelectionType } from "../type";
import { defaultResourceGroup } from "../const";
import { useMutation } from "@apollo/client";

interface RequestResourceSelectProps {
  selections: any[];
  setSelections: (data: any) => void;
  errors: any;
}

const RequestResourceSelectUpdate = ({
  selections,
  setSelections,
  errors,
}: RequestResourceSelectProps) => {
  const [resources, setResources] = useState<DropDownItem[]>([]);

  const { data: resourcesData } = useResourcesListQuery();
  const [deleteSelectionMutation] = useMutation(DeleteSelectionDocument);



  const handleUpdate = (value: ResourceSelectionType, index: number) => {
    const _resourceGroup = selections;
    for (let i = 0; i < _resourceGroup.length; i++) {
      if (i === index) {
        _resourceGroup[i] = value;
      }
    }
    setSelections([..._resourceGroup]);
  };
  const handleOnAdd = () => {
    if (selections.indexOf(defaultResourceGroup) > -1) return;
    setSelections([...selections, defaultResourceGroup]);
  };


  const handleOnRemove = async (index: number) => {
    const _resourceGroup = selections;
    if (_resourceGroup[index].selectionId === undefined) {
      _resourceGroup.splice(index, 1);
      setSelections([..._resourceGroup, defaultResourceGroup]);
      return;
    }
    await deleteSelectionMutation({
      mutation: DeleteSelectionDocument,
      variables: { selectionId: _resourceGroup[index].selectionId },
    });
    _resourceGroup.splice(index, 1);
    if (_resourceGroup.length) setSelections([..._resourceGroup]);
    if (_resourceGroup.length === 0)
      setSelections([...selections, defaultResourceGroup]);
  };

  const getAvailableResources = (index: number) => {
    return resources.filter(
      (r) => !selections.some((rg, i) => rg.resource === r.value && i !== index)
    );
  };

  useEffect(() => {
    if (resourcesData?.Resources.items) {
      const tempItems = [...resourcesData?.Resources.items];
      tempItems.sort((a: any, b: any) =>
        a?.title > b?.title ? 1 : b?.title > a?.title ? -1 : 0
    );
    const _resources = tempItems.map((item) => ({
      label: item.title,
      value: item.id,
      quantityInCase: item.quantityInCase, 
      caseWeight: item.caseWeight, 
    }));
    setResources([{ label: "-- Select --", value: 0 }, ..._resources]);
  }
}, [resourcesData]);

  const totalWeight = selections.reduce((sum, item) => {
    const resource = resources.find(r => r.value === item.resource);
    return sum + (((resource as any)?.caseWeight || 0) * (item.cases || 0));
  }, 0);
  return (
    <Box bgcolor="white" borderRadius={2} mt={3} p={2} boxShadow={3}>
      <Typography fontWeight={600}>Resource</Typography>
      <Grid container>
        {selections.map((group, index) => {
          return (
            <ResourceSelectItem
              key={`${group.resource}-${index}`}
              resourceGroup={group}
              resources={getAvailableResources(index)}
              addNew={index === selections.length - 1}
              onChange={(value) => {
                handleUpdate(value, index);
              }}
              onAdd={handleOnAdd}
              onRemove={() => {
                handleOnRemove(index);
              }}
            />
          );
        })}
      </Grid>
      <Grid container>
        <Grid item>
          <Typography sx={{ mt: 3, background: "#ECF3FD", px: 1, borderRadius: 50, fontSize: 15, color: "#2B5693", border: "1px solid #5882bb" }}>
            Total Cases: <b>{selections.reduce((sum, item) => sum + (item.cases || 0), 0)}</b> - Calculated Weight: <b>{totalWeight}</b>
          </Typography>
        </Grid>
      </Grid>
      {errors?.resourceGroup && (
        <Typography mt={2} fontWeight={600} color="#f00">
          {errors?.resourceGroup?.message}
        </Typography>
      )}
    </Box>
  );
};

export default RequestResourceSelectUpdate;
