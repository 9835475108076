import React, { createContext, useContext, useState } from 'react';
import { RequestSortField, SortDirectionField } from '../graphql/generated/graphql';
import { RequestsResponseType } from '../components/requests/type';

interface RequestsContextType {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  rows: RequestsResponseType[];
  deleteModalVisible: boolean;
  deleteId: number | undefined;
  selected: readonly number[];
  searchIds: string;
  searchKey: string;
  sort: RequestSortField;
  sortDirection: SortDirectionField;
  filter: any;
  filterId?: string;
  filterType: string;
  filterText: string | Date | boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setTotalCount: (count: number) => void;
  setRows: (rows: RequestsResponseType[]) => void;
  setDeleteModalVisible: (open: boolean) => void;
  setDeleteId: (deleteId: number | undefined) => void;
  setSelected: (selected: readonly number[]) => void;
  setSearchIds: (searchKey: string) => void;
  setSearchKey: (searchKey: string) => void;
  setSort: (sort: RequestSortField) => void;
  setSortDirection: (sortDirection: SortDirectionField) => void;
  setFilter: (filter: any) => void;
  setFilterId: (filterId?: string) => void;
  setFilterType: (filterType: string) => void;
  setFilterText: (filterText: string | Date | boolean) => void;
}

const RequestsContext = createContext<RequestsContextType | undefined>(undefined);

export const RequestsProvider: React.FC<{ children: any }> = ({ children }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rows, setRows] = useState<RequestsResponseType[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [searchIds, setSearchIds] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [sort, setSort] = useState<RequestSortField>(RequestSortField.RequestNumber);
  const [sortDirection, setSortDirection] = useState<SortDirectionField>(SortDirectionField.Desc);
  const [filter, setFilter] = useState<any>({});
  const [filterId, setFilterId] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('equalTo');
  const [filterText, setFilterText] = useState<string | Date | boolean>('');

  return (
    <RequestsContext.Provider value={{
      page,
      rowsPerPage,
      totalCount,
      rows,
      deleteModalVisible,
      deleteId,
      selected,
      searchIds,
      searchKey,
      sort,
      sortDirection,
      filter,
      filterId,
      filterType,
      filterText,
      setPage,
      setRowsPerPage,
      setTotalCount,
      setRows,
      setDeleteModalVisible,
      setDeleteId,
      setSelected,
      setSearchIds,
      setSearchKey,
      setSort,
      setSortDirection,
      setFilter,
      setFilterId,
      setFilterType,
      setFilterText
    }}>
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequestsContext = (): RequestsContextType => {
  const context = useContext(RequestsContext);
  if (!context) throw new Error('useRequestsContext must be used within a RequestsProvider');
  return context;
};
