import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { RequestsContainer } from '../../components/requests/RequestsContainer';

const RequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.viewRequest) navigate('/');
  }, [me, me?.role?.viewRequest, navigate])

  return <RequestsContainer />;
};

export default RequestPage;
