export const uploadFile = async ({file, presignedUrl, type, presignedName}:{file: File, presignedUrl: string, type: string, presignedName: string}) => {
  try {
    await fetch(presignedUrl, {
      method: 'PUT', 
      body: file,
    })
    return `${process.env.REACT_APP_CF_URL}/${presignedName}`
  } catch {
    return ``
  }
}