import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import { Spacer } from '../../libs/ui/Spacer';
import { RequestPasswordResetDocument } from '../../graphql/generated/graphql';

const ForgotPasswordContainer = () => {

  const [message, setMessage] = useState<string>('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });

  const methods = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, reset } = methods;

  const [requestResetPassword] = useMutation(RequestPasswordResetDocument);

  const onSubmit = async (data: FieldValue<any>) => {
    const { data: response } = await requestResetPassword({ variables: { email: data?.email?.toLowerCase() }})
    if (response?.RequestPasswordReset?.status) {
      reset({ email: '' });
      setMessage(response?.RequestPasswordReset?.message);
    } else {
      setMessage(response?.RequestPasswordReset?.message);
    }
  }

  return (
    <Box width='100vw' height={700} display='flex' alignItems='center' justifyContent='center'>
      <Stack width='30%' direction='column'>
        <Typography variant='h6' m={1} fontWeight={600}>Reset Password</Typography>
        <Box minWidth={300} height='fit-content' border='1px solid #1c3e84' borderRadius={2.5} padding={3.75} display='flex' flexDirection='column'>
          <Stack direction='column' spacing={1} justifyContent='center' sx={{ pt: 0 }}>
            <FormTextField name='email' label='Email Address' control={control} />
            <Spacer height={8} />
            <Box display='flex' flexDirection='row' justifyContent='flex-end'>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Send</Button>
            </Box>
          </Stack>
        </Box>
        <Box width='100%' mt={2}>
          {message !== '' && <Typography textAlign='center' color={message.includes('link') ? '#000' : '#f00'}>{message}</Typography>}
        </Box>
      </Stack>
    </Box>
  )
}

export default ForgotPasswordContainer;
