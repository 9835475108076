import React from 'react';
import { Box } from '@mui/material';
import FormSubmissionDetailContainer from '../../components/forms/FormSubmissionDetailContainer';

const FormSubmissionDetailPage: React.FC = () => {
  return (
    <Box>
      <FormSubmissionDetailContainer />
    </Box>
  )
}

export default FormSubmissionDetailPage;
