import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import RoleEditContainer from '../../components/roles/RoleEditContainer';
import { useUserRoleQuery } from '../../graphql/generated/graphql';

const RoleEditPage = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changePermission) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changePermission, navigate])

  const { data } = useUserRoleQuery({
    variables: { userroleId: Number(roleId) },
    fetchPolicy: 'network-only',
  });

  return (
    <Box>
      {data?.UserRole.data && <RoleEditContainer userRole={data?.UserRole.data} />}
    </Box>
  )
}

export default RoleEditPage;
