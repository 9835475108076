import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import { useResourcesContext } from '../../contexts/resources';
import { ResourceTableHead } from './ResourceTableHead';
import { ResourceForm } from './ResourceForm';
import InventoryModal from './InventoryModal';
import { ResourceImage } from './SourceImage';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import CircularLoading from '../../libs/ui/Loading';
import { findDifferentValues } from '../../libs/utils/getUpdates';
import { showSuccess } from '../../libs/utils/toasts';
import { getResourceInput } from './util';
import {
  CreateResourceDocument,
  DeleteResourceDocument,
  IResource,
  ResourceInput,
  ResourcesDocument,
  ResourceSortField,
  ResourceUpdateInput,
  SortDirectionField,
  UpdateResourceDocument,
  useResourcesQuery,
} from '../../graphql/generated/graphql';
import { headCells, initTempResourceInput } from './const';

export const ResourceContainer = () => {
  const navigate = useNavigate();
  const { me } = useAuth();
  const {
    page,
    rowsPerPage,
    totalCount,
    rows,
    selected,
    deleteModalVisible,
    deleteId,
    orderBy,
    order,
    tempResourceInput,
    openFormModal,
    onEdit,
    inventoryModalVisible,
    selectResource,
    setPage,
    setRowsPerPage,
    setTotalCount,
    setRows,
    setDeleteModalVisible,
    setDeleteId,
    setSelected,
    setOrderBy,
    setOrder,
    setTempResourceInput,
    setOpenFormModal,
    setOnEdit,
    setInventoryModalVisible,
    setSelectResource,
  } = useResourcesContext();

  /*
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<IResource[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof IResource>('title');
  const [order, setOrder] = useState<Order>('asc');
  const [tempResourceInput, setTempResourceInput] = useState<ResourceInput>(initTempResourceInput);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [inventoryModalVisible, setInventoryModalVisible] = useState(false);
  const [selectResource, setSelectResource] = useState<IResource | null>(null);
  */

  const [createResourceQuery] = useMutation(CreateResourceDocument, {
    refetchQueries: [
      {
        query: ResourcesDocument,
        variables: { resourcesPage: page + 1, resourcesPageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [updateResourceMutation] = useMutation(UpdateResourceDocument, {
    refetchQueries: [
      {
        query: ResourcesDocument,
        variables: { resourcesPage: page + 1, resourcesPageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [deleteResourceMutation] = useMutation(DeleteResourceDocument, {
    refetchQueries: [
      {
        query: ResourcesDocument,
        variables: { page: page + 1, pageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ]
  });

  const createResource = async (inputData: ResourceInput) => {
    const { data } = await createResourceQuery({ variables: { resource: inputData } });
    if (data?.CreateResource.data) {
      let _rows = [...rows];
      _rows.push(data.CreateResource.data);
      setRows([..._rows]);
      setTotalCount(totalCount + 1);
    }
    setTempResourceInput(initTempResourceInput);
  };

  const updateResource = async (inputData: ResourceInput | ResourceUpdateInput) => {
    const _postData = findDifferentValues(tempResourceInput, inputData);

    const { data } = await updateResourceMutation({ variables: { resources: _postData } });
    if (data?.UpdateResource.data) {
      let _rows = rows;
      const existIndex = _rows.findIndex((row) => row.id === data.UpdateResource.data?.id);
      if (existIndex >= 0) {
        const newRows = _rows.map((row, index) => index === existIndex ? data.UpdateResource.data : row)
        setRows([...newRows]);
      }
    }
    setTempResourceInput(initTempResourceInput);
  };

  const { data, loading } = useResourcesQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      search: '',
      sort: orderBy as ResourceSortField,
      sortDirection: order === 'asc' ? SortDirectionField.Asc : SortDirectionField.Desc
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.Resources.items) {
      // @ts-ignore
      // setRows(_rows => {
      //   data.Resources.items.forEach((item: IResource) => {
      //     const _exist = _rows.find((row: IResource) => row.id === item.id);
      //     if (!_exist) _rows.push(item);
      //     if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
      //   });
      //   return [..._rows];
      // });
      setRows(data?.Resources.items)
    }
    if (data?.Resources) setTotalCount(data.Resources.total);
  }, [data, setRows, setTotalCount]);

  const handleRequestSort = (property: keyof IResource) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: number) => {
    return selected.indexOf(name) !== -1;
  };

  const handleSave = async (data: ResourceInput) => {
    if (onEdit) {
      await updateResource(data);
    } else {
      await createResource(data);
    }
    setOnEdit(false);
    setOpenFormModal(false);
    setTempResourceInput(initTempResourceInput);
  };

  const handleDelete = async () => {
    const { data } = await deleteResourceMutation({
      variables: { resourceId: deleteId }
    });

    if (data?.DeleteResource.status) {
      // @ts-ignore
      setRows(_rows => {
        return _rows.filter((item: IResource) => item.id !== deleteId)
      })
      setDeleteId(undefined);
      setDeleteModalVisible(false);
    }
  }

  const onAddResources = () => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    setOpenFormModal(true);
  }

  const onEditResource = (row: IResource) => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    setTempResourceInput(getResourceInput(row));
    setOpenFormModal(true);
    setOnEdit(true);
  }

  const onDeleteResource = (row: IResource) => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    setDeleteId(row.id);
    setDeleteModalVisible(true)
  }

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  // const emptyRows = rowsPerPage - rows.length;
  const emptyRows = 0;

  const visibleRows = useMemo(() => {
    // return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return rows
  }, [rows]);

  const ToolbarActions = (
    <Button variant='contained' size='small' onClick={onAddResources}>Add Resources</Button>
  );

  if (loading) return <CircularLoading />;

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Resource Inventory'
          numSelected={selected.length}
          onAdd={() => setOpenFormModal(true)}
          disableAction={!me?.isSuperuser && !me?.role?.changeUsersProfile}
          customActionButton
          actions={ToolbarActions}
          showActions
        />
        <TableContainer sx={{ minHeight: 'calc(100vh - 410px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}>
            <ResourceTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, row.id)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id.toString()}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    {(me?.isSuperuser || me?.role?.editResource) &&
                      <TableCell width={200} align='center'>
                        <Box>
                          <IconButton onClick={() => onEditResource(row)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => onDeleteResource(row)}>
                            <DeleteIcon />
                          </IconButton>
                          <br />
                          <Button variant='outlined' size='small' onClick={() => { setSelectResource(row); setInventoryModalVisible(true) }}>
                            Inventory
                          </Button>
                          <Button variant='outlined' size='small' sx={{ ml: 1 }} onClick={() => navigate(`/app/resources/${row.id}/inventories`)}>
                            Log
                          </Button>
                        </Box>
                      </TableCell>
                    }
                    <TableCell align='center'>
                      <ResourceImage url={row.imageUrl} altString={row.title} />
                    </TableCell>
                    <TableCell align='left'>{row.title}</TableCell>
                    {/* <TableCell align='left'>{row.description}</TableCell> */}
                    <TableCell align='center'>{row.sku}</TableCell>
                    <TableCell align='center'>{row.quantityInCase}</TableCell>
                    <TableCell align='center'>{row.caseWeight}</TableCell>
                    <TableCell width={70} align='center'>
                      {row.isActive && <CheckIcon sx={{ color: 'green' }} />}
                    </TableCell>
                    <TableCell width={70} align='center'>
                      {row.isPublished && <CheckIcon sx={{ color: 'green' }} />}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <DeleteModal
        title='Are you sure you want to delete this resource?'
        open={deleteModalVisible}
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />

      <Modal open={openFormModal} onClose={() => { setOpenFormModal(false); setTempResourceInput(initTempResourceInput); setOnEdit(false) }}>
        <Box>
          <ResourceForm
            onEdit={onEdit}
            value={tempResourceInput}
            setValue={setTempResourceInput}
            onCancel={() => { setOnEdit(false); setTempResourceInput(initTempResourceInput); setOpenFormModal(false) }}
            onSave={handleSave}
          />
        </Box>
      </Modal>

      <InventoryModal resource={selectResource} inventoryModalVisible={inventoryModalVisible} setInventoryModalVisible={setInventoryModalVisible} />
    </Box>
  );
}
