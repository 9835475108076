import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function CircularLoading() {
  return (
    <Box height='100vh' bgcolor='rgba(0, 0, 0, 0.1)' position='absolute' top={0} left={0} right={0} display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
}
