import * as React from "react";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { PostsTableHeadCell } from "./type";
import {
  StyledTableCell as TableCell,
  StyledTableRow as TableRow,
} from "../../libs/ui/StyledComponents";
import { Box, TableSortLabel } from "@mui/material";
import { Order } from "./const";

interface PostTableHeadProps {
  headCells: PostsTableHeadCell[];
  order?: Order;
  orderBy?: string;
  onRequestSort?: (property: string) => void;
}

export function PostTableHead(props: PostTableHeadProps) {
  const {
    headCells,
    orderBy,
    order = "desc",
    onRequestSort,
  } = props;

  const createSortHandler = (property: string) => {
    if (onRequestSort) onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.textAlign ?? "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
            sx={{
              width: headCell?.minWidth,
              minWidth: headCell?.minWidth,
              maxWidth: headCell.maxWidth,
            }}
          >
            {headCell?.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order ?? "desc" : "asc"}
                onClick={() => {
                  if (headCell.id !== "actions") createSortHandler(headCell.id);
                }}
                sx={{display: 'flex', justifyContent: "space-between"}}
              >
                <Typography
                  sx={{
                    width: headCell?.minWidth,
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "1.5rem",
                    letterSpacing: "0.01071em",
                    ml: headCell?.ml,
                  }}
                >
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography
                sx={{
                  width: headCell?.minWidth,
                  fontWeight: "500",
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                  letterSpacing: "0.01071em",
                  ml: headCell?.ml,
                }}
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
