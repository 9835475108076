import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PageSlide from '../../components/common/PageSlide';
import SidebarBorderImage from '../../components/common/SidebarBorderImage';
import CenterBox from '../../components/common/CenterBox';
import ArrowButton from '../../components/common/ArrowButton';
import { useResourceByIdQuery } from '../../graphql/generated/graphql';
import { BookSlides } from '../../const/slide';

const ResourceDetailPage = () => {
  const { id: resourceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  const { data } = useResourceByIdQuery({
    variables: { resourceId: Number(resourceId) },
    fetchPolicy: 'network-only',
  });

  if (data?.Resource.data) {
    const resource = data.Resource.data;
    const myHTML = { __html: resource.description ?? '' };
    return (
      <Grid bgcolor={'#f9f1df'} container>
        <PageSlide images={BookSlides} />
        <Grid container p={3}>
          <Grid item xs={0} xl={1} />
          <Grid item xs={5} xl={4}>
            <SidebarBorderImage image={resource.imageUrl} />
          </Grid>
          <Grid item xs={7} xl={6}>
            <Typography sx={{ paddingBottom: '20px' }} variant={'h1'} fontFamily={'Sorts Mill Goudy,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'} fontSize='4.5rem'>{resource.title}</Typography>
            {
              !!resource.author && <Typography sx={{ paddingBottom: '20px' }} fontFamily={'Sorts Mill Goudy,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'} fontSize='1.5rem'> {resource.author}</Typography>
            }
            {
              resource?.exclusiveOffer &&
              <Typography
                sx={{
                  paddingBottom: '20px',
                  fontStyle: 'italic',
                  fontFamily: 'Sorts Mill Goudy,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
                  lineHeight: '1.25'
                }} fontSize='1.5rem'>* Exclusively offered for military audiences (Active Duty, Retired and Veterans)</Typography>
            }
            <div style={{ fontSize: '1.5rem' }} className='resource-description resource-text' dangerouslySetInnerHTML={myHTML}></div>
            <CenterBox customStyle={{ mt: 6 }}>
              <ArrowButton label='Inquire About Resources' onClick={() => navigate(`/book-apply`)} />
            </CenterBox>
          </Grid>
          <Grid item xs={0} xl={1} />
        </Grid>
      </Grid>
    );
  }
  return <></>;
};

export default ResourceDetailPage;
