import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useCookies } from 'react-cookie';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { COOKIES_TOKEN_NAME } from '../graphql/apolloClient';
import {
  LoginDocument,
  RequestLoginDocument,
  LoginMutation,
  RequestLoginMutation,
  LoginMutationVariables,
  MeDocument,
  MeQuery,
  RequestLoginMutationVariables,
  RequestLoginMutationSchemaVariables,
  RequestLoginMutationWithToken,
  RequestLoginDocumentWithToken,
} from '../graphql/generated/graphql';
import { UserResponseData } from '../const/type';

interface IContextProps {
  canAutoLogin: boolean;
  setMe: React.Dispatch<React.SetStateAction<UserResponseData | undefined>>;
  signIn: ({ email, password }: LoginMutationVariables) => Promise<any>;
  signOut: () => void;
  me?: UserResponseData;
  requestSignIn: ({ input }: RequestLoginMutationSchemaVariables) => Promise<any>;
  requestSignInWithToken: ({ email }: RequestLoginMutationVariables) => Promise<any>;
}

const authContext = createContext({} as IContextProps);

export function AuthProvider({ children, client }: { children: ReactNode; client: ApolloClient<NormalizedCacheObject> }) {
  const auth = useProvideAuth(client);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = (): IContextProps => useContext(authContext);

function useProvideAuth(apolloClient: ApolloClient<NormalizedCacheObject>): IContextProps {
  const [me, setMe] = useState<UserResponseData | undefined>(undefined);
  const [cookies, setCookie, removeCookie] = useCookies([COOKIES_TOKEN_NAME]);

  const canAutoLogin = useMemo((): boolean => !!cookies[COOKIES_TOKEN_NAME], [cookies]);

  const signIn = useCallback(async ({ email, password }: LoginMutationVariables): Promise<any> => {
    const { data }: FetchResult<LoginMutation> = await apolloClient.mutate({
      mutation: LoginDocument,
      variables: { email, password },
    });
    if (data?.Login.data?.accessToken) {
      setCookie(COOKIES_TOKEN_NAME, data?.Login.data?.accessToken, { path: '/', maxAge: 604800 });
      const { data: meData }: FetchResult<MeQuery> = await apolloClient.query(
        { query: MeDocument, fetchPolicy: 'network-only', },
      );
      if (meData?.Me.data) {
        setMe(meData.Me.data);
        return {success: true, data: meData.Me.data};
      } else {
        return { success: false, data: null};
      }
    } else {
      return { success: false, message: data?.Login.message!, data: null};
    }
  }, [apolloClient, setCookie]);
  const requestSignIn = useCallback(async ({ input: { ids, content, subject, title, link, from, bcc }}: RequestLoginMutationSchemaVariables): Promise<any> => {
    const { data }: FetchResult<RequestLoginMutation> = await apolloClient.mutate({
      mutation: RequestLoginDocument,
      variables: { 
        input: {
          ids,
          content,
          subject,
          title,
          link,
          from,
          bcc
        }
      },
    });
    if (data?.RequestLogin?.data) {
        return {success: true, data: data?.RequestLogin.data};
    } else {
      return { success: false, message: data?.RequestLogin?.message!, data: null};
    }
  }, [apolloClient]);
  const requestSignInWithToken = useCallback(async ({ email, token }: RequestLoginMutationVariables): Promise<any> => {
    const { data }: FetchResult<RequestLoginMutationWithToken> = await apolloClient.mutate({
      mutation: RequestLoginDocumentWithToken,
      variables: { email, token },
    });
    if (data?.RequestLoginWithToken.data?.accessToken) {
      setCookie(COOKIES_TOKEN_NAME, data?.RequestLoginWithToken.data?.accessToken, { path: '/', maxAge: 604800 });
      const { data: meData }: FetchResult<MeQuery> = await apolloClient.query(
        { query: MeDocument, fetchPolicy: 'network-only', },
      );
      if (meData?.Me.data) {
        setMe(meData.Me.data);
        return {success: true, data: meData.Me.data};
      } else {
        return { success: false, data: null};
      }
    } else {
      return { success: false, message: data?.RequestLoginWithToken.message!, data: null};
    }
  }, [apolloClient, setCookie]);

  const signOut = useCallback(() => {
    removeCookie(COOKIES_TOKEN_NAME);
    setMe(undefined);
  }, [removeCookie]);

  return {
    canAutoLogin,
    setMe,
    signIn,
    signOut,
    me,
    requestSignIn,
    requestSignInWithToken
  };
}
