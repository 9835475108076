import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormSwitch from '../../libs/ui/FormSwitch';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormNumberField from '../../libs/ui/FormNumberField';
import FormEditorField from '../../libs/ui/FormEditorField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import { showSuccess } from '../../libs/utils/toasts';
import { CreatePageDocument, PagesInput, useDropMenuPagesQuery, useFormsQuery } from '../../graphql/generated/graphql';

export const PageCreateContainer = () => {
  const navigate = useNavigate();

  const [formNames, setFormNames] = useState<any[]>([]);
  const [parentPages, setParentPages] = useState<any[]>([{ label: 'no parent', value: null }]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Page Name is required'),
    url: Yup.string(),
    pageTitle: Yup.string().required('page title is required'),
    isNav: Yup.boolean(),
    isHome: Yup.boolean(),
    isShow: Yup.boolean(),
    dropMenu: Yup.boolean(),
    parentId: Yup.number().nullable(),
    order: Yup.number(),
    headLine: Yup.string().required('Header Text is required'),
    content: Yup.string(),
    sideContent: Yup.string(),
    formId: Yup.number().nullable(),
    isApplicationForm: Yup.bool(),
    isApplicationLogin: Yup.bool(),
    adminEmail: Yup.string(),
    fromEmail: Yup.string(),
    bccEmails: Yup.string(),
    emailHeader: Yup.string(),
    emailBottom: Yup.string(),
    loginFromEmail: Yup.string(),
    loginBccEmails: Yup.string(),
    loginSubjectEmail: Yup.string(),
    loginTitleEmail: Yup.string(),
    loginEmailContent: Yup.string(),
    loginEmailLongTermContent: Yup.string(),
    loginLinkEmail: Yup.string(),
    isFeedbackForm: Yup.bool(),
    feedbackFormFromEmail: Yup.string(),
    feedbackFormBccEmails: Yup.string(),
    feedbackFormSubjectEmail: Yup.string(),
    feedbackFormTitleEmail: Yup.string(),
    feedbackFormEmailContent: Yup.string(),
    feedbackFormLinkEmail: Yup.string(),
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      url: '',
      pageTitle: '',
      isNav: false,
      isHome: false,
      isShow: true,
      dropMenu: false,
      parentId: null,
      order: 0,
      headLine: '',
      content: '',
      sideContent: '',
      formId: null,
      isApplicationForm: false,
      isApplicationLogin: false,
      adminEmail: '',
      fromEmail: '',
      bccEmails: '',
      emailHeader: '',
      emailBottom: '',
      loginFromEmail: '',
      loginBccEmails: '',
      loginSubjectEmail: '',
      loginTitleEmail: '',
      loginEmailContent: '',
      loginEmailLongTermContent: '',
      loginLinkEmail: '',
      isFeedbackForm: false,
      feedbackFormFromEmail: '',
      feedbackFormBccEmails: '',
      feedbackFormSubjectEmail: '',
      feedbackFormTitleEmail: '',
      feedbackFormEmailContent: '',
      feedbackFormLinkEmail: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, watch, getValues } = methods;

  const [createPageMutation] = useMutation(CreatePageDocument);

  const { data: pageData } = useDropMenuPagesQuery({
    variables: { page: 1, pageSize: 10000 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (pageData?.Pages.items) {
      const _parentPages = pageData.Pages.items.map((item) => {
        return { label: item.name, value: item.id };
      });
      setParentPages([{ label: ' --- ', value: null }, ..._parentPages]);
    }
  }, [pageData]);

  const { data: formsData } = useFormsQuery({
    variables: { page: 1, pageSize: 10000 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (formsData?.Forms.items) {
      const _forms = formsData.Forms.items.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setFormNames([{ label: ' --- ', value: null }, ..._forms]);
    }
  }, [formsData]);

  const createPage = async (inputData: PagesInput) => {
    const { data } = await createPageMutation({
      variables: { pages: inputData },
    });
    if (data?.CreatePage.data) {
      showSuccess('Page was created successfully.');
      setTimeout(() => navigate(-1), 3000);
    }
  };

  const onSubmit = (data: FieldValue<any>) => {
    onSave({ ...data, loginLinkEmail: getValues('url'), feedbackFormLinkEmail: `${window.location.origin}/${getValues('url')}` });
  };

  const onSave = (data: PagesInput) => {
    createPage(data);
  };

  return (
    <Grid container display='flex' justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h5'>Add Page</Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={5} pr={2}>
          <FormTextField
            name='name'
            label='Page Name'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Name</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
          <FormTextField
            name='pageTitle'
            label='Page Title'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Title</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
          <FormTextField
            name='url'
            label='Page Url'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Url</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' }}}
            control={control}
          />
        </Grid>
        <Grid item xs={5} height={168} pl={2}>
          <Box display='flex' justifyContent='flex-start'>
            <FormSwitch
              name='isNav'
              label='Is Nav'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Nav</Typography>}
              containerProps={{ sx: { mt: 1.5, display: 'flex' } }}
              control={control}
            />
            <FormSwitch
              name='isHome'
              label='Is Home'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Home</Typography>}
              containerProps={{ sx: { mt: 1.5, ml: 4, display: 'flex' } }}
              control={control}
            />
            <FormSwitch
              name='isShow'
              label='Is Show'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Show</Typography>}
              containerProps={{ sx: { mt: 1.5, ml: 4, display: 'flex' } }}
              control={control}
            />
          </Box>
          <FormNumberField
            name='order'
            label='Order'
            labelComponent={<Typography width={130} fontSize={16} fontWeight={500} lineHeight='40px'>Order</Typography>}
            size='small'
            type='number'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
            control={control}
          />
          <FormDropdownField
            name='parentId'
            label='Parent Menu'
            labelComponent={<Typography minWidth={130} mt={2} mb={0.5} fontSize={16} fontWeight={500}>Parent Menu</Typography>}
            size='small'
            disabled={parentPages.length === 0}
            options={parentPages}
            sx={{ background: 'white' }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormTextField
            name='headLine'
            label='HeadLine'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>HeadLine</Typography>}
            size='small'
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row'} }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='content'
            label='Content'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Content</Typography>}
            rows={1}
            defaultValue=''
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 1 } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='sideContent'
            label='Side Content'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Side Content</Typography>}
            rows={1}
            defaultValue=''
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 1 } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormDropdownField
            name='formId'
            label='Form'
            labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Form</Typography>}
            size='small'
            options={formNames}
            sx={{ height: '40px', background: 'white' }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('formId') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={4}>
            <FormCheckBoxField
              name='isFeedbackForm'
              label='Is this the Request Feedback Form'
              size='small'
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      {watch('isFeedbackForm') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormFromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormBccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormSubjectEmail'
              label='Subject'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormTitleEmail'
              label='Title'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='feedbackFormEmailContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
              defaultValue={''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {/* Text Field for the CMS to control the login route in the email sent to applicants */}
        {/* <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginLinkEmail'
              label='Login Link'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Login Link</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid> */}
      </>}
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isApplicationForm'
            label='Include Application Form'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('isApplicationForm') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='adminEmail'
              label='Admin Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Admin Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='fromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='bccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='emailHeader'
              label='Email Header'
              labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Email Header</Typography>}
              rows={1}
              defaultValue=''
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='emailBottom'
              label='Email Footer'
              labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Email Footer</Typography>}
              rows={1}
              defaultValue=''
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isApplicationLogin'
            label='Include Application Login'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('isApplicationLogin') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginFromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginBccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginSubjectEmail'
              label='Subject'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginTitleEmail'
              label='Title'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='loginEmailContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
              defaultValue={''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='loginEmailLongTermContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Long term inquiry</Typography>}
              defaultValue={''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {/* Text Field for the CMS to control the login route in the email sent to applicants */}
        {/* <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginLinkEmail'
              label='Login Link'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Login Link</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid> */}
      </>}
      <Grid item xs={8} pr={1} mt={4} mb={4}>
        <Box width='100%' mt={2} display='flex' justifyContent='flex-end'>
          <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
          <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
      </Grid>
    </Grid>
  );
};
