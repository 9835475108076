import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ResourceSelectItem } from './ResourceSelectItem';
import { DropDownItem } from '../../../libs/ui/FormDropdownField';
import { useResourcesListQuery } from '../../../graphql/generated/graphql';
import { ResourceSelectionType } from '../type';
import { defaultResourceGroup } from '../const';

interface RequestResourceSelectProps {
  setResourceGroup: (data: ResourceSelectionType[]) => void;
  resourceGroup: ResourceSelectionType[];
  errors: any;
}

const RequestResourceSelect = ({
  setResourceGroup,
  resourceGroup,
  errors
}: RequestResourceSelectProps) => {
  const [resources, setResources] = useState<DropDownItem[]>([]);
 
  const { data: resourcesData } = useResourcesListQuery();

  const handleUpdate = (value: ResourceSelectionType, index: number) => {
    const _resourceGroup = resourceGroup;
    for (let i = 0; i < _resourceGroup.length; i++) {
      if (i === index) {
        _resourceGroup[i] = value;
      }
    }
    setResourceGroup([..._resourceGroup]);
  };
  const handleOnAdd = () => {
    if (resourceGroup.indexOf(defaultResourceGroup) > -1) return;
    setResourceGroup([...resourceGroup, defaultResourceGroup]);
  }

  const handleOnRemove = (index: number) => {
    const _resourceGroup = resourceGroup;
    _resourceGroup.splice(index, 1)
    if (_resourceGroup.length) setResourceGroup([..._resourceGroup]);
    if (_resourceGroup.length === 0) setResourceGroup([...resourceGroup, defaultResourceGroup]);
  }

  const getAvailableResources = (index: number) => {
    return resources.filter(
      (r) => !resourceGroup.some((rg, i) => rg.resource === r.value && i !== index)
    );
  };

  useEffect(() => {
    if (resourcesData?.Resources.items) {
      setResourceGroup([{...defaultResourceGroup,}])
      const tempItems = [ ...resourcesData?.Resources.items ]
      tempItems.sort(
        (a: any, b: any) => (
          a?.title > b?.title) ? 1 : ((b?.title > a?.title) ? -1 : 0
      ))
      const _resources = tempItems.map((item) => ({
        label: item.title,
        value: item.id,
        quantityInCase: item.quantityInCase, 
        caseWeight: item.caseWeight, 
      }));
      setResources([{ label: "-- Select --", value: 0 }, ..._resources]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesData]);

  const totalWeight = resourceGroup.reduce((sum, item) => {
    const resource = resources.find(r => r.value === item.resource);
    return sum + (((resource as any)?.caseWeight || 0) * (item.cases || 0));
  }, 0);
  return (
    <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
      <Typography fontWeight={600}>Resource</Typography>
      <Grid container>
        {resourceGroup.map((group, index) => {
          return (
            <ResourceSelectItem
              key={`${group.resource}-${index}`}
              resourceGroup={group}
              resources={getAvailableResources(index)}
              addNew={index === resourceGroup.length - 1}
              onChange={(value) => {
                handleUpdate(value, index);
              }}
              onAdd={handleOnAdd}
              onRemove={() => {handleOnRemove(index)}}
            />
          );
        })}
      </Grid>
      <Grid container>
          <Grid item>
            <Typography sx={{ mt: 3, background: "#ECF3FD", px: 1, borderRadius: 50, fontSize: 15, color: "#2B5693", border: "1px solid #5882bb" }}>
              Total Cases: <b>{resourceGroup.reduce((sum, item) => sum + (item.cases || 0), 0)}</b> - Calculated Weight: <b>{totalWeight}</b>
            </Typography>
          </Grid>
      </Grid>
      {errors?.resourceGroup && <Typography mt={2} fontWeight={600} color='#f00'>{errors?.resourceGroup?.message}</Typography>}
    </Box>
  );
};

export default RequestResourceSelect;
