import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './contexts/auth';
import { UsersProvider } from './contexts/users';
import { RequestsProvider } from './contexts/requests';
import { ResourcesProvider } from './contexts/resources';
import apolloClient from './graphql/apolloClient';
import AppRoutes from './navigation/appRoutes';
import './App.css';

const theme = createTheme();

export const useBeforeRender = (callback: any, deps: any) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), []);
};

const App = () => {
  useBeforeRender(() => {
    window.addEventListener('error', (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={apolloClient}>
        <CookiesProvider>
          <AuthProvider client={apolloClient}>
            <UsersProvider>
              <RequestsProvider>
                <ResourcesProvider>
                  <BrowserRouter>
                    <AppRoutes />
                    <ToastContainer />
                  </BrowserRouter>
                </ResourcesProvider>
              </RequestsProvider>
            </UsersProvider>
          </AuthProvider>
        </CookiesProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;

