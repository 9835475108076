import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import UserEditContainer from '../../components/users/UserEditContainer';

const UserEditPage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changeUsersProfile) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changeUsersProfile, navigate])

  return (
    <UserEditContainer />
  )
}

export default UserEditPage;
