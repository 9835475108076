import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormBuilder } from '@formio/react';
import 'formiojs/dist/formio.full.css';
import FormTextField from '../../libs/ui/FormTextField';
import FormEditorField from '../../libs/ui/FormEditorField';
import { CreateFormDocument } from '../../graphql/generated/graphql';

export const FormNewContainer = () => {
  const navigate = useNavigate();

  const [components, setComponents] = useState<any[]>([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Form Name is required'),
    adminEmail: Yup.string().email().required('Admin Email is required'),
    fromEmail: Yup.string().required('From Email is required'),
    bccEmails: Yup.string().nullable(),
    emailHeader: Yup.string().nullable(),
    emailFooter: Yup.string().nullable()
  });

  const methods = useForm({
    defaultValues: { name: '', adminEmail: '', fromEmail: '', bccEmails:'', emailHeader: '', emailFooter: '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control} = methods;

  const [createFormMutation] = useMutation(CreateFormDocument);

  const exportToJson = async (formData: FieldValue<any>) => {
    const { data } = await createFormMutation({
      variables: {
        forms: {
          name: formData.name,
          content: JSON.stringify(components),
          adminEmail: formData.adminEmail,
          fromEmail: formData.fromEmail,
          bccEmails: formData.bccEmails,
          emailHeader: formData.emailHeader ?? '',
          emailBottom: formData.emailFooter ?? '',
          replyEmail: ''
        },
      }
    });
    if (data?.CreateForm.status) navigate(-1);
  };

  return (
    <Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={4} sm={4} md={3} px={1}>
          <FormTextField
            name='name'
            label='Name'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Name</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 4 } }}
          />
        </Grid>
      </Grid>
      <FormBuilder form={{ display: 'form' }} onChange={(newFormState: any) => setComponents(newFormState)} />
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={4} sm={4} md={3}>
          <FormTextField
            name='adminEmail'
            label='Admin Email'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Admin Email</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={4} sm={4} md={3}>
          <FormTextField
            name='fromEmail'
            label='From Email'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={10}>
          <FormTextField
            name='bccEmails'
            label='Bcc Emails'
            size='small'
            labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={10}>
          <FormEditorField
            name='emailHeader'
            label='Email Header'
            defaultValue=''
            labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Header</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={4} sm={3} md={2} />
        <Grid item xs={10}>
          <FormEditorField
            name='emailFooter'
            label='Email Footer'
            defaultValue=''
            labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Footer</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>
      <Box mt={5} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='contained' onClick={handleSubmit(exportToJson)}>Save</Button>
      </Box>
    </Grid>
  )
}
