import { useEffect, useMemo } from 'react';
import { Box, Button, Grid, Stack, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormDateTimeField from '../../libs/ui/FormDateTimeField';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { useResourcesInventoryLazyQuery } from '../../graphql/generated/graphql';

const currentDate = new Date();
const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));

const RemainingInventoryReports = () => {

  const validationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const methods = useForm({
    defaultValues: {
      fromDate: oneYearAgo,
      endDate: new Date(),
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [getData, { data }] = useResourcesInventoryLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    getData({ variables: { start: oneYearAgo, end: new Date() } });
  }, [getData]);

  const summary = useMemo(() => {
    if (data?.ResourcesInventory?.items && data.ResourcesInventory.items.length > 0) return data.ResourcesInventory.items;
    return []
  }, [data])

  const onSubmit = (_value: FieldValue<any>) => {
    getData({ variables: { start: _value.fromDate, end: _value.endDate } });
  };

  return (
    <Box mt={1}>
      <Box border='solid 1px lightgray' mt={2} px={1} py={2}>
        <Stack width='fit-content' bgcolor='#fff' mt={-2.5} px={1} direction='column' justifyContent='center' spacing={1}>
          <Typography variant='body1' fontWeight={600}>Months of Remaining Inventory</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='fromDate'
              label='Date From'
              labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Date From</Typography>}
              size='small'
              type='datetime-local'
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='endDate'
              label='Date To'
              labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Date To</Typography>}
              size='small'
              type='date'
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} display='flex' justifyContent='flex-start'>
        <Button variant='outlined' onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Box>
      <TableContainer sx={{ mt: 1 }}>
        <Table aria-labelledby='tableTitle' size='small'>
          <TableHead>
            <StyledTableRow>
              <TableCell width={150}>SKU</TableCell>
              <TableCell>Title</TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Current Inventory</TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Monthly Average for Selected Period</TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Projected Months of Inventory per SKU</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[ ...summary ]?.sort((a: any, b: any) => (a.title?.toLowerCase() > b.title?.toLowerCase()) ? 1 : ((b.title?.toLowerCase() > a.title?.toLowerCase()) ? -1 : 0))?.map(row => (
              <StyledTableRow hover role='checkbox' tabIndex={-1}>
                <TableCell align='left'>{row.sku}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.title}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.inventory}</TableCell>
                <TableCell align='right' sx={{ paddingRight: '12px!important' }}>{(row.lastYearShipped! / 12).toFixed(1)}</TableCell>
                <TableCell align='right' sx={{ paddingRight: '12px!important' }}>{row.lastYearShipped === 0 ? 0 : (row.inventory * 12 / row.lastYearShipped!).toFixed(1)}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default RemainingInventoryReports;
