import React from 'react';
import { Button } from '@mui/material';

const buttonStyle = {
  borderColor: '#ba2827',
  color: '#d2232a',

  '&:hover': {
    backgroundColor: '#c9403e',
    borderColor: '#ba2827',
    color: '#fff',
  },
};

export interface ReadMoreButtonProps {
  label?: string;
  onClick?: () => void;
}

const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({ label = 'READ MORE', onClick }) => {
  return (
    <Button variant='outlined' sx={buttonStyle} onClick={onClick}>{label}</Button>
  )
}

export default ReadMoreButton;
