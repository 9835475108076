import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
import FormTextField from '../../libs/ui/FormTextField';
import FormPhoneField from '../../libs/ui/FormPhoneField';
import { Spacer } from '../../libs/ui/Spacer';
import { showSuccess } from '../../libs/utils/toasts';
import { UpdateUserAllInfoDocument, UpdateUserDocument } from '../../graphql/generated/graphql';

const AdminProfilePage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.isSuperuser && !me?.role?.changeProfile) navigate('/');
  }, [me, me?.isSuperuser, me?.role?.changeProfile, navigate]);

  const [updateUser] = useMutation(UpdateUserDocument, {});
  const [updateUserAllInfo] = useMutation(UpdateUserAllInfoDocument, {});

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords do not match'),
  });

  const passwordMethods = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(passwordValidationSchema),
  });

  const { handleSubmit: passwordHandleSubmit, control: passwordControl, getValues: getPasswordValues } = passwordMethods;

  const updatePassword = async () => {
    const user = {
      password: getPasswordValues('password'),
      id: me?.id,
    };
    const { data } = await updateUser({
      mutation: UpdateUserDocument,
      variables: { user },
    });
    if (data?.UpdateUser.status) showSuccess('This Admin profile password was updated successfully');
  };

  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().min(10, 'Must be 10 digits'),
    email: Yup.string().email(),
  });

  const profileMethods = useForm({
    defaultValues: {
      firstName: me?.firstName || undefined,
      lastName: me?.lastName || undefined,
      phone: me?.phone || undefined,
      email: me?.email || undefined,
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const { handleSubmit: profileHandleSubmit, control: profileControl, getValues: getProfileValues } = profileMethods;

  const updateProfile = async () => {
    const user = {
      firstName: getProfileValues('firstName'),
      lastName: getProfileValues('lastName'),
      phone: getProfileValues('phone'),
      email: getProfileValues('email'),
      id: me?.id,
    };

    const { data } = await updateUserAllInfo(
      {
        mutation: UpdateUserAllInfoDocument,
        variables: {
          exclusionGroup: 0,
          user: {
            user,
          },
        },
      }
    );
    if (data?.UpdateUserAllInfo.status) showSuccess('This Admin profile was updated successfully');
  };

  return (
    <Box px={5}>
      <Grid container>
        <Grid item xs={6}>
          <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
            <Typography variant='h5'>Admin Password</Typography>
            <Spacer height={10} />
            <FormTextField
              name='password'
              label='Password'
              type='password'
              size='small'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Password{' '}<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={passwordControl}
            />
            <Spacer height={10} />
            <FormTextField
              name='confirmPassword'
              label='confirmPassword'
              type='password'
              size='small'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Confirm Password{' '}<span style={{ fontWeight: 400, color: '#f00' }}>*</span></Typography>}
              control={passwordControl}
            />
            <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
              <Button variant='outlined' sx={{ minWidth: 200 }} onClick={passwordHandleSubmit(updatePassword)}>update</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box bgcolor='white' borderRadius={2} mt={3} p={2} boxShadow={3}>
        <Typography variant='h5'>Admin Profile</Typography>
        <Grid container>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='firstName'
              label='First Name'
              size='small'
              placeholder='First'
              labelComponent={<Typography fontWeight={600}>First Name</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='lastName'
              label='Last Name'
              size='small'
              placeholder='Last'
              labelComponent={<Typography fontWeight={600}>Last Name</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormPhoneField
              name='phone'
              label='Phone'
              size='small'
              labelComponent={<Typography fontWeight={600}>Phone</Typography>}
              control={profileControl}
            />
          </Grid>
          <Grid item xs={6} p={1}>
            <FormTextField
              name='email'
              label='Email'
              size='small'
              type='email'
              rows={1}
              labelComponent={<Typography fontWeight={600}>Email</Typography>}
              control={profileControl}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='outlined' sx={{ width: 200 }} onClick={profileHandleSubmit(updateProfile)}>Update</Button>
      </Box>
    </Box>
  );
}

export default AdminProfilePage;
