import React, { useState } from 'react';
import { Checkbox, Typography, TableRow, TableHead, TableCell, IconButton, Popper, Box, Select, MenuItem, TextField, Button, TableSortLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { visuallyHidden } from '@mui/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { UsersTableHeadCell } from './type';
import { DateFilterOperators, NumberFilterOperators, Order, StringFilterOperators } from '../../const/type';
import { UserSortField } from '../../graphql/generated/graphql';

interface ResourceTableProps {
  headCells: UsersTableHeadCell[];
  numSelected: number;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  onRequestSort?: (property: UserSortField) => void;
  filterId: string | undefined;
  filterType: string;
  setFilterType: (value: string) => void;
  filterText: string;
  setFilterText: (value: string) => void;
  applyFilter: (value: string | undefined) => void;
  role?: string;
}

export const UsersTableHead = (props: ResourceTableProps) => {
  const {
    headCells,
    numSelected,
    rowCount,
    onSelectAllClick,
    orderBy,
    order = 'desc',
    onRequestSort,
    filterId,
    filterType,
    setFilterType,
    filterText,
    setFilterText,
    applyFilter,
    role,
  } = props;

  const [tempFilterId, setTempFilterId] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    if (filterId === id) {
      setFilterType('equalTo');
      setFilterText('');
      applyFilter(undefined);
    } else if (['organization', 'firstName', 'lastName', 'email', 'phone', 'status'].includes(id)) {
      setFilterType('iLike');
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    } else if (['createTime', 'acceptedDate'].includes(id)) {
      setFilterType('lessThanEqual');
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    }
  };

  const createSortHandler = (property: string) => {
    if (onRequestSort) onRequestSort(property as UserSortField);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <TableHead>
      <TableRow>
        {role !== 'admin' && <TableCell padding='checkbox'>
          <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount} checked={numSelected === rowCount} onChange={onSelectAllClick} />
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.textAlign ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={false}
            sx={{ width: headCell?.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
          >
            {headCell?.sortable ? (
              <Box display='flex' alignItems='center' justifyContent='space-evenly'>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order ?? 'desc' : 'asc'}
                  onClick={() => {
                    if (headCell.id !== 'actions') createSortHandler(headCell.id);
                  }}
                  sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  <Typography sx={{ ml: headCell?.ml, fontSize: '0.875rem', lineHeight: '1.5rem', letterSpacing: '0.01071em' }}>{headCell.label}</Typography>
                  {orderBy === headCell.id ? <Box component='span' sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null}
                </TableSortLabel>
                {!['actions'].includes(headCell.id) && <IconButton aria-describedby={id} type='button' onClick={(event) => handleClick(event, headCell.id)}>
                  {filterId === headCell.id ? <FilterAltIcon /> : <FilterAltOffIcon />}
                </IconButton>}
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box bgcolor='#fff' p={1} display='flex'>
                    <Select label='Operator' value={filterType} onChange={event => setFilterType(event.target.value)}>
                      {['id'].includes(tempFilterId!) && NumberFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['organization', 'firstName', 'lastName', 'email', 'phone', 'status'].includes(tempFilterId!) && StringFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['createTime', 'acceptedDate'].includes(tempFilterId!) && DateFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    {['id'].includes(tempFilterId!) &&
                      <TextField label='Value' sx={{ mx: 1 }}  value={filterText} onChange={event => setFilterText(event.target.value)} />}
                    {['organization', 'firstName', 'lastName', 'email', 'phone', 'status'].includes(tempFilterId!) &&
                      <TextField label='Value' sx={{ mx: 1 }}  value={filterText} onChange={event => setFilterText(event.target.value)} />}
                    {['createTime', 'acceptedDate'].includes(tempFilterId!) &&
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker sx={{ width: 150, mx: 1 }} onChange={(value: any) => setFilterText(value)} />
                      </LocalizationProvider>}
                    <Button variant='outlined' disabled={filterText === ''} onClick={() => {
                      applyFilter(tempFilterId)
                      setAnchorEl(null)
                    }}>Apply</Button>
                  </Box>
                </Popper>
              </Box>
            ) : (
              <Box display='flex' alignItems='center' justifyContent='space-evenly'>
                <Typography sx={{ width: headCell?.minWidth, ml: headCell?.ml, fontSize: '0.875rem', lineHeight: '1.5rem', letterSpacing: '0.01071em' }}>{headCell.label}</Typography>
                {!['actions'].includes(headCell.id) && <IconButton aria-describedby={id} type='button' onClick={(event) => handleClick(event, headCell.id)}>
                  {filterId === headCell.id ? <FilterAltIcon /> : <FilterAltOffIcon />}
                </IconButton>}
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box bgcolor='#fff' p={1} display='flex'>
                    <Select label='Operator' value={filterType} onChange={event => setFilterType(event.target.value)}>
                      {['id'].includes(tempFilterId!) && NumberFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['organization', 'firstName', 'lastName', 'email', 'phone', 'status'].includes(tempFilterId!) && StringFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['createTime', 'acceptedDate'].includes(tempFilterId!) && DateFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    {['id'].includes(tempFilterId!) &&
                      <TextField label='Value' sx={{ mx: 1 }}  value={filterText} onChange={event => setFilterText(event.target.value)} />}
                    {['organization', 'firstName', 'lastName', 'email', 'phone', 'status'].includes(tempFilterId!) &&
                      <TextField label='Value' sx={{ mx: 1 }}  value={filterText} onChange={event => setFilterText(event.target.value)} />}
                    {['createTime', 'acceptedDate'].includes(tempFilterId!) &&
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker sx={{ width: 150, mx: 1 }} onChange={(value: any) => setFilterText(value)} />
                      </LocalizationProvider>}
                    <Button variant='outlined' disabled={filterText === ''} onClick={() => {
                      applyFilter(tempFilterId)
                      setAnchorEl(null)
                    }}>Apply</Button>
                  </Box>
                </Popper>
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
