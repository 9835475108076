import * as React from 'react';
import { Box, BoxProps, Stack, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Control, Controller } from 'react-hook-form';

export type FormDateFieldProps = TextFieldProps & {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  labelComponent?: React.ReactElement;
  label: string;
  size?: 'small' | 'medium';
  containerProps?: BoxProps;
  onChangeInput?: (value: any) => void;
};

/**
 * Describe your component here
 */
const FormDateField: React.FC<FormDateFieldProps> = (props) => {
  const {
    name,
    control,
    label,
    labelComponent,
    containerProps,
    onChangeInput,
    size = 'small',
    defaultValue = '',
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={containerProps?.sx}>
        {labelComponent}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Stack width='100%' bgcolor='#fff'>
              <DatePicker
                slotProps={{ textField: { label, size } }}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (onChangeInput) onChangeInput(e);
                }}
                onAccept={onChange}
              />
            </Stack>
          )}
          defaultValue={defaultValue}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default FormDateField;
