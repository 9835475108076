import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormNumberField from '../../libs/ui/FormNumberField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormEditorField from '../../libs/ui/FormEditorField';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { CreateTestimonialDocument, useMinistriesQuery } from '../../graphql/generated/graphql';

const modalBoxStyle = {
  width: '80%',
  maxHeight: '800px',
  backgroundColor: '#fff',
  padding: 3,
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

interface BlockType {
  id?: number | undefined;
  order: number | undefined;
  sideContent: string | undefined;
  content: string | undefined;
}

const TestimonialNewContainer = () => {
  const navigate = useNavigate();

  const [ministries, setMinistries] = useState<any[]>([]);
  const [rows, setRows] = useState<BlockType[]>([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectId, setSelectId] = useState<number | undefined>(undefined);
  const [selectBlock, setSelectBlock] = useState<BlockType | undefined>(undefined);

  const [createTestimonial] = useMutation(CreateTestimonialDocument, {});

  const { data: ministriesData} = useMinistriesQuery({
    variables: { page: 1, pageSize: 100 },
    nextFetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (ministriesData?.Ministries.items) {
      const array = ministriesData?.Ministries.items.map(item => {
        return { label: item.name, value: item.id }
      })
      setMinistries(array);
    }
  }, [ministriesData])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    summary: Yup.string().required('Summary is required'),
    pageTitle: Yup.string().required('Page Title is required'),
    order: Yup.number(),
    url: Yup.string().required('Page Url is required'),
    ministryId: Yup.string().required('Ministry is required'),
    sideContent: Yup.string(),
    headLine: Yup.string(),
    content: Yup.string(),
  });

  const methods = useForm({
    defaultValues: { name: '', summary: '', pageTitle: '', order: 0, url: '', ministryId: '', sideContent: '', headLine: '', content: '' },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, getValues } = methods;

  const addTestimonial = async () => {
    const testimonial = {
      name: getValues('name'),
      summary: getValues('summary'),
      pageTitle: getValues('pageTitle'),
      order: Number(getValues('order')),
      url: getValues('url'),
      ministryId: getValues('ministryId'),
      sideContent: getValues('sideContent'),
      headLine: getValues('headLine'),
      content: getValues('content'),
      blocks: rows,
    }

    const { data } = await createTestimonial({
      mutation: CreateTestimonialDocument,
      variables: { testimonial }
    })
    if (data?.CreateTestimonial.status) navigate(-1);
  }

  const blockValidationSchema = Yup.object().shape({
    order: Yup.number(),
    sideContent: Yup.string(),
    content: Yup.string(),
  });

  const blockMethods = useForm({
    defaultValues: { order: 0, sideContent: '', content: '' },
    resolver: yupResolver(blockValidationSchema),
  });

  const { handleSubmit: blockHandleSubmit, control: blockControl, getValues: blockGetValues, reset } = blockMethods;

  const addBlock = () => {
    if (typeof selectId === 'number') {
      rows[selectId] = {
        order: Number(blockGetValues('order')),
        sideContent: blockGetValues('sideContent'),
        content: blockGetValues('content')
      };
      setRows(rows);
      setSelectId(undefined);
      setSelectBlock(undefined);
      setAddModalVisible(false);
    } else {
      const block = {
        order: Number(blockGetValues('order')),
        sideContent: blockGetValues('sideContent'),
        content: blockGetValues('content')
      }
      setRows(rows.concat([block]));
      setAddModalVisible(false);
    }
  }

  const deleteBlock = (index: number) => {
    rows.splice(index, 1)
    setRows(rows);
  }

  return (
    <Box p={3}>
      <Grid container>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='name'
            label='Name For Ministry Page'
            size='small'
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Name For Ministry Page</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <FormEditorField
            name='summary'
            label='Summary For Ministry Page'
            defaultValue=''
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Summary For Ministry Page</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            height={100}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='pageTitle'
            label='Page Title For Testimonial Page'
            size='small'
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Page Title For Testimonial Page</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormNumberField
            name='order'
            label='Order'
            size='small'
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Order</Typography>}
            control={control}
            type={'number'}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormTextField
            name='url'
            label='Page Url'
            size='small'
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Page Url</Typography>}
            control={control}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <FormDropdownField
            name='ministryId'
            label='Show summary on Page / Ministry'
            size='small'
            disabled={ministries.length === 0}
            options={ministries}
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Show summary on Page / Ministry</Typography>}
            control={control}
            sx={{ background: 'white' }}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <FormEditorField
            name='sideContent'
            label='Side Content'
            defaultValue=''
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Side Content</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <FormTextField
            name='headLine'
            label='HeadLine'
            size='small'
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>HeadLine</Typography>}
            control={control}
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <FormEditorField
            name='content'
            label='Content'
            defaultValue=''
            labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Content</Typography>}
            control={control}
            rows={1}
            inputProps={{ sx: { backgroundColor: 'white' } }}
          />
        </Grid>
      </Grid>

      <Box my={3}>
        <CustomTableToolbar
          title='Content Blocks'
          numSelected={0}
          onDelete={() => {}}
          onAdd={() => {
            reset({
              order: 0,
              sideContent: '',
              content: '',
            });
            setAddModalVisible(true)
          }}
        />
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Order</TableCell>
                <TableCell width={80}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} sx={{ cursor: 'pointer' }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.order}</TableCell>
                  <TableCell width={80} align='right'>
                    <Box display='flex'>
                      <IconButton onClick={() => {
                        setSelectId(index);
                        setSelectBlock(row);
                        reset({
                          order: row.order,
                          sideContent: row.sideContent,
                          content: row.content,
                        });
                        setAddModalVisible(true);
                      }}>
                        <Tooltip id='button-report' title='Edit'>
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => deleteBlock(index)}>
                        <Tooltip id='button-report' title='Delete'>
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box my={4} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button variant='contained' color='primary' sx={{ width: '200px', height: '40px' }} onClick={handleSubmit(addTestimonial)}>Add</Button>
      </Box>

      <Modal open={addModalVisible} onClose={() => setAddModalVisible(false)}>
        <Box sx={modalBoxStyle}>
          <Box bgcolor='#e3e3e3' borderRadius={1} p={1}>
            <Typography fontWeight={500} textAlign='center'>{typeof selectId === 'number' ? 'Update Block Content' : 'Add Block Content'}</Typography>
          </Box>
          <Grid container>
            <Grid item xs={6} p={1}>
              <FormNumberField
                name='order'
                label='Order'
                size='small'
                labelComponent={<Typography fontSize={16} fontWeight={500} lineHeight={2}>Order</Typography>}
                control={blockControl}
                type={'number'}
                inputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={6} p={1} />
            <Grid item xs={6} p={1}>
              <FormEditorField
                name='sideContent'
                label='Side Content'
                defaultValue={selectBlock?.sideContent || ''}
                labelComponent={<Typography fontWeight={600}>Side Content</Typography>}
                control={blockControl}
                rows={1}
                inputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={6} p={1}>
              <FormEditorField
                name='content'
                label='Content'
                defaultValue={selectBlock?.content || ''}
                labelComponent={<Typography fontWeight={600}>Content</Typography>}
                control={blockControl}
                rows={1}
                inputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
          </Grid>
          <Box mt={4} display='flex' alignItems='center' justifyContent='flex-end'>
            <Button variant='contained' color='primary' sx={{ width: '200px', height: '40px' }} onClick={blockHandleSubmit(addBlock)}>{typeof selectId === 'number' ? 'Update' : 'Add'}</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default TestimonialNewContainer;
