import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Popper,
  Select,
  TableHead,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { visuallyHidden } from '@mui/utils';
import {
  StyledTableCell as TableCell,
  StyledTableRow as TableRow,
} from '../../libs/ui/StyledComponents';
import { RequestTableHeadCell } from './type';
import { BooleanFilterOperators, DateFilterOperators, Order, StringFilterOperators } from '../../const/type';

interface ResourceTableProps {
  headCells: RequestTableHeadCell[];
  numSelected: number;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  filterId: string | undefined;
  setFilterId: (value: string | undefined) => void;
  filterType: string;
  setFilterType: (value: string) => void;
  filterText: string | Date | boolean;
  setFilterText: (value: string | Date | boolean) => void;
  applyFilter: (value: string | undefined) => void;
}

export function RequestTableHead(props: ResourceTableProps) {
  const {
    headCells,
    numSelected,
    rowCount,
    onSelectAllClick,
    orderBy,
    order = 'desc',
    onRequestSort,
    filterId,
    filterType,
    setFilterType,
    filterText,
    setFilterText,
    applyFilter,
  } = props;

  const [tempFilterId, setTempFilterId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  //   setFilterId(id);
  // };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    if (filterId === id) {
      setFilterType('equalTo');
      setFilterText('');
      applyFilter(undefined);
    } else if (['status', 'organization', 'firstName', 'lastName', 'email', 'city', 'state'].includes(id)) {
      setFilterType('iLike');
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    } else if (['requestTime', 'shippedTime'].includes(id)) {
      setFilterType('lessThanEqual');
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setTempFilterId(id);
    }
  };

  const createSortHandler = (property: string) => {
    if (onRequestSort) onRequestSort(property);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount} checked={numSelected === rowCount} onChange={onSelectAllClick} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.textAlign ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={false}
            sx={{ width: headCell?.minWidth, minWidth: headCell?.minWidth, maxWidth: headCell.maxWidth }}
          >
            {headCell?.sortable ? (
              <Box display='flex' justifyContent='space-evenly'>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order ?? 'desc' : 'asc'}
                  onClick={() => {
                    if (headCell.id !== 'actions') createSortHandler(headCell.id);
                  }}
                  sx={{ display: 'flex' }}
                >
                  <Typography sx={{ ml: headCell?.ml, fontSize: '0.875rem', fontWeight: '500', lineHeight: '1.5rem', letterSpacing: '0.01071em', flexShrink: 1 }}>
                    {headCell.label}
                  </Typography>
                  {orderBy === headCell.id ?
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    :
                    null
                  }
                </TableSortLabel>

                {!['id'].includes(headCell.id) && <IconButton aria-describedby={id} type='button' onClick={(event) => handleClick(event, headCell.id)}>
                  {filterId === headCell.id ? <FilterAltIcon /> : <FilterAltOffIcon />}
                </IconButton>}
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box bgcolor='#fff' p={1} display='flex'>
                    <Select label='Operator' value={filterType} onChange={event => setFilterType(event.target.value)}>
                      {['status', 'organization', 'firstName', 'lastName', 'email', 'city', 'state'].includes(tempFilterId!) && StringFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['requestTime', 'shippedTime'].includes(tempFilterId!) && DateFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                      {['redFlag', 'yellowFlag'].includes(tempFilterId!) && BooleanFilterOperators.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    {['status', 'organization', 'firstName', 'lastName', 'email', 'city', 'state'].includes(tempFilterId!) &&
                      <TextField label='Value' sx={{ mx: 1 }} value={filterText} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.target.value)} />}
                    {['requestTime', 'shippedTime'].includes(tempFilterId!) &&
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker sx={{ width: 150, mx: 1 }} onChange={(value: any) => setFilterText(value)} />
                      </LocalizationProvider>}
                    {['redFlag', 'yellowFlag'].includes(tempFilterId!) &&
                      <Box sx={{ mx: 1 }}>
                        <Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.target.checked)} />
                      </Box>}
                    <Button variant='outlined' disabled={filterText === ''} onClick={() => applyFilter(tempFilterId)}>Apply</Button>
                  </Box>
                </Popper>
              </Box>
            ) : (
              <Typography sx={{ width: headCell?.minWidth, ml: headCell?.ml, fontSize: '0.875rem', fontWeight: '500', lineHeight: '1.5rem', letterSpacing: '0.01071em' }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
