import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IResource } from '../../graphql/generated/graphql';
import { NormalParagraphHTMLContent } from '../../components/common/NormalParagraph';

const imageStyle = {
  width: '100%',
  border: '15px solid #231f20',
  cursor: 'pointer',
};

export const MinistryResourceCard = ({ resource }: { resource: Partial<IResource> }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: '8px' }} onClick={() => navigate(`/resources/${resource?.id}`)}>
      <img src={resource.imageUrl} loading='lazy' style={imageStyle} alt='' />
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ fontFamily: 'Barlow', fontSize: '20px', fontWeight: 600, cursor: 'pointer' }}>
          <NormalParagraphHTMLContent label={`${resource?.title}`} />
        </Typography>
        <Typography sx={{ fontFamily: 'Barlow', fontSize: '16px', fontStyle: 'italic', cursor: 'pointer' }}>
          <NormalParagraphHTMLContent label={`${resource?.subTitle}`} />
        </Typography>
        <Typography sx={{ fontFamily: 'Barlow', fontSize: '18px', fontWeight: 600, cursor: 'pointer', textTransform: 'uppercase' }}>
          <NormalParagraphHTMLContent label={`${resource?.author}`} />
        </Typography>
        <Typography sx={{ marginTop: 1, fontFamily: 'Barlow', fontSize: '14px', cursor: 'pointer' }}>
          <NormalParagraphHTMLContent label={resource?.description ?? ''} />
        </Typography>
      </Box>
    </Box>
  );
};
