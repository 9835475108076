import { Box, Button, Modal, Typography } from '@mui/material';

interface DeleteModalProps {
  title: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const deleteModalStyle = {
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  boxShadow: 24,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const DeleteModal = ({ title, open, onCancel, onConfirm }: DeleteModalProps) => {
  return (
    <Modal
      open={open}
      aria-labelledby='modal-delete-title'
      aria-describedby='modal-delete-description'
      disableAutoFocus
    >
      <Box sx={deleteModalStyle}>
        <Typography id='delete--modal-description'>{title}</Typography>
        <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='outlined' onClick={onCancel}>Cancel</Button>
          <Button variant='contained' sx={{ ml: 2 }} onClick={onConfirm}>Confirm</Button>
        </Box>
      </Box>
    </Modal>
  );
};
